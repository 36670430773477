import classNames from "classnames";
import * as React from "react";
import styles from "./Row.module.css";

interface Props {
  className?: string;
  hasLargeMargin?: boolean;
  hasSmallMargin?: boolean;
  hasSmallMarginBottom?: boolean;
}

const Row: React.FC<Props> = ({
  children,
  className,
  hasLargeMargin,
  hasSmallMargin,
  hasSmallMarginBottom
}) => {
  return (
    <div
      className={classNames(
        styles.row,
        {
          [styles.largeMargin]: hasLargeMargin,
          [styles.smallMargin]: hasSmallMargin,
          [styles.smallMarginBottom]: hasSmallMarginBottom
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Row;
