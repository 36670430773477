import React from "react";
import { useTranslation } from "react-i18next";

import useAuth from "../../util/useAuth";
import Button, { ButtonSizes } from "./Button";
import Column from "./Column";
import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import Row from "./Row";

interface PasswordReminderModalContext {
  closePasswordReminderModal: () => void;
  isPasswordReminderModalOpen: boolean;
  openPasswordReminderModal: () => void;
}

export const PasswordReminderModalContext = React.createContext<
  PasswordReminderModalContext
>({
  closePasswordReminderModal: () => null,
  isPasswordReminderModalOpen: false,
  openPasswordReminderModal: () => null
});

export const PasswordReminderModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isPasswordReminderModalOpen, setIsModalOpen] = React.useState(false);

  const closePasswordReminderModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openPasswordReminderModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <PasswordReminderModalContext.Provider
      value={{
        closePasswordReminderModal,
        isPasswordReminderModalOpen,
        openPasswordReminderModal
      }}
    >
      {children}
    </PasswordReminderModalContext.Provider>
  );
};

export const usePasswordReminderModalContext = (): PasswordReminderModalContext =>
  React.useContext(PasswordReminderModalContext);

interface Props {
  isOpen: boolean;
  onClose: any;
}

const PasswordReminderModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(["login", "commons"]);
  const { getPasswordExpireInDays } = useAuth();
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} zIndex={1060}>
        <ModalContent>
          <ModalHeader title={t("titlePasswordReminder")} />
          <ModalBody>
            <Row>
              <Column>
                <label>
                  {t("textPasswordReminder", {
                    days: getPasswordExpireInDays()
                  })}
                </label>
              </Column>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleCloseModal}
              size={ButtonSizes.XSMALL_SECONDARY}
              type="button"
            >
              {t("buttonCancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default PasswordReminderModal;
