import classNames from "classnames";
import * as React from "react";
import styles from "./FormGroup.module.css";

interface Props {
  hasLargeMarginBottom?: boolean;
}

const FormGroup: React.FC<Props> = ({ children, hasLargeMarginBottom }) => {
  return (
    <div
      className={classNames(styles["form-group"], {
        [styles.largeMarginBottom]: hasLargeMarginBottom
      })}
    >
      {children}
    </div>
  );
};

export default FormGroup;
