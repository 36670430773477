import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { USER_ROLE } from "../../constants";
import {
  OperatorListQuery,
  RoleListQuery,
  User,
  UserListQuery,
  useUpdateTokenMutation
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import { translateValue } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import Container from "../Commons/Container";
import { useErrorModalContext } from "../Commons/ErrorModal";
import { useLoginModalContext } from "../Commons/LoginModal";
import NaviBelow from "../Commons/NaviBelow";
import Panel from "../Commons/Panel";
import Table from "../Commons/Table";
import ChangePasswordModal from "./ChangePasswordModal";
import DeleteUserModal from "./DeleteUserModal";
import Info from "./Info";
import NewUserModal from "./NewUserModal";

import UpdateTokenModal from "./UpdateTokenModal";
import UpdateUsernameModal from "./UpdateUsernameModal";

import styles from "./UserList.module.css";

enum MODAL_TYPE {
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  DELETE_USER = "DELETE_USER",
  NEW_USER = "NEW_USER",
  UPDATE_TOKEN = "UPDATE_TOKEN",
  UPDATE_USERNAME = "UPDATE_USERNAME"
}

interface Props {
  operatorsData: OperatorListQuery;
  rolesData: RoleListQuery;
  usersData: UserListQuery;
}

const UserList: React.FC<Props> = ({ operatorsData, rolesData, usersData }) => {
  const { getUserId, hasPermission } = useAuth();
  const [modalType, setModalType] = useState<MODAL_TYPE | null>(null);
  const [selectedUser, setUser] = useState<User | null>(null);
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const [updateToken, { data: tokenData }] = useUpdateTokenMutation();
  const { t } = useTranslation(["userList", "navbar", "commons"]);

  const handleCloseModal = () => {
    setModalType(null);
  };

  const handleChangePassword = (user: User) => {
    setUser(user);
    setModalType(MODAL_TYPE.CHANGE_PASSWORD);
  };

  const handleUpdateUsername = (user: User) => {
    setUser(user);
    setModalType(MODAL_TYPE.UPDATE_USERNAME);
  };

  const handleUserDelete = (user: User) => {
    setUser(user);
    setModalType(MODAL_TYPE.DELETE_USER);
  };

  const handleUpdateToken = async (id: string) => {
    try {
      await updateToken({ variables: { id } });
      setModalType(MODAL_TYPE.UPDATE_TOKEN);
    } catch (err) {
      handleError(err, openErrorModal, t, openLoginModal);
    }
  };

  const renderModal = () => {
    switch (modalType) {
      case MODAL_TYPE.NEW_USER:
        return (
          <NewUserModal
            isOpen={true}
            onClose={handleCloseModal}
            operatorOptions={operatorOptions}
            userRoleOptions={userRoleOptions}
          />
        );

      case MODAL_TYPE.CHANGE_PASSWORD:
        return (
          selectedUser && (
            <ChangePasswordModal
              isOpen={true}
              onClose={handleCloseModal}
              user={selectedUser}
            />
          )
        );

      case MODAL_TYPE.DELETE_USER:
        return (
          selectedUser && (
            <DeleteUserModal
              isOpen={true}
              onClose={handleCloseModal}
              user={selectedUser}
            />
          )
        );

      case MODAL_TYPE.UPDATE_TOKEN:
        return (
          <UpdateTokenModal
            isOpen={true}
            onClose={handleCloseModal}
            token={
              tokenData && tokenData.updateToken ? tokenData.updateToken : ""
            }
          />
        );
      case MODAL_TYPE.UPDATE_USERNAME:
        return (
          selectedUser && (
            <UpdateUsernameModal
              isOpen={true}
              onClose={handleCloseModal}
              user={selectedUser}
            />
          )
        );
    }
  };

  const allOperators = operatorsData.operators
    ? operatorsData.operators.results
    : [];
  const operatorOptions = allOperators.map(item => {
    return {
      label: item.name.fi,
      value: item.id
    };
  });
  const allRoles = rolesData.roles;
  const userRoleOptions = allRoles
    .filter(role =>
      hasPermission(PERMISSIONS.ALL_OPERATORS) ? true : role !== USER_ROLE.ADMIN
    )
    .map(role => ({
      label: translateValue("userRole", role, t),
      value: role
    }));

  const getOperatorName = (id: string) => {
    const operator = allOperators.find(item => {
      return item && item.id === id;
    });
    return operator && operator.name ? operator.name.fi : "";
  };

  const allUsers = usersData.users ? usersData.users : [];
  const sortedUsers = [...allUsers].sort((a: any, b: any) => {
    const operatorNameA = a.operatorId ? getOperatorName(a.operatorId) : "";
    const operatorNameB = b.operatorId ? getOperatorName(b.operatorId) : "";
    return operatorNameA >= operatorNameB ? 1 : -1;
  });

  return (
    <React.Fragment>
      <Breadcrumb
        name={t("navbar:users")}
        canGoback={false}
        canAddUser={hasPermission(PERMISSIONS.USER_CREATE)}
        onClickNewUser={() => setModalType(MODAL_TYPE.NEW_USER)}
      />
      <Container isContent={true}>
        <Info />
        <Panel hasBorder={true}>
          <Table isBordered={true} isCondensed={true} isInsidePanel={true}>
            <thead>
              <tr>
                <th>{t("labelUsername")}</th>
                <th>{t("labelOperator")}</th>
                <th>{t("labelRole")}</th>
                <th>{t("labelActions")}</th>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map(user => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{getOperatorName(user.operatorId || "")}</td>
                  <td>{translateValue("userRole", user.role || "", t)}</td>
                  <td>
                    <div className={styles.buttonContainer}>
                      {user.role === USER_ROLE.OPERATOR_API
                        ? hasPermission(PERMISSIONS.USER_UPDATE) && (
                            <Button
                              onClick={() => handleUpdateToken(user.id)}
                              size={ButtonSizes.XSMALL}
                            >
                              {t("buttonUpdateToken")}
                            </Button>
                          )
                        : hasPermission(PERMISSIONS.USER_UPDATE) && (
                            <>
                              <Button
                                onClick={() => handleChangePassword(user)}
                                size={ButtonSizes.XSMALL}
                                type="button"
                              >
                                {t("buttonChangePassword")}
                              </Button>
                              <Button
                                onClick={() => handleUpdateUsername(user)}
                                size={ButtonSizes.XSMALL}
                                type="button"
                              >
                                {t("buttonChangeUsername")}
                              </Button>
                            </>
                          )}

                      {hasPermission(PERMISSIONS.USER_UPDATE) &&
                        getUserId() !== user.id && (
                          <Button
                            onClick={() => handleUserDelete(user)}
                            size={ButtonSizes.XSMALL}
                          >
                            {t("buttonDelete")}
                          </Button>
                        )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel>
      </Container>
      <NaviBelow>
        {hasPermission(PERMISSIONS.USER_CREATE) && (
          <Button
            isResponsive={true}
            size={ButtonSizes.SMALL}
            onClick={() => setModalType(MODAL_TYPE.NEW_USER)}
            type="button"
          >
            {t("buttonAddNewUser")}
          </Button>
        )}
      </NaviBelow>
      {renderModal()}
    </React.Fragment>
  );
};
export default UserList;
