import * as React from "react";
import { Link } from "react-router-dom";
import { Facility, Hub as HubType } from "../../generated/graphql";
import FacilityList from "../FacilityList/FacilityList";
import styles from "./HubList.module.css";

interface Props {
  facilities: Facility[];
  hub: HubType;
}

const Hub: React.FC<Props> = ({ facilities, hub }) => (
  <React.Fragment>
    <tr className={styles.hubRow}>
      <th colSpan={4}>
        <Link to={`/hubs/${hub.id}`}>{hub && hub.name ? hub.name.fi : ""}</Link>
      </th>
    </tr>

    <FacilityList facilities={facilities} />
  </React.Fragment>
);

export default Hub;
