import classNames from "classnames";
import * as React from "react";
import styles from "./Button.module.css";

export enum ButtonSizes {
  LARGE = "l",
  MEDIUM = "m",
  SMALL = "s",
  SMALL_ALERT = "s-alert",
  XSMALL = "xs",
  XSMALL_ALERT = "xs-alert",
  XSMALL_SECONDARY = "xs-secondary"
}

export type ButtonSizeType = ButtonSizes;

interface Props {
  className?: string;
  disabled?: boolean;
  isActive?: boolean;
  isInputGroupButton?: boolean;
  isResponsive?: boolean;
  onClick?: (event?: any) => void;
  size?: ButtonSizeType;
  type?: "button" | "submit" | "reset" | undefined;
}

const Button: React.FC<Props> = ({
  children,
  className,
  disabled,
  isActive,
  isInputGroupButton,
  isResponsive,
  onClick,
  size,
  type = "button"
}) => {
  return (
    <button
      className={classNames(
        styles.btn,
        {
          [styles["btn-input-group"]]: isInputGroupButton,
          [styles.active]: isActive,
          [styles["btn-responsive"]]: isResponsive,
          [styles[`btn-${size}`]]: size
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
