import React from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteUserMutation,
  User,
  UserListDocument
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Button, { ButtonSizes } from "../Commons/Button";
import { useErrorModalContext } from "../Commons/ErrorModal";
import FormError from "../Commons/FormError";
import { useLoginModalContext } from "../Commons/LoginModal";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";

interface Props {
  isOpen: boolean;
  onClose: any;
  user: User;
}

const DeleteUserModal: React.FC<Props> = ({ user, onClose, isOpen }) => {
  const { hasPermission } = useAuth();
  const { t } = useTranslation(["userList", "commons"]);
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const [deleteUser] = useDeleteUserMutation();
  const constructError = React.useCallback(() => {
    return t("commons:error");
  }, [t]);

  const handleDeleteUser = async (e: any) => {
    e.preventDefault();
    try {
      await deleteUser({
        variables: { id: user.id },
        update(cache) {
          try {
            const userListData: any = cache.readQuery({
              query: UserListDocument
            });

            if (userListData.users) {
              const newUsers: any = [...userListData.users].filter(
                item => item.id !== user.id
              );

              cache.writeQuery({
                query: UserListDocument,
                data: { users: newUsers }
              });
            }
          } catch (err) {
            console.error(err);
          }
        }
      });
      onClose();
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        openLoginModal,
        constructError,
        setValidationErrors
      );
    }
  };
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Modal isCentered={true} isOpen={isOpen} width={400}>
        <ModalContent>
          <ModalBody>
            <FormError errors={validationErrors} />
            <div style={{ textAlign: "center" }}>
              {t("messageDoYouWantToDeleteUser")} "{user ? user.username : ""}
              "?
            </div>
          </ModalBody>

          <ModalFooter isCentered={true}>
            {hasPermission(PERMISSIONS.USER_UPDATE) && (
              <Button
                size={ButtonSizes.XSMALL}
                onClick={handleDeleteUser}
                type="button"
              >
                {t("buttonConfirmDelete")}
              </Button>
            )}
            <Button
              size={ButtonSizes.XSMALL_SECONDARY}
              onClick={handleCloseModal}
              type="button"
            >
              {t("buttonCancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
export default DeleteUserModal;
