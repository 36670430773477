import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  useCapacityTypeListQuery,
  useFacilityListQuery,
  useHubListQuery,
  useOperatorListQuery,
  useRegionsWithFacilitiesQuery,
  useRegionsWithHubsQuery,
  useUsageListQuery
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import useAuth from "../../util/useAuth";
import { useErrorModalContext } from "../Commons/ErrorModal";
import LoadingSpinner from "../Commons/LoadingSpinner";
import { useLoginModalContext } from "../Commons/LoginModal";
import Reports from "./Reports";

const ReportsContainer: React.FC = () => {
  const { t } = useTranslation(["commons"]);
  const { getUserRegionId } = useAuth();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const {
    data: capacityTypesData,
    error: capacityTypesError,
    loading: loadingCapacityTypes
  } = useCapacityTypeListQuery();
  const {
    data: facilitiesData,
    error: facilitiesErrors,
    loading: loadingFacilities
  } = useFacilityListQuery();
  const {
    data: hubsData,
    error: hubsErrors,
    loading: loadingHubs
  } = useHubListQuery();
  const {
    data: operatorsData,
    error: operatorsError,
    loading: loadingOperators
  } = useOperatorListQuery();
  const userRegionId = getUserRegionId();
  const { data: regionsWithFacilitiesData } = useRegionsWithFacilitiesQuery({
    skip: !userRegionId
  });
  const {
    data: regionsData,
    error: regionsError,
    loading: loadingRegions,
    refetch: refetchRegions
  } = useRegionsWithHubsQuery({
    errorPolicy: "all"
  });
  const {
    data: usagesData,
    error: usagesError,
    loading: loadingUsages
  } = useUsageListQuery();

  const loading =
    loadingCapacityTypes ||
    loadingFacilities ||
    loadingHubs ||
    loadingOperators ||
    loadingRegions ||
    loadingUsages;
  const error =
    !!capacityTypesError ||
    !!facilitiesErrors ||
    !!hubsErrors ||
    !!operatorsError ||
    !!regionsError ||
    !!usagesError;

  React.useEffect(() => {
    if (regionsError) {
      handleError(regionsError, openErrorModal, t, () => {
        openLoginModal({
          callbackFn: () => {
            refetchRegions();
          }
        });
      });
    }
  }, [openErrorModal, openLoginModal, refetchRegions, regionsError, t]);

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <div />;
  }

  return (
    <Reports
      capacityTypesData={capacityTypesData}
      facilitiesData={facilitiesData}
      hubsData={hubsData}
      operatorsData={operatorsData}
      regionsData={regionsData}
      regionsWithFacilitiesData={regionsWithFacilitiesData}
      usagesData={usagesData}
    />
  );
};

export default ReportsContainer;
