import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  useOperatorListQuery,
  useRoleListQuery,
  useUserListQuery
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import { useErrorModalContext } from "../Commons/ErrorModal";
import LoadingSpinner from "../Commons/LoadingSpinner";
import { useLoginModalContext } from "../Commons/LoginModal";
import UserList from "./UserList";

const UserListContainer = () => {
  const { t } = useTranslation(["commons"]);
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const {
    data: operatorsData,
    error: operatorsError,
    loading: loadingOperators
  } = useOperatorListQuery();
  const {
    data: rolesData,
    error: rolesError,
    loading: loadingRoles
  } = useRoleListQuery();
  const {
    data: usersData,
    error: usersErrors,
    loading: loadingUsers,
    refetch: refetchUsers
  } = useUserListQuery({
    // For some reason cache is not updated if error policy is not set to all/ignore
    errorPolicy: "all"
  });

  React.useEffect(() => {
    if (usersErrors) {
      handleError(usersErrors, openErrorModal, t, () =>
        openLoginModal({
          callbackFn: () => refetchUsers()
        })
      );
    }
  }, [openLoginModal, usersErrors, openErrorModal, refetchUsers, t]);

  if (loadingOperators || loadingRoles || loadingUsers) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (
    operatorsError ||
    !operatorsData ||
    rolesError ||
    !rolesData ||
    usersErrors ||
    !usersData
  ) {
    return <div />;
  }

  return (
    <>
      <UserList
        operatorsData={operatorsData}
        rolesData={rolesData}
        usersData={usersData}
      />
    </>
  );
};
export default UserListContainer;
