import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
  HubProfileQuery,
  useFacilityListQuery,
  UserListQuery
} from "../../generated/graphql";
import { formatDate } from "../../util/dateUtils";
import {
  getBboxFromPoint,
  getBounds,
  getCenter,
  getFacilityFeatures,
  getGeoJson,
  getHubFeatures,
  getMaxBbox
} from "../../util/mapUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Breadcrumb from "../Commons/Breadcrumb";
import BuiltCapacity from "../Commons/BuiltCapacity";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import Location from "../Commons/Location";
import NaviBelow from "../Commons/NaviBelow";
import Row from "../Commons/Row";
import HubBasicInfo from "./BasicInfo";
import FacilityTable from "./FacilityTable";

interface Params {
  id: string;
}

interface Props extends RouteComponentProps<Params> {
  hub: HubProfileQuery;
  usersData: UserListQuery | undefined;
}

const HubProfile: React.FC<Props> = ({ history, hub, match, usersData }) => {
  const { t } = useTranslation(["hubProfile", "commons"]);
  const { hasPermission } = useAuth();
  const ids = hub.hubDetails ? hub.hubDetails.facilityIds : [];

  const hubName = hub.hubDetails.name ? hub.hubDetails.name.fi : "";
  const modifierName = React.useMemo(() => {
    const users = usersData && usersData.users ? usersData.users : [];
    const user = users.find(item => item.id === hub.hubDetails.modifiedBy);

    return user ? user.username : null;
  }, [hub.hubDetails, usersData]);
  const { data: facilitiesData, error, loading } = useFacilityListQuery({
    skip: !ids.length,
    variables: { ids }
  });

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div>{t("commons:error")}</div>;
  }

  const facilities =
    facilitiesData && facilitiesData.facilities
      ? facilitiesData.facilities.facilities
      : [];

  const hubDetails = hub.hubDetails;
  const facilitiesGeoJson = getGeoJson(getFacilityFeatures(facilities));
  const hubGeoJson = getGeoJson(getHubFeatures([hubDetails]));
  const bboxes = [getBboxFromPoint(hubDetails.location.coordinates)];

  facilities.forEach(facility => {
    bboxes.push(facility.location.bbox);
  });

  const bbox = getMaxBbox(bboxes);
  const bounds = getBounds(bbox);
  const center = getCenter(bbox);

  const moveToHubEdit = () => {
    const {
      params: { id }
    } = match;
    history.push(`/hubs/edit/${id}`);
  };

  const moveToHubList = () => {
    history.push("/hubs");
  };

  return (
    <>
      <Breadcrumb
        name={hubName}
        canEdit={hasPermission(PERMISSIONS.HUB_UPDATE)}
        canGoback={true}
        onClickEdit={moveToHubEdit}
      />
      <Container isContent={true}>
        <HubBasicInfo data={hub} />
        <Location
          bounds={bounds}
          center={center}
          facilitiesGeoJson={facilitiesGeoJson}
          hubGeoJson={hubGeoJson}
          hubs={undefined}
        />
        <h3>{t("titleFacilities")}</h3>
        {!facilities.length ? (
          <p>{t("messageNoFacilities")}</p>
        ) : (
          <FacilityTable facilities={facilities} />
        )}

        {!!facilities.length && (
          <>
            <h3 className="h3-view">{t("titleBuiltCapacity")}</h3>
            <BuiltCapacity facilities={facilities} />
          </>
        )}

        {hubDetails.modifiedAt && (
          <Row hasLargeMargin={true}>
            <Column>
              <p>
                {t("messageModified")} {formatDate(hubDetails.modifiedAt, "dd.MM.yyyy HH:mm")}{" "}
                {modifierName ? `- ${modifierName}` : ""}
              </p>
            </Column>
          </Row>
        )}
      </Container>

      <NaviBelow>
        <Button
          isResponsive={true}
          onClick={moveToHubList}
          size={ButtonSizes.SMALL}
          type="button"
        >
          {t("buttonBack")}
        </Button>
        {hasPermission(PERMISSIONS.HUB_UPDATE) && (
          <Button
            isResponsive={true}
            onClick={moveToHubEdit}
            size={ButtonSizes.SMALL}
            type="button"
          >
            {t("buttonEdit")}
          </Button>
        )}
      </NaviBelow>
    </>
  );
};

export default withRouter(HubProfile);
