import * as React from "react";
import { useTranslation } from "react-i18next";
import { useContactDetailsQuery } from "../../generated/graphql";
import Column from "../Commons/Column";
import Row from "../Commons/Row";

interface Props {
  id: string;
  title: string;
}

const Contact: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation("commons");
  const { data, error, loading } = useContactDetailsQuery({
    skip: !props.id,
    variables: { id: props.id.toString() }
  });

  if (loading) {
    return <div>{t("loading")}</div>;
  }

  if (error || !data) {
    return <div>{t("error")}</div>;
  }

  const contactDetails = data.contactDetails;
  return (
    <>
      <h4>{props.title}</h4>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <p>
            <span>{contactDetails.name ? contactDetails.name.fi : "-"}</span>{" "}
            (fi)
          </p>
        </Column>
        <Column column={3}>
          <p>
            <span>{contactDetails.name ? contactDetails.name.sv : "-"}</span>{" "}
            (sv)
          </p>
        </Column>
        <Column column={3} isLastColumn={true}>
          <p>
            <span>{contactDetails.name ? contactDetails.name.en : "-"}</span>{" "}
            (en)
          </p>
        </Column>
      </Row>

      {contactDetails.phone && (
        <Row>
          <Column column={3} isFirstColumn={true}>
            <p>{contactDetails.phone}</p>
          </Column>
        </Row>
      )}

      {contactDetails.email && (
        <Row>
          <Column column={3} isFirstColumn={true}>
            <p>{contactDetails.email}</p>
          </Column>
        </Row>
      )}
    </>
  );
};

export default Contact;
