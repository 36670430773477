interface Enum {
  [key: string]: string | number | null;
}

export function toPascalCase(snakecase: string) {
  return (
    snakecase[0].toUpperCase() +
    snakecase
      .substr(1)
      .toLowerCase()
      .replace(/(_[a-z])/g, $1 => $1.toUpperCase().replace("_", ""))
  );
}

function pathToPascalCase(path: string) {
  return (
    path[0].toUpperCase() +
    path
      .substr(1)
      .replace(/(\.[a-z])/g, $1 => $1.toUpperCase().replace(".", ""))
      .replace(/\[(.+?)\]/g, "")
      .replace(".", "")
  );
}

export function translatePath(prefix: string, value: string, t: Function) {
  return t(prefix + pathToPascalCase(value));
}

export function translateValue(prefix: string, value: string, t: Function) {
  return t(prefix + toPascalCase(value));
}

export function translateEnum(prefix: string, values: Enum, t: Function) {
  return translateList(
    prefix,
    Object.keys(values).filter(key => values[key] && key !== "__typename"),
    t
  );
}

export function translateList(prefix: string, list: string[], t: Function) {
  return list.map(value => translateValue(prefix, value, t)).join(", ");
}
