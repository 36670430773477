import { FACILITY_TYPE } from "../constants";
import { Port } from "../generated/graphql";

interface Langs {
  fi: string;
  sv: string;
  en: string;
}

export const getStreetAddress = (
  lang: keyof Langs,
  ports?: Port[],
  type?: string
) => {
  if (ports && ports.length) {
    for (const port of ports) {
      if (
        (type === FACILITY_TYPE.BICYCLE && port.bicycle) ||
        (type !== FACILITY_TYPE.BICYCLE && port.entry && port.exit)
      ) {
        return (
          port.address &&
          port.address.streetAddress &&
          port.address.streetAddress[lang]
        );
      }
    }
  }
  return "";
};
