import gql from "graphql-tag";
import * as React from "react";
import * as ReactApollo from "react-apollo";
import * as ReactApolloHooks from "react-apollo-hooks";
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: "Address";
  streetAddress?: Maybe<LocalizedObject>;
  postalCode?: Maybe<Scalars["String"]>;
  city?: Maybe<LocalizedObject>;
};

export type AddressFeature = {
  __typename?: "AddressFeature";
  type: Scalars["String"];
  geometry: AddressGeometry;
  properties: AddressProperties;
};

export type AddressGeometry = {
  __typename?: "AddressGeometry";
  type: Scalars["String"];
  coordinates: Array<Scalars["Float"]>;
};

export type AddressInput = {
  streetAddress?: Maybe<LocalizedObjectInput>;
  postalCode?: Maybe<Scalars["String"]>;
  city?: Maybe<LocalizedObjectInput>;
};

export type AddressProperties = {
  __typename?: "AddressProperties";
  layer: Scalars["String"];
  source: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  housenumber?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  postalcode?: Maybe<Scalars["String"]>;
  confidence: Scalars["Float"];
  distance: Scalars["Float"];
  accuracy: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  locality?: Maybe<Scalars["String"]>;
  neighbourhood?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

export type AddressResponse = {
  __typename?: "AddressResponse";
  features: Array<AddressFeature>;
};

export type Capacity = {
  __typename?: "Capacity";
  CAR?: Maybe<Scalars["Int"]>;
  ELECTRIC_CAR?: Maybe<Scalars["Int"]>;
  BICYCLE_SECURE_SPACE?: Maybe<Scalars["Int"]>;
  DISABLED?: Maybe<Scalars["Int"]>;
  MOTORCYCLE?: Maybe<Scalars["Int"]>;
  BICYCLE?: Maybe<Scalars["Int"]>;
};

export type CapacityInput = {
  CAR?: Maybe<Scalars["Int"]>;
  ELECTRIC_CAR?: Maybe<Scalars["Int"]>;
  BICYCLE_SECURE_SPACE?: Maybe<Scalars["Int"]>;
  DISABLED?: Maybe<Scalars["Int"]>;
  MOTORCYCLE?: Maybe<Scalars["Int"]>;
  BICYCLE?: Maybe<Scalars["Int"]>;
};

export type Contact = {
  __typename?: "Contact";
  id: Scalars["ID"];
  name: LocalizedObject;
  operatorId?: Maybe<Scalars["ID"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  address?: Maybe<Address>;
  openingHours?: Maybe<LocalizedObject>;
  info?: Maybe<LocalizedObject>;
};

export type ContactEditInput = {
  id: Scalars["ID"];
  name: LocalizedObjectInput;
  operatorId?: Maybe<Scalars["ID"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressInput>;
  openingHours?: Maybe<LocalizedObjectInput>;
  info?: Maybe<LocalizedObjectInput>;
};

export type ContactInput = {
  name: LocalizedObjectInput;
  operatorId?: Maybe<Scalars["ID"]>;
  phone?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressInput>;
  openingHours?: Maybe<LocalizedObjectInput>;
  info?: Maybe<LocalizedObjectInput>;
};

export type ContactOptions = {
  __typename?: "ContactOptions";
  emergency?: Maybe<Scalars["ID"]>;
  operator?: Maybe<Scalars["ID"]>;
  service?: Maybe<Scalars["ID"]>;
};

export type ContactResponse = {
  __typename?: "ContactResponse";
  results: Array<Contact>;
  hasMore?: Maybe<Scalars["Boolean"]>;
};

export type ContactsInput = {
  emergency?: Maybe<Scalars["ID"]>;
  operator: Scalars["ID"];
  service?: Maybe<Scalars["ID"]>;
};

export type Crs = {
  __typename?: "Crs";
  type: Scalars["String"];
  properties: CrsName;
};

export type CrsInput = {
  type: Scalars["String"];
  properties: CrsNameInput;
};

export type CrsName = {
  __typename?: "CrsName";
  name: Scalars["String"];
};

export type CrsNameInput = {
  name: Scalars["String"];
};

export type Day = {
  __typename?: "Day";
  BUSINESS_DAY?: Maybe<FromUntil>;
  SATURDAY?: Maybe<FromUntil>;
  SUNDAY?: Maybe<FromUntil>;
};

export type FacilitiesGeoJsonResponse = {
  __typename?: "FacilitiesGeoJSONResponse";
  hasMore?: Maybe<Scalars["Boolean"]>;
  features: Array<FacilityGeoJson>;
  type?: Maybe<Scalars["String"]>;
};

export type FacilitiesSummary = {
  __typename?: "FacilitiesSummary";
  facilityCount?: Maybe<Scalars["Int"]>;
  capacities?: Maybe<Capacity>;
};

export type Facility = {
  __typename?: "Facility";
  id: Scalars["ID"];
  name: LocalizedObject;
  status?: Maybe<Scalars["String"]>;
  landings?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
  reuse_count?: Maybe<Scalars["Int"]>;
  operatorId?: Maybe<Scalars["ID"]>;
  builtCapacity?: Maybe<Capacity>;
  authenticationMethods?: Maybe<Array<Maybe<Scalars["String"]>>>;
  usages: Array<Scalars["String"]>;
  location: Location;
  deletedAt?: Maybe<Scalars["String"]>;
  softDeletedAt?: Maybe<Scalars["String"]>;
  surveyOptions?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type FacilityDeleteInput = {
  id: Scalars["ID"];
};

export type FacilityDetails = {
  __typename?: "FacilityDetails";
  id: Scalars["ID"];
  type?: Maybe<Scalars["String"]>;
  name: LocalizedObject;
  location: Location;
  operatorId: Scalars["ID"];
  status?: Maybe<Scalars["String"]>;
  pricingMethod: Scalars["String"];
  authenticationMethods?: Maybe<Array<Scalars["String"]>>;
  builtCapacity?: Maybe<Capacity>;
  usages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pricing: Array<Pricing>;
  unavailableCapacities: Array<UnavailableCapacity>;
  aliases: Array<Scalars["String"]>;
  ports: Array<Port>;
  services: Array<Scalars["String"]>;
  contacts: ContactOptions;
  paymentInfo: PaymentInfo;
  openingHours: OpeningHour;
  deletedAt?: Maybe<Scalars["String"]>;
  softDeletedAt?: Maybe<Scalars["String"]>;
  modifiedAt?: Maybe<Scalars["String"]>;
  modifiedBy?: Maybe<Scalars["ID"]>;
  surveyOptions?: Maybe<Array<Scalars["String"]>>;
};

export type FacilityEditInput = {
  id: Scalars["ID"];
  facilityType?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  name: LocalizedObjectInput;
  aliases: Array<Scalars["String"]>;
  operatorId: Scalars["ID"];
  status: Scalars["String"];
  location: LocationInput;
  ports: Array<PortInput>;
  openingHours: OpeningHoursInput;
  builtCapacity?: Maybe<CapacityInput>;
  unavailableCapacities: Array<UnavailableCapacityInput>;
  pricingMethod: Scalars["String"];
  pricing: Array<PricingInput>;
  services: Array<Scalars["String"]>;
  paymentInfo: PaymentInfoInput;
  contacts: ContactsInput;
  authenticationMethods: Array<Scalars["String"]>;
  timetable?: Maybe<Timetable>;
  surveyOptions: Array<Scalars["String"]>;
};

export type FacilityGeoJson = {
  __typename?: "FacilityGeoJSON";
  id: Scalars["ID"];
  geometry: Geometry;
  properties: Properties;
  type: Scalars["String"];
};

export type FacilityInput = {
  name: LocalizedObjectInput;
  type?: Maybe<Scalars["String"]>;
  aliases: Array<Scalars["String"]>;
  operatorId: Scalars["ID"];
  status: Scalars["String"];
  location: LocationInput;
  ports: Array<PortInput>;
  openingHours: OpeningHoursInput;
  builtCapacity?: Maybe<CapacityInput>;
  unavailableCapacities: Array<UnavailableCapacityInput>;
  pricingMethod: Scalars["String"];
  pricing: Array<PricingInput>;
  services: Array<Scalars["String"]>;
  paymentInfo: PaymentInfoInput;
  contacts: ContactsInput;
  facilityType?: Maybe<Scalars["String"]>;
  timetable?: Maybe<Timetable>;
  authenticationMethods: Array<Scalars["String"]>;
  surveyOptions: Array<Scalars["String"]>;
};

export type FacilityResponse = {
  __typename?: "FacilityResponse";
  cursor?: Maybe<Scalars["String"]>;
  totalCount?: Maybe<Scalars["Int"]>;
  hasMore: Scalars["Boolean"];
  facilities: Array<Facility>;
};

export type FacilityUsageReportInput = {
  capacityTypes?: Maybe<Array<Scalars["String"]>>;
  endDate?: Maybe<Array<Scalars["Int"]>>;
  facilities?: Maybe<Array<Scalars["ID"]>>;
  hubs?: Maybe<Array<Scalars["ID"]>>;
  interval?: Maybe<Scalars["Int"]>;
  operators?: Maybe<Array<Scalars["ID"]>>;
  regions?: Maybe<Array<Scalars["String"]>>;
  startDate?: Maybe<Array<Scalars["Int"]>>;
  usages?: Maybe<Array<Scalars["String"]>>;
};

export type FromUntil = {
  __typename?: "FromUntil";
  from: Scalars["String"];
  until: Scalars["String"];
};

export type Geometry = {
  __typename?: "Geometry";
  crs: Crs;
  bbox: Array<Maybe<Scalars["Float"]>>;
  coordinates: Array<Array<Array<Scalars["Float"]>>>;
  type: Scalars["String"];
};

export type Hub = {
  __typename?: "Hub";
  id: Scalars["ID"];
  name: LocalizedObject;
  location: HubLocation;
  facilityIds: Array<Scalars["ID"]>;
  address?: Maybe<Address>;
  hasMore?: Maybe<Scalars["Boolean"]>;
};

export type HubDetails = {
  __typename?: "HubDetails";
  id?: Maybe<Scalars["ID"]>;
  name: LocalizedObject;
  location: HubLocation;
  facilityIds: Array<Scalars["ID"]>;
  address?: Maybe<Address>;
  modifiedAt?: Maybe<Scalars["String"]>;
  modifiedBy?: Maybe<Scalars["ID"]>;
};

export type HubGeoJson = {
  __typename?: "HubGeoJSON";
  id?: Maybe<Scalars["ID"]>;
  geometry?: Maybe<Geometry>;
  properties?: Maybe<Properties>;
  type?: Maybe<Scalars["String"]>;
};

export type HubLocation = {
  __typename?: "HubLocation";
  crs: Crs;
  type: Scalars["String"];
  coordinates: Array<Scalars["Float"]>;
};

export type HubLocationInput = {
  type?: Maybe<Scalars["String"]>;
  coordinates: Array<Scalars["Float"]>;
};

export type HubResponse = {
  __typename?: "HubResponse";
  cursor?: Maybe<Scalars["String"]>;
  totalCount?: Maybe<Scalars["Int"]>;
  hasMore: Scalars["Boolean"];
  hubs: Array<Hub>;
};

export type HubsGeoJsonResponse = {
  __typename?: "HubsGeoJSONResponse";
  hasMore?: Maybe<Scalars["Boolean"]>;
  features?: Maybe<Array<Maybe<HubGeoJson>>>;
  type?: Maybe<Scalars["String"]>;
};

export type HubsSummary = {
  __typename?: "HubsSummary";
  hubCount?: Maybe<Scalars["Int"]>;
  capacities?: Maybe<Capacity>;
};

export type LocalizedObject = {
  __typename?: "LocalizedObject";
  fi: Scalars["String"];
  sv?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
};

export type LocalizedObjectInput = {
  fi: Scalars["String"];
  sv?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
};

export type Location = {
  __typename?: "Location";
  crs: Crs;
  bbox: Array<Scalars["Float"]>;
  type: Scalars["String"];
  coordinates: Array<Array<Array<Scalars["Float"]>>>;
};

export type LocationInput = {
  crs: CrsInput;
  bbox: Array<Scalars["Float"]>;
  type: Scalars["String"];
  coordinates: Array<Array<Array<Scalars["Float"]>>>;
};

export type Login = {
  __typename?: "Login";
  operatorId?: Maybe<Scalars["String"]>;
  passwordExpireInDays: Scalars["Int"];
  permissions: Array<Scalars["String"]>;
  regionId?: Maybe<Scalars["String"]>;
  role: Scalars["String"];
  token: Scalars["String"];
  userId: Scalars["ID"];
  username: Scalars["String"];
};

export type MaxUtilizationReportInput = {
  capacityTypes?: Maybe<Array<Scalars["String"]>>;
  endDate?: Maybe<Array<Scalars["Int"]>>;
  facilities?: Maybe<Array<Scalars["ID"]>>;
  hubs?: Maybe<Array<Scalars["ID"]>>;
  operators?: Maybe<Array<Scalars["ID"]>>;
  regions?: Maybe<Array<Scalars["String"]>>;
  startDate?: Maybe<Array<Scalars["Int"]>>;
  usages?: Maybe<Array<Scalars["String"]>>;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["String"]>;
  createFacility: FacilityDetails;
  deleteFacility: FacilityDetails;
  editFacility: FacilityDetails;
  softDeleteFacility: FacilityDetails;
  updateUtilization?: Maybe<Array<Maybe<UtilizationMutationResponse>>>;
  createHub: HubDetails;
  editHub: HubDetails;
  login?: Maybe<Login>;
  forgottenPassword?: Maybe<Scalars["String"]>;
  resetPassword?: Maybe<Scalars["String"]>;
  createOperator: Operator;
  editOperator: Operator;
  createContact: Contact;
  editContact: Contact;
  contactsByOperator: ContactResponse;
  address: AddressResponse;
  facilityUsageReport?: Maybe<Scalars["String"]>;
  maxUtilizationReport?: Maybe<Scalars["String"]>;
  hubsAndFacilitiesReport?: Maybe<Scalars["String"]>;
  requestLogReport?: Maybe<Scalars["String"]>;
  createUser?: Maybe<User>;
  changePassword?: Maybe<Scalars["String"]>;
  updateToken?: Maybe<Scalars["String"]>;
  updateUsername?: Maybe<Scalars["String"]>;
  deleteUser?: Maybe<Scalars["String"]>;
};

export type MutationCreateFacilityArgs = {
  facility?: Maybe<FacilityInput>;
};

export type MutationDeleteFacilityArgs = {
  facility?: Maybe<FacilityDeleteInput>;
};

export type MutationEditFacilityArgs = {
  facility?: Maybe<FacilityEditInput>;
};

export type MutationSoftDeleteFacilityArgs = {
  facility?: Maybe<FacilityDeleteInput>;
};

export type MutationUpdateUtilizationArgs = {
  facilityId: Scalars["ID"];
  newUtilizations?: Maybe<Array<Maybe<UtilizationInput>>>;
};

export type MutationCreateHubArgs = {
  address: AddressInput;
  facilityIds: Array<Scalars["ID"]>;
  location: HubLocationInput;
  name: LocalizedObjectInput;
};

export type MutationEditHubArgs = {
  id: Scalars["ID"];
  address: AddressInput;
  facilityIds: Array<Scalars["ID"]>;
  location: HubLocationInput;
  name: LocalizedObjectInput;
};

export type MutationLoginArgs = {
  password?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type MutationForgottenPasswordArgs = {
  email: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  token: Scalars["String"];
  password: Scalars["String"];
};

export type MutationCreateOperatorArgs = {
  operator?: Maybe<OperatorInput>;
};

export type MutationEditOperatorArgs = {
  operator?: Maybe<OperatorEditInput>;
};

export type MutationCreateContactArgs = {
  contact?: Maybe<ContactInput>;
};

export type MutationEditContactArgs = {
  contact?: Maybe<ContactEditInput>;
};

export type MutationContactsByOperatorArgs = {
  operatorId: Scalars["String"];
};

export type MutationAddressArgs = {
  lang: Scalars["String"];
  lat: Scalars["Float"];
  lon: Scalars["Float"];
};

export type MutationFacilityUsageReportArgs = {
  report?: Maybe<FacilityUsageReportInput>;
};

export type MutationMaxUtilizationReportArgs = {
  report?: Maybe<MaxUtilizationReportInput>;
};

export type MutationRequestLogReportArgs = {
  report?: Maybe<RequestLogReportInput>;
};

export type MutationCreateUserArgs = {
  user?: Maybe<UserInput>;
};

export type MutationChangePasswordArgs = {
  id?: Maybe<Scalars["ID"]>;
  newPassword?: Maybe<Scalars["String"]>;
};

export type MutationUpdateTokenArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateUsernameArgs = {
  id: Scalars["ID"];
  newUsername: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"];
};

export type OpeningHour = {
  __typename?: "OpeningHour";
  openNow: Scalars["Boolean"];
  byDayType?: Maybe<Day>;
  liipyByDayType?: Maybe<Day>;
  info?: Maybe<LocalizedObject>;
  url?: Maybe<LocalizedObject>;
};

export type OpeningHoursInput = {
  detail?: Maybe<LocalizedObjectInput>;
  url?: Maybe<LocalizedObjectInput>;
};

export type Operator = {
  __typename?: "Operator";
  id: Scalars["ID"];
  name: LocalizedObject;
  regionId?: Maybe<Scalars["Int"]>;
};

export type OperatorEditInput = {
  id: Scalars["ID"];
  name: LocalizedObjectInput;
  regionId?: Maybe<Scalars["Int"]>;
};

export type OperatorInput = {
  name: LocalizedObjectInput;
  regionId?: Maybe<Scalars["Int"]>;
};

export type OperatorResponse = {
  __typename?: "OperatorResponse";
  hasMore?: Maybe<Scalars["Boolean"]>;
  results: Array<Operator>;
};

export type PaymentInfo = {
  __typename?: "PaymentInfo";
  detail?: Maybe<LocalizedObject>;
  url?: Maybe<LocalizedObject>;
  paymentMethods: Array<Scalars["String"]>;
};

export type PaymentInfoInput = {
  detail?: Maybe<LocalizedObjectInput>;
  paymentMethods: Array<Scalars["String"]>;
  url?: Maybe<LocalizedObjectInput>;
};

export type Port = {
  __typename?: "Port";
  location: PortLocation;
  entry: Scalars["Boolean"];
  exit: Scalars["Boolean"];
  pedestrian: Scalars["Boolean"];
  bicycle: Scalars["Boolean"];
  address?: Maybe<PortAddress>;
};

export type PortAddress = {
  __typename?: "PortAddress";
  streetAddress?: Maybe<LocalizedObject>;
  city?: Maybe<LocalizedObject>;
};

export type PortAddressInput = {
  streetAddress?: Maybe<LocalizedObjectInput>;
  city?: Maybe<LocalizedObjectInput>;
};

export type PortInput = {
  location: PortLocationInput;
  entry: Scalars["Boolean"];
  exit: Scalars["Boolean"];
  pedestrian: Scalars["Boolean"];
  bicycle: Scalars["Boolean"];
  address: PortAddressInput;
};

export type PortLocation = {
  __typename?: "PortLocation";
  crs: Crs;
  type: Scalars["String"];
  coordinates: Array<Scalars["Float"]>;
};

export type PortLocationInput = {
  crs: CrsInput;
  type: Scalars["String"];
  coordinates: Array<Scalars["Float"]>;
};

export type Prediction = {
  __typename?: "Prediction";
  capacityType: Scalars["String"];
  usage: Scalars["String"];
  timestamp: Scalars["String"];
  spacesAvailable?: Maybe<Scalars["Int"]>;
  facilityId?: Maybe<Scalars["ID"]>;
  hubId?: Maybe<Scalars["ID"]>;
};

export type Pricing = {
  __typename?: "Pricing";
  usage: Scalars["String"];
  capacityType: Scalars["String"];
  maxCapacity: Scalars["Int"];
  dayType: Scalars["String"];
  time: PricingTimeRange;
  price?: Maybe<LocalizedObject>;
  priceExtra?: Maybe<Scalars["String"]>;
  priceOther?: Maybe<Scalars["String"]>;
};

export type PricingInput = {
  usage: Scalars["String"];
  capacityType: Scalars["String"];
  maxCapacity: Scalars["Int"];
  dayType: Scalars["String"];
  time: PricingTimeRangeInput;
  price?: Maybe<LocalizedObjectInput>;
  priceExtra?: Maybe<Scalars["String"]>;
  priceOther?: Maybe<Scalars["String"]>;
};

export type PricingTimeRange = {
  __typename?: "PricingTimeRange";
  from: Scalars["String"];
  until: Scalars["String"];
};

export type PricingTimeRangeInput = {
  from: Scalars["String"];
  until: Scalars["String"];
};

export type Properties = {
  __typename?: "Properties";
  name?: Maybe<LocalizedObject>;
  status?: Maybe<Scalars["String"]>;
  operatorId?: Maybe<Scalars["ID"]>;
  builtCapacity?: Maybe<Capacity>;
  usages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  facilityIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["String"]>;
  facilities: FacilityResponse;
  facilityDetails: FacilityDetails;
  facilitiesSummary?: Maybe<FacilitiesSummary>;
  facilityUtilization: Array<Utilization>;
  facilityPrediction: Array<Prediction>;
  facilitiesGeoJSON: FacilitiesGeoJsonResponse;
  facilityGeoJSON?: Maybe<FacilityGeoJson>;
  utilizations?: Maybe<Array<Maybe<Utilization>>>;
  hubs: HubResponse;
  hubDetails: HubDetails;
  hubsSmmary?: Maybe<HubsSummary>;
  hubUtilization?: Maybe<Array<Maybe<Utilization>>>;
  hubPrediction?: Maybe<Array<Maybe<Prediction>>>;
  hubsGeoJSON?: Maybe<HubsGeoJsonResponse>;
  hubGeoJSON?: Maybe<HubGeoJson>;
  operators: OperatorResponse;
  operatorDetails: Operator;
  contacts: ContactResponse;
  contactDetails: Contact;
  capacityTypes: Array<Scalars["String"]>;
  dayTypes: Array<Scalars["String"]>;
  facilityStatuses: Array<Scalars["String"]>;
  pricingMethods: Array<Scalars["String"]>;
  regions: Array<Maybe<Region>>;
  regionsWithFacilities: Array<RegionWithFacilities>;
  regionsWithHubs: Array<RegionWithHubs>;
  roles: Array<Scalars["String"]>;
  services: Array<Scalars["String"]>;
  usages: Array<Scalars["String"]>;
  paymentMethods: Array<Scalars["String"]>;
  authenticationMethods: Array<Scalars["String"]>;
  users: Array<User>;
  surveyOptions: Array<Scalars["String"]>;
};

export type QueryFacilitiesArgs = {
  after?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<Scalars["String"]>;
  sortDir?: Maybe<Scalars["String"]>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  ids?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  geometry?: Maybe<Scalars["String"]>;
  maxDistance?: Maybe<Scalars["Float"]>;
};

export type QueryFacilityDetailsArgs = {
  id: Scalars["ID"];
  geojson?: Maybe<Scalars["Boolean"]>;
};

export type QueryFacilitiesSummaryArgs = {
  summary?: Maybe<Scalars["Boolean"]>;
};

export type QueryFacilityUtilizationArgs = {
  id: Scalars["ID"];
};

export type QueryFacilityPredictionArgs = {
  id: Scalars["ID"];
  after?: Maybe<Scalars["Int"]>;
  at?: Maybe<Scalars["String"]>;
};

export type QueryFacilityGeoJsonArgs = {
  id: Scalars["ID"];
};

export type QueryHubsArgs = {
  after?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  sortBy?: Maybe<Scalars["String"]>;
  sortDir?: Maybe<Scalars["String"]>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  ids?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  facilityIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  geometry?: Maybe<Scalars["String"]>;
  maxDistance?: Maybe<Scalars["Float"]>;
};

export type QueryHubDetailsArgs = {
  id: Scalars["ID"];
  geojson?: Maybe<Scalars["Boolean"]>;
};

export type QueryHubsSmmaryArgs = {
  summary?: Maybe<Scalars["Boolean"]>;
};

export type QueryHubUtilizationArgs = {
  id: Scalars["ID"];
};

export type QueryHubPredictionArgs = {
  id: Scalars["ID"];
  after?: Maybe<Scalars["Int"]>;
  at?: Maybe<Scalars["String"]>;
};

export type QueryHubGeoJsonArgs = {
  id: Scalars["ID"];
};

export type QueryOperatorDetailsArgs = {
  id: Scalars["ID"];
};

export type QueryContactsArgs = {
  ids?: Maybe<Scalars["String"]>;
};

export type QueryContactDetailsArgs = {
  id: Scalars["ID"];
};

export type Region = {
  __typename?: "Region";
  id: Scalars["ID"];
  name?: Maybe<LocalizedObject>;
};

export type RegionWithFacilities = {
  __typename?: "RegionWithFacilities";
  id: Scalars["ID"];
  name?: Maybe<LocalizedObject>;
  facilityIds: Array<Scalars["ID"]>;
};

export type RegionWithHubs = {
  __typename?: "RegionWithHubs";
  id: Scalars["ID"];
  name?: Maybe<LocalizedObject>;
  hubIds: Array<Scalars["ID"]>;
};

export type RequestLogReportInput = {
  endDate?: Maybe<Array<Scalars["Int"]>>;
  requestLogInterval: Scalars["String"];
  startDate?: Maybe<Array<Scalars["Int"]>>;
};

export type Subscription = {
  __typename?: "Subscription";
  _empty?: Maybe<Scalars["String"]>;
};

export type Timetable = {
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
};

export type UnavailableCapacity = {
  __typename?: "UnavailableCapacity";
  capacityType: Scalars["String"];
  usage: Scalars["String"];
  capacity: Scalars["Int"];
};

export type UnavailableCapacityInput = {
  capacityType: Scalars["String"];
  usage: Scalars["String"];
  capacity: Scalars["Int"];
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  username?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  operatorId?: Maybe<Scalars["ID"]>;
};

export type UserInput = {
  role?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  operatorId?: Maybe<Scalars["ID"]>;
};

export type Utilization = {
  __typename?: "Utilization";
  facilityId: Scalars["ID"];
  capacityType: Scalars["String"];
  usage: Scalars["String"];
  timestamp?: Maybe<Scalars["String"]>;
  spacesAvailable?: Maybe<Scalars["Int"]>;
  capacity?: Maybe<Scalars["Int"]>;
  openNow?: Maybe<Scalars["Boolean"]>;
};

export type UtilizationInput = {
  capacityType?: Maybe<Scalars["String"]>;
  usage?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["String"]>;
  spacesAvailable?: Maybe<Scalars["Int"]>;
  capacity?: Maybe<Scalars["Int"]>;
};

export type UtilizationMutationResponse = {
  __typename?: "UtilizationMutationResponse";
  facilityId?: Maybe<Scalars["ID"]>;
  capacityType?: Maybe<Scalars["String"]>;
  usage?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["String"]>;
  spacesAvailable?: Maybe<Scalars["Int"]>;
  capacity?: Maybe<Scalars["Int"]>;
};
export type AuthenticationMethodListQueryVariables = {};

export type AuthenticationMethodListQuery = { __typename?: "Query" } & Pick<
  Query,
  "authenticationMethods"
>;

export type CapacityTypeListQueryVariables = {};

export type CapacityTypeListQuery = { __typename?: "Query" } & Pick<
  Query,
  "capacityTypes"
>;

export type LoginMutationVariables = {
  username?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
};

export type LoginMutation = { __typename?: "Mutation" } & {
  login: Maybe<
    { __typename?: "Login" } & Pick<
      Login,
      | "operatorId"
      | "passwordExpireInDays"
      | "permissions"
      | "regionId"
      | "role"
      | "token"
      | "userId"
      | "username"
    >
  >;
};

export type ContactListQueryVariables = {
  ids?: Maybe<Scalars["String"]>;
};

export type ContactListQuery = { __typename?: "Query" } & {
  contacts: { __typename?: "ContactResponse" } & Pick<
    ContactResponse,
    "hasMore"
  > & {
      results: Array<
        { __typename?: "Contact" } & Pick<
          Contact,
          "id" | "operatorId" | "phone" | "email"
        > & {
            name: { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "en" | "sv"
            >;
            address: Maybe<
              { __typename?: "Address" } & Pick<Address, "postalCode"> & {
                  streetAddress: Maybe<
                    { __typename?: "LocalizedObject" } & Pick<
                      LocalizedObject,
                      "fi" | "en" | "sv"
                    >
                  >;
                  city: Maybe<
                    { __typename?: "LocalizedObject" } & Pick<
                      LocalizedObject,
                      "fi" | "en" | "sv"
                    >
                  >;
                }
            >;
            openingHours: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
            info: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
          }
      >;
    };
};

export type ContactDetailsQueryVariables = {
  id: Scalars["ID"];
};

export type ContactDetailsQuery = { __typename?: "Query" } & {
  contactDetails: { __typename?: "Contact" } & Pick<
    Contact,
    "id" | "operatorId" | "phone" | "email"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "sv" | "en"
      >;
      address: Maybe<
        { __typename?: "Address" } & Pick<Address, "postalCode"> & {
            streetAddress: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
            city: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
          }
      >;
      openingHours: Maybe<
        { __typename?: "LocalizedObject" } & Pick<
          LocalizedObject,
          "fi" | "en" | "sv"
        >
      >;
      info: Maybe<
        { __typename?: "LocalizedObject" } & Pick<
          LocalizedObject,
          "fi" | "sv" | "en"
        >
      >;
    };
};

export type CreateContactMutationVariables = {
  contact: ContactInput;
};

export type CreateContactMutation = { __typename?: "Mutation" } & {
  createContact: { __typename?: "Contact" } & Pick<
    Contact,
    "id" | "operatorId" | "phone" | "email"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "en" | "sv"
      >;
      address: Maybe<
        { __typename?: "Address" } & Pick<Address, "postalCode"> & {
            streetAddress: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
            city: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
          }
      >;
      openingHours: Maybe<
        { __typename?: "LocalizedObject" } & Pick<
          LocalizedObject,
          "fi" | "en" | "sv"
        >
      >;
      info: Maybe<
        { __typename?: "LocalizedObject" } & Pick<
          LocalizedObject,
          "fi" | "en" | "sv"
        >
      >;
    };
};

export type EditContactMutationVariables = {
  contact: ContactEditInput;
};

export type EditContactMutation = { __typename?: "Mutation" } & {
  editContact: { __typename?: "Contact" } & Pick<
    Contact,
    "id" | "operatorId" | "phone" | "email"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "en" | "sv"
      >;
      address: Maybe<
        { __typename?: "Address" } & Pick<Address, "postalCode"> & {
            streetAddress: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
            city: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
          }
      >;
      openingHours: Maybe<
        { __typename?: "LocalizedObject" } & Pick<
          LocalizedObject,
          "fi" | "en" | "sv"
        >
      >;
      info: Maybe<
        { __typename?: "LocalizedObject" } & Pick<
          LocalizedObject,
          "fi" | "en" | "sv"
        >
      >;
    };
};

export type ContactsByOperatorMutationVariables = {
  operatorId: Scalars["String"];
};

export type ContactsByOperatorMutation = { __typename?: "Mutation" } & {
  contactsByOperator: { __typename?: "ContactResponse" } & Pick<
    ContactResponse,
    "hasMore"
  > & {
      results: Array<
        { __typename?: "Contact" } & Pick<
          Contact,
          "id" | "operatorId" | "phone" | "email"
        > & {
            name: { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "en" | "sv"
            >;
            address: Maybe<
              { __typename?: "Address" } & Pick<Address, "postalCode"> & {
                  streetAddress: Maybe<
                    { __typename?: "LocalizedObject" } & Pick<
                      LocalizedObject,
                      "fi" | "en" | "sv"
                    >
                  >;
                  city: Maybe<
                    { __typename?: "LocalizedObject" } & Pick<
                      LocalizedObject,
                      "fi" | "en" | "sv"
                    >
                  >;
                }
            >;
            openingHours: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
            info: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
          }
      >;
    };
};

export type DayTypeListQueryVariables = {};

export type DayTypeListQuery = { __typename?: "Query" } & Pick<
  Query,
  "dayTypes"
>;

export type CreateFacilityMutationVariables = {
  facility: FacilityInput;
};

export type CreateFacilityMutation = { __typename?: "Mutation" } & {
  createFacility: { __typename?: "FacilityDetails" } & Pick<
    FacilityDetails,
    | "id"
    | "type"
    | "aliases"
    | "services"
    | "status"
    | "operatorId"
    | "pricingMethod"
    | "authenticationMethods"
    | "surveyOptions"
    | "modifiedAt"
    | "modifiedBy"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "sv" | "en"
      >;
      location: { __typename?: "Location" } & Pick<
        Location,
        "bbox" | "type" | "coordinates"
      > & {
          crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
              properties: { __typename?: "CrsName" } & Pick<CrsName, "name">;
            };
        };
      builtCapacity: Maybe<
        { __typename?: "Capacity" } & Pick<
          Capacity,
          | "CAR"
          | "BICYCLE"
          | "ELECTRIC_CAR"
          | "BICYCLE_SECURE_SPACE"
          | "DISABLED"
          | "MOTORCYCLE"
          | "BICYCLE"
        >
      >;
      unavailableCapacities: Array<
        { __typename?: "UnavailableCapacity" } & Pick<
          UnavailableCapacity,
          "capacityType" | "usage" | "capacity"
        >
      >;
      ports: Array<
        { __typename?: "Port" } & Pick<
          Port,
          "entry" | "exit" | "pedestrian" | "bicycle"
        > & {
            location: { __typename?: "PortLocation" } & Pick<
              PortLocation,
              "type" | "coordinates"
            > & {
                crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
                    properties: { __typename?: "CrsName" } & Pick<
                      CrsName,
                      "name"
                    >;
                  };
              };
            address: Maybe<
              { __typename?: "PortAddress" } & {
                streetAddress: Maybe<
                  { __typename?: "LocalizedObject" } & Pick<
                    LocalizedObject,
                    "fi" | "sv" | "en"
                  >
                >;
                city: Maybe<
                  { __typename?: "LocalizedObject" } & Pick<
                    LocalizedObject,
                    "fi" | "sv" | "en"
                  >
                >;
              }
            >;
          }
      >;
      pricing: Array<
        { __typename?: "Pricing" } & Pick<
          Pricing,
          | "usage"
          | "capacityType"
          | "maxCapacity"
          | "dayType"
          | "priceExtra"
          | "priceOther"
        > & {
            time: { __typename?: "PricingTimeRange" } & Pick<
              PricingTimeRange,
              "from" | "until"
            >;
            price: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "sv" | "en"
              >
            >;
          }
      >;
      paymentInfo: { __typename?: "PaymentInfo" } & Pick<
        PaymentInfo,
        "paymentMethods"
      > & {
          detail: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
          url: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
        };
      contacts: { __typename?: "ContactOptions" } & Pick<
        ContactOptions,
        "emergency" | "operator" | "service"
      >;
      openingHours: { __typename?: "OpeningHour" } & Pick<
        OpeningHour,
        "openNow"
      > & {
          byDayType: Maybe<
            { __typename?: "Day" } & {
              BUSINESS_DAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SUNDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SATURDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
            }
          >;
          liipyByDayType: Maybe<
            { __typename?: "Day" } & {
              BUSINESS_DAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SUNDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SATURDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
            }
          >;
          info: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
          url: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
        };
    };
};

export type EditFacilityMutationVariables = {
  facility: FacilityEditInput;
};

export type EditFacilityMutation = { __typename?: "Mutation" } & {
  editFacility: { __typename?: "FacilityDetails" } & Pick<
    FacilityDetails,
    | "id"
    | "type"
    | "aliases"
    | "services"
    | "status"
    | "authenticationMethods"
    | "surveyOptions"
    | "operatorId"
    | "pricingMethod"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "sv" | "en"
      >;
      location: { __typename?: "Location" } & Pick<
        Location,
        "bbox" | "type" | "coordinates"
      > & {
          crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
              properties: { __typename?: "CrsName" } & Pick<CrsName, "name">;
            };
        };
      builtCapacity: Maybe<
        { __typename?: "Capacity" } & Pick<
          Capacity,
          | "CAR"
          | "BICYCLE"
          | "ELECTRIC_CAR"
          | "BICYCLE_SECURE_SPACE"
          | "DISABLED"
          | "MOTORCYCLE"
          | "BICYCLE"
        >
      >;
      unavailableCapacities: Array<
        { __typename?: "UnavailableCapacity" } & Pick<
          UnavailableCapacity,
          "capacityType" | "usage" | "capacity"
        >
      >;
      ports: Array<
        { __typename?: "Port" } & Pick<
          Port,
          "entry" | "exit" | "pedestrian" | "bicycle"
        > & {
            location: { __typename?: "PortLocation" } & Pick<
              PortLocation,
              "type" | "coordinates"
            > & {
                crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
                    properties: { __typename?: "CrsName" } & Pick<
                      CrsName,
                      "name"
                    >;
                  };
              };
            address: Maybe<
              { __typename?: "PortAddress" } & {
                streetAddress: Maybe<
                  { __typename?: "LocalizedObject" } & Pick<
                    LocalizedObject,
                    "fi" | "sv" | "en"
                  >
                >;
                city: Maybe<
                  { __typename?: "LocalizedObject" } & Pick<
                    LocalizedObject,
                    "fi" | "sv" | "en"
                  >
                >;
              }
            >;
          }
      >;
      pricing: Array<
        { __typename?: "Pricing" } & Pick<
          Pricing,
          | "usage"
          | "capacityType"
          | "maxCapacity"
          | "dayType"
          | "priceExtra"
          | "priceOther"
        > & {
            time: { __typename?: "PricingTimeRange" } & Pick<
              PricingTimeRange,
              "from" | "until"
            >;
            price: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "sv" | "en"
              >
            >;
          }
      >;
      paymentInfo: { __typename?: "PaymentInfo" } & Pick<
        PaymentInfo,
        "paymentMethods"
      > & {
          detail: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
          url: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
        };
      contacts: { __typename?: "ContactOptions" } & Pick<
        ContactOptions,
        "emergency" | "operator" | "service"
      >;
      openingHours: { __typename?: "OpeningHour" } & Pick<
        OpeningHour,
        "openNow"
      > & {
          byDayType: Maybe<
            { __typename?: "Day" } & {
              BUSINESS_DAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SUNDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SATURDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
            }
          >;
          liipyByDayType: Maybe<
            { __typename?: "Day" } & {
              BUSINESS_DAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SUNDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SATURDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
            }
          >;
          info: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
          url: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
        };
    };
};

export type FacilityListQueryVariables = {
  ids?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type FacilityListQuery = { __typename?: "Query" } & {
  facilities: { __typename?: "FacilityResponse" } & Pick<
    FacilityResponse,
    "totalCount" | "hasMore"
  > & {
      facilities: Array<
        { __typename?: "Facility" } & Pick<
          Facility,
          | "id"
          | "status"
          | "operatorId"
          | "usages"
          | "deletedAt"
          | "softDeletedAt"
        > & {
            name: { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >;
            location: { __typename?: "Location" } & Pick<
              Location,
              "bbox" | "type" | "coordinates"
            > & {
                crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
                    properties: { __typename?: "CrsName" } & Pick<
                      CrsName,
                      "name"
                    >;
                  };
              };
            builtCapacity: Maybe<
              { __typename?: "Capacity" } & Pick<
                Capacity,
                | "CAR"
                | "ELECTRIC_CAR"
                | "BICYCLE_SECURE_SPACE"
                | "DISABLED"
                | "MOTORCYCLE"
                | "BICYCLE"
              >
            >;
          }
      >;
    };
};

export type FacilitiesGeoJsonQueryVariables = {};

export type FacilitiesGeoJsonQuery = { __typename?: "Query" } & {
  facilitiesGeoJSON: { __typename?: "FacilitiesGeoJSONResponse" } & {
    features: Array<
      { __typename?: "FacilityGeoJSON" } & Pick<
        FacilityGeoJson,
        "id" | "type"
      > & {
          geometry: { __typename?: "Geometry" } & Pick<
            Geometry,
            "bbox" | "coordinates" | "type"
          > & {
              crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
                  properties: { __typename?: "CrsName" } & Pick<
                    CrsName,
                    "name"
                  >;
                };
            };
          properties: { __typename?: "Properties" } & Pick<
            Properties,
            "status" | "operatorId" | "usages" | "facilityIds"
          > & {
              name: Maybe<
                { __typename?: "LocalizedObject" } & Pick<
                  LocalizedObject,
                  "fi" | "sv" | "en"
                >
              >;
              builtCapacity: Maybe<
                { __typename?: "Capacity" } & Pick<
                  Capacity,
                  | "CAR"
                  | "ELECTRIC_CAR"
                  | "BICYCLE_SECURE_SPACE"
                  | "DISABLED"
                  | "MOTORCYCLE"
                  | "BICYCLE"
                >
              >;
            };
        }
    >;
  };
};

export type FacilityProfileQueryVariables = {
  id: Scalars["ID"];
};

export type FacilityProfileQuery = { __typename?: "Query" } & {
  facilityDetails: { __typename?: "FacilityDetails" } & Pick<
    FacilityDetails,
    | "id"
    | "type"
    | "aliases"
    | "services"
    | "status"
    | "operatorId"
    | "authenticationMethods"
    | "surveyOptions"
    | "pricingMethod"
    | "deletedAt"
    | "softDeletedAt"
    | "modifiedAt"
    | "modifiedBy"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "sv" | "en"
      >;
      location: { __typename?: "Location" } & Pick<
        Location,
        "bbox" | "type" | "coordinates"
      > & {
          crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
              properties: { __typename?: "CrsName" } & Pick<CrsName, "name">;
            };
        };
      builtCapacity: Maybe<
        { __typename?: "Capacity" } & Pick<
          Capacity,
          | "CAR"
          | "BICYCLE"
          | "ELECTRIC_CAR"
          | "BICYCLE_SECURE_SPACE"
          | "DISABLED"
          | "MOTORCYCLE"
          | "BICYCLE"
        >
      >;
      unavailableCapacities: Array<
        { __typename?: "UnavailableCapacity" } & Pick<
          UnavailableCapacity,
          "capacityType" | "usage" | "capacity"
        >
      >;
      ports: Array<
        { __typename?: "Port" } & Pick<
          Port,
          "entry" | "exit" | "pedestrian" | "bicycle"
        > & {
            location: { __typename?: "PortLocation" } & Pick<
              PortLocation,
              "type" | "coordinates"
            > & {
                crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
                    properties: { __typename?: "CrsName" } & Pick<
                      CrsName,
                      "name"
                    >;
                  };
              };
            address: Maybe<
              { __typename?: "PortAddress" } & {
                streetAddress: Maybe<
                  { __typename?: "LocalizedObject" } & Pick<
                    LocalizedObject,
                    "fi" | "sv" | "en"
                  >
                >;
                city: Maybe<
                  { __typename?: "LocalizedObject" } & Pick<
                    LocalizedObject,
                    "fi" | "sv" | "en"
                  >
                >;
              }
            >;
          }
      >;
      pricing: Array<
        { __typename?: "Pricing" } & Pick<
          Pricing,
          | "usage"
          | "capacityType"
          | "maxCapacity"
          | "dayType"
          | "priceExtra"
          | "priceOther"
        > & {
            time: { __typename?: "PricingTimeRange" } & Pick<
              PricingTimeRange,
              "from" | "until"
            >;
            price: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "sv" | "en"
              >
            >;
          }
      >;
      paymentInfo: { __typename?: "PaymentInfo" } & Pick<
        PaymentInfo,
        "paymentMethods"
      > & {
          detail: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
          url: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
        };
      contacts: { __typename?: "ContactOptions" } & Pick<
        ContactOptions,
        "emergency" | "operator" | "service"
      >;
      openingHours: { __typename?: "OpeningHour" } & Pick<
        OpeningHour,
        "openNow"
      > & {
          byDayType: Maybe<
            { __typename?: "Day" } & {
              BUSINESS_DAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SUNDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SATURDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
            }
          >;
          liipyByDayType: Maybe<
            { __typename?: "Day" } & {
              BUSINESS_DAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SUNDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
              SATURDAY: Maybe<
                { __typename?: "FromUntil" } & Pick<FromUntil, "from" | "until">
              >;
            }
          >;
          info: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
          url: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
        };
    };
};

export type OperatorDetailsQueryVariables = {
  id: Scalars["ID"];
};

export type OperatorDetailsQuery = { __typename?: "Query" } & {
  operatorDetails: { __typename?: "Operator" } & Pick<
    Operator,
    "id" | "regionId"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "sv" | "en"
      >;
    };
};

export type HubsByFacilityQueryVariables = {
  facilityIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type HubsByFacilityQuery = { __typename?: "Query" } & {
  hubs: { __typename?: "HubResponse" } & Pick<HubResponse, "totalCount"> & {
      hubs: Array<
        { __typename?: "Hub" } & Pick<Hub, "id" | "facilityIds"> & {
            name: { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >;
            location: { __typename?: "HubLocation" } & Pick<
              HubLocation,
              "coordinates" | "type"
            > & {
                crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
                    properties: { __typename?: "CrsName" } & Pick<
                      CrsName,
                      "name"
                    >;
                  };
              };
          }
      >;
    };
};

export type FacilityUtilizationQueryVariables = {
  id: Scalars["ID"];
};

export type FacilityUtilizationQuery = { __typename?: "Query" } & {
  facilityUtilization: Array<
    { __typename?: "Utilization" } & Pick<
      Utilization,
      | "facilityId"
      | "capacityType"
      | "usage"
      | "timestamp"
      | "spacesAvailable"
      | "capacity"
      | "openNow"
    >
  >;
};

export type DeleteFacilityMutationVariables = {
  facility: FacilityDeleteInput;
};

export type DeleteFacilityMutation = { __typename?: "Mutation" } & {
  deleteFacility: { __typename?: "FacilityDetails" } & Pick<
    FacilityDetails,
    "id"
  >;
};

export type SoftDeleteFacilityMutationVariables = {
  facility: FacilityDeleteInput;
};

export type SoftDeleteFacilityMutation = { __typename?: "Mutation" } & {
  softDeleteFacility: { __typename?: "FacilityDetails" } & Pick<
    FacilityDetails,
    "id" | "softDeletedAt"
  >;
};

export type FacilityStatusListQueryVariables = {};

export type FacilityStatusListQuery = { __typename?: "Query" } & Pick<
  Query,
  "facilityStatuses"
>;

export type GeocodedAddressMutationVariables = {
  lang: Scalars["String"];
  lat: Scalars["Float"];
  lon: Scalars["Float"];
};

export type GeocodedAddressMutation = { __typename?: "Mutation" } & {
  address: { __typename?: "AddressResponse" } & {
    features: Array<
      { __typename?: "AddressFeature" } & {
        properties: { __typename?: "AddressProperties" } & Pick<
          AddressProperties,
          "name" | "locality"
        >;
      }
    >;
  };
};

export type CreateHubMutationVariables = {
  address: AddressInput;
  facilityIds: Array<Scalars["ID"]>;
  location: HubLocationInput;
  name: LocalizedObjectInput;
};

export type CreateHubMutation = { __typename?: "Mutation" } & {
  createHub: { __typename?: "HubDetails" } & Pick<HubDetails, "id">;
};

export type EditHubMutationVariables = {
  id: Scalars["ID"];
  address: AddressInput;
  facilityIds: Array<Scalars["ID"]>;
  location: HubLocationInput;
  name: LocalizedObjectInput;
};

export type EditHubMutation = { __typename?: "Mutation" } & {
  editHub: { __typename?: "HubDetails" } & Pick<
    HubDetails,
    "id" | "facilityIds" | "modifiedAt" | "modifiedBy"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "sv" | "en"
      >;
      location: { __typename?: "HubLocation" } & Pick<
        HubLocation,
        "type" | "coordinates"
      > & {
          crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
              properties: { __typename?: "CrsName" } & Pick<CrsName, "name">;
            };
        };
      address: Maybe<
        { __typename?: "Address" } & Pick<Address, "postalCode"> & {
            streetAddress: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "sv" | "en"
              >
            >;
            city: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "sv" | "en"
              >
            >;
          }
      >;
    };
};

export type HubListQueryVariables = {};

export type HubListQuery = { __typename?: "Query" } & {
  hubs: { __typename?: "HubResponse" } & Pick<
    HubResponse,
    "totalCount" | "hasMore"
  > & {
      hubs: Array<
        { __typename?: "Hub" } & Pick<Hub, "id" | "facilityIds"> & {
            name: { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >;
            location: { __typename?: "HubLocation" } & Pick<
              HubLocation,
              "coordinates" | "type"
            > & {
                crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
                    properties: { __typename?: "CrsName" } & Pick<
                      CrsName,
                      "name"
                    >;
                  };
              };
          }
      >;
    };
};

export type HubProfileQueryVariables = {
  id: Scalars["ID"];
};

export type HubProfileQuery = { __typename?: "Query" } & {
  hubDetails: { __typename?: "HubDetails" } & Pick<
    HubDetails,
    "id" | "facilityIds" | "modifiedAt" | "modifiedBy"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "sv" | "en"
      >;
      location: { __typename?: "HubLocation" } & Pick<
        HubLocation,
        "type" | "coordinates"
      > & {
          crs: { __typename?: "Crs" } & Pick<Crs, "type"> & {
              properties: { __typename?: "CrsName" } & Pick<CrsName, "name">;
            };
        };
      address: Maybe<
        { __typename?: "Address" } & Pick<Address, "postalCode"> & {
            streetAddress: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
            city: Maybe<
              { __typename?: "LocalizedObject" } & Pick<
                LocalizedObject,
                "fi" | "en" | "sv"
              >
            >;
          }
      >;
    };
};

export type OperatorListQueryVariables = {};

export type OperatorListQuery = { __typename?: "Query" } & {
  operators: { __typename?: "OperatorResponse" } & Pick<
    OperatorResponse,
    "hasMore"
  > & {
      results: Array<
        { __typename?: "Operator" } & Pick<Operator, "id" | "regionId"> & {
            name: { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "en" | "sv"
            >;
          }
      >;
    };
};

export type CreateOperatorMutationVariables = {
  operator: OperatorInput;
};

export type CreateOperatorMutation = { __typename?: "Mutation" } & {
  createOperator: { __typename?: "Operator" } & Pick<
    Operator,
    "id" | "regionId"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "en" | "sv"
      >;
    };
};

export type EditOperatorMutationVariables = {
  operator: OperatorEditInput;
};

export type EditOperatorMutation = { __typename?: "Mutation" } & {
  editOperator: { __typename?: "Operator" } & Pick<
    Operator,
    "id" | "regionId"
  > & {
      name: { __typename?: "LocalizedObject" } & Pick<
        LocalizedObject,
        "fi" | "en" | "sv"
      >;
    };
};

export type ForgottenPasswordMutationVariables = {
  email: Scalars["String"];
};

export type ForgottenPasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "forgottenPassword"
>;

export type ResetPasswordMutationVariables = {
  token: Scalars["String"];
  password: Scalars["String"];
};

export type ResetPasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "resetPassword"
>;

export type PaymentMethodListQueryVariables = {};

export type PaymentMethodListQuery = { __typename?: "Query" } & Pick<
  Query,
  "paymentMethods"
>;

export type FacilityPredictionQueryVariables = {
  id: Scalars["ID"];
  after?: Maybe<Scalars["Int"]>;
  at?: Maybe<Scalars["String"]>;
};

export type FacilityPredictionQuery = { __typename?: "Query" } & {
  facilityPrediction: Array<
    { __typename?: "Prediction" } & Pick<
      Prediction,
      "capacityType" | "usage" | "timestamp" | "spacesAvailable" | "facilityId"
    >
  >;
};

export type PricingMethodListQueryVariables = {};

export type PricingMethodListQuery = { __typename?: "Query" } & Pick<
  Query,
  "pricingMethods"
>;

export type RegionListQueryVariables = {};

export type RegionListQuery = { __typename?: "Query" } & {
  regions: Array<
    Maybe<
      { __typename?: "Region" } & Pick<Region, "id"> & {
          name: Maybe<
            { __typename?: "LocalizedObject" } & Pick<
              LocalizedObject,
              "fi" | "sv" | "en"
            >
          >;
        }
    >
  >;
};

export type RegionsWithFacilitiesQueryVariables = {};

export type RegionsWithFacilitiesQuery = { __typename?: "Query" } & {
  regionsWithFacilities: Array<
    { __typename?: "RegionWithFacilities" } & Pick<
      RegionWithFacilities,
      "id" | "facilityIds"
    > & {
        name: Maybe<
          { __typename?: "LocalizedObject" } & Pick<
            LocalizedObject,
            "fi" | "sv" | "en"
          >
        >;
      }
  >;
};

export type RegionsWithHubsQueryVariables = {};

export type RegionsWithHubsQuery = { __typename?: "Query" } & {
  regionsWithHubs: Array<
    { __typename?: "RegionWithHubs" } & Pick<
      RegionWithHubs,
      "id" | "hubIds"
    > & {
        name: Maybe<
          { __typename?: "LocalizedObject" } & Pick<
            LocalizedObject,
            "fi" | "sv" | "en"
          >
        >;
      }
  >;
};

export type FacilityUsageReportMutationVariables = {
  report?: Maybe<FacilityUsageReportInput>;
};

export type FacilityUsageReportMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "facilityUsageReport"
>;

export type HubsAndFacilitiesReportMutationVariables = {};

export type HubsAndFacilitiesReportMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "hubsAndFacilitiesReport">;

export type MaxUtilizationReportMutationVariables = {
  report?: Maybe<MaxUtilizationReportInput>;
};

export type MaxUtilizationReportMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "maxUtilizationReport"
>;

export type RequestLogReportMutationVariables = {
  report?: Maybe<RequestLogReportInput>;
};

export type RequestLogReportMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "requestLogReport"
>;

export type RoleListQueryVariables = {};

export type RoleListQuery = { __typename?: "Query" } & Pick<Query, "roles">;

export type ServiceListQueryVariables = {};

export type ServiceListQuery = { __typename?: "Query" } & Pick<
  Query,
  "services"
>;

export type SurveyOptionsListQueryVariables = {};

export type SurveyOptionsListQuery = { __typename?: "Query" } & Pick<
  Query,
  "surveyOptions"
>;

export type UsageListQueryVariables = {};

export type UsageListQuery = { __typename?: "Query" } & Pick<Query, "usages">;

export type UserListQueryVariables = {};

export type UserListQuery = { __typename?: "Query" } & {
  users: Array<
    { __typename?: "User" } & Pick<
      User,
      "id" | "username" | "operatorId" | "role"
    >
  >;
};

export type OperatorNamesQueryVariables = {};

export type OperatorNamesQuery = { __typename?: "Query" } & {
  operators: { __typename?: "OperatorResponse" } & {
    results: Array<
      { __typename?: "Operator" } & Pick<Operator, "id"> & {
          name: { __typename?: "LocalizedObject" } & Pick<
            LocalizedObject,
            "fi"
          >;
        }
    >;
  };
};

export type ChangePasswordMutationVariables = {
  id: Scalars["ID"];
  newPassword: Scalars["String"];
};

export type ChangePasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "changePassword"
>;

export type DeleteUserMutationVariables = {
  id: Scalars["ID"];
};

export type DeleteUserMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteUser"
>;

export type CreateUserMutationVariables = {
  user: UserInput;
};

export type CreateUserMutation = { __typename?: "Mutation" } & {
  createUser: Maybe<
    { __typename?: "User" } & Pick<
      User,
      "id" | "username" | "role" | "operatorId"
    >
  >;
};

export type UpdateUsernameMutationVariables = {
  id: Scalars["ID"];
  newUsername: Scalars["String"];
};

export type UpdateUsernameMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "updateUsername"
>;

export type UpdateTokenMutationVariables = {
  id: Scalars["ID"];
};

export type UpdateTokenMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "updateToken"
>;

export const AuthenticationMethodListDocument = gql`
  query AuthenticationMethodList {
    authenticationMethods
  }
`;
export type AuthenticationMethodListComponentProps = Omit<
  ReactApollo.QueryProps<
    AuthenticationMethodListQuery,
    AuthenticationMethodListQueryVariables
  >,
  "query"
>;

export const AuthenticationMethodListComponent = (
  props: AuthenticationMethodListComponentProps
) => (
  <ReactApollo.Query<
    AuthenticationMethodListQuery,
    AuthenticationMethodListQueryVariables
  >
    query={AuthenticationMethodListDocument}
    {...props}
  />
);

export type AuthenticationMethodListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    AuthenticationMethodListQuery,
    AuthenticationMethodListQueryVariables
  >
> &
  TChildProps;
export function withAuthenticationMethodList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    AuthenticationMethodListQuery,
    AuthenticationMethodListQueryVariables,
    AuthenticationMethodListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    AuthenticationMethodListQuery,
    AuthenticationMethodListQueryVariables,
    AuthenticationMethodListProps<TChildProps>
  >(AuthenticationMethodListDocument, {
    alias: "withAuthenticationMethodList",
    ...operationOptions
  });
}

export function useAuthenticationMethodListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    AuthenticationMethodListQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    AuthenticationMethodListQuery,
    AuthenticationMethodListQueryVariables
  >(AuthenticationMethodListDocument, baseOptions);
}
export type AuthenticationMethodListQueryHookResult = ReturnType<
  typeof useAuthenticationMethodListQuery
>;
export const CapacityTypeListDocument = gql`
  query CapacityTypeList {
    capacityTypes
  }
`;
export type CapacityTypeListComponentProps = Omit<
  ReactApollo.QueryProps<CapacityTypeListQuery, CapacityTypeListQueryVariables>,
  "query"
>;

export const CapacityTypeListComponent = (
  props: CapacityTypeListComponentProps
) => (
  <ReactApollo.Query<CapacityTypeListQuery, CapacityTypeListQueryVariables>
    query={CapacityTypeListDocument}
    {...props}
  />
);

export type CapacityTypeListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<CapacityTypeListQuery, CapacityTypeListQueryVariables>
> &
  TChildProps;
export function withCapacityTypeList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CapacityTypeListQuery,
    CapacityTypeListQueryVariables,
    CapacityTypeListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    CapacityTypeListQuery,
    CapacityTypeListQueryVariables,
    CapacityTypeListProps<TChildProps>
  >(CapacityTypeListDocument, {
    alias: "withCapacityTypeList",
    ...operationOptions
  });
}

export function useCapacityTypeListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    CapacityTypeListQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    CapacityTypeListQuery,
    CapacityTypeListQueryVariables
  >(CapacityTypeListDocument, baseOptions);
}
export type CapacityTypeListQueryHookResult = ReturnType<
  typeof useCapacityTypeListQuery
>;
export const LoginDocument = gql`
  mutation Login($username: String, $password: String) {
    login(password: $password, username: $username) {
      operatorId
      passwordExpireInDays
      permissions
      regionId
      role
      token
      userId
      username
    }
  }
`;
export type LoginMutationFn = ReactApollo.MutationFn<
  LoginMutation,
  LoginMutationVariables
>;
export type LoginComponentProps = Omit<
  ReactApollo.MutationProps<LoginMutation, LoginMutationVariables>,
  "mutation"
>;

export const LoginComponent = (props: LoginComponentProps) => (
  <ReactApollo.Mutation<LoginMutation, LoginMutationVariables>
    mutation={LoginDocument}
    {...props}
  />
);

export type LoginProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<LoginMutation, LoginMutationVariables>
> &
  TChildProps;
export function withLogin<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    LoginMutation,
    LoginMutationVariables,
    LoginProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    LoginMutation,
    LoginMutationVariables,
    LoginProps<TChildProps>
  >(LoginDocument, {
    alias: "withLogin",
    ...operationOptions
  });
}

export function useLoginMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export const ContactListDocument = gql`
  query ContactList($ids: String) {
    contacts(ids: $ids) {
      hasMore
      results {
        id
        name {
          fi
          en
          sv
        }
        operatorId
        phone
        email
        address {
          streetAddress {
            fi
            en
            sv
          }
          postalCode
          city {
            fi
            en
            sv
          }
        }
        openingHours {
          fi
          en
          sv
        }
        info {
          fi
          en
          sv
        }
      }
    }
  }
`;
export type ContactListComponentProps = Omit<
  ReactApollo.QueryProps<ContactListQuery, ContactListQueryVariables>,
  "query"
>;

export const ContactListComponent = (props: ContactListComponentProps) => (
  <ReactApollo.Query<ContactListQuery, ContactListQueryVariables>
    query={ContactListDocument}
    {...props}
  />
);

export type ContactListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ContactListQuery, ContactListQueryVariables>
> &
  TChildProps;
export function withContactList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ContactListQuery,
    ContactListQueryVariables,
    ContactListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ContactListQuery,
    ContactListQueryVariables,
    ContactListProps<TChildProps>
  >(ContactListDocument, {
    alias: "withContactList",
    ...operationOptions
  });
}

export function useContactListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<ContactListQueryVariables>
) {
  return ReactApolloHooks.useQuery<ContactListQuery, ContactListQueryVariables>(
    ContactListDocument,
    baseOptions
  );
}
export type ContactListQueryHookResult = ReturnType<typeof useContactListQuery>;
export const ContactDetailsDocument = gql`
  query ContactDetails($id: ID!) {
    contactDetails(id: $id) {
      id
      name {
        fi
        sv
        en
      }
      operatorId
      phone
      email
      address {
        streetAddress {
          fi
          en
          sv
        }
        postalCode
        city {
          fi
          en
          sv
        }
      }
      openingHours {
        fi
        en
        sv
      }
      info {
        fi
        sv
        en
      }
    }
  }
`;
export type ContactDetailsComponentProps = Omit<
  ReactApollo.QueryProps<ContactDetailsQuery, ContactDetailsQueryVariables>,
  "query"
> &
  ({ variables: ContactDetailsQueryVariables; skip?: false } | { skip: true });

export const ContactDetailsComponent = (
  props: ContactDetailsComponentProps
) => (
  <ReactApollo.Query<ContactDetailsQuery, ContactDetailsQueryVariables>
    query={ContactDetailsDocument}
    {...props}
  />
);

export type ContactDetailsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ContactDetailsQuery, ContactDetailsQueryVariables>
> &
  TChildProps;
export function withContactDetails<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ContactDetailsQuery,
    ContactDetailsQueryVariables,
    ContactDetailsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ContactDetailsQuery,
    ContactDetailsQueryVariables,
    ContactDetailsProps<TChildProps>
  >(ContactDetailsDocument, {
    alias: "withContactDetails",
    ...operationOptions
  });
}

export function useContactDetailsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<ContactDetailsQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    ContactDetailsQuery,
    ContactDetailsQueryVariables
  >(ContactDetailsDocument, baseOptions);
}
export type ContactDetailsQueryHookResult = ReturnType<
  typeof useContactDetailsQuery
>;
export const CreateContactDocument = gql`
  mutation CreateContact($contact: ContactInput!) {
    createContact(contact: $contact) {
      id
      name {
        fi
        en
        sv
      }
      operatorId
      phone
      email
      address {
        streetAddress {
          fi
          en
          sv
        }
        postalCode
        city {
          fi
          en
          sv
        }
      }
      openingHours {
        fi
        en
        sv
      }
      info {
        fi
        en
        sv
      }
    }
  }
`;
export type CreateContactMutationFn = ReactApollo.MutationFn<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export type CreateContactComponentProps = Omit<
  ReactApollo.MutationProps<
    CreateContactMutation,
    CreateContactMutationVariables
  >,
  "mutation"
>;

export const CreateContactComponent = (props: CreateContactComponentProps) => (
  <ReactApollo.Mutation<CreateContactMutation, CreateContactMutationVariables>
    mutation={CreateContactDocument}
    {...props}
  />
);

export type CreateContactProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateContactMutation, CreateContactMutationVariables>
> &
  TChildProps;
export function withCreateContact<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateContactMutation,
    CreateContactMutationVariables,
    CreateContactProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateContactMutation,
    CreateContactMutationVariables,
    CreateContactProps<TChildProps>
  >(CreateContactDocument, {
    alias: "withCreateContact",
    ...operationOptions
  });
}

export function useCreateContactMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, baseOptions);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export const EditContactDocument = gql`
  mutation EditContact($contact: ContactEditInput!) {
    editContact(contact: $contact) {
      id
      name {
        fi
        en
        sv
      }
      operatorId
      phone
      email
      address {
        streetAddress {
          fi
          en
          sv
        }
        postalCode
        city {
          fi
          en
          sv
        }
      }
      openingHours {
        fi
        en
        sv
      }
      info {
        fi
        en
        sv
      }
    }
  }
`;
export type EditContactMutationFn = ReactApollo.MutationFn<
  EditContactMutation,
  EditContactMutationVariables
>;
export type EditContactComponentProps = Omit<
  ReactApollo.MutationProps<EditContactMutation, EditContactMutationVariables>,
  "mutation"
>;

export const EditContactComponent = (props: EditContactComponentProps) => (
  <ReactApollo.Mutation<EditContactMutation, EditContactMutationVariables>
    mutation={EditContactDocument}
    {...props}
  />
);

export type EditContactProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<EditContactMutation, EditContactMutationVariables>
> &
  TChildProps;
export function withEditContact<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    EditContactMutation,
    EditContactMutationVariables,
    EditContactProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    EditContactMutation,
    EditContactMutationVariables,
    EditContactProps<TChildProps>
  >(EditContactDocument, {
    alias: "withEditContact",
    ...operationOptions
  });
}

export function useEditContactMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    EditContactMutation,
    EditContactMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    EditContactMutation,
    EditContactMutationVariables
  >(EditContactDocument, baseOptions);
}
export type EditContactMutationHookResult = ReturnType<
  typeof useEditContactMutation
>;
export const ContactsByOperatorDocument = gql`
  mutation ContactsByOperator($operatorId: String!) {
    contactsByOperator(operatorId: $operatorId) {
      hasMore
      results {
        id
        name {
          fi
          en
          sv
        }
        operatorId
        phone
        email
        address {
          streetAddress {
            fi
            en
            sv
          }
          postalCode
          city {
            fi
            en
            sv
          }
        }
        openingHours {
          fi
          en
          sv
        }
        info {
          fi
          en
          sv
        }
      }
    }
  }
`;
export type ContactsByOperatorMutationFn = ReactApollo.MutationFn<
  ContactsByOperatorMutation,
  ContactsByOperatorMutationVariables
>;
export type ContactsByOperatorComponentProps = Omit<
  ReactApollo.MutationProps<
    ContactsByOperatorMutation,
    ContactsByOperatorMutationVariables
  >,
  "mutation"
>;

export const ContactsByOperatorComponent = (
  props: ContactsByOperatorComponentProps
) => (
  <ReactApollo.Mutation<
    ContactsByOperatorMutation,
    ContactsByOperatorMutationVariables
  >
    mutation={ContactsByOperatorDocument}
    {...props}
  />
);

export type ContactsByOperatorProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ContactsByOperatorMutation,
    ContactsByOperatorMutationVariables
  >
> &
  TChildProps;
export function withContactsByOperator<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ContactsByOperatorMutation,
    ContactsByOperatorMutationVariables,
    ContactsByOperatorProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ContactsByOperatorMutation,
    ContactsByOperatorMutationVariables,
    ContactsByOperatorProps<TChildProps>
  >(ContactsByOperatorDocument, {
    alias: "withContactsByOperator",
    ...operationOptions
  });
}

export function useContactsByOperatorMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    ContactsByOperatorMutation,
    ContactsByOperatorMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    ContactsByOperatorMutation,
    ContactsByOperatorMutationVariables
  >(ContactsByOperatorDocument, baseOptions);
}
export type ContactsByOperatorMutationHookResult = ReturnType<
  typeof useContactsByOperatorMutation
>;
export const DayTypeListDocument = gql`
  query DayTypeList {
    dayTypes
  }
`;
export type DayTypeListComponentProps = Omit<
  ReactApollo.QueryProps<DayTypeListQuery, DayTypeListQueryVariables>,
  "query"
>;

export const DayTypeListComponent = (props: DayTypeListComponentProps) => (
  <ReactApollo.Query<DayTypeListQuery, DayTypeListQueryVariables>
    query={DayTypeListDocument}
    {...props}
  />
);

export type DayTypeListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<DayTypeListQuery, DayTypeListQueryVariables>
> &
  TChildProps;
export function withDayTypeList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DayTypeListQuery,
    DayTypeListQueryVariables,
    DayTypeListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    DayTypeListQuery,
    DayTypeListQueryVariables,
    DayTypeListProps<TChildProps>
  >(DayTypeListDocument, {
    alias: "withDayTypeList",
    ...operationOptions
  });
}

export function useDayTypeListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<DayTypeListQueryVariables>
) {
  return ReactApolloHooks.useQuery<DayTypeListQuery, DayTypeListQueryVariables>(
    DayTypeListDocument,
    baseOptions
  );
}
export type DayTypeListQueryHookResult = ReturnType<typeof useDayTypeListQuery>;
export const CreateFacilityDocument = gql`
  mutation CreateFacility($facility: FacilityInput!) {
    createFacility(facility: $facility) {
      id
      type
      name {
        fi
        sv
        en
      }
      location {
        crs {
          type
          properties {
            name
          }
        }
        bbox
        type
        coordinates
      }
      aliases
      services
      status
      operatorId
      builtCapacity {
        CAR
        BICYCLE
        ELECTRIC_CAR
        BICYCLE_SECURE_SPACE
        DISABLED
        MOTORCYCLE
        BICYCLE
      }
      unavailableCapacities {
        capacityType
        usage
        capacity
      }
      ports {
        location {
          crs {
            type
            properties {
              name
            }
          }
          type
          coordinates
        }
        entry
        exit
        pedestrian
        bicycle
        address {
          streetAddress {
            fi
            sv
            en
          }
          city {
            fi
            sv
            en
          }
        }
      }
      pricingMethod
      pricing {
        usage
        capacityType
        maxCapacity
        dayType
        time {
          from
          until
        }
        price {
          fi
          sv
          en
        }
        priceExtra
        priceOther
      }
      paymentInfo {
        detail {
          fi
          sv
          en
        }
        paymentMethods
        url {
          fi
          sv
          en
        }
      }
      contacts {
        emergency
        operator
        service
      }
      openingHours {
        openNow
        byDayType {
          BUSINESS_DAY {
            from
            until
          }
          SUNDAY {
            from
            until
          }
          SATURDAY {
            from
            until
          }
        }
        liipyByDayType {
          BUSINESS_DAY {
            from
            until
          }
          SUNDAY {
            from
            until
          }
          SATURDAY {
            from
            until
          }
        }
        info {
          fi
          sv
          en
        }
        url {
          fi
          sv
          en
        }
      }
      authenticationMethods
      surveyOptions
      modifiedAt
      modifiedBy
    }
  }
`;
export type CreateFacilityMutationFn = ReactApollo.MutationFn<
  CreateFacilityMutation,
  CreateFacilityMutationVariables
>;
export type CreateFacilityComponentProps = Omit<
  ReactApollo.MutationProps<
    CreateFacilityMutation,
    CreateFacilityMutationVariables
  >,
  "mutation"
>;

export const CreateFacilityComponent = (
  props: CreateFacilityComponentProps
) => (
  <ReactApollo.Mutation<CreateFacilityMutation, CreateFacilityMutationVariables>
    mutation={CreateFacilityDocument}
    {...props}
  />
);

export type CreateFacilityProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    CreateFacilityMutation,
    CreateFacilityMutationVariables
  >
> &
  TChildProps;
export function withCreateFacility<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateFacilityMutation,
    CreateFacilityMutationVariables,
    CreateFacilityProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateFacilityMutation,
    CreateFacilityMutationVariables,
    CreateFacilityProps<TChildProps>
  >(CreateFacilityDocument, {
    alias: "withCreateFacility",
    ...operationOptions
  });
}

export function useCreateFacilityMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    CreateFacilityMutation,
    CreateFacilityMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    CreateFacilityMutation,
    CreateFacilityMutationVariables
  >(CreateFacilityDocument, baseOptions);
}
export type CreateFacilityMutationHookResult = ReturnType<
  typeof useCreateFacilityMutation
>;
export const EditFacilityDocument = gql`
  mutation EditFacility($facility: FacilityEditInput!) {
    editFacility(facility: $facility) {
      id
      type
      name {
        fi
        sv
        en
      }
      location {
        crs {
          type
          properties {
            name
          }
        }
        bbox
        type
        coordinates
      }
      aliases
      services
      status
      authenticationMethods
      surveyOptions
      operatorId
      builtCapacity {
        CAR
        BICYCLE
        ELECTRIC_CAR
        BICYCLE_SECURE_SPACE
        DISABLED
        MOTORCYCLE
        BICYCLE
      }
      unavailableCapacities {
        capacityType
        usage
        capacity
      }
      ports {
        location {
          crs {
            type
            properties {
              name
            }
          }
          type
          coordinates
        }
        entry
        exit
        pedestrian
        bicycle
        address {
          streetAddress {
            fi
            sv
            en
          }
          city {
            fi
            sv
            en
          }
        }
      }
      pricingMethod
      pricing {
        usage
        capacityType
        maxCapacity
        dayType
        time {
          from
          until
        }
        price {
          fi
          sv
          en
        }
        priceExtra
        priceOther
      }
      paymentInfo {
        detail {
          fi
          sv
          en
        }
        paymentMethods
        url {
          fi
          sv
          en
        }
      }
      contacts {
        emergency
        operator
        service
      }
      openingHours {
        openNow
        byDayType {
          BUSINESS_DAY {
            from
            until
          }
          SUNDAY {
            from
            until
          }
          SATURDAY {
            from
            until
          }
        }
        liipyByDayType {
          BUSINESS_DAY {
            from
            until
          }
          SUNDAY {
            from
            until
          }
          SATURDAY {
            from
            until
          }
        }
        info {
          fi
          sv
          en
        }
        url {
          fi
          sv
          en
        }
      }
    }
  }
`;
export type EditFacilityMutationFn = ReactApollo.MutationFn<
  EditFacilityMutation,
  EditFacilityMutationVariables
>;
export type EditFacilityComponentProps = Omit<
  ReactApollo.MutationProps<
    EditFacilityMutation,
    EditFacilityMutationVariables
  >,
  "mutation"
>;

export const EditFacilityComponent = (props: EditFacilityComponentProps) => (
  <ReactApollo.Mutation<EditFacilityMutation, EditFacilityMutationVariables>
    mutation={EditFacilityDocument}
    {...props}
  />
);

export type EditFacilityProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<EditFacilityMutation, EditFacilityMutationVariables>
> &
  TChildProps;
export function withEditFacility<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    EditFacilityMutation,
    EditFacilityMutationVariables,
    EditFacilityProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    EditFacilityMutation,
    EditFacilityMutationVariables,
    EditFacilityProps<TChildProps>
  >(EditFacilityDocument, {
    alias: "withEditFacility",
    ...operationOptions
  });
}

export function useEditFacilityMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    EditFacilityMutation,
    EditFacilityMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    EditFacilityMutation,
    EditFacilityMutationVariables
  >(EditFacilityDocument, baseOptions);
}
export type EditFacilityMutationHookResult = ReturnType<
  typeof useEditFacilityMutation
>;
export const FacilityListDocument = gql`
  query FacilityList($ids: [ID]) {
    facilities(ids: $ids) {
      totalCount
      facilities {
        id
        name {
          fi
          sv
          en
        }
        status
        location {
          crs {
            type
            properties {
              name
            }
          }
          bbox
          type
          coordinates
        }
        builtCapacity {
          CAR
          ELECTRIC_CAR
          BICYCLE_SECURE_SPACE
          DISABLED
          MOTORCYCLE
          BICYCLE
        }
        operatorId
        usages
        deletedAt
        softDeletedAt
      }
      hasMore
    }
  }
`;
export type FacilityListComponentProps = Omit<
  ReactApollo.QueryProps<FacilityListQuery, FacilityListQueryVariables>,
  "query"
>;

export const FacilityListComponent = (props: FacilityListComponentProps) => (
  <ReactApollo.Query<FacilityListQuery, FacilityListQueryVariables>
    query={FacilityListDocument}
    {...props}
  />
);

export type FacilityListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<FacilityListQuery, FacilityListQueryVariables>
> &
  TChildProps;
export function withFacilityList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    FacilityListQuery,
    FacilityListQueryVariables,
    FacilityListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    FacilityListQuery,
    FacilityListQueryVariables,
    FacilityListProps<TChildProps>
  >(FacilityListDocument, {
    alias: "withFacilityList",
    ...operationOptions
  });
}

export function useFacilityListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FacilityListQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    FacilityListQuery,
    FacilityListQueryVariables
  >(FacilityListDocument, baseOptions);
}
export type FacilityListQueryHookResult = ReturnType<
  typeof useFacilityListQuery
>;
export const FacilitiesGeoJsonDocument = gql`
  query FacilitiesGeoJSON {
    facilitiesGeoJSON {
      features {
        id
        geometry {
          bbox
          coordinates
          crs {
            type
            properties {
              name
            }
          }
          type
        }
        properties {
          name {
            fi
            sv
            en
          }
          status
          operatorId
          builtCapacity {
            CAR
            ELECTRIC_CAR
            BICYCLE_SECURE_SPACE
            DISABLED
            MOTORCYCLE
            BICYCLE
          }
          usages
          facilityIds
        }
        type
      }
    }
  }
`;
export type FacilitiesGeoJsonComponentProps = Omit<
  ReactApollo.QueryProps<
    FacilitiesGeoJsonQuery,
    FacilitiesGeoJsonQueryVariables
  >,
  "query"
>;

export const FacilitiesGeoJsonComponent = (
  props: FacilitiesGeoJsonComponentProps
) => (
  <ReactApollo.Query<FacilitiesGeoJsonQuery, FacilitiesGeoJsonQueryVariables>
    query={FacilitiesGeoJsonDocument}
    {...props}
  />
);

export type FacilitiesGeoJsonProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<FacilitiesGeoJsonQuery, FacilitiesGeoJsonQueryVariables>
> &
  TChildProps;
export function withFacilitiesGeoJson<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    FacilitiesGeoJsonQuery,
    FacilitiesGeoJsonQueryVariables,
    FacilitiesGeoJsonProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    FacilitiesGeoJsonQuery,
    FacilitiesGeoJsonQueryVariables,
    FacilitiesGeoJsonProps<TChildProps>
  >(FacilitiesGeoJsonDocument, {
    alias: "withFacilitiesGeoJson",
    ...operationOptions
  });
}

export function useFacilitiesGeoJsonQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    FacilitiesGeoJsonQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    FacilitiesGeoJsonQuery,
    FacilitiesGeoJsonQueryVariables
  >(FacilitiesGeoJsonDocument, baseOptions);
}
export type FacilitiesGeoJsonQueryHookResult = ReturnType<
  typeof useFacilitiesGeoJsonQuery
>;
export const FacilityProfileDocument = gql`
  query FacilityProfile($id: ID!) {
    facilityDetails(id: $id) {
      id
      type
      name {
        fi
        sv
        en
      }
      location {
        crs {
          type
          properties {
            name
          }
        }
        bbox
        type
        coordinates
      }
      aliases
      services
      status
      operatorId
      builtCapacity {
        CAR
        BICYCLE
        ELECTRIC_CAR
        BICYCLE_SECURE_SPACE
        DISABLED
        MOTORCYCLE
        BICYCLE
      }
      unavailableCapacities {
        capacityType
        usage
        capacity
      }
      authenticationMethods
      surveyOptions
      ports {
        location {
          crs {
            type
            properties {
              name
            }
          }
          type
          coordinates
        }
        entry
        exit
        pedestrian
        bicycle
        address {
          streetAddress {
            fi
            sv
            en
          }
          city {
            fi
            sv
            en
          }
        }
      }
      pricingMethod
      pricing {
        usage
        capacityType
        maxCapacity
        dayType
        time {
          from
          until
        }
        price {
          fi
          sv
          en
        }
        priceExtra
        priceOther
      }
      paymentInfo {
        detail {
          fi
          sv
          en
        }
        paymentMethods
        url {
          fi
          sv
          en
        }
      }
      contacts {
        emergency
        operator
        service
      }
      openingHours {
        openNow
        byDayType {
          BUSINESS_DAY {
            from
            until
          }
          SUNDAY {
            from
            until
          }
          SATURDAY {
            from
            until
          }
        }
        liipyByDayType {
          BUSINESS_DAY {
            from
            until
          }
          SUNDAY {
            from
            until
          }
          SATURDAY {
            from
            until
          }
        }
        info {
          fi
          sv
          en
        }
        url {
          fi
          sv
          en
        }
      }
      deletedAt
      softDeletedAt
      modifiedAt
      modifiedBy
    }
  }
`;
export type FacilityProfileComponentProps = Omit<
  ReactApollo.QueryProps<FacilityProfileQuery, FacilityProfileQueryVariables>,
  "query"
> &
  ({ variables: FacilityProfileQueryVariables; skip?: false } | { skip: true });

export const FacilityProfileComponent = (
  props: FacilityProfileComponentProps
) => (
  <ReactApollo.Query<FacilityProfileQuery, FacilityProfileQueryVariables>
    query={FacilityProfileDocument}
    {...props}
  />
);

export type FacilityProfileProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<FacilityProfileQuery, FacilityProfileQueryVariables>
> &
  TChildProps;
export function withFacilityProfile<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    FacilityProfileQuery,
    FacilityProfileQueryVariables,
    FacilityProfileProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    FacilityProfileQuery,
    FacilityProfileQueryVariables,
    FacilityProfileProps<TChildProps>
  >(FacilityProfileDocument, {
    alias: "withFacilityProfile",
    ...operationOptions
  });
}

export function useFacilityProfileQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FacilityProfileQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    FacilityProfileQuery,
    FacilityProfileQueryVariables
  >(FacilityProfileDocument, baseOptions);
}
export type FacilityProfileQueryHookResult = ReturnType<
  typeof useFacilityProfileQuery
>;
export const OperatorDetailsDocument = gql`
  query OperatorDetails($id: ID!) {
    operatorDetails(id: $id) {
      id
      name {
        fi
        sv
        en
      }
      regionId
    }
  }
`;
export type OperatorDetailsComponentProps = Omit<
  ReactApollo.QueryProps<OperatorDetailsQuery, OperatorDetailsQueryVariables>,
  "query"
> &
  ({ variables: OperatorDetailsQueryVariables; skip?: false } | { skip: true });

export const OperatorDetailsComponent = (
  props: OperatorDetailsComponentProps
) => (
  <ReactApollo.Query<OperatorDetailsQuery, OperatorDetailsQueryVariables>
    query={OperatorDetailsDocument}
    {...props}
  />
);

export type OperatorDetailsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<OperatorDetailsQuery, OperatorDetailsQueryVariables>
> &
  TChildProps;
export function withOperatorDetails<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    OperatorDetailsQuery,
    OperatorDetailsQueryVariables,
    OperatorDetailsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    OperatorDetailsQuery,
    OperatorDetailsQueryVariables,
    OperatorDetailsProps<TChildProps>
  >(OperatorDetailsDocument, {
    alias: "withOperatorDetails",
    ...operationOptions
  });
}

export function useOperatorDetailsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<OperatorDetailsQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    OperatorDetailsQuery,
    OperatorDetailsQueryVariables
  >(OperatorDetailsDocument, baseOptions);
}
export type OperatorDetailsQueryHookResult = ReturnType<
  typeof useOperatorDetailsQuery
>;
export const HubsByFacilityDocument = gql`
  query HubsByFacility($facilityIds: [ID]) {
    hubs(facilityIds: $facilityIds) {
      totalCount
      hubs {
        id
        name {
          fi
          sv
          en
        }
        facilityIds
        location {
          coordinates
          crs {
            type
            properties {
              name
            }
          }
          type
        }
      }
    }
  }
`;
export type HubsByFacilityComponentProps = Omit<
  ReactApollo.QueryProps<HubsByFacilityQuery, HubsByFacilityQueryVariables>,
  "query"
>;

export const HubsByFacilityComponent = (
  props: HubsByFacilityComponentProps
) => (
  <ReactApollo.Query<HubsByFacilityQuery, HubsByFacilityQueryVariables>
    query={HubsByFacilityDocument}
    {...props}
  />
);

export type HubsByFacilityProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<HubsByFacilityQuery, HubsByFacilityQueryVariables>
> &
  TChildProps;
export function withHubsByFacility<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    HubsByFacilityQuery,
    HubsByFacilityQueryVariables,
    HubsByFacilityProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    HubsByFacilityQuery,
    HubsByFacilityQueryVariables,
    HubsByFacilityProps<TChildProps>
  >(HubsByFacilityDocument, {
    alias: "withHubsByFacility",
    ...operationOptions
  });
}

export function useHubsByFacilityQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<HubsByFacilityQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    HubsByFacilityQuery,
    HubsByFacilityQueryVariables
  >(HubsByFacilityDocument, baseOptions);
}
export type HubsByFacilityQueryHookResult = ReturnType<
  typeof useHubsByFacilityQuery
>;
export const FacilityUtilizationDocument = gql`
  query FacilityUtilization($id: ID!) {
    facilityUtilization(id: $id) {
      facilityId
      capacityType
      usage
      timestamp
      spacesAvailable
      capacity
      openNow
    }
  }
`;
export type FacilityUtilizationComponentProps = Omit<
  ReactApollo.QueryProps<
    FacilityUtilizationQuery,
    FacilityUtilizationQueryVariables
  >,
  "query"
> &
  (
    | { variables: FacilityUtilizationQueryVariables; skip?: false }
    | { skip: true });

export const FacilityUtilizationComponent = (
  props: FacilityUtilizationComponentProps
) => (
  <ReactApollo.Query<
    FacilityUtilizationQuery,
    FacilityUtilizationQueryVariables
  >
    query={FacilityUtilizationDocument}
    {...props}
  />
);

export type FacilityUtilizationProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    FacilityUtilizationQuery,
    FacilityUtilizationQueryVariables
  >
> &
  TChildProps;
export function withFacilityUtilization<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    FacilityUtilizationQuery,
    FacilityUtilizationQueryVariables,
    FacilityUtilizationProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    FacilityUtilizationQuery,
    FacilityUtilizationQueryVariables,
    FacilityUtilizationProps<TChildProps>
  >(FacilityUtilizationDocument, {
    alias: "withFacilityUtilization",
    ...operationOptions
  });
}

export function useFacilityUtilizationQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    FacilityUtilizationQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    FacilityUtilizationQuery,
    FacilityUtilizationQueryVariables
  >(FacilityUtilizationDocument, baseOptions);
}
export type FacilityUtilizationQueryHookResult = ReturnType<
  typeof useFacilityUtilizationQuery
>;
export const DeleteFacilityDocument = gql`
  mutation DeleteFacility($facility: FacilityDeleteInput!) {
    deleteFacility(facility: $facility) {
      id
    }
  }
`;
export type DeleteFacilityMutationFn = ReactApollo.MutationFn<
  DeleteFacilityMutation,
  DeleteFacilityMutationVariables
>;
export type DeleteFacilityComponentProps = Omit<
  ReactApollo.MutationProps<
    DeleteFacilityMutation,
    DeleteFacilityMutationVariables
  >,
  "mutation"
>;

export const DeleteFacilityComponent = (
  props: DeleteFacilityComponentProps
) => (
  <ReactApollo.Mutation<DeleteFacilityMutation, DeleteFacilityMutationVariables>
    mutation={DeleteFacilityDocument}
    {...props}
  />
);

export type DeleteFacilityProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    DeleteFacilityMutation,
    DeleteFacilityMutationVariables
  >
> &
  TChildProps;
export function withDeleteFacility<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeleteFacilityMutation,
    DeleteFacilityMutationVariables,
    DeleteFacilityProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeleteFacilityMutation,
    DeleteFacilityMutationVariables,
    DeleteFacilityProps<TChildProps>
  >(DeleteFacilityDocument, {
    alias: "withDeleteFacility",
    ...operationOptions
  });
}

export function useDeleteFacilityMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    DeleteFacilityMutation,
    DeleteFacilityMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    DeleteFacilityMutation,
    DeleteFacilityMutationVariables
  >(DeleteFacilityDocument, baseOptions);
}
export type DeleteFacilityMutationHookResult = ReturnType<
  typeof useDeleteFacilityMutation
>;
export const SoftDeleteFacilityDocument = gql`
  mutation SoftDeleteFacility($facility: FacilityDeleteInput!) {
    softDeleteFacility(facility: $facility) {
      id
      softDeletedAt
    }
  }
`;
export type SoftDeleteFacilityMutationFn = ReactApollo.MutationFn<
  SoftDeleteFacilityMutation,
  SoftDeleteFacilityMutationVariables
>;
export type SoftDeleteFacilityComponentProps = Omit<
  ReactApollo.MutationProps<
    SoftDeleteFacilityMutation,
    SoftDeleteFacilityMutationVariables
  >,
  "mutation"
>;

export const SoftDeleteFacilityComponent = (
  props: SoftDeleteFacilityComponentProps
) => (
  <ReactApollo.Mutation<
    SoftDeleteFacilityMutation,
    SoftDeleteFacilityMutationVariables
  >
    mutation={SoftDeleteFacilityDocument}
    {...props}
  />
);

export type SoftDeleteFacilityProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    SoftDeleteFacilityMutation,
    SoftDeleteFacilityMutationVariables
  >
> &
  TChildProps;
export function withSoftDeleteFacility<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SoftDeleteFacilityMutation,
    SoftDeleteFacilityMutationVariables,
    SoftDeleteFacilityProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    SoftDeleteFacilityMutation,
    SoftDeleteFacilityMutationVariables,
    SoftDeleteFacilityProps<TChildProps>
  >(SoftDeleteFacilityDocument, {
    alias: "withSoftDeleteFacility",
    ...operationOptions
  });
}

export function useSoftDeleteFacilityMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    SoftDeleteFacilityMutation,
    SoftDeleteFacilityMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    SoftDeleteFacilityMutation,
    SoftDeleteFacilityMutationVariables
  >(SoftDeleteFacilityDocument, baseOptions);
}
export type SoftDeleteFacilityMutationHookResult = ReturnType<
  typeof useSoftDeleteFacilityMutation
>;
export const FacilityStatusListDocument = gql`
  query FacilityStatusList {
    facilityStatuses
  }
`;
export type FacilityStatusListComponentProps = Omit<
  ReactApollo.QueryProps<
    FacilityStatusListQuery,
    FacilityStatusListQueryVariables
  >,
  "query"
>;

export const FacilityStatusListComponent = (
  props: FacilityStatusListComponentProps
) => (
  <ReactApollo.Query<FacilityStatusListQuery, FacilityStatusListQueryVariables>
    query={FacilityStatusListDocument}
    {...props}
  />
);

export type FacilityStatusListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    FacilityStatusListQuery,
    FacilityStatusListQueryVariables
  >
> &
  TChildProps;
export function withFacilityStatusList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    FacilityStatusListQuery,
    FacilityStatusListQueryVariables,
    FacilityStatusListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    FacilityStatusListQuery,
    FacilityStatusListQueryVariables,
    FacilityStatusListProps<TChildProps>
  >(FacilityStatusListDocument, {
    alias: "withFacilityStatusList",
    ...operationOptions
  });
}

export function useFacilityStatusListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    FacilityStatusListQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    FacilityStatusListQuery,
    FacilityStatusListQueryVariables
  >(FacilityStatusListDocument, baseOptions);
}
export type FacilityStatusListQueryHookResult = ReturnType<
  typeof useFacilityStatusListQuery
>;
export const GeocodedAddressDocument = gql`
  mutation GeocodedAddress($lang: String!, $lat: Float!, $lon: Float!) {
    address(lang: $lang, lat: $lat, lon: $lon) {
      features {
        properties {
          name
          locality
        }
      }
    }
  }
`;
export type GeocodedAddressMutationFn = ReactApollo.MutationFn<
  GeocodedAddressMutation,
  GeocodedAddressMutationVariables
>;
export type GeocodedAddressComponentProps = Omit<
  ReactApollo.MutationProps<
    GeocodedAddressMutation,
    GeocodedAddressMutationVariables
  >,
  "mutation"
>;

export const GeocodedAddressComponent = (
  props: GeocodedAddressComponentProps
) => (
  <ReactApollo.Mutation<
    GeocodedAddressMutation,
    GeocodedAddressMutationVariables
  >
    mutation={GeocodedAddressDocument}
    {...props}
  />
);

export type GeocodedAddressProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    GeocodedAddressMutation,
    GeocodedAddressMutationVariables
  >
> &
  TChildProps;
export function withGeocodedAddress<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    GeocodedAddressMutation,
    GeocodedAddressMutationVariables,
    GeocodedAddressProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    GeocodedAddressMutation,
    GeocodedAddressMutationVariables,
    GeocodedAddressProps<TChildProps>
  >(GeocodedAddressDocument, {
    alias: "withGeocodedAddress",
    ...operationOptions
  });
}

export function useGeocodedAddressMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    GeocodedAddressMutation,
    GeocodedAddressMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    GeocodedAddressMutation,
    GeocodedAddressMutationVariables
  >(GeocodedAddressDocument, baseOptions);
}
export type GeocodedAddressMutationHookResult = ReturnType<
  typeof useGeocodedAddressMutation
>;
export const CreateHubDocument = gql`
  mutation CreateHub(
    $address: AddressInput!
    $facilityIds: [ID!]!
    $location: HubLocationInput!
    $name: LocalizedObjectInput!
  ) {
    createHub(
      address: $address
      facilityIds: $facilityIds
      location: $location
      name: $name
    ) {
      id
    }
  }
`;
export type CreateHubMutationFn = ReactApollo.MutationFn<
  CreateHubMutation,
  CreateHubMutationVariables
>;
export type CreateHubComponentProps = Omit<
  ReactApollo.MutationProps<CreateHubMutation, CreateHubMutationVariables>,
  "mutation"
>;

export const CreateHubComponent = (props: CreateHubComponentProps) => (
  <ReactApollo.Mutation<CreateHubMutation, CreateHubMutationVariables>
    mutation={CreateHubDocument}
    {...props}
  />
);

export type CreateHubProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateHubMutation, CreateHubMutationVariables>
> &
  TChildProps;
export function withCreateHub<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateHubMutation,
    CreateHubMutationVariables,
    CreateHubProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateHubMutation,
    CreateHubMutationVariables,
    CreateHubProps<TChildProps>
  >(CreateHubDocument, {
    alias: "withCreateHub",
    ...operationOptions
  });
}

export function useCreateHubMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    CreateHubMutation,
    CreateHubMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    CreateHubMutation,
    CreateHubMutationVariables
  >(CreateHubDocument, baseOptions);
}
export type CreateHubMutationHookResult = ReturnType<
  typeof useCreateHubMutation
>;
export const EditHubDocument = gql`
  mutation EditHub(
    $id: ID!
    $address: AddressInput!
    $facilityIds: [ID!]!
    $location: HubLocationInput!
    $name: LocalizedObjectInput!
  ) {
    editHub(
      id: $id
      address: $address
      facilityIds: $facilityIds
      location: $location
      name: $name
    ) {
      id
      name {
        fi
        sv
        en
      }
      location {
        crs {
          type
          properties {
            name
          }
        }
        type
        coordinates
      }
      address {
        streetAddress {
          fi
          sv
          en
        }
        postalCode
        city {
          fi
          sv
          en
        }
      }
      facilityIds
      modifiedAt
      modifiedBy
    }
  }
`;
export type EditHubMutationFn = ReactApollo.MutationFn<
  EditHubMutation,
  EditHubMutationVariables
>;
export type EditHubComponentProps = Omit<
  ReactApollo.MutationProps<EditHubMutation, EditHubMutationVariables>,
  "mutation"
>;

export const EditHubComponent = (props: EditHubComponentProps) => (
  <ReactApollo.Mutation<EditHubMutation, EditHubMutationVariables>
    mutation={EditHubDocument}
    {...props}
  />
);

export type EditHubProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<EditHubMutation, EditHubMutationVariables>
> &
  TChildProps;
export function withEditHub<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    EditHubMutation,
    EditHubMutationVariables,
    EditHubProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    EditHubMutation,
    EditHubMutationVariables,
    EditHubProps<TChildProps>
  >(EditHubDocument, {
    alias: "withEditHub",
    ...operationOptions
  });
}

export function useEditHubMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    EditHubMutation,
    EditHubMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    EditHubMutation,
    EditHubMutationVariables
  >(EditHubDocument, baseOptions);
}
export type EditHubMutationHookResult = ReturnType<typeof useEditHubMutation>;
export const HubListDocument = gql`
  query HubList {
    hubs {
      totalCount
      hubs {
        id
        name {
          fi
          sv
          en
        }
        facilityIds
        location {
          coordinates
          crs {
            type
            properties {
              name
            }
          }
          type
        }
      }
      hasMore
    }
  }
`;
export type HubListComponentProps = Omit<
  ReactApollo.QueryProps<HubListQuery, HubListQueryVariables>,
  "query"
>;

export const HubListComponent = (props: HubListComponentProps) => (
  <ReactApollo.Query<HubListQuery, HubListQueryVariables>
    query={HubListDocument}
    {...props}
  />
);

export type HubListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<HubListQuery, HubListQueryVariables>
> &
  TChildProps;
export function withHubList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    HubListQuery,
    HubListQueryVariables,
    HubListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    HubListQuery,
    HubListQueryVariables,
    HubListProps<TChildProps>
  >(HubListDocument, {
    alias: "withHubList",
    ...operationOptions
  });
}

export function useHubListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<HubListQueryVariables>
) {
  return ReactApolloHooks.useQuery<HubListQuery, HubListQueryVariables>(
    HubListDocument,
    baseOptions
  );
}
export type HubListQueryHookResult = ReturnType<typeof useHubListQuery>;
export const HubProfileDocument = gql`
  query HubProfile($id: ID!) {
    hubDetails(id: $id) {
      id
      name {
        fi
        sv
        en
      }
      location {
        crs {
          type
          properties {
            name
          }
        }
        type
        coordinates
      }
      address {
        streetAddress {
          fi
          en
          sv
        }
        postalCode
        city {
          fi
          en
          sv
        }
      }
      facilityIds
      modifiedAt
      modifiedBy
    }
  }
`;
export type HubProfileComponentProps = Omit<
  ReactApollo.QueryProps<HubProfileQuery, HubProfileQueryVariables>,
  "query"
> &
  ({ variables: HubProfileQueryVariables; skip?: false } | { skip: true });

export const HubProfileComponent = (props: HubProfileComponentProps) => (
  <ReactApollo.Query<HubProfileQuery, HubProfileQueryVariables>
    query={HubProfileDocument}
    {...props}
  />
);

export type HubProfileProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<HubProfileQuery, HubProfileQueryVariables>
> &
  TChildProps;
export function withHubProfile<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    HubProfileQuery,
    HubProfileQueryVariables,
    HubProfileProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    HubProfileQuery,
    HubProfileQueryVariables,
    HubProfileProps<TChildProps>
  >(HubProfileDocument, {
    alias: "withHubProfile",
    ...operationOptions
  });
}

export function useHubProfileQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<HubProfileQueryVariables>
) {
  return ReactApolloHooks.useQuery<HubProfileQuery, HubProfileQueryVariables>(
    HubProfileDocument,
    baseOptions
  );
}
export type HubProfileQueryHookResult = ReturnType<typeof useHubProfileQuery>;
export const OperatorListDocument = gql`
  query OperatorList {
    operators {
      results {
        id
        name {
          fi
          en
          sv
        }
        regionId
      }
      hasMore
    }
  }
`;
export type OperatorListComponentProps = Omit<
  ReactApollo.QueryProps<OperatorListQuery, OperatorListQueryVariables>,
  "query"
>;

export const OperatorListComponent = (props: OperatorListComponentProps) => (
  <ReactApollo.Query<OperatorListQuery, OperatorListQueryVariables>
    query={OperatorListDocument}
    {...props}
  />
);

export type OperatorListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<OperatorListQuery, OperatorListQueryVariables>
> &
  TChildProps;
export function withOperatorList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    OperatorListQuery,
    OperatorListQueryVariables,
    OperatorListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    OperatorListQuery,
    OperatorListQueryVariables,
    OperatorListProps<TChildProps>
  >(OperatorListDocument, {
    alias: "withOperatorList",
    ...operationOptions
  });
}

export function useOperatorListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<OperatorListQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    OperatorListQuery,
    OperatorListQueryVariables
  >(OperatorListDocument, baseOptions);
}
export type OperatorListQueryHookResult = ReturnType<
  typeof useOperatorListQuery
>;
export const CreateOperatorDocument = gql`
  mutation CreateOperator($operator: OperatorInput!) {
    createOperator(operator: $operator) {
      id
      name {
        fi
        en
        sv
      }
      regionId
    }
  }
`;
export type CreateOperatorMutationFn = ReactApollo.MutationFn<
  CreateOperatorMutation,
  CreateOperatorMutationVariables
>;
export type CreateOperatorComponentProps = Omit<
  ReactApollo.MutationProps<
    CreateOperatorMutation,
    CreateOperatorMutationVariables
  >,
  "mutation"
>;

export const CreateOperatorComponent = (
  props: CreateOperatorComponentProps
) => (
  <ReactApollo.Mutation<CreateOperatorMutation, CreateOperatorMutationVariables>
    mutation={CreateOperatorDocument}
    {...props}
  />
);

export type CreateOperatorProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    CreateOperatorMutation,
    CreateOperatorMutationVariables
  >
> &
  TChildProps;
export function withCreateOperator<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateOperatorMutation,
    CreateOperatorMutationVariables,
    CreateOperatorProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateOperatorMutation,
    CreateOperatorMutationVariables,
    CreateOperatorProps<TChildProps>
  >(CreateOperatorDocument, {
    alias: "withCreateOperator",
    ...operationOptions
  });
}

export function useCreateOperatorMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    CreateOperatorMutation,
    CreateOperatorMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    CreateOperatorMutation,
    CreateOperatorMutationVariables
  >(CreateOperatorDocument, baseOptions);
}
export type CreateOperatorMutationHookResult = ReturnType<
  typeof useCreateOperatorMutation
>;
export const EditOperatorDocument = gql`
  mutation EditOperator($operator: OperatorEditInput!) {
    editOperator(operator: $operator) {
      id
      name {
        fi
        en
        sv
      }
      regionId
    }
  }
`;
export type EditOperatorMutationFn = ReactApollo.MutationFn<
  EditOperatorMutation,
  EditOperatorMutationVariables
>;
export type EditOperatorComponentProps = Omit<
  ReactApollo.MutationProps<
    EditOperatorMutation,
    EditOperatorMutationVariables
  >,
  "mutation"
>;

export const EditOperatorComponent = (props: EditOperatorComponentProps) => (
  <ReactApollo.Mutation<EditOperatorMutation, EditOperatorMutationVariables>
    mutation={EditOperatorDocument}
    {...props}
  />
);

export type EditOperatorProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<EditOperatorMutation, EditOperatorMutationVariables>
> &
  TChildProps;
export function withEditOperator<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    EditOperatorMutation,
    EditOperatorMutationVariables,
    EditOperatorProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    EditOperatorMutation,
    EditOperatorMutationVariables,
    EditOperatorProps<TChildProps>
  >(EditOperatorDocument, {
    alias: "withEditOperator",
    ...operationOptions
  });
}

export function useEditOperatorMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    EditOperatorMutation,
    EditOperatorMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    EditOperatorMutation,
    EditOperatorMutationVariables
  >(EditOperatorDocument, baseOptions);
}
export type EditOperatorMutationHookResult = ReturnType<
  typeof useEditOperatorMutation
>;
export const ForgottenPasswordDocument = gql`
  mutation ForgottenPassword($email: String!) {
    forgottenPassword(email: $email)
  }
`;
export type ForgottenPasswordMutationFn = ReactApollo.MutationFn<
  ForgottenPasswordMutation,
  ForgottenPasswordMutationVariables
>;
export type ForgottenPasswordComponentProps = Omit<
  ReactApollo.MutationProps<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
  >,
  "mutation"
>;

export const ForgottenPasswordComponent = (
  props: ForgottenPasswordComponentProps
) => (
  <ReactApollo.Mutation<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
  >
    mutation={ForgottenPasswordDocument}
    {...props}
  />
);

export type ForgottenPasswordProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
  >
> &
  TChildProps;
export function withForgottenPassword<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables,
    ForgottenPasswordProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables,
    ForgottenPasswordProps<TChildProps>
  >(ForgottenPasswordDocument, {
    alias: "withForgottenPassword",
    ...operationOptions
  });
}

export function useForgottenPasswordMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
  >(ForgottenPasswordDocument, baseOptions);
}
export type ForgottenPasswordMutationHookResult = ReturnType<
  typeof useForgottenPasswordMutation
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;
export type ResetPasswordMutationFn = ReactApollo.MutationFn<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export type ResetPasswordComponentProps = Omit<
  ReactApollo.MutationProps<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
  "mutation"
>;

export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
  <ReactApollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables>
    mutation={ResetPasswordDocument}
    {...props}
  />
);

export type ResetPasswordProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<ResetPasswordMutation, ResetPasswordMutationVariables>
> &
  TChildProps;
export function withResetPassword<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
    ResetPasswordProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
    ResetPasswordProps<TChildProps>
  >(ResetPasswordDocument, {
    alias: "withResetPassword",
    ...operationOptions
  });
}

export function useResetPasswordMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export const PaymentMethodListDocument = gql`
  query PaymentMethodList {
    paymentMethods
  }
`;
export type PaymentMethodListComponentProps = Omit<
  ReactApollo.QueryProps<
    PaymentMethodListQuery,
    PaymentMethodListQueryVariables
  >,
  "query"
>;

export const PaymentMethodListComponent = (
  props: PaymentMethodListComponentProps
) => (
  <ReactApollo.Query<PaymentMethodListQuery, PaymentMethodListQueryVariables>
    query={PaymentMethodListDocument}
    {...props}
  />
);

export type PaymentMethodListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<PaymentMethodListQuery, PaymentMethodListQueryVariables>
> &
  TChildProps;
export function withPaymentMethodList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PaymentMethodListQuery,
    PaymentMethodListQueryVariables,
    PaymentMethodListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    PaymentMethodListQuery,
    PaymentMethodListQueryVariables,
    PaymentMethodListProps<TChildProps>
  >(PaymentMethodListDocument, {
    alias: "withPaymentMethodList",
    ...operationOptions
  });
}

export function usePaymentMethodListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    PaymentMethodListQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    PaymentMethodListQuery,
    PaymentMethodListQueryVariables
  >(PaymentMethodListDocument, baseOptions);
}
export type PaymentMethodListQueryHookResult = ReturnType<
  typeof usePaymentMethodListQuery
>;
export const FacilityPredictionDocument = gql`
  query FacilityPrediction($id: ID!, $after: Int, $at: String) {
    facilityPrediction(id: $id, after: $after, at: $at) {
      capacityType
      usage
      timestamp
      spacesAvailable
      facilityId
    }
  }
`;
export type FacilityPredictionComponentProps = Omit<
  ReactApollo.QueryProps<
    FacilityPredictionQuery,
    FacilityPredictionQueryVariables
  >,
  "query"
> &
  (
    | { variables: FacilityPredictionQueryVariables; skip?: false }
    | { skip: true });

export const FacilityPredictionComponent = (
  props: FacilityPredictionComponentProps
) => (
  <ReactApollo.Query<FacilityPredictionQuery, FacilityPredictionQueryVariables>
    query={FacilityPredictionDocument}
    {...props}
  />
);

export type FacilityPredictionProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    FacilityPredictionQuery,
    FacilityPredictionQueryVariables
  >
> &
  TChildProps;
export function withFacilityPrediction<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    FacilityPredictionQuery,
    FacilityPredictionQueryVariables,
    FacilityPredictionProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    FacilityPredictionQuery,
    FacilityPredictionQueryVariables,
    FacilityPredictionProps<TChildProps>
  >(FacilityPredictionDocument, {
    alias: "withFacilityPrediction",
    ...operationOptions
  });
}

export function useFacilityPredictionQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    FacilityPredictionQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    FacilityPredictionQuery,
    FacilityPredictionQueryVariables
  >(FacilityPredictionDocument, baseOptions);
}
export type FacilityPredictionQueryHookResult = ReturnType<
  typeof useFacilityPredictionQuery
>;
export const PricingMethodListDocument = gql`
  query PricingMethodList {
    pricingMethods
  }
`;
export type PricingMethodListComponentProps = Omit<
  ReactApollo.QueryProps<
    PricingMethodListQuery,
    PricingMethodListQueryVariables
  >,
  "query"
>;

export const PricingMethodListComponent = (
  props: PricingMethodListComponentProps
) => (
  <ReactApollo.Query<PricingMethodListQuery, PricingMethodListQueryVariables>
    query={PricingMethodListDocument}
    {...props}
  />
);

export type PricingMethodListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<PricingMethodListQuery, PricingMethodListQueryVariables>
> &
  TChildProps;
export function withPricingMethodList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PricingMethodListQuery,
    PricingMethodListQueryVariables,
    PricingMethodListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    PricingMethodListQuery,
    PricingMethodListQueryVariables,
    PricingMethodListProps<TChildProps>
  >(PricingMethodListDocument, {
    alias: "withPricingMethodList",
    ...operationOptions
  });
}

export function usePricingMethodListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    PricingMethodListQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    PricingMethodListQuery,
    PricingMethodListQueryVariables
  >(PricingMethodListDocument, baseOptions);
}
export type PricingMethodListQueryHookResult = ReturnType<
  typeof usePricingMethodListQuery
>;
export const RegionListDocument = gql`
  query RegionList {
    regions {
      id
      name {
        fi
        sv
        en
      }
    }
  }
`;
export type RegionListComponentProps = Omit<
  ReactApollo.QueryProps<RegionListQuery, RegionListQueryVariables>,
  "query"
>;

export const RegionListComponent = (props: RegionListComponentProps) => (
  <ReactApollo.Query<RegionListQuery, RegionListQueryVariables>
    query={RegionListDocument}
    {...props}
  />
);

export type RegionListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<RegionListQuery, RegionListQueryVariables>
> &
  TChildProps;
export function withRegionList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RegionListQuery,
    RegionListQueryVariables,
    RegionListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    RegionListQuery,
    RegionListQueryVariables,
    RegionListProps<TChildProps>
  >(RegionListDocument, {
    alias: "withRegionList",
    ...operationOptions
  });
}

export function useRegionListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<RegionListQueryVariables>
) {
  return ReactApolloHooks.useQuery<RegionListQuery, RegionListQueryVariables>(
    RegionListDocument,
    baseOptions
  );
}
export type RegionListQueryHookResult = ReturnType<typeof useRegionListQuery>;
export const RegionsWithFacilitiesDocument = gql`
  query RegionsWithFacilities {
    regionsWithFacilities {
      id
      name {
        fi
        sv
        en
      }
      facilityIds
    }
  }
`;
export type RegionsWithFacilitiesComponentProps = Omit<
  ReactApollo.QueryProps<
    RegionsWithFacilitiesQuery,
    RegionsWithFacilitiesQueryVariables
  >,
  "query"
>;

export const RegionsWithFacilitiesComponent = (
  props: RegionsWithFacilitiesComponentProps
) => (
  <ReactApollo.Query<
    RegionsWithFacilitiesQuery,
    RegionsWithFacilitiesQueryVariables
  >
    query={RegionsWithFacilitiesDocument}
    {...props}
  />
);

export type RegionsWithFacilitiesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    RegionsWithFacilitiesQuery,
    RegionsWithFacilitiesQueryVariables
  >
> &
  TChildProps;
export function withRegionsWithFacilities<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RegionsWithFacilitiesQuery,
    RegionsWithFacilitiesQueryVariables,
    RegionsWithFacilitiesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    RegionsWithFacilitiesQuery,
    RegionsWithFacilitiesQueryVariables,
    RegionsWithFacilitiesProps<TChildProps>
  >(RegionsWithFacilitiesDocument, {
    alias: "withRegionsWithFacilities",
    ...operationOptions
  });
}

export function useRegionsWithFacilitiesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    RegionsWithFacilitiesQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    RegionsWithFacilitiesQuery,
    RegionsWithFacilitiesQueryVariables
  >(RegionsWithFacilitiesDocument, baseOptions);
}
export type RegionsWithFacilitiesQueryHookResult = ReturnType<
  typeof useRegionsWithFacilitiesQuery
>;
export const RegionsWithHubsDocument = gql`
  query RegionsWithHubs {
    regionsWithHubs {
      id
      name {
        fi
        sv
        en
      }
      hubIds
    }
  }
`;
export type RegionsWithHubsComponentProps = Omit<
  ReactApollo.QueryProps<RegionsWithHubsQuery, RegionsWithHubsQueryVariables>,
  "query"
>;

export const RegionsWithHubsComponent = (
  props: RegionsWithHubsComponentProps
) => (
  <ReactApollo.Query<RegionsWithHubsQuery, RegionsWithHubsQueryVariables>
    query={RegionsWithHubsDocument}
    {...props}
  />
);

export type RegionsWithHubsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<RegionsWithHubsQuery, RegionsWithHubsQueryVariables>
> &
  TChildProps;
export function withRegionsWithHubs<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RegionsWithHubsQuery,
    RegionsWithHubsQueryVariables,
    RegionsWithHubsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    RegionsWithHubsQuery,
    RegionsWithHubsQueryVariables,
    RegionsWithHubsProps<TChildProps>
  >(RegionsWithHubsDocument, {
    alias: "withRegionsWithHubs",
    ...operationOptions
  });
}

export function useRegionsWithHubsQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<RegionsWithHubsQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    RegionsWithHubsQuery,
    RegionsWithHubsQueryVariables
  >(RegionsWithHubsDocument, baseOptions);
}
export type RegionsWithHubsQueryHookResult = ReturnType<
  typeof useRegionsWithHubsQuery
>;
export const FacilityUsageReportDocument = gql`
  mutation FacilityUsageReport($report: FacilityUsageReportInput) {
    facilityUsageReport(report: $report)
  }
`;
export type FacilityUsageReportMutationFn = ReactApollo.MutationFn<
  FacilityUsageReportMutation,
  FacilityUsageReportMutationVariables
>;
export type FacilityUsageReportComponentProps = Omit<
  ReactApollo.MutationProps<
    FacilityUsageReportMutation,
    FacilityUsageReportMutationVariables
  >,
  "mutation"
>;

export const FacilityUsageReportComponent = (
  props: FacilityUsageReportComponentProps
) => (
  <ReactApollo.Mutation<
    FacilityUsageReportMutation,
    FacilityUsageReportMutationVariables
  >
    mutation={FacilityUsageReportDocument}
    {...props}
  />
);

export type FacilityUsageReportProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    FacilityUsageReportMutation,
    FacilityUsageReportMutationVariables
  >
> &
  TChildProps;
export function withFacilityUsageReport<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    FacilityUsageReportMutation,
    FacilityUsageReportMutationVariables,
    FacilityUsageReportProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    FacilityUsageReportMutation,
    FacilityUsageReportMutationVariables,
    FacilityUsageReportProps<TChildProps>
  >(FacilityUsageReportDocument, {
    alias: "withFacilityUsageReport",
    ...operationOptions
  });
}

export function useFacilityUsageReportMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    FacilityUsageReportMutation,
    FacilityUsageReportMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    FacilityUsageReportMutation,
    FacilityUsageReportMutationVariables
  >(FacilityUsageReportDocument, baseOptions);
}
export type FacilityUsageReportMutationHookResult = ReturnType<
  typeof useFacilityUsageReportMutation
>;
export const HubsAndFacilitiesReportDocument = gql`
  mutation HubsAndFacilitiesReport {
    hubsAndFacilitiesReport
  }
`;
export type HubsAndFacilitiesReportMutationFn = ReactApollo.MutationFn<
  HubsAndFacilitiesReportMutation,
  HubsAndFacilitiesReportMutationVariables
>;
export type HubsAndFacilitiesReportComponentProps = Omit<
  ReactApollo.MutationProps<
    HubsAndFacilitiesReportMutation,
    HubsAndFacilitiesReportMutationVariables
  >,
  "mutation"
>;

export const HubsAndFacilitiesReportComponent = (
  props: HubsAndFacilitiesReportComponentProps
) => (
  <ReactApollo.Mutation<
    HubsAndFacilitiesReportMutation,
    HubsAndFacilitiesReportMutationVariables
  >
    mutation={HubsAndFacilitiesReportDocument}
    {...props}
  />
);

export type HubsAndFacilitiesReportProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    HubsAndFacilitiesReportMutation,
    HubsAndFacilitiesReportMutationVariables
  >
> &
  TChildProps;
export function withHubsAndFacilitiesReport<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    HubsAndFacilitiesReportMutation,
    HubsAndFacilitiesReportMutationVariables,
    HubsAndFacilitiesReportProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    HubsAndFacilitiesReportMutation,
    HubsAndFacilitiesReportMutationVariables,
    HubsAndFacilitiesReportProps<TChildProps>
  >(HubsAndFacilitiesReportDocument, {
    alias: "withHubsAndFacilitiesReport",
    ...operationOptions
  });
}

export function useHubsAndFacilitiesReportMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    HubsAndFacilitiesReportMutation,
    HubsAndFacilitiesReportMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    HubsAndFacilitiesReportMutation,
    HubsAndFacilitiesReportMutationVariables
  >(HubsAndFacilitiesReportDocument, baseOptions);
}
export type HubsAndFacilitiesReportMutationHookResult = ReturnType<
  typeof useHubsAndFacilitiesReportMutation
>;
export const MaxUtilizationReportDocument = gql`
  mutation MaxUtilizationReport($report: MaxUtilizationReportInput) {
    maxUtilizationReport(report: $report)
  }
`;
export type MaxUtilizationReportMutationFn = ReactApollo.MutationFn<
  MaxUtilizationReportMutation,
  MaxUtilizationReportMutationVariables
>;
export type MaxUtilizationReportComponentProps = Omit<
  ReactApollo.MutationProps<
    MaxUtilizationReportMutation,
    MaxUtilizationReportMutationVariables
  >,
  "mutation"
>;

export const MaxUtilizationReportComponent = (
  props: MaxUtilizationReportComponentProps
) => (
  <ReactApollo.Mutation<
    MaxUtilizationReportMutation,
    MaxUtilizationReportMutationVariables
  >
    mutation={MaxUtilizationReportDocument}
    {...props}
  />
);

export type MaxUtilizationReportProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    MaxUtilizationReportMutation,
    MaxUtilizationReportMutationVariables
  >
> &
  TChildProps;
export function withMaxUtilizationReport<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    MaxUtilizationReportMutation,
    MaxUtilizationReportMutationVariables,
    MaxUtilizationReportProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    MaxUtilizationReportMutation,
    MaxUtilizationReportMutationVariables,
    MaxUtilizationReportProps<TChildProps>
  >(MaxUtilizationReportDocument, {
    alias: "withMaxUtilizationReport",
    ...operationOptions
  });
}

export function useMaxUtilizationReportMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    MaxUtilizationReportMutation,
    MaxUtilizationReportMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    MaxUtilizationReportMutation,
    MaxUtilizationReportMutationVariables
  >(MaxUtilizationReportDocument, baseOptions);
}
export type MaxUtilizationReportMutationHookResult = ReturnType<
  typeof useMaxUtilizationReportMutation
>;
export const RequestLogReportDocument = gql`
  mutation RequestLogReport($report: RequestLogReportInput) {
    requestLogReport(report: $report)
  }
`;
export type RequestLogReportMutationFn = ReactApollo.MutationFn<
  RequestLogReportMutation,
  RequestLogReportMutationVariables
>;
export type RequestLogReportComponentProps = Omit<
  ReactApollo.MutationProps<
    RequestLogReportMutation,
    RequestLogReportMutationVariables
  >,
  "mutation"
>;

export const RequestLogReportComponent = (
  props: RequestLogReportComponentProps
) => (
  <ReactApollo.Mutation<
    RequestLogReportMutation,
    RequestLogReportMutationVariables
  >
    mutation={RequestLogReportDocument}
    {...props}
  />
);

export type RequestLogReportProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    RequestLogReportMutation,
    RequestLogReportMutationVariables
  >
> &
  TChildProps;
export function withRequestLogReport<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RequestLogReportMutation,
    RequestLogReportMutationVariables,
    RequestLogReportProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RequestLogReportMutation,
    RequestLogReportMutationVariables,
    RequestLogReportProps<TChildProps>
  >(RequestLogReportDocument, {
    alias: "withRequestLogReport",
    ...operationOptions
  });
}

export function useRequestLogReportMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    RequestLogReportMutation,
    RequestLogReportMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    RequestLogReportMutation,
    RequestLogReportMutationVariables
  >(RequestLogReportDocument, baseOptions);
}
export type RequestLogReportMutationHookResult = ReturnType<
  typeof useRequestLogReportMutation
>;
export const RoleListDocument = gql`
  query RoleList {
    roles
  }
`;
export type RoleListComponentProps = Omit<
  ReactApollo.QueryProps<RoleListQuery, RoleListQueryVariables>,
  "query"
>;

export const RoleListComponent = (props: RoleListComponentProps) => (
  <ReactApollo.Query<RoleListQuery, RoleListQueryVariables>
    query={RoleListDocument}
    {...props}
  />
);

export type RoleListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<RoleListQuery, RoleListQueryVariables>
> &
  TChildProps;
export function withRoleList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RoleListQuery,
    RoleListQueryVariables,
    RoleListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    RoleListQuery,
    RoleListQueryVariables,
    RoleListProps<TChildProps>
  >(RoleListDocument, {
    alias: "withRoleList",
    ...operationOptions
  });
}

export function useRoleListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<RoleListQueryVariables>
) {
  return ReactApolloHooks.useQuery<RoleListQuery, RoleListQueryVariables>(
    RoleListDocument,
    baseOptions
  );
}
export type RoleListQueryHookResult = ReturnType<typeof useRoleListQuery>;
export const ServiceListDocument = gql`
  query ServiceList {
    services
  }
`;
export type ServiceListComponentProps = Omit<
  ReactApollo.QueryProps<ServiceListQuery, ServiceListQueryVariables>,
  "query"
>;

export const ServiceListComponent = (props: ServiceListComponentProps) => (
  <ReactApollo.Query<ServiceListQuery, ServiceListQueryVariables>
    query={ServiceListDocument}
    {...props}
  />
);

export type ServiceListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<ServiceListQuery, ServiceListQueryVariables>
> &
  TChildProps;
export function withServiceList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ServiceListQuery,
    ServiceListQueryVariables,
    ServiceListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ServiceListQuery,
    ServiceListQueryVariables,
    ServiceListProps<TChildProps>
  >(ServiceListDocument, {
    alias: "withServiceList",
    ...operationOptions
  });
}

export function useServiceListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<ServiceListQueryVariables>
) {
  return ReactApolloHooks.useQuery<ServiceListQuery, ServiceListQueryVariables>(
    ServiceListDocument,
    baseOptions
  );
}
export type ServiceListQueryHookResult = ReturnType<typeof useServiceListQuery>;
export const SurveyOptionsListDocument = gql`
  query SurveyOptionsList {
    surveyOptions
  }
`;
export type SurveyOptionsListComponentProps = Omit<
  ReactApollo.QueryProps<
    SurveyOptionsListQuery,
    SurveyOptionsListQueryVariables
  >,
  "query"
>;

export const SurveyOptionsListComponent = (
  props: SurveyOptionsListComponentProps
) => (
  <ReactApollo.Query<SurveyOptionsListQuery, SurveyOptionsListQueryVariables>
    query={SurveyOptionsListDocument}
    {...props}
  />
);

export type SurveyOptionsListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<SurveyOptionsListQuery, SurveyOptionsListQueryVariables>
> &
  TChildProps;
export function withSurveyOptionsList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SurveyOptionsListQuery,
    SurveyOptionsListQueryVariables,
    SurveyOptionsListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SurveyOptionsListQuery,
    SurveyOptionsListQueryVariables,
    SurveyOptionsListProps<TChildProps>
  >(SurveyOptionsListDocument, {
    alias: "withSurveyOptionsList",
    ...operationOptions
  });
}

export function useSurveyOptionsListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<
    SurveyOptionsListQueryVariables
  >
) {
  return ReactApolloHooks.useQuery<
    SurveyOptionsListQuery,
    SurveyOptionsListQueryVariables
  >(SurveyOptionsListDocument, baseOptions);
}
export type SurveyOptionsListQueryHookResult = ReturnType<
  typeof useSurveyOptionsListQuery
>;
export const UsageListDocument = gql`
  query UsageList {
    usages
  }
`;
export type UsageListComponentProps = Omit<
  ReactApollo.QueryProps<UsageListQuery, UsageListQueryVariables>,
  "query"
>;

export const UsageListComponent = (props: UsageListComponentProps) => (
  <ReactApollo.Query<UsageListQuery, UsageListQueryVariables>
    query={UsageListDocument}
    {...props}
  />
);

export type UsageListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<UsageListQuery, UsageListQueryVariables>
> &
  TChildProps;
export function withUsageList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UsageListQuery,
    UsageListQueryVariables,
    UsageListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    UsageListQuery,
    UsageListQueryVariables,
    UsageListProps<TChildProps>
  >(UsageListDocument, {
    alias: "withUsageList",
    ...operationOptions
  });
}

export function useUsageListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<UsageListQueryVariables>
) {
  return ReactApolloHooks.useQuery<UsageListQuery, UsageListQueryVariables>(
    UsageListDocument,
    baseOptions
  );
}
export type UsageListQueryHookResult = ReturnType<typeof useUsageListQuery>;
export const UserListDocument = gql`
  query UserList {
    users {
      id
      username
      operatorId
      role
    }
  }
`;
export type UserListComponentProps = Omit<
  ReactApollo.QueryProps<UserListQuery, UserListQueryVariables>,
  "query"
>;

export const UserListComponent = (props: UserListComponentProps) => (
  <ReactApollo.Query<UserListQuery, UserListQueryVariables>
    query={UserListDocument}
    {...props}
  />
);

export type UserListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<UserListQuery, UserListQueryVariables>
> &
  TChildProps;
export function withUserList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UserListQuery,
    UserListQueryVariables,
    UserListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    UserListQuery,
    UserListQueryVariables,
    UserListProps<TChildProps>
  >(UserListDocument, {
    alias: "withUserList",
    ...operationOptions
  });
}

export function useUserListQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<UserListQueryVariables>
) {
  return ReactApolloHooks.useQuery<UserListQuery, UserListQueryVariables>(
    UserListDocument,
    baseOptions
  );
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export const OperatorNamesDocument = gql`
  query OperatorNames {
    operators {
      results {
        id
        name {
          fi
        }
      }
    }
  }
`;
export type OperatorNamesComponentProps = Omit<
  ReactApollo.QueryProps<OperatorNamesQuery, OperatorNamesQueryVariables>,
  "query"
>;

export const OperatorNamesComponent = (props: OperatorNamesComponentProps) => (
  <ReactApollo.Query<OperatorNamesQuery, OperatorNamesQueryVariables>
    query={OperatorNamesDocument}
    {...props}
  />
);

export type OperatorNamesProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<OperatorNamesQuery, OperatorNamesQueryVariables>
> &
  TChildProps;
export function withOperatorNames<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    OperatorNamesQuery,
    OperatorNamesQueryVariables,
    OperatorNamesProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    OperatorNamesQuery,
    OperatorNamesQueryVariables,
    OperatorNamesProps<TChildProps>
  >(OperatorNamesDocument, {
    alias: "withOperatorNames",
    ...operationOptions
  });
}

export function useOperatorNamesQuery(
  baseOptions?: ReactApolloHooks.QueryHookOptions<OperatorNamesQueryVariables>
) {
  return ReactApolloHooks.useQuery<
    OperatorNamesQuery,
    OperatorNamesQueryVariables
  >(OperatorNamesDocument, baseOptions);
}
export type OperatorNamesQueryHookResult = ReturnType<
  typeof useOperatorNamesQuery
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($id: ID!, $newPassword: String!) {
    changePassword(id: $id, newPassword: $newPassword)
  }
`;
export type ChangePasswordMutationFn = ReactApollo.MutationFn<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export type ChangePasswordComponentProps = Omit<
  ReactApollo.MutationProps<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
  "mutation"
>;

export const ChangePasswordComponent = (
  props: ChangePasswordComponentProps
) => (
  <ReactApollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables>
    mutation={ChangePasswordDocument}
    {...props}
  />
);

export type ChangePasswordProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
> &
  TChildProps;
export function withChangePassword<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    ChangePasswordProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    ChangePasswordProps<TChildProps>
  >(ChangePasswordDocument, {
    alias: "withChangePassword",
    ...operationOptions
  });
}

export function useChangePasswordMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, baseOptions);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = ReactApollo.MutationFn<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export type DeleteUserComponentProps = Omit<
  ReactApollo.MutationProps<DeleteUserMutation, DeleteUserMutationVariables>,
  "mutation"
>;

export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
  <ReactApollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables>
    mutation={DeleteUserDocument}
    {...props}
  />
);

export type DeleteUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<DeleteUserMutation, DeleteUserMutationVariables>
> &
  TChildProps;
export function withDeleteUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >(DeleteUserDocument, {
    alias: "withDeleteUser",
    ...operationOptions
  });
}

export function useDeleteUserMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument, baseOptions);
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export const CreateUserDocument = gql`
  mutation CreateUser($user: UserInput!) {
    createUser(user: $user) {
      id
      username
      role
      operatorId
    }
  }
`;
export type CreateUserMutationFn = ReactApollo.MutationFn<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export type CreateUserComponentProps = Omit<
  ReactApollo.MutationProps<CreateUserMutation, CreateUserMutationVariables>,
  "mutation"
>;

export const CreateUserComponent = (props: CreateUserComponentProps) => (
  <ReactApollo.Mutation<CreateUserMutation, CreateUserMutationVariables>
    mutation={CreateUserDocument}
    {...props}
  />
);

export type CreateUserProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<CreateUserMutation, CreateUserMutationVariables>
> &
  TChildProps;
export function withCreateUser<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    CreateUserMutation,
    CreateUserMutationVariables,
    CreateUserProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    CreateUserMutation,
    CreateUserMutationVariables,
    CreateUserProps<TChildProps>
  >(CreateUserDocument, {
    alias: "withCreateUser",
    ...operationOptions
  });
}

export function useCreateUserMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CreateUserDocument, baseOptions);
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export const UpdateUsernameDocument = gql`
  mutation UpdateUsername($id: ID!, $newUsername: String!) {
    updateUsername(id: $id, newUsername: $newUsername)
  }
`;
export type UpdateUsernameMutationFn = ReactApollo.MutationFn<
  UpdateUsernameMutation,
  UpdateUsernameMutationVariables
>;
export type UpdateUsernameComponentProps = Omit<
  ReactApollo.MutationProps<
    UpdateUsernameMutation,
    UpdateUsernameMutationVariables
  >,
  "mutation"
>;

export const UpdateUsernameComponent = (
  props: UpdateUsernameComponentProps
) => (
  <ReactApollo.Mutation<UpdateUsernameMutation, UpdateUsernameMutationVariables>
    mutation={UpdateUsernameDocument}
    {...props}
  />
);

export type UpdateUsernameProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    UpdateUsernameMutation,
    UpdateUsernameMutationVariables
  >
> &
  TChildProps;
export function withUpdateUsername<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateUsernameMutation,
    UpdateUsernameMutationVariables,
    UpdateUsernameProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateUsernameMutation,
    UpdateUsernameMutationVariables,
    UpdateUsernameProps<TChildProps>
  >(UpdateUsernameDocument, {
    alias: "withUpdateUsername",
    ...operationOptions
  });
}

export function useUpdateUsernameMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateUsernameMutation,
    UpdateUsernameMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateUsernameMutation,
    UpdateUsernameMutationVariables
  >(UpdateUsernameDocument, baseOptions);
}
export type UpdateUsernameMutationHookResult = ReturnType<
  typeof useUpdateUsernameMutation
>;
export const UpdateTokenDocument = gql`
  mutation UpdateToken($id: ID!) {
    updateToken(id: $id)
  }
`;
export type UpdateTokenMutationFn = ReactApollo.MutationFn<
  UpdateTokenMutation,
  UpdateTokenMutationVariables
>;
export type UpdateTokenComponentProps = Omit<
  ReactApollo.MutationProps<UpdateTokenMutation, UpdateTokenMutationVariables>,
  "mutation"
>;

export const UpdateTokenComponent = (props: UpdateTokenComponentProps) => (
  <ReactApollo.Mutation<UpdateTokenMutation, UpdateTokenMutationVariables>
    mutation={UpdateTokenDocument}
    {...props}
  />
);

export type UpdateTokenProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<UpdateTokenMutation, UpdateTokenMutationVariables>
> &
  TChildProps;
export function withUpdateToken<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    UpdateTokenMutation,
    UpdateTokenMutationVariables,
    UpdateTokenProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    UpdateTokenMutation,
    UpdateTokenMutationVariables,
    UpdateTokenProps<TChildProps>
  >(UpdateTokenDocument, {
    alias: "withUpdateToken",
    ...operationOptions
  });
}

export function useUpdateTokenMutation(
  baseOptions?: ReactApolloHooks.MutationHookOptions<
    UpdateTokenMutation,
    UpdateTokenMutationVariables
  >
) {
  return ReactApolloHooks.useMutation<
    UpdateTokenMutation,
    UpdateTokenMutationVariables
  >(UpdateTokenDocument, baseOptions);
}
export type UpdateTokenMutationHookResult = ReturnType<
  typeof useUpdateTokenMutation
>;
