import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { User, useUpdateUsernameMutation } from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import { translatePath } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import { useErrorModalContext } from "../Commons/ErrorModal";
import FormError from "../Commons/FormError";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import { useLoginModalContext } from "../Commons/LoginModal";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";
import ModalHeader from "../Commons/ModalHeader";
import Row from "../Commons/Row";

interface Props {
  isOpen: boolean;
  onClose: any;
  user: User;
}

const UpdateUsernameModal: React.FC<Props> = (props: Props) => {
  const { hasPermission } = useAuth();
  const { t } = useTranslation(["userList", "commons"]);
  const [updateUsername] = useUpdateUsernameMutation();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

  const constructError = React.useCallback(
    (path: string, error: string) => {
      return `${translatePath("path", path, t)}: ${t("commons:error" + error)}`;
    },
    [t]
  );

  const handleCloseModal = () => {
    props.onClose();
  };

  return (
    <Formik
      initialValues={{
        username: ""
      }}
      onSubmit={async values => {
        try {
          await updateUsername({
            variables: { id: props.user.id, newUsername: values.username }
          });
          props.onClose();
        } catch (err) {
          console.log(err);
          handleError(
            err,
            openErrorModal,
            t,
            openLoginModal,
            constructError,
            setValidationErrors
          );
        }
      }}
    >
      {() => {
        return (
          <Modal isOpen={props.isOpen}>
            <ModalContent>
              <Form>
                <ModalHeader title={t("titleUpdateUsername")} />
                <ModalBody>
                  <FormError errors={validationErrors} />
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <label>{t("labelNewUsername")} *</label>
                      <FormGroup hasLargeMarginBottom={true}>
                        <Field
                          component={InputField}
                          name="username"
                          type="text"
                        />
                      </FormGroup>
                    </Column>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Button
                    size={ButtonSizes.XSMALL_SECONDARY}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    {t("buttonCancel")}
                  </Button>
                  {hasPermission(PERMISSIONS.USER_UPDATE) && (
                    <Button size={ButtonSizes.XSMALL} type="submit">
                      {t("buttonReady")}
                    </Button>
                  )}
                </ModalFooter>
              </Form>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
export default UpdateUsernameModal;
