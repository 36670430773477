import * as React from "react";
import { useTranslation } from "react-i18next";
import { Capacity, Facility } from "../../generated/graphql";
import CapacityTable from "./CapacityTable";

interface Props {
  facilities: Facility[] | Facility | null;
}

const BuiltCapacity: React.FC<Props> = ({ facilities }) => {
  const { t } = useTranslation("commons");

  if (Array.isArray(facilities)) {
    const totalCapacities = facilities.reduce<Capacity>(
      (previousValue: any, currentValue: Facility) => {
        if (!currentValue || !currentValue.builtCapacity) {
          return previousValue;
        }
        const builtCapacity: any = currentValue.builtCapacity;
        Object.keys(builtCapacity).forEach(key => {
          if (builtCapacity[key] && key !== "__typename") {
            previousValue[key] = previousValue[key]
              ? previousValue[key] + builtCapacity[key]
              : builtCapacity[key];
          }
        });
        return previousValue;
      },
      {}
    );
    return <CapacityTable data={totalCapacities} />;
  } else if (facilities && facilities.builtCapacity) {
    return <CapacityTable data={facilities.builtCapacity} />;
  } else {
    return <p>{t("messageNoBuiltCapacity")}</p>;
  }
};

export default BuiltCapacity;
