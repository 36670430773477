import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Capacity,
  Facility,
  Prediction,
  Utilization
} from "../../generated/graphql";
import { formatDate } from "../../util/dateUtils";
import { translateValue } from "../../util/translateUtils";
import Column from "../Commons/Column";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import Table from "../Commons/Table";
import styles from "./UtilizationTable.module.css";

const getSpacesAvailable = (
  predictionItems: Prediction[],
  utilization: Utilization
) => {
  const prediction = predictionItems.find(
    item =>
      utilization.capacityType === item.capacityType &&
      utilization.usage === item.usage
  );
  return prediction ? prediction.spacesAvailable : null;
};

interface Props {
  facilities: Facility[];
  prediction0Items: Prediction[];
  prediction10Items: Prediction[];
  prediction20Items: Prediction[];
  prediction30Items: Prediction[];
  prediction60Items: Prediction[];
  utilizationItems: Utilization[];
}

const UtilizationTable: React.FC<Props> = ({
  facilities,
  prediction0Items,
  prediction10Items,
  prediction20Items,
  prediction30Items,
  prediction60Items,
  utilizationItems
}) => {
  const { t } = useTranslation(["facilityProfile", "commons"]);
  const now = React.useMemo(() => new Date(), []);
  if (!utilizationItems.length) {
    return null;
  }

  const totalCapacities: any = facilities.reduce<Capacity>(
    (previousValue: any, currentValue: Facility) => {
      if (!currentValue || !currentValue.builtCapacity) {
        return previousValue;
      }
      const builtCapacity: any = currentValue.builtCapacity;
      Object.keys(builtCapacity).forEach(key => {
        if (builtCapacity[key]) {
          previousValue[key] = previousValue[key]
            ? previousValue[key] + builtCapacity[key]
            : builtCapacity[key];
        }
      });
      return previousValue;
    },
    {}
  );

  return (
    <>
      <h4>{t("titleAvailable")}</h4>
      <Row>
        <Column>
          <p>
            {t("messageAvailableAndForecast", {
              time: formatDate(now, "HH.mm")
            })}
          </p>
        </Column>
      </Row>
      <Row>
        <Column>
          <Panel hasBorder={true}>
            <Table
              isBordered={true}
              isCondensed={true}
              isInsidePanel={true}
              isStriped={true}
            >
              <thead>
                <tr>
                  <th className={styles.capacityTypeColumn}>
                    {t("labelCapacityType")}
                  </th>
                  <th className={styles.usageColumn}>{t("labelUsage")}</th>
                  <th className={styles.builtCapacityColumn}>
                    {t("labelBuiltCapacity")}
                  </th>
                  <th>{t("labelCurrent")}</th>
                  {!!prediction0Items.length && (
                    <th>
                      {formatDate(prediction0Items[0].timestamp, "HH:mm")}
                    </th>
                  )}
                  {!!prediction10Items.length && (
                    <th>
                      {formatDate(prediction10Items[0].timestamp, "HH:mm")}
                    </th>
                  )}
                  {!!prediction20Items.length && (
                    <th>
                      {formatDate(prediction20Items[0].timestamp, "HH:mm")}
                    </th>
                  )}
                  {!!prediction30Items.length && (
                    <th>
                      {formatDate(prediction30Items[0].timestamp, "HH:mm")}
                    </th>
                  )}
                  {!!prediction60Items.length && (
                    <th>
                      {formatDate(prediction60Items[0].timestamp, "HH:mm")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {utilizationItems.map((item, index) => {
                  const shouldShowCapacityType = () => {
                    if (index === 0) {
                      return true;
                    } else if (
                      utilizationItems[index - 1].capacityType ===
                      item.capacityType
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  };
                  return (
                    <tr key={index}>
                      <td>
                        {shouldShowCapacityType() &&
                          translateValue(
                            "commons:capacityType",
                            item.capacityType,
                            t
                          )}
                      </td>
                      <td>{translateValue("commons:usage", item.usage, t)}</td>

                      <td>{totalCapacities[item.capacityType || ""]}</td>
                      <td>
                        <span>{item.spacesAvailable}</span>{" "}
                        {item.timestamp && (
                          <small>
                            ({formatDate(item.timestamp, "dd.MM.yyyy HH:mm")})
                          </small>
                        )}
                      </td>
                      {!!prediction0Items.length && (
                        <td>{getSpacesAvailable(prediction0Items, item)}</td>
                      )}
                      {!!prediction10Items.length && (
                        <td>{getSpacesAvailable(prediction10Items, item)}</td>
                      )}
                      {!!prediction20Items.length && (
                        <td>{getSpacesAvailable(prediction20Items, item)}</td>
                      )}
                      {!!prediction30Items.length && (
                        <td>{getSpacesAvailable(prediction30Items, item)}</td>
                      )}
                      {!!prediction60Items.length && (
                        <td>{getSpacesAvailable(prediction60Items, item)}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Panel>
        </Column>
      </Row>
    </>
  );
};

export default UtilizationTable;
