import classNames from "classnames";
import { FastField, FieldArray } from "formik";
import { forEach } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { FACILITY_TYPE, PRICING_METHOD } from "../../constants";
import { Pricing } from "../../generated/graphql";
import Button, { ButtonSizes } from "../Commons/Button";
import Checkbox from "../Commons/Checkbox";
import CheckboxField from "../Commons/CheckboxField";
import Column from "../Commons/Column";
import InputField from "../Commons/InputField";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";
import Table from "../Commons/Table";
import tableStyles from "../Commons/Table.module.css";
import TextAreaField from "../Commons/TextAreaField";
import styles from "./PricingTable.module.css";

interface PricingRow extends Pricing {
  selected: boolean;
  isOnClipboard: boolean;
  price: {
    en: string;
    fi: string;
    sv: string;
  };
}

interface Props {
  capacityTypeOptions: Option[];
  dayTypeOptions: Option[];
  name: string;
  pricing: PricingRow[];
  setFieldValue: (field: string, value: any) => void;
  usageOptions: Option[];
  facilityType: string;
  pricingMethod?: string;
}

const PricingTable: React.FC<Props> = ({
  dayTypeOptions,
  capacityTypeOptions,
  name,
  usageOptions,
  pricing,
  setFieldValue,
  pricingMethod,
  facilityType
}) => {
  const { t } = useTranslation(["facilityEdit", "commons"]);
  const [advancedMode, setAdvancedMode] = React.useState(false);

  const isAllSelected = () => {
    let allRowsSelected = true;
    forEach(pricing, (item: any) => {
      if (!item.selected) {
        allRowsSelected = false;
      }
    });
    return allRowsSelected;
  };

  const isAnySelected = () => {
    let anyRowsSelected = false;
    forEach(pricing, row => {
      if (row.selected) {
        anyRowsSelected = true;
        return false;
      }
    });
    return anyRowsSelected;
  };

  const isAnyOnClipboard = () => {
    let anyOnClipboard = false;
    forEach(pricing, row => {
      if (row.isOnClipboard) {
        anyOnClipboard = true;
        return false;
      }
    });
    return anyOnClipboard;
  };

  const selectAll = () => {
    pricing.forEach((row, index: number) => {
      setFieldValue(`${name}[${index}].selected`, true);
    });
  };

  const unselectAll = () => {
    pricing.forEach((row, index: number) => {
      setFieldValue(`${name}[${index}].selected`, false);
    });
  };

  const validateNumber = (value: any) => {
    let errorMessage;
    if (isNaN(value)) {
      errorMessage = value + 'is not a number';
    }
    return errorMessage;
  }

  const showPriceFields =
    pricingMethod === PRICING_METHOD.PAID_10H ||
    pricingMethod === PRICING_METHOD.PAID_12H ||
    pricingMethod === PRICING_METHOD.PAID_16H ||
    pricingMethod === PRICING_METHOD.PAID_24H ||
    pricingMethod === PRICING_METHOD.PAID_16H_24H_48H;

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        const handleAdd = () => {
          arrayHelpers.push({
            selected: false,
            capacityType: facilityType,
            dayType: "",
            maxCapacity: "",
            price: {
              fi: "",
              sv: "",
              en: ""
            },
            priceExtra: null,
            priceOther: null,
            time: {
              from: "",
              until: ""
            },
            usage: "PARK_AND_RIDE"
          });
        };

        const copyOnClipboard = () => {
          pricing.forEach((row, index: number) => {
            if (row.selected) {
              setFieldValue(`${name}[${index}].selected`, false);
              setFieldValue(`${name}[${index}].isOnClipboard`, true);
            } else {
              setFieldValue(`${name}[${index}].isOnClipboard`, false);
            }
          });
        };

        const copyFirstOnClipboard = () => {
          const copyIndex = pricing.findIndex(row => row.selected);

          pricing.forEach((row, index: number) => {
            if (copyIndex === index) {
              setFieldValue(`${name}[${index}].selected`, false);
              setFieldValue(`${name}[${index}].isOnClipboard`, true);
            } else {
              setFieldValue(`${name}[${index}].isOnClipboard`, false);
            }
          });
        };

        const pasteRowsFromClipboard = () => {
          pricing.forEach(row => {
            if (row.isOnClipboard) {
              arrayHelpers.push({ ...row, isOnClipboard: false });
            }
          });
        };

        const pastePricingValues = (
          property:
            | "capacityType"
            | "dayType"
            | "maxCapacity"
            | "price"
            | "time"
            | "usage"
        ) => {
          const clipboardRows = pricing.filter(item => item.isOnClipboard);
          const len = clipboardRows.length;
          if (len === 0) {
            return;
          }

          let j = 0;
          forEach(pricing, (row, index) => {
            if (row.selected) {
              switch (property) {
                case "time":
                  setFieldValue(
                    `${name}[${index}].time.from`,
                    clipboardRows[j++ % len].time.from
                  );
                  setFieldValue(
                    `${name}[${index}].time.until`,
                    clipboardRows[j++ % len].time.until
                  );
                  break;
                case "price":
                  setFieldValue(
                    `${name}[${index}].price.fi`,
                    clipboardRows[j++ % len].price.fi
                  );
                  break;
                default:
                  setFieldValue(
                    `${name}[${index}].${property}`,
                    clipboardRows[j++ % len][property]
                  );
              }
            }
          });
        };

        const handleRemove = () => {
          const removeIndexes: number[] = [];
          pricing.forEach((row, index: number) => {
            if (row.selected) {
              removeIndexes.push(index);
            }
          });
          removeIndexes.reverse();
          removeIndexes.forEach(index => {
            arrayHelpers.remove(index);
          });
        };

        const toggleAdvanvedMode = () => {
          setAdvancedMode(!advancedMode);
        };
        const hourLimit = () => {
          return pricingMethod && pricingMethod.match(/-?\d+\.?\d*/);
        };

        if (!pricing.length) {
          return (
            <>
              <Row hasLargeMargin={true}>
                <Column>
                  <p>{t("noPricingRows")}</p>
                </Column>
              </Row>
              <Row hasSmallMargin={true}>
                <Column small={8} isFirstColumn={true}>
                  <Button
                    isResponsive={true}
                    size={ButtonSizes.XSMALL}
                    onClick={handleAdd}
                    type="button"
                  >
                    {t("buttonAddPricingRow")}
                  </Button>
                </Column>
              </Row>
            </>
          );
        }

        return (
          <>
            <Row hasLargeMargin={true}>
              <Column>
                <Panel hasBorder={true}>
                  <Table
                    isBordered={true}
                    isCondensed={true}
                    isInsidePanel={true}
                    isStriped={true}
                  >
                    <thead>
                      <tr>
                        <th
                          className={classNames(
                            styles.pricingSelectColumn,
                            styles.clipboardIndicatorColumn
                          )}
                        />
                        <th className={styles.pricingSelectColumn}>
                          <Checkbox
                            checked={isAllSelected()}
                            onChange={isAllSelected() ? unselectAll : selectAll}
                          />
                        </th>

                        <th className={styles.pricingDayTypeColumn}>
                          <span>{t("labelDayType")}</span>
                        </th>
                        <th
                          className={styles.pricingCapacityTypeColumn}
                          style={
                            facilityType === "BICYCLE"
                              ? { display: "none" }
                              : {}
                          }
                        >
                          <span>{t("labelCapacityType")}</span>
                        </th>
                        <th className={styles.pricingUsageColumn}>
                          <span>{t("labelUsage")}</span>
                        </th>

                        <th className={styles.pricingFromColumn}>
                          <span>{t("labelTimeFrom")}</span>
                        </th>
                        <th className={styles.pricingUntilColumn}>
                          <span>{t("labelTimeUntil")}</span>
                        </th>
                        <th>
                          <span>{t("commons:capacity")}</span>
                        </th>
                        <th
                          style={
                            facilityType === FACILITY_TYPE.BICYCLE ||
                            !showPriceFields
                              ? { display: "none" }
                              : {}
                          }
                        >
                          {pricingMethod === PRICING_METHOD.PAID_16H_24H_48H
                            ? <span>€/16 h</span>
                            : <span>€/{hourLimit()} h</span>
                          }
                          
                        </th>

                        <th
                          style={
                            facilityType === FACILITY_TYPE.BICYCLE ||
                            !showPriceFields
                              ? { display: "none" }
                              : {}
                          }
                        >
                          {pricingMethod === PRICING_METHOD.PAID_16H_24H_48H
                            ? <span>€/24 h</span>
                            : <span>{t("commons:over")} {hourLimit()} h €/h</span>
                          }
                        </th>
                        <th
                          style={
                            facilityType === FACILITY_TYPE.BICYCLE
                              ? { display: "none" }
                              : {}
                          }
                        >
                          {pricingMethod === PRICING_METHOD.PAID_16H_24H_48H
                            ? <span>€/48 h</span>
                            : <span>{t("labelPriceOther")}</span>
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pricing.map((pricingRow, index: number) => {
                        const isNewPricingGroup = () => {
                          if (index === 0) {
                            return false;
                          }

                          const previous = pricing[index - 1];
                          return (
                            previous.capacityType !== pricingRow.capacityType ||
                            previous.usage !== pricingRow.usage
                          );
                        };
                        return (
                          <tr
                            key={index}
                            className={classNames({
                              [styles.onClipboard]: pricingRow.isOnClipboard,
                              [styles.newPricingGroup]: isNewPricingGroup()
                            })}
                          >
                            <td className={styles.clipboardIndicatorColumn} />
                            <td
                              className={classNames(
                                styles.pricingSelectColumn,
                                tableStyles.centeredCell
                              )}
                            >
                              <FastField
                                name={`${name}[${index}].selected`}
                                component={CheckboxField}
                              />
                            </td>

                            <td>
                              <FastField
                                hasSmallFontSize={true}
                                isClearable={false}
                                isMulti={false}
                                name={`${name}[${index}].dayType`}
                                options={dayTypeOptions}
                                component={SelectField}
                              />
                            </td>
                            <td
                              style={
                                facilityType === "BICYCLE"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              <FastField
                                hasSmallFontSize={true}
                                isClearable={false}
                                isMulti={false}
                                name={`${name}[${index}].capacityType`}
                                options={capacityTypeOptions}
                                component={SelectField}
                              />
                            </td>
                            <td>
                              <FastField
                                hasSmallFontSize={true}
                                isClearable={false}
                                isMulti={false}
                                name={`${name}[${index}].usage`}
                                options={usageOptions}
                                component={SelectField}
                              />
                            </td>
                            <td>
                              <FastField
                                component={InputField}
                                hasSmallFontSize={true}
                                maxLength={5}
                                name={`${name}[${index}].time.from`}
                                type="text"
                              />
                            </td>
                            <td>
                              <FastField
                                component={InputField}
                                hasSmallFontSize={true}
                                maxLength={5}
                                name={`${name}[${index}].time.until`}
                                type="text"
                              />
                            </td>
                            <td>
                              <FastField
                                component={InputField}
                                hasSmallFontSize={true}
                                name={`${name}[${index}].maxCapacity`}
                                type="number"
                              />
                            </td>
                            <td
                              style={
                                facilityType === FACILITY_TYPE.BICYCLE ||
                                !showPriceFields
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              <FastField
                                component={TextAreaField}
                                hasSmallFontSize={true}
                                maxLength={255}
                                name={`${name}[${index}].price.fi`}
                                rows={1}
                                type="number"
                                validate={validateNumber}
                              />
                            </td>
                            <td
                              style={
                                facilityType === FACILITY_TYPE.BICYCLE ||
                                !showPriceFields
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              <FastField
                                component={TextAreaField}
                                hasSmallFontSize={true}
                                maxLength={255}
                                name={`${name}[${index}].priceExtra`}
                                rows={1}
                                type="number"
                                validate={validateNumber}
                              />
                            </td>
                            <td
                              style={
                                facilityType === FACILITY_TYPE.BICYCLE
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              <FastField
                                component={TextAreaField}
                                hasSmallFontSize={true}
                                maxLength={255}
                                name={`${name}[${index}].priceOther`}
                                rows={1}
                                type="number"
                                validate={validateNumber}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      {advancedMode && isAnyOnClipboard() && isAnySelected() && (
                        <tr>
                          <th className={styles.clipboardIndicatorColumn} />
                          <th className={styles.pricingSelectColumn} />
                          <th>
                            <Button
                              isResponsive={true}
                              size={ButtonSizes.XSMALL}
                              onClick={() => pastePricingValues("capacityType")}
                              type="button"
                            >
                              {t("buttonPaste")}
                            </Button>
                          </th>
                          <th>
                            <Button
                              isResponsive={true}
                              size={ButtonSizes.XSMALL}
                              onClick={() => pastePricingValues("usage")}
                              type="button"
                            >
                              {t("buttonPaste")}
                            </Button>
                          </th>
                          <th>
                            <Button
                              isResponsive={true}
                              size={ButtonSizes.XSMALL}
                              onClick={() => pastePricingValues("maxCapacity")}
                              type="button"
                            >
                              {t("buttonPaste")}
                            </Button>
                          </th>

                          <th>
                            <Button
                              isResponsive={true}
                              size={ButtonSizes.XSMALL}
                              onClick={() => pastePricingValues("dayType")}
                              type="button"
                            >
                              {t("buttonPaste")}
                            </Button>
                          </th>
                          <th />
                          <th colSpan={2}>
                            <Button
                              isResponsive={true}
                              size={ButtonSizes.XSMALL}
                              onClick={() => pastePricingValues("time")}
                              type="button"
                            >
                              {t("buttonPaste")}
                            </Button>
                          </th>

                          <th />
                          <th colSpan={3}>
                            <Button
                              isResponsive={true}
                              size={ButtonSizes.XSMALL}
                              onClick={() => pastePricingValues("price")}
                              type="button"
                            >
                              {t("buttonPaste")}
                            </Button>
                          </th>
                        </tr>
                      )}
                    </tfoot>
                  </Table>
                </Panel>
              </Column>
            </Row>
            <Row hasSmallMargin={true}>
              <Column small={8} isFirstColumn={true}>
                <Button
                  className={styles.buttonWithRightMargin}
                  isResponsive={true}
                  onClick={handleAdd}
                  size={ButtonSizes.XSMALL}
                  type="button"
                >
                  {t("buttonAddPricingRow")}
                </Button>
                {!!pricing.length && (
                  <Button
                    className={styles.buttonWithRightMargin}
                    isResponsive={true}
                    size={ButtonSizes.XSMALL}
                    disabled={!isAnyOnClipboard() || isAnySelected()}
                    onClick={pasteRowsFromClipboard}
                    type="button"
                  >
                    {t("buttonPaste")}
                  </Button>
                )}
                {!!pricing.length && (
                  <Button
                    className={styles.buttonWithRightMargin}
                    isResponsive={true}
                    size={ButtonSizes.XSMALL}
                    disabled={!isAnySelected()}
                    onClick={copyOnClipboard}
                    type="button"
                  >
                    {t("buttonCopy")}
                  </Button>
                )}
                {advancedMode && !!pricing.length && (
                  <Button
                    className={styles.buttonWithRightMargin}
                    isResponsive={true}
                    size={ButtonSizes.XSMALL}
                    disabled={!isAnySelected()}
                    onClick={copyFirstOnClipboard}
                    type="button"
                  >
                    {t("buttonCopyFirst")}
                  </Button>
                )}
                {!!pricing.length && (
                  <Button
                    className={styles.buttonWithRightMargin}
                    isResponsive={true}
                    size={ButtonSizes.XSMALL}
                    disabled={!isAnySelected()}
                    onClick={handleRemove}
                    type="button"
                  >
                    {t("buttonRemove")}
                  </Button>
                )}
              </Column>
              <Column small={4} isLastColumn={true}>
                {!!pricing.length && (
                  <Button
                    className={styles.buttonAdditionalFeatures}
                    isActive={advancedMode}
                    onClick={toggleAdvanvedMode}
                    size={ButtonSizes.XSMALL}
                    type="button"
                  >
                    {t("buttonAdditionalFeatures")}
                  </Button>
                )}
              </Column>
            </Row>
          </>
        );
      }}
    />
  );
};

export default PricingTable;
