import * as React from "react";
import styles from "./Modal.module.css";

interface Props {
  title?: string;
}

const ModalHeader: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles["modal-header"]}>
      <h3 className={styles["modal-title"]}>{title}</h3>
    </div>
  );
};
export default ModalHeader;
