import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.css";
import Button, { ButtonSizes } from "./Button";
import buttonStyles from "./Button.module.css";
import Column from "./Column";
import Container from "./Container";
import Row from "./Row";

interface Props {
  canAddContact?: boolean;
  canAddFacility?: boolean;
  canAddHub?: boolean;
  canAddOperator?: boolean;
  canAddUser?: boolean;
  canCancel?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  canGoback?: boolean;
  canSave?: boolean;
  canSoftDelete?: boolean;
  isSoftDeleted?: boolean;
  name: any;
  onClickCancel?: () => void;
  onClickDelete?: () => void;
  onClickEdit?: () => void;
  onClickNewContact?: () => void;
  onClickNewOperator?: () => void;
  onClickNewUser?: () => void;
  onClickSave?: () => void;
  onClickSoftDelete?: () => void;
}
const Breadcrumb: React.FC<Props> = ({
  canAddContact,
  canAddFacility,
  canAddHub,
  canAddOperator,
  canAddUser,
  canDelete,
  canCancel,
  canSoftDelete,
  canEdit,
  canGoback,
  canSave,
  isSoftDeleted,
  name,
  onClickCancel,
  onClickDelete,
  onClickEdit,
  onClickNewContact,
  onClickNewOperator,
  onClickNewUser,
  onClickSave,
  onClickSoftDelete
}) => {
  const { t } = useTranslation([
    "commons",
    "contactList",
    "operatorList",
    "userList"
  ]);
  const linkStyles = classNames(
    buttonStyles.btn,
    buttonStyles["btn-s"],
    buttonStyles["btn-responsive"]
  );

  return (
    <Row className={styles.breadcrumb}>
      <Container>
        <Row>
          <Column small={6} isFirstColumn={true}>
            <h2>
              {name}{" "}
              {isSoftDeleted && (
                <span className={styles.softDeleted}>
                  ({t("messageSoftDeleted")})
                </span>
              )}
            </h2>
          </Column>
          <Column small={6} isLastColumn={true} textRight={true}>
            <div className={styles.breadcrumbButtons}>
              {canGoback && (
                <Link className={linkStyles} to="/hubs">
                  {t("breadcrumbBack")}
                </Link>
              )}
              {canDelete && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL_ALERT}
                  onClick={onClickDelete}
                  type="button"
                >
                  {t("breadcrumbDelete")}
                </Button>
              )}
              {canSoftDelete && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL_ALERT}
                  onClick={onClickSoftDelete}
                  type="button"
                >
                  {t("breadcrumbSoftDelete")}
                </Button>
              )}

              {canAddFacility && (
                <Link className={linkStyles} to="/facilities/create">
                  {t("breadcrumbCreateFacility")}
                </Link>
              )}
              {canAddHub && (
                <Link className={linkStyles} to="/hubs/create">
                  {t("breadcrumbCreateHub")}
                </Link>
              )}
              {canEdit && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={onClickEdit}
                  type="button"
                >
                  {t("breadcrumbEdit")}
                </Button>
              )}
              {canAddContact && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={onClickNewContact}
                  type="button"
                >
                  {t("contactList:buttonAddContact")}
                </Button>
              )}
              {canAddOperator && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={onClickNewOperator}
                  type="button"
                >
                  {t("operatorList:buttonAddOperator")}
                </Button>
              )}
              {canAddUser && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={onClickNewUser}
                  type="button"
                >
                  {t("userList:buttonAddNewUser")}
                </Button>
              )}
              {canCancel && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={onClickCancel}
                  type="button"
                >
                  {t("breadcrumbCancel")}
                </Button>
              )}
              {canSave && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={onClickSave}
                  type="button"
                >
                  {t("breadcrumbSave")}
                </Button>
              )}
            </div>
          </Column>
        </Row>
      </Container>
    </Row>
  );
};

export default Breadcrumb;
