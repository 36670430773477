import { flatten, forEach, map, sortBy } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Facility,
  FacilityListQuery,
  Hub as HubType,
  HubListQuery
} from "../../generated/graphql";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import fieldStyles from "../Commons/Field.module.css";
import NaviBelow from "../Commons/NaviBelow";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import Table from "../Commons/Table";
import FacilityList from "../FacilityList/FacilityList";
import Hub from "./Hub";
import styles from "./HubList.module.css";
import Info from "./Info";

interface HubWithFacilities {
  attachedFacilities: Facility[];
  hub: HubType;
}
interface Props extends RouteComponentProps {
  facilitiesData: FacilityListQuery;
  hubsData: HubListQuery;
}
const HubList: React.FC<Props> = ({ facilitiesData, history, hubsData }) => {
  const { t } = useTranslation("hubList");
  const { hasPermission } = useAuth();
  const [search, setSearch] = React.useState("");
  const facilities =
    facilitiesData && facilitiesData.facilities
      ? facilitiesData.facilities.facilities
      : [];
  const hubs = hubsData ? hubsData.hubs.hubs : [];

  const getAttachedFacilitiesOfHub = React.useCallback(
    (hub: HubType): Facility[] => {
      const facilityIds = hub.facilityIds;
      return facilities.filter((facility: any) => {
        return facilityIds.indexOf(facility.id) !== -1;
      });
    },
    [facilities]
  );

  const unattachedFacilityIds = React.useMemo(() => {
    const facilityIds = sortBy(flatten(map(hubs, "facilityIds")));
    return map(facilities, "id").filter(id => {
      return facilityIds.indexOf(id) === -1;
    });
  }, [facilities, hubs]);

  const unattachedFacilities = React.useMemo(() => {
    return facilities.filter(facility => {
      return unattachedFacilityIds.indexOf(facility.id) !== -1;
    });
  }, [unattachedFacilityIds, facilities]);

  const hubsWithFacilities: HubWithFacilities[] = React.useMemo(() => {
    return hubs.map(hub => {
      const attachedFacilities = getAttachedFacilitiesOfHub(hub);
      return {
        attachedFacilities,
        hub
      };
    });
  }, [hubs, getAttachedFacilitiesOfHub]);

  const getFilteredFacilities = React.useCallback(
    (items: Facility[]): Facility[] => {
      return items.filter(facility => {
        const name = facility.name ? facility.name.fi || "" : "";
        return name.toLowerCase().includes(search.toLowerCase());
      });
    },
    [search]
  );

  const filteredUnattachedFacilities = React.useMemo(() => {
    return getFilteredFacilities(unattachedFacilities);
  }, [getFilteredFacilities, unattachedFacilities]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const shouldShowHub = React.useCallback(
    (name: string, filteredFacilities: Facility[]): boolean => {
      return (
        name.toLowerCase().includes(search.toLowerCase()) ||
        !!filteredFacilities.length
      );
    },
    [search]
  );

  const noResults = React.useMemo(() => {
    if (filteredUnattachedFacilities.length) {
      return false;
    }
    let showNoResultsMessage = true;

    forEach(hubsWithFacilities, item => {
      const hub = item.hub;
      const name = hub.name ? hub.name.fi || "" : "";
      const attachedFacilities = item.attachedFacilities;
      const filteredFacilities = getFilteredFacilities(attachedFacilities);
      const showHub = shouldShowHub(name, filteredFacilities);

      if (showHub) {
        showNoResultsMessage = false;
        return true;
      }
    });

    return showNoResultsMessage;
  }, [
    filteredUnattachedFacilities,
    getFilteredFacilities,
    hubsWithFacilities,
    shouldShowHub
  ]);

  const moveToCreateFacility = () => {
    history.push("/facilities/create");
  };

  const moveToCreateHub = () => {
    history.push("/hubs/create");
  };

  return (
    <>
      <Breadcrumb
        name={t("titleHubs")}
        canAddFacility={hasPermission(PERMISSIONS.FACILITY_CREATE)}
        canAddHub={hasPermission(PERMISSIONS.HUB_CREATE)}
        canGoback={false}
      />
      <Container isContent={true}>
        <Info />
        <Row hasSmallMargin={true}>
          <Column column={2} isFirstColumn={true}>
            <input
              className={fieldStyles["form-control"]}
              type="text"
              onChange={handleSearchChange}
              placeholder={t("placeholderSearchHubsOrFacilities")}
              value={search}
            />
          </Column>
        </Row>

        {noResults ? (
          <Row hasSmallMargin={true}>
            <Column>
              <p>{t("messageNoHubsOrFacilities")}</p>
            </Column>
          </Row>
        ) : (
          <Row hasSmallMargin={true}>
            <Column>
              <Panel hasBorder={true}>
                <Table
                  isBordered={true}
                  isCondensed={true}
                  isInsidePanel={true}
                >
                  <thead>
                    <tr>
                      <th className={styles.tableColumn}>{t("labelName")}</th>
                      <th className={styles.tableColumn}>{t("labelStatus")}</th>
                      <th className={styles.tableColumn}>{t("labelUsage")}</th>
                      <th className={styles.tableColumn}>
                        {t("labelCapacityTypes")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <FacilityList facilities={filteredUnattachedFacilities} />

                    {hubsWithFacilities.map(item => {
                      const shouldShowHubWithFacilities = (
                        hubWithFacilities: HubWithFacilities
                      ): boolean => {
                        const hub = hubWithFacilities.hub;
                        const name = hub.name ? hub.name.fi || "" : "";
                        const filteredFacilities = getFilteredFacilities(
                          hubWithFacilities.attachedFacilities
                        );
                        return shouldShowHub(name, filteredFacilities);
                      };

                      return (
                        shouldShowHubWithFacilities(item) && (
                          <Hub
                            key={item.hub.id}
                            facilities={getFilteredFacilities(
                              item.attachedFacilities
                            )}
                            hub={item.hub}
                          />
                        )
                      );
                    })}
                  </tbody>
                </Table>
              </Panel>
            </Column>
          </Row>
        )}
      </Container>
      <NaviBelow>
        {hasPermission(PERMISSIONS.FACILITY_CREATE) && (
          <Button
            isResponsive={true}
            size={ButtonSizes.SMALL}
            onClick={moveToCreateFacility}
            type="button"
          >
            {t("buttonCreateFacility")}
          </Button>
        )}
        {hasPermission(PERMISSIONS.HUB_CREATE) && (
          <Button
            isResponsive={true}
            size={ButtonSizes.SMALL}
            onClick={moveToCreateHub}
            type="button"
          >
            {t("buttonCreateHub")}
          </Button>
        )}
      </NaviBelow>
    </>
  );
};

export default withRouter(HubList);
