import { Field, Formik } from "formik";
import { get } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CRS } from "../../constants";
import { PortInput, PortLocationInput } from "../../generated/graphql";
import { PortProperties } from "../../util/mapUtils";
import { multiLanguageValidation } from "../../util/validationUtils";
import Button, { ButtonSizes } from "../Commons/Button";
import CheckboxField from "../Commons/CheckboxField";
import Column from "../Commons/Column";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import InputGroup, { InputGroupText } from "../Commons/InputGroup";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";
import ModalHeader from "../Commons/ModalHeader";
import Row from "../Commons/Row";

const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    streetAddress: multiLanguageValidation,
    city: multiLanguageValidation
  })
});

interface Props {
  initialAddress: string | null;
  initialAddressSv: string | null;
  initialCity: string | null;
  isOpen: boolean;
  location: PortLocationInput | null;
  onClickCancel: () => void;
  onClickDelete: (index: number) => void;
  onClickOk: (port: PortInput, index?: number) => void;
  portProperties: PortProperties | null;
  facilityType: string;
}

const PortEditModal: React.FC<Props> = ({
  initialAddress,
  initialAddressSv,
  initialCity,
  isOpen,
  location,
  onClickCancel,
  onClickDelete,
  onClickOk,
  portProperties,
  facilityType
}) => {
  const getInitialValueByPath = React.useCallback(
    (path: string, defaultValue: any = "") => {
      return get(portProperties, path) || defaultValue;
    },
    [portProperties]
  );

  const isFacilityForBicycle = () => {
    return facilityType === "BICYCLE";
  };
  const isFacilityForCar = () => {
    return facilityType === "CAR";
  };

  const { t } = useTranslation("facilityEdit");
  const initialValues = React.useMemo(
    () => ({
      location: location
        ? location
        : portProperties
        ? portProperties.location
        : { crs: CRS, coordinates: [], type: "Point" },
      bicycle: portProperties ? portProperties.bicycle : isFacilityForBicycle(),
      entry: portProperties ? portProperties.entry : isFacilityForCar(),
      exit: portProperties ? portProperties.exit : isFacilityForCar(),
      pedestrian: portProperties ? portProperties.pedestrian : false,
      address: {
        streetAddress: {
          fi: portProperties
            ? getInitialValueByPath("address.streetAddress.fi")
            : initialAddress || "",
          sv: portProperties
            ? getInitialValueByPath("address.streetAddress.sv")
            : initialAddressSv || initialAddress || "",
          en: portProperties
            ? getInitialValueByPath("address.streetAddress.en")
            : initialAddress || ""
        },
        city: {
          fi: portProperties
            ? getInitialValueByPath("address.city.fi")
            : initialCity || "",
          sv: portProperties
            ? getInitialValueByPath("address.city.sv")
            : initialCity || "",
          en: portProperties
            ? getInitialValueByPath("address.city.en")
            : initialCity || ""
        }
      }
    }),
    [
      getInitialValueByPath,
      location,
      portProperties,
      initialAddress,
      initialCity
    ]
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={values => {
        onClickOk(values, portProperties ? portProperties.index : undefined);
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, resetForm, values }) => {
        const handleClickCancel = () => {
          resetForm();
          onClickCancel();
        };

        const handleClickDelete = () => {
          if (portProperties) {
            onClickDelete(portProperties.index);
          }
        };

        return (
          <Modal isOpen={isOpen}>
            <ModalContent>
              <ModalHeader title={t("titlePortModal")} />
              <ModalBody>
                <Row>
                  <Column column={3} isFirstColumn={true}>
                    <FormGroup hasLargeMarginBottom={true}>
                      <Field
                        name="entry"
                        component={CheckboxField}
                        hasMargin={false}
                        label={t("portEntry")}
                      />
                      <br />
                      <Field
                        name="exit"
                        component={CheckboxField}
                        hasMargin={false}
                        label={t("portExit")}
                      />
                      <br />
                      <Field
                        name="pedestrian"
                        component={CheckboxField}
                        hasMargin={false}
                        label={t("portPedestrian")}
                      />
                      <br />
                      <Field
                        name="bicycle"
                        component={CheckboxField}
                        hasMargin={false}
                        label={t("portBicycle")}
                      />
                    </FormGroup>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <label>{t("labelStreetAddress")}</label>
                  </Column>
                </Row>
                <Row>
                  <Column column={3} isFirstColumn={true}>
                    <FormGroup hasLargeMarginBottom={true}>
                      <InputGroup>
                        <Field
                          name="address.streetAddress.fi"
                          type="text"
                          maxLength={255}
                          component={InputField}
                          withAddon={true}
                        />
                        <InputGroupText>fi</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                  <Column column={3}>
                    <FormGroup hasLargeMarginBottom={true}>
                      <InputGroup>
                        <Field
                          name="address.streetAddress.sv"
                          type="text"
                          maxLength={255}
                          component={InputField}
                          withAddon={true}
                        />
                        <InputGroupText>sv</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                  <Column column={3} isLastColumn={true}>
                    <FormGroup hasLargeMarginBottom={true}>
                      <InputGroup>
                        <Field
                          name="address.streetAddress.en"
                          type="text"
                          maxLength={255}
                          component={InputField}
                          withAddon={true}
                        />
                        <InputGroupText>en</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                </Row>

                <Row>
                  <Column>
                    <label>{t("labelCity")}</label>
                  </Column>
                </Row>
                <Row>
                  <Column column={3} isFirstColumn={true}>
                    <FormGroup hasLargeMarginBottom={true}>
                      <InputGroup>
                        <Field
                          name="address.city.fi"
                          type="text"
                          maxLength={255}
                          component={InputField}
                          withAddon={true}
                        />
                        <InputGroupText>fi</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                  <Column column={3}>
                    <FormGroup hasLargeMarginBottom={true}>
                      <InputGroup>
                        <Field
                          name="address.city.sv"
                          type="text"
                          maxLength={255}
                          component={InputField}
                          withAddon={true}
                        />
                        <InputGroupText>sv</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                  <Column column={3} isLastColumn={true}>
                    <FormGroup hasLargeMarginBottom={true}>
                      <InputGroup>
                        <Field
                          name="address.city.en"
                          type="text"
                          maxLength={255}
                          component={InputField}
                          withAddon={true}
                        />
                        <InputGroupText>en</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  size={ButtonSizes.XSMALL_SECONDARY}
                  onClick={handleClickCancel}
                  type="button"
                >
                  {t("buttonPortCancel")}
                </Button>
                {portProperties && (
                  <Button
                    size={ButtonSizes.XSMALL_ALERT}
                    onClick={handleClickDelete}
                    type="button"
                  >
                    {t("buttonPortDelete")}
                  </Button>
                )}
                <Button
                  size={ButtonSizes.XSMALL}
                  onClick={handleSubmit}
                  type="button"
                >
                  {t("buttonPortOk")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default PortEditModal;
