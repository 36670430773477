import * as React from "react";
import { useTranslation } from "react-i18next";
import { UnavailableCapacity } from "../../generated/graphql";
import { translateValue } from "../../util/translateUtils";
import Panel from "../Commons/Panel";
import Table from "../Commons/Table";

interface Props {
  unavailableCapacities: UnavailableCapacity[];
}

const UnavailableCapacityTable: React.FC<Props> = ({
  unavailableCapacities
}) => {
  const { t } = useTranslation("commons");

  if (!unavailableCapacities || !unavailableCapacities.length) {
    return null;
  }

  return (
    <Panel hasBorder={true}>
      <Table
        isBordered={true}
        isCondensed={true}
        isInsidePanel={true}
        isStriped={true}
      >
        <thead>
          <tr>
            <th>{t("labelCapacityType")}</th>
            <th>{t("labelUsage")}</th>
            <th>{t("labelUnavailable")}</th>
          </tr>
        </thead>
        <tbody>
          {unavailableCapacities.map((unavailableCapacity, i) => {
            const shouldShowCapacityType = () => {
              if (i === 0) {
                return true;
              } else if (
                unavailableCapacities[i - 1].capacityType ===
                unavailableCapacity.capacityType
              ) {
                return false;
              } else {
                return true;
              }
            };

            return (
              <tr key={i}>
                <td>
                  {shouldShowCapacityType() &&
                    translateValue(
                      "capacityType",
                      unavailableCapacity.capacityType,
                      t
                    )}
                </td>
                <td>{translateValue("usage", unavailableCapacity.usage, t)}</td>
                <td>{unavailableCapacity.capacity}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Panel>
  );
};

export default UnavailableCapacityTable;
