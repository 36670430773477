import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import * as Yup from "yup";

import { useResetPasswordMutation } from "../../generated/graphql";
import logoUrl from "../../resources/assets/img/hsl-liipi-logo2.png";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import styles from "./Reset.module.css";

const ResetPassword = () => {
  const { t } = useTranslation(["commons", "userList", "login"]);
  const searchParams = new URLSearchParams(useLocation().search);
  const [resetPassword] = useResetPasswordMutation();
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("commons:errorBasicRequirements"))
      .test("regex", t("commons:errorBadPassword"), val => {
        const regExp = new RegExp(
          "^(?=.*\\d)(?=.*[a-zåäö])(?!.*\\s)(?=.*[A-ZÅÄÖ]).{8,50}$"
        );
        return regExp.test(val);
      }),
    confirmPassword: Yup.string()
      .required(t("commons:errorBasicRequirements"))
      .oneOf(
        [Yup.ref("password"), null],
        t("commons:errorPasswordConfirmationMismatch")
      )
  });

  const token = searchParams.get("token") || "";
  return (
    <>
      <div className={styles.resetContainer}>
        <Row>
          <Column medium={12} isLastColumn={true}>
            <Panel hasBorder={true} className={styles.resetPanel}>
              <div className="panel-body">
                <Row hasLargeMargin={true}>
                  <Column medium={12}>
                    <img
                      className={styles.logo}
                      src={logoUrl}
                      alt="HSL Liityntäpysäköinti"
                    />
                  </Column>
                </Row>
                <div className="text-center">
                  <h3>
                    <i className="fa fa-lock fa-4x" />
                  </h3>
                  <h2 className="text-center">
                    {t("commons:createNewPassword")}
                  </h2>
                  <p>{t("commons:errorBadPassword")}</p>
                  <div className="panel-body">
                    <Formik
                      initialValues={{
                        password: "",
                        confirmPassword: ""
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async values => {
                        try {
                          await resetPassword({
                            variables: {
                              token,
                              password: values.password
                            }
                          });
                          setSuccessMessage(t("commons:passwordChanged"));
                          setErrorMessage("");
                          setTimeout(() => {
                            window.location.href = "/";
                          }, 3000);
                        } catch (error) {
                          setSuccessMessage("");
                          setErrorMessage(t("commons:passwordCannotChange"));
                        }
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <Row>
                            <Column medium={12}>
                              <FormGroup hasLargeMarginBottom={true}>
                                <Field
                                  component={InputField}
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  placeholder={t("login:labelPassword")}
                                />
                              </FormGroup>
                            </Column>
                          </Row>
                          <Row>
                            <Column medium={12}>
                              <FormGroup hasLargeMarginBottom={true}>
                                <Field
                                  component={InputField}
                                  name="confirmPassword"
                                  type="password"
                                  className="form-control"
                                  placeholder={t(
                                    "userList:labelConfirmNewPassword"
                                  )}
                                />
                              </FormGroup>
                            </Column>
                          </Row>

                          {errors.password && touched.password ? (
                            <span style={{ color: "red", marginBottom: "4px" }}>
                              {errors.password}
                            </span>
                          ) : null}
                          {
                            <span style={{ color: "#5ed49d" }}>
                              {successMessage}
                            </span>
                          }
                          {<span style={{ color: "red" }}>{errorMessage}</span>}
                          <div className="form-group">
                            <Button size={ButtonSizes.SMALL} type="submit">
                              {t("commons:send")}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Panel>
          </Column>
        </Row>
      </div>
    </>
  );
};

export default ResetPassword;
