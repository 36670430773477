import classNames from "classnames";
import * as React from "react";
import styles from "./ButtonGroupSelector.module.css";
import { Option } from "./SelectField";

interface Props {
  onChange: (value: any) => void;
  options: Option[];
  value: string | number;
}

const ButtonGroupSelector: React.FC<Props> = ({ onChange, options, value }) => {
  return (
    <div className={styles.btnGroup}>
      {options.map(option => {
        return (
          <button
            key={option.value}
            className={classNames(styles.btn, styles["btn-xs"], {
              [styles.active]: value === option.value
            })}
            onClick={() => {
              onChange(option.value);
            }}
            type="button"
          >
            <span className={styles.checkmark}>✓ </span>
            {option.label}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonGroupSelector;
