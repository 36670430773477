import { Field } from "formik";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
  AuthenticationMethodListQuery,
  CapacityTypeListQuery,
  DayTypeListQuery,
  PaymentMethodListQuery,
  PricingMethodListQuery,
  ServiceListQuery,
  UsageListQuery
} from "../../generated/graphql";
import { toPascalCase, translateValue } from "../../util/translateUtils";
import checkboxStyles from "../Commons/Checkbox.module.css";
import Column from "../Commons/Column";

import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import InputGroup, { InputGroupText } from "../Commons/InputGroup";
import RadioButtonField from "../Commons/RadioButtonField";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";
import iconStyles from "../FacilityList/FacilityList.module.css";
import PricingTable from "./PricingTable";
interface Params {
  id?: string;
}
interface Props extends RouteComponentProps<Params> {
  dayTypesData: DayTypeListQuery;
  paymentMethodsData: PaymentMethodListQuery;
  authenticationMethodsData: AuthenticationMethodListQuery;
  pricingMethodsData: PricingMethodListQuery;
  servicesData: ServiceListQuery;
  usagesData: UsageListQuery;
  capacityTypesData: CapacityTypeListQuery;
  values: any;
  pricing: any;
  facilityType: string;
  timetable: boolean;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: any) => void;
}

const PriceAndPaymentBicycle: React.FC<Props> = ({
  authenticationMethodsData,
  capacityTypesData,
  dayTypesData,
  servicesData,
  values,
  usagesData,
  pricing,
  handleChange,
  setFieldValue
}) => {
  const { t } = useTranslation([
    "facilityEdit",
    "facilityList",
    "facilityProfile",
    "commons"
  ]);

  const allAuthenticationMethods = authenticationMethodsData
    ? authenticationMethodsData.authenticationMethods
    : [];
  const authenticationMethodOptions: Option[] = allAuthenticationMethods.map(
    item => ({
      label: translateValue("commons:authenticationMethod", item, t),
      value: item
    })
  );

  const allServices = servicesData ? servicesData.services : [];
  const serviceOptions: Option[] = allServices.map(service => {
    return {
      label: translateValue("commons:service", service, t),
      value: service
    };
  });
  const allUsages = usagesData ? usagesData.usages : [];
  const usageOptions = allUsages.map(item => {
    return {
      label: translateValue("commons:usage", item, t),
      value: item
    };
  });
  const allCapacityTypes =
    capacityTypesData && capacityTypesData.capacityTypes
      ? capacityTypesData.capacityTypes
      : [];
  const capacityTypeOptions = allCapacityTypes.map(capacityType => {
    return {
      label: translateValue("commons:capacityType", capacityType, t),
      value: capacityType
    };
  });
  const allDayTypes = dayTypesData ? dayTypesData.dayTypes : [];
  const dayTypeOptions = allDayTypes.map(item => {
    return {
      label: translateValue("commons:dayType", item, t),
      value: item
    };
  });

  const handleCheckBox = (e: any) => {
    handleChange(e);
    if (!e.target.checked && values.pricing.length) {
      values.pricing[0].time.from = "";
      values.pricing[0].time.until = "";
      setTimeSelected(false);
      setFieldValue("pricingMethod", "PARK_AND_RIDE_247_FREE");
      removePricingRows();
    } else {
      addPricingRow();
      setFieldValue("pricingMethod", "CUSTOM");
      setTimeSelected(true);
    }
  };
  const addPricingRow = () => {
    if (!values.pricing.length) {
      values.pricing.push({
        capacityType: "BICYCLE",
        dayType: "BUSINESS_DAY",
        maxCapacity: 0,
        price: {
          fi: null,
          sv: null,
          en: null
        },
        priceExtra: null,
        priceOther: null,
        time: {
          from: "00",
          until: "24"
        },
        usage: "PARK_AND_RIDE"
      });
    }
  };
  const removePricingRows = () => {
    values.pricing.length = 0;
    values.pricing.push({
      capacityType: "BICYCLE",
      dayType: "BUSINESS_DAY",
      maxCapacity: values.builtCapacity.BICYCLE,
      price: {
        fi: null,
        sv: null,
        en: null
      },
      priceExtra: null,
      priceOther: null,
      time: {
        from: "00",
        until: "24"
      },
      usage: "PARK_AND_RIDE"
    });
  };

  const [timeSelected, setTimeSelected] = React.useState(
    values.pricing.length &&
      values.pricing[0].time.from !== null &&
      values.pricing[0].time.from !== "" &&
      values.pricingMethod === "CUSTOM"
  );
  return (
    <>
      <h3>{t("titlePaymentAndServices")}</h3>
      <Row>
        <Column className={iconStyles.facilityIconsContainer}>
          <Field
            component={RadioButtonField}
            checked={true}
            icon={
              <span
                title={translateValue("capacityType", "BICYCLE", t)}
                className={iconStyles["facilityIcon" + toPascalCase("BICYCLE")]}
              />
            }
            name="pricingMethod"
            label={t("commons:freeOfCharge")}
            title={t("commons:freeOfCharge")}
            value="free"
          />
        </Column>
      </Row>
      <h4>{t("facilityEdit:labelTime")}</h4>
      <Field
        type="checkbox"
        defaultChecked={values.pricingMethod === "CUSTOM"}
        onChange={(e: any) => {
          handleCheckBox(e);
        }}
        className={checkboxStyles.checkbox}
      />
      <span>{t("commons:Yes")}</span>
      <Row />
      {!!timeSelected && (
        <>
          <PricingTable
            capacityTypeOptions={capacityTypeOptions}
            dayTypeOptions={dayTypeOptions}
            name="pricing"
            pricing={pricing}
            setFieldValue={setFieldValue}
            usageOptions={usageOptions}
            facilityType={values.type}
            pricingMethod={values.pricingMethod}
          />
        </>
      )}
      <h4>{t("titleAuthentication")}</h4>
      <Row>
        <Column longColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <Field
              isClearable={true}
              isMulti={true}
              name="authenticationMethods"
              options={authenticationMethodOptions}
              placeholder={t("titleAuthentication")}
              component={SelectField}
            />
          </FormGroup>
        </Column>
      </Row>

      <h4>{t("titleServices")}</h4>
      <Row>
        <Column longColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <Field
              isClearable={true}
              isMulti={true}
              name="services"
              options={serviceOptions}
              placeholder={t("placeholderServices")}
              component={SelectField}
            />
          </FormGroup>
        </Column>
      </Row>
      <h4>{t("titlePaymentDetail")}</h4>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="paymentInfo.detail.fi"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>fi</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
        <Column column={3}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="paymentInfo.detail.sv"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>sv</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
        <Column column={3} isLastColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="paymentInfo.detail.en"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>en</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
      </Row>
    </>
  );
};

export default withRouter(PriceAndPaymentBicycle);
