import { decode } from "base64-arraybuffer";
import { saveAs } from "file-saver";
import { Field, Form, Formik } from "formik";
import { capitalize } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { USER_ROLE } from "../../constants";
import {
  CapacityTypeListQuery,
  Facility,
  FacilityListQuery,
  Hub,
  HubListQuery,
  Operator,
  OperatorListQuery,
  RegionsWithFacilitiesQuery,
  RegionsWithHubsQuery,
  RegionWithHubs,
  UsageListQuery,
  useFacilityUsageReportMutation,
  useHubsAndFacilitiesReportMutation,
  useMaxUtilizationReportMutation,
  useRequestLogReportMutation
} from "../../generated/graphql";
import { dateToArray } from "../../util/dateUtils";
import { handleError } from "../../util/helpers";
import { translateValue } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import DatePickerField from "../Commons/DatePickerFields";
import { useErrorModalContext } from "../Commons/ErrorModal";
import FormGroup from "../Commons/FormGroup";
import { useLoginModalContext } from "../Commons/LoginModal";
import RadioButtonField from "../Commons/RadioButtonField";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";
import Info from "./Info";

interface Fields {
  [key: string]: string[];
}

const sortOptions = (a: Option, b: Option): number => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

const XLSXContentType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";

const FIELDS = {
  RANGE: "range",
  INTERVAL: "interval",
  OPERATOR: "operator",
  USAGES: "usages",
  CAPACITY_TYPES: "capacityTypes",
  REGIONS: "regions",
  HUBS: "hubs",
  FACILITIES: "facilities",
  REGUEST_LOG_INTERVAL: "requestLogInterval"
};

const Fields: Fields = {
  facilityUsage: [
    FIELDS.RANGE,
    FIELDS.INTERVAL,
    FIELDS.OPERATOR,
    FIELDS.USAGES,
    FIELDS.CAPACITY_TYPES,
    FIELDS.REGIONS,
    FIELDS.HUBS,
    FIELDS.FACILITIES
  ],
  hubsAndFacilities: [],
  maxUtilization: [
    FIELDS.RANGE,
    FIELDS.OPERATOR,
    FIELDS.USAGES,
    FIELDS.CAPACITY_TYPES,
    FIELDS.REGIONS,
    FIELDS.HUBS,
    FIELDS.FACILITIES
  ],
  requestLog: [FIELDS.RANGE, FIELDS.REGUEST_LOG_INTERVAL]
};

const REPORT_TYPE = {
  FACILITY_USAGE: "facilityUsage",
  MAX_UTILIZATION: "maxUtilization",
  HUBS_AND_FACILITIES: "hubsAndFacilities",
  REQUEST_LOG: "requestLog"
};

interface Props {
  capacityTypesData: CapacityTypeListQuery | undefined;
  facilitiesData: FacilityListQuery | undefined;
  hubsData: HubListQuery | undefined;
  operatorsData: OperatorListQuery | undefined;
  regionsData: RegionsWithHubsQuery | undefined;
  regionsWithFacilitiesData: RegionsWithFacilitiesQuery | undefined;
  usagesData: UsageListQuery | undefined;
}

const Reports: React.FC<Props> = ({
  capacityTypesData,
  facilitiesData,
  hubsData,
  operatorsData,
  regionsData,
  regionsWithFacilitiesData,
  usagesData
}) => {
  const { getUserOperatorId, getUserRegionId, hasPermission } = useAuth();
  const userOperatorId = getUserOperatorId();
  const userRegionsId = getUserRegionId();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const { t } = useTranslation(["reports", "commons"]);
  const [downloadFacilityUsageReport] = useFacilityUsageReportMutation();
  const [
    downloadHubsAndFacilitiesReport
  ] = useHubsAndFacilitiesReportMutation();
  const [downloadMaxUtilizationReport] = useMaxUtilizationReportMutation();
  const [downloadRequestLogReport] = useRequestLogReportMutation();
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  const intervalOptions: Option[] = ["5", "10", "15", "30", "60"].map(val => {
    return {
      label: `${val} min`,
      value: val
    };
  });

  const requestLogIntervalOptions: Option[] = ["HOUR", "DAY", "MONTH"].map(
    val => {
      return {
        label: t(`requestLogInterval${capitalize(val)}`),
        value: val
      };
    }
  );

  const allOperators: Operator[] =
    operatorsData && operatorsData.operators && operatorsData.operators.results
      ? operatorsData.operators.results
      : [];
  const operatorOptions: Option[] = allOperators.map(operator => {
    return {
      value: operator.id.toString(),
      label: operator.name ? operator.name.fi || "" : ""
    };
  });

  const allUsages: string[] =
    usagesData && usagesData.usages ? usagesData.usages : [];
  const usageOptions: Option[] = allUsages
    .map(usage => ({
      value: usage,
      label: translateValue("commons:usage", usage, t)
    }))
    .sort(sortOptions);

  const allCapacityTypes: string[] =
    capacityTypesData && capacityTypesData.capacityTypes
      ? capacityTypesData.capacityTypes
      : [];
  const capacityTypeOptions: Option[] = allCapacityTypes
    .map(capacityType => ({
      value: capacityType,
      label: translateValue("commons:capacityType", capacityType, t)
    }))
    .sort(sortOptions);

  const allRegions: RegionWithHubs[] =
    regionsData && regionsData.regionsWithHubs
      ? regionsData.regionsWithHubs
      : [];
  const regionOptions: Option[] = allRegions
    .map(region => ({
      value: region.id,
      label: region.name ? region.name.fi || "" : ""
    }))
    .sort(sortOptions);

  const allHubs: Hub[] =
    hubsData && hubsData.hubs && hubsData.hubs ? hubsData.hubs.hubs : [];

  const getFilteredHubs = (regionIds: string[]): Hub[] => {
    const hubIds: string[] = [];
    const newHubs: Hub[] = [];
    allRegions
      .filter(region =>
        regionIds.length ? regionIds.indexOf(region.id) !== -1 : true
      )
      .forEach(region => hubIds.push(...region.hubIds));

    hubIds.forEach(hubId => {
      const hub = allHubs.find(item => item.id.toString() === hubId);
      if (hub) {
        newHubs.push(hub);
      }
    });
    return newHubs;
  };

  const getHubOptions = (items: Hub[]): Option[] => {
    return items
      .map(hub => ({
        label: hub.name ? hub.name.fi || "" : "",
        value: hub.id.toString()
      }))
      .sort(sortOptions);
  };

  // Get facilities which are allowed to operator users with defined region
  const allowedFacilities = React.useMemo(() => {
    const region =
      regionsWithFacilitiesData &&
      regionsWithFacilitiesData.regionsWithFacilities &&
      regionsWithFacilitiesData.regionsWithFacilities.find(
        item => item.id === userRegionsId
      );
    return userRegionsId ? (region && region.facilityIds) || [] : [];
  }, [regionsWithFacilitiesData, userRegionsId]);

  const allFacilities: Facility[] =
    facilitiesData && facilitiesData.facilities
      ? facilitiesData.facilities.facilities.filter(facility =>
          userOperatorId
            ? userOperatorId === facility.operatorId ||
              allowedFacilities.includes(facility.id)
            : true
        )
      : [];

  const getFilteredFacilities = (
    regionIds: string[],
    hubIds: string[]
  ): Facility[] => {
    if (!regionIds.length && !hubIds.length) {
      return allFacilities;
    }

    const newFacilities: Facility[] = [];
    const facilityIds: string[] = [];
    const hubItems = hubIds.length
      ? hubIds
          .map(hubId => allHubs.find(item => item.id.toString() === hubId))
          .filter(hub => !!hub)
      : getFilteredHubs(regionIds);

    hubItems.forEach(hub => {
      facilityIds.push(...(hub ? hub.facilityIds : []));
    });

    facilityIds.forEach(facilityId => {
      const facility = allFacilities.find(
        item => item && item.id === facilityId
      );
      if (facility) {
        newFacilities.push(facility);
      }
    });

    return newFacilities;
  };

  const getFacilityOptions = (items: Facility[]): Option[] => {
    return items
      .map((facility: any) => ({
        label: facility.name.fi,
        value: facility.id.toString()
      }))
      .sort(sortOptions);
  };

  let filteredHubs: Hub[] = getFilteredHubs([]);
  let hubOptions: Option[] = getHubOptions(filteredHubs);
  let filteredFacilities: Facility[] = getFilteredFacilities([], []);
  let facilityOptions: Option[] = getFacilityOptions(filteredFacilities);

  const addWaitClass = () => {
    document.documentElement.classList.add("wait");
  };

  const removeWaitClass = () => {
    document.documentElement.classList.remove("wait");
  };

  const getReportFilename = (type: string, params: any): string => {
    let name = t(`${type}Label`);

    if (
      type === REPORT_TYPE.FACILITY_USAGE ||
      type === REPORT_TYPE.MAX_UTILIZATION ||
      type === REPORT_TYPE.REQUEST_LOG
    ) {
      const startDate = params.startDate;
      name += `_${startDate[0]}${("0" + startDate[1]).slice(-2)}${(
        "0" + startDate[2]
      ).slice(-2)}`;

      if (!/^\s*$/.test(params.endDate)) {
        const endDate = params.endDate;
        name += `_${endDate[0]}${("0" + endDate[1]).slice(-2)}${(
          "0" + endDate[2]
        ).slice(-2)}`;
      }
    } else if (type === REPORT_TYPE.HUBS_AND_FACILITIES) {
      const date = new Date();
      name += `_${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(
        -2
      )}${("0" + date.getDate()).slice(-2)}`;
    }

    name += ".xlsx";
    name = name.replace(/ /g, "_");

    return name;
  };

  const getOperatorName = (oId: string) => {
    const operator = allOperators.find(item => item.id === oId);
    return operator ? operator.name.fi : "";
  };

  return (
    <>
      <Breadcrumb name={t("reports")} canGoback={false} />
      <Container isContent={true}>
        <Formik
          initialValues={{
            capacityTypes: ["CAR"],
            endDate: now,
            facilities: [],
            hubs: [],
            interval: "60",
            operators:
              userOperatorId && !userRegionsId ? [userOperatorId || "0"] : [],
            startDate: new Date(year, month, 1),
            regions: [],
            reportType: "facilityUsage",
            requestLogInterval: "DAY",
            usages: []
          }}
          onSubmit={async ({
            capacityTypes,
            endDate,
            facilities,
            hubs,
            interval,
            operators,
            regions,
            reportType,
            requestLogInterval,
            startDate,
            usages
          }) => {
            addWaitClass();

            switch (reportType) {
              case REPORT_TYPE.FACILITY_USAGE: {
                const generateFacilityUsageReport = async () => {
                  const facilityUsageVariables = {
                    capacityTypes,
                    endDate: dateToArray(endDate),
                    facilities,
                    hubs,
                    interval: Number(interval),
                    operators,
                    regions,
                    startDate: dateToArray(startDate),
                    usages
                  };

                  try {
                    const facilityUsageData: any = await downloadFacilityUsageReport(
                      {
                        variables: { report: { ...facilityUsageVariables } }
                      }
                    );

                    const facilityUsageReport =
                      facilityUsageData &&
                      facilityUsageData.data &&
                      facilityUsageData.data.facilityUsageReport;

                    if (facilityUsageReport) {
                      const filename = getReportFilename(
                        reportType,
                        facilityUsageVariables
                      );
                      saveAs(
                        new Blob([decode(facilityUsageReport)], {
                          type: XLSXContentType
                        }),
                        filename
                      );
                    }
                    removeWaitClass();
                  } catch (e) {
                    removeWaitClass();
                    handleError(e, openErrorModal, t, () =>
                      openLoginModal({
                        callbackFn: generateFacilityUsageReport
                      })
                    );
                  }
                };

                generateFacilityUsageReport();
                break;
              }
              case REPORT_TYPE.HUBS_AND_FACILITIES: {
                const generateHubsAndFacilitiesReport = async () => {
                  try {
                    const hubsAndFacilitiesData: any = await downloadHubsAndFacilitiesReport();

                    const hubsAndFacilitiesReport =
                      hubsAndFacilitiesData &&
                      hubsAndFacilitiesData.data &&
                      hubsAndFacilitiesData.data.hubsAndFacilitiesReport;

                    if (hubsAndFacilitiesReport) {
                      const filename = getReportFilename(reportType, {});
                      saveAs(
                        new Blob([decode(hubsAndFacilitiesReport)], {
                          type: XLSXContentType
                        }),
                        filename
                      );
                    }
                    removeWaitClass();
                  } catch (e) {
                    removeWaitClass();
                    handleError(e, openErrorModal, t, () =>
                      openLoginModal({
                        callbackFn: generateHubsAndFacilitiesReport
                      })
                    );
                  }
                };
                generateHubsAndFacilitiesReport();
                break;
              }
              case REPORT_TYPE.MAX_UTILIZATION: {
                const generateMaxUtilizationReport = async () => {
                  const maxUtilizationVariables = {
                    capacityTypes,
                    endDate: dateToArray(endDate),
                    facilities,
                    hubs,
                    operators,
                    regions,
                    startDate: dateToArray(startDate),
                    usages
                  };
                  try {
                    const maxUtilizationData: any = await downloadMaxUtilizationReport(
                      {
                        variables: { report: { ...maxUtilizationVariables } }
                      }
                    );

                    const maxUtilizationReport =
                      maxUtilizationData &&
                      maxUtilizationData.data &&
                      maxUtilizationData.data.maxUtilizationReport;

                    if (maxUtilizationReport) {
                      const filename = getReportFilename(
                        reportType,
                        maxUtilizationVariables
                      );
                      saveAs(
                        new Blob([decode(maxUtilizationReport)], {
                          type: XLSXContentType
                        }),
                        filename
                      );
                    }
                    removeWaitClass();
                  } catch (e) {
                    removeWaitClass();
                    handleError(e, openErrorModal, t, () =>
                      openLoginModal({
                        callbackFn: generateMaxUtilizationReport
                      })
                    );
                  }
                };

                generateMaxUtilizationReport();
                break;
              }
              case REPORT_TYPE.REQUEST_LOG: {
                const generateRequestLogReport = async () => {
                  const requestLogVariables = {
                    endDate: dateToArray(endDate),
                    requestLogInterval,
                    startDate: dateToArray(startDate)
                  };

                  try {
                    const requestLogData: any = await downloadRequestLogReport({
                      variables: { report: { ...requestLogVariables } }
                    });

                    const requestLogResponse =
                      requestLogData &&
                      requestLogData.data &&
                      requestLogData.data.requestLogReport;

                    if (requestLogResponse) {
                      const filename = getReportFilename(
                        reportType,
                        requestLogVariables
                      );
                      saveAs(
                        new Blob([decode(requestLogResponse)], {
                          type: XLSXContentType
                        }),
                        filename
                      );
                    }
                    removeWaitClass();
                  } catch (e) {
                    removeWaitClass();
                    handleError(e, openErrorModal, t, () =>
                      openLoginModal({
                        callbackFn: generateRequestLogReport
                      })
                    );
                  }
                };

                generateRequestLogReport();
                break;
              }
            }
          }}
        >
          {({ setFieldValue, values }) => {
            const { reportType } = values;

            const showField = (controlName: string): boolean => {
              return Fields[reportType].indexOf(controlName) !== -1;
            };

            const handleFieldChange = (name: string, value: any) => {
              switch (name) {
                case "endDate":
                  if (value && value < values.startDate) {
                    setFieldValue("startDate", value);
                  }
                  setFieldValue(name, value);
                  break;
                case "hubs": {
                  filteredFacilities = getFilteredFacilities(
                    values.regions,
                    value
                  );
                  facilityOptions = getFacilityOptions(filteredFacilities);
                  const newFacilities = values.facilities.filter(
                    facility =>
                      facilityOptions.findIndex(
                        (item: any) => item.value === facility
                      ) !== -1
                  );

                  setFieldValue("facilities", newFacilities);
                  setFieldValue("hubs", value);
                  break;
                }
                case "regions": {
                  filteredHubs = getFilteredHubs(value);
                  hubOptions = getHubOptions(filteredHubs);
                  const newHubs = values.hubs.filter(
                    hub =>
                      hubOptions.findIndex(
                        (item: any) => item.value === hub
                      ) !== -1
                  );
                  filteredFacilities = getFilteredFacilities(value, newHubs);
                  facilityOptions = getFacilityOptions(filteredFacilities);
                  const newFacilities = values.facilities.filter(
                    facility =>
                      facilityOptions.findIndex(
                        (item: any) => item.value === facility
                      ) !== -1
                  );

                  setFieldValue("hubs", newHubs);
                  setFieldValue("facilities", newFacilities);
                  setFieldValue("regions", value);
                  break;
                }
                case "startDate":
                  if (value && value > values.endDate) {
                    setFieldValue("endDate", value);
                  }
                  setFieldValue(name, value);
                  break;
              }
            };

            return (
              <Form>
                <Info />
                <Row>
                  <Column>
                    <Field
                      component={RadioButtonField}
                      checked={values.reportType === REPORT_TYPE.FACILITY_USAGE}
                      name="reportType"
                      label={t("facilityUsageLabel")}
                      title={t("facilityUsageTitle")}
                      value="facilityUsage"
                    />
                    <Field
                      component={RadioButtonField}
                      checked={
                        values.reportType === REPORT_TYPE.MAX_UTILIZATION
                      }
                      name="reportType"
                      label={t("maxUtilizationLabel")}
                      title={t("maxUtilizationTitle")}
                      value="maxUtilization"
                    />
                    <Field
                      component={RadioButtonField}
                      checked={
                        values.reportType === REPORT_TYPE.HUBS_AND_FACILITIES
                      }
                      name="reportType"
                      label={t("hubsAndFacilitiesLabel")}
                      title={t("hubsAndFacilitiesTitle")}
                      value="hubsAndFacilities"
                    />
                    <Field
                      component={RadioButtonField}
                      checked={values.reportType === REPORT_TYPE.REQUEST_LOG}
                      name="reportType"
                      label={t("requestLogLabel")}
                      title={t("requestLogTitle")}
                      value="requestLog"
                    />
                  </Column>
                </Row>

                <Row hasSmallMarginBottom={true}>
                  <Column>
                    <h3>{t(`${reportType}Label`)}</h3>
                    <Row>
                      <Column>
                        <p>{t(`${reportType}Info`)}</p>
                      </Column>
                    </Row>
                  </Column>
                </Row>

                {showField(FIELDS.RANGE) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelRange")} *</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <DatePickerField
                            isClearable={false}
                            maxDate={new Date()}
                            name="startDate"
                            onChange={handleFieldChange}
                            value={values.startDate}
                          />
                          {" - "}
                          <DatePickerField
                            isClearable={false}
                            maxDate={new Date()}
                            name="endDate"
                            onChange={handleFieldChange}
                            value={values.endDate}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.INTERVAL) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelInterval")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <Field
                            component={SelectField}
                            isClearable={false}
                            isSmallInput={true}
                            name="interval"
                            options={intervalOptions}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.REGUEST_LOG_INTERVAL) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelRequestLogInterval")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <Field
                            component={SelectField}
                            isClearable={false}
                            isSmallInput={true}
                            name="requestLogInterval"
                            options={requestLogIntervalOptions}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.OPERATOR) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelOperator")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          {!!userOperatorId && !userRegionsId ? (
                            <p>{getOperatorName(userOperatorId || "")}</p>
                          ) : (
                            <Field
                              component={SelectField}
                              isClearable={false}
                              isMulti={true}
                              name="operators"
                              options={operatorOptions}
                              placeholder={t("placeholderOperator")}
                            />
                          )}
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.USAGES) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelUsage")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <Field
                            component={SelectField}
                            isClearable={false}
                            isMulti={true}
                            name="usages"
                            options={usageOptions}
                            placeholder={t("placeholderUsage")}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.CAPACITY_TYPES) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelCapacity")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <Field
                            component={SelectField}
                            isClearable={false}
                            isMulti={true}
                            name="capacityTypes"
                            options={capacityTypeOptions}
                            placeholder={t("placeholderCapacity")}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.REGIONS) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelRegion")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <Field
                            component={SelectField}
                            isClearable={true}
                            isMulti={true}
                            name="regions"
                            onChange={handleFieldChange}
                            options={regionOptions}
                            placeholder={t("placeholderRegion")}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.HUBS) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelHub")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <Field
                            component={SelectField}
                            isClearable={true}
                            isMulti={true}
                            name="hubs"
                            onChange={handleFieldChange}
                            options={hubOptions}
                            placeholder={t("placeholderHub")}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {showField(FIELDS.FACILITIES) && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelFacility")}</label>
                      </Column>
                    </Row>
                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <Field
                            component={SelectField}
                            isClearable={true}
                            isMulti={true}
                            name="facilities"
                            options={facilityOptions}
                            placeholder={t("placeholderFacility")}
                          />
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                {hasPermission(PERMISSIONS.REPORT_GENERATE) && (
                  <Row hasSmallMargin={true}>
                    <Column isFirstColumn={true}>
                      <p>{t("labelNotice")}</p>
                      <Button size={ButtonSizes.SMALL} type="submit">
                        {t("buttonLoad")}
                      </Button>
                    </Column>
                  </Row>
                )}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default Reports;
