import * as React from "react";
import { useTranslation } from "react-i18next";
import { useOperatorDetailsQuery } from "../../generated/graphql";
import Column from "../Commons/Column";
import Row from "../Commons/Row";

interface Props {
  id: string;
}

const Operator: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["facilityProfile", "commons"]);

  const { data, error, loading } = useOperatorDetailsQuery({
    variables: { id: props.id.toString() }
  });

  if (loading) {
    return <div>{t("commons:loading")}</div>;
  }

  if (error || !data) {
    return <div>{t("commons:error")}</div>;
  }

  const operatorDetails = data.operatorDetails;

  return (
    <>
      <h4>{t("titleOperator")}</h4>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <p>
            <span>{operatorDetails.name.fi || "-"}</span> (fi)
          </p>
        </Column>
        <Column column={3}>
          <p>
            <span>{operatorDetails.name.sv || "-"}</span> (sv)
          </p>
        </Column>
        <Column column={3} isLastColumn={true}>
          <p>
            <span>{operatorDetails.name.en || "-"}</span> (en)
          </p>
        </Column>
      </Row>
    </>
  );
};

export default Operator;
