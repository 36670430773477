import * as React from "react";
import { useTranslation } from "react-i18next";
import { HubProfileQuery } from "../../generated/graphql";
import Column from "../Commons/Column";
import Row from "../Commons/Row";

interface Props {
  data: HubProfileQuery;
}

const HubBasicInfo: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation(["hubProfile", "commons"]);
  const details = data.hubDetails;

  const shouldShowStreetAddress = () => {
    return (
      !!data.hubDetails &&
      !!data.hubDetails.address &&
      !!data.hubDetails.address.streetAddress
    );
  };

  const shouldShowPostalCode = () => {
    return (
      !!data.hubDetails &&
      !!data.hubDetails.address &&
      !!data.hubDetails.address.postalCode
    );
  };

  const shouldShowCity = () => {
    return (
      !!data.hubDetails &&
      !!data.hubDetails.address &&
      !!data.hubDetails.address.city
    );
  };

  const shouldShowAddress = () => {
    return (
      shouldShowStreetAddress() || shouldShowPostalCode() || shouldShowCity()
    );
  };

  return (
    <>
      <h3 className="h3-first">{t("titleBasicInfo")}</h3>
      <h4>{t("labelName")}</h4>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <p>
            <span>{details.name ? details.name.fi : "-"}</span> (fi)
          </p>
        </Column>
        <Column column={3}>
          <p>
            <span>{details.name ? details.name.sv : "-"}</span> (sv)
          </p>
        </Column>
        <Column column={3} isLastColumn={true}>
          <p>
            <span>{details.name ? details.name.en : "-"}</span> (en)
          </p>
        </Column>
      </Row>
      {shouldShowAddress() && (
        <>
          <h4>{t("titleAddress")}</h4>
          {shouldShowStreetAddress() && (
            <Row>
              <Column column={3} isFirstColumn={true}>
                <p>
                  <span>
                    {details.address && details.address.streetAddress
                      ? details.address.streetAddress.fi
                      : "-"}
                  </span>{" "}
                  (fi)
                </p>
              </Column>
              <Column column={3}>
                <p>
                  <span>
                    {details.address && details.address.streetAddress
                      ? details.address.streetAddress.sv
                      : "-"}
                  </span>{" "}
                  (sv)
                </p>
              </Column>
              <Column column={3} isLastColumn={true}>
                <p>
                  <span>
                    {details.address && details.address.streetAddress
                      ? details.address.streetAddress.en
                      : "-"}
                  </span>{" "}
                  (en)
                </p>
              </Column>
            </Row>
          )}
          {shouldShowPostalCode() && (
            <Row>
              <Column column={3} isFirstColumn={true}>
                <p>
                  {details.address && details.address.postalCode
                    ? details.address.postalCode
                    : "-"}
                </p>
              </Column>
              <Column column={3}>
                <p>
                  {details.address && details.address.postalCode
                    ? details.address.postalCode
                    : "-"}
                </p>
              </Column>
              <Column column={3} isLastColumn={true}>
                <p>
                  {details.address && details.address.postalCode
                    ? details.address.postalCode
                    : "-"}
                </p>
              </Column>
            </Row>
          )}
          {shouldShowCity() && (
            <Row>
              <Column column={3} isFirstColumn={true}>
                <p>
                  <span>
                    {details.address && details.address.city
                      ? details.address.city.fi
                      : "-"}
                  </span>{" "}
                  (fi)
                </p>
              </Column>
              <Column column={3}>
                <p>
                  <span>
                    {details.address && details.address.city
                      ? details.address.city.sv
                      : "-"}
                  </span>{" "}
                  (sv)
                </p>
              </Column>
              <Column column={3} isLastColumn={true}>
                <p>
                  <span>
                    {details.address && details.address.city
                      ? details.address.city.en
                      : "-"}
                  </span>{" "}
                  (en)
                </p>
              </Column>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default HubBasicInfo;
