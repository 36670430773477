import React from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonSizes } from "../Commons/Button";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";

interface Props {
  isOpen: boolean;
  onClose: any;
  token: string;
}

const UpdateTokenModal: React.FC<Props> = ({ token, onClose, isOpen }) => {
  const { t } = useTranslation(["userList"]);
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Modal isCentered={true} isOpen={isOpen} width={600}>
        <ModalContent>
          <ModalBody>
            <div style={{ textAlign: "center" }}>
              {t("messageTokenReceived") + "  " + token}
            </div>
          </ModalBody>

          <ModalFooter isCentered={true}>
            <Button
              onClick={handleCloseModal}
              size={ButtonSizes.XSMALL}
              type="button"
            >
              {t("buttonTokenOk")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
export default UpdateTokenModal;
