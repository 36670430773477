import * as React from "react";
import Column from "./Column";
import Container from "./Container";
import styles from "./NaviBelow.module.css";
import Row from "./Row";

const NaviBelow: React.FC = ({ children }) => {
  return (
    <Container>
      <Row className={styles.naviBelow}>
        <Column small={6} isFirstColumn={true} />
        <Column small={6} isLastColumn={true} textRight={true}>
          <div className={styles.naviBelowButtons}>{children}</div>
        </Column>
      </Row>
    </Container>
  );
};

export default NaviBelow;
