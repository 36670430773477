import React from "react";
import Column from "./Column";
import styles from "./FormError.module.css";
import Row from "./Row";

interface Props {
  errors: string[];
}
const FormError: React.FC<Props> = ({ errors }) => {
  if (!errors || !errors.length) {
    return null;
  }
  return (
    <Row>
      <Column>
        <div className={styles.formError}>
          {errors &&
            errors.map((error, i) => (
              <div key={i}>
                <span>{error}</span>
              </div>
            ))}
        </div>
      </Column>
    </Row>
  );
};
export default FormError;
