import { Field, FieldProps } from "formik";
import * as React from "react";
import styles from "./Field.module.css";

interface Props extends FieldProps {
  checked: boolean;
  icon?: React.ReactElement;
  label: string;
  title: string;
  value: string;
}

const RadioButton: React.FC<Props> = ({
  checked,
  field: { name },
  icon,
  label,
  title,
  value
}) => {
  return (
    <label className={styles.radiobuttonLabel} title={title}>
      <Field checked={checked} type="radio" name={name} value={value} />
      {` ${label}`}
      {icon}
    </label>
  );
};

export default RadioButton;
