import classNames from "classnames";
import { FieldProps } from "formik";
import * as React from "react";
import styles from "./Checkbox.module.css";

interface Props extends FieldProps {
  className?: string;
  hasMargin?: boolean;
  label?: string;
}

const CheckboxField: React.FC<Props> = ({
  className,
  field,
  form,
  hasMargin = true,
  label,
  ...props
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const handleChange = () => {
    setFieldValue(name, !value);
  };

  return label ? (
    <label className={styles.checkboxLabel}>
      <input
        type="checkbox"
        className={classNames(styles.checkbox, {
          [styles.noMargin]: !hasMargin
        })}
        {...props}
        checked={value}
        onChange={handleChange}
      />
      {label}
    </label>
  ) : (
    <input
      type="checkbox"
      className={classNames(styles.checkbox, {
        [styles.noMargin]: !hasMargin
      })}
      {...props}
      checked={value}
      onChange={handleChange}
    />
  );
};

export default CheckboxField;
