import React from "react";
import ReactModal from "react-modal";

interface Props {
  bottom?: string;
  children: any;
  className?: string;
  contentLabel?: string;
  isCentered?: boolean;
  isOpen: boolean;
  top?: string;
  width?: number;
  zIndex?: number;
}

if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

const Modal: React.FC<Props> = ({
  bottom,
  children,
  className,
  contentLabel,
  isCentered,
  isOpen,
  top,
  width,
  zIndex
}) => {
  const contentStyle: any = {
    width: width ? `${width}px` : "943px",
    background: "none",
    border: "none",
    maxWidth: "calc(100% - 40px)",
    margin: "auto",
    padding: "0",
    top: top || "20px",
    left: "20px",
    right: "20px",
    marginBottom: "20px",
    bottom: bottom || "unset",
    overflow: "visible"
  };
  if (isCentered) {
    contentStyle.top = "50%";
    contentStyle.transform = "translate(0, -50%)";
  }

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={contentLabel}
      bodyOpenClassName="modal-open"
      className={className || ""}
      style={{
        overlay: {
          zIndex: zIndex || 1000,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflow: "auto"
        },
        content: contentStyle
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
