import * as React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../Commons/Breadcrumb";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import Row from "../Commons/Row";

const About: React.FC<any> = () => {
  const { t } = useTranslation("about");
  return (
    <>
      <Breadcrumb name={t("about")} />
      <Container isContent={true}>
        <Row hasSmallMarginBottom={true}>
          <Column>
            <p>{t("aboutInfo")}</p>
            <ul>
              <li>
                <a href="https://www.hsl.fi/kayttoehdot">
                  {" "}
                  {t("aboutTermsOfService")}
                </a>
              </li>
              <li>
                <a href="/docs/" target="_blank">
                  {t("aboutAPIdocs")}
                </a>
                {". "}
                {t("aboutBullet1")}{" "}
                <a href="https://github.com/HSLdevcom/parkandrideAPI">
                  GitHub repository
                </a>
              </li>
              <li>{t("aboutBullet2")}</li>
              <li>
                {t("aboutLicense")}{" "}
                <a href="http://creativecommons.org/licenses/by/4.0/deed.fi">
                  Creative Commons Nimeä 4.0 Kansainvälinen{" "}
                </a>
                {t("aboutWithLicense")}
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default About;
