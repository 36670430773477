import * as React from "react";
import { useTranslation } from "react-i18next";
import { Capacity } from "../../generated/graphql";
import { translateValue } from "../../util/translateUtils";
import Panel from "./Panel";
import Table from "./Table";

interface Props {
  data: Capacity;
}

const CapacityTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation("commons");

  return (
    <Panel hasBorder={true}>
      <Table
        isBordered={true}
        isCondensed={true}
        isInsidePanel={true}
        isStriped={true}
      >
        <thead>
          <tr>
            <th>{t("labelCapacityType")}</th>
            <th>{t("labelBuiltCapacity")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map(key => (
            <tr key={key}>
              <td>{translateValue("capacityType", key, t)}</td>
              <td>{(data as any)[key]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel>
  );
};

export default CapacityTable;
