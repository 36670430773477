import classNames from "classnames";
import * as React from "react";
import styles from "./Panel.module.css";

interface Props {
  className?: string;
  hasBorder?: boolean;
}

const Panel: React.FC<Props> = ({ children, className, hasBorder }) => {
  return (
    <div
      className={classNames(
        styles.panel,
        { [styles["panel-default"]]: hasBorder },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Panel;
