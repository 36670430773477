import classNames from "classnames";
import { FieldProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import styles from "./Field.module.css";
import "./SelectField.css";

export interface Option {
  label: string;
  value: string;
}

type Value = string | number | null;

interface Props extends FieldProps {
  alignWithAddon?: boolean;
  className?: string;
  hasSmallFontSize?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  isSmallInput?: boolean;
  onChange?: (name: string, value: any) => void;
  options: Option[];
  placeholder?: string;
  withAddon?: boolean;
}

const components = {
  IndicatorSeparator: null
};

const SelectField: React.FC<Props> = ({
  alignWithAddon = false,
  className,
  field,
  form,
  hasSmallFontSize = false,
  isClearable = true,
  isDisabled = false,
  isMulti = false,
  isSmallInput = false,
  onChange,
  options,
  placeholder,
  withAddon = false
}) => {
  const { name, value } = field;
  const { errors, submitCount, touched } = form;

  const handleChange = (val: any) => {
    const { setFieldValue } = form;
    if (isMulti) {
      const items: string[] = Array.isArray(val)
        ? val.map(item => (item ? item.value : null))
        : [];

      onChange ? onChange(name, items) : setFieldValue(name, items);
    } else {
      onChange
        ? onChange(name, val ? val.value : null)
        : setFieldValue(name, val ? val.value : null);
    }
  };

  const { t } = useTranslation("commons");

  const getValue = (val: Value | Value[]) => {
    if (isMulti) {
      return Array.isArray(val)
        ? val.map(item => options.find(option => option.value === item))
        : [];
    }
    return value ? options.find(option => option.value === value) : null;
  };

  return (
    <Select
      className={classNames(className, {
        [styles["form-control-with-addon"]]: withAddon,
        [styles["form-control-align-with-addon"]]: alignWithAddon,
        [styles.smallFontSize]: hasSmallFontSize,
        [styles.smallInput]: isSmallInput,
        [styles.selectValidationError]: get(errors, name),
        [styles.selectIsTouched]: get(touched, name),
        [styles.selectIsSubmitted]: submitCount
      })}
      classNamePrefix="select-field"
      components={components}
      options={options}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      onChange={handleChange}
      placeholder={placeholder || t("placeholder")}
      value={getValue(value)}
    />
  );
};

export default SelectField;
