import L, { LeafletMouseEvent } from "leaflet";
import * as React from "react";
import { CircleMarker, GeoJSON, Map, TileLayer } from "react-leaflet";

import {
  COLORS,
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  FACILITY_MARKER_OPTIONS,
  FACILITY_RESOLUTION_TRESHOLD,
  MAX_ZOOM,
  SELECTED_FACILITY_STYLE,
  UNSELECTED_FACILITY_STYLE
} from "../../constants";
import {
  FacilityGeoJson,
  RegionsWithFacilitiesQuery
} from "../../generated/graphql";
import {
  coordsToLatLng,
  getPolygonCenter,
  LatLngTuple
} from "../../util/mapUtils";
import useAuth from "../../util/useAuth";
import styles from "../Commons/Location.module.css";

interface Props {
  associatedFacilityIds: string[];
  bounds: LatLngTuple[] | undefined;
  center: LatLngTuple | undefined;
  className: string | undefined;
  facilityFeatures: FacilityGeoJson[];
  hubLocation: LatLngTuple | null;
  onFeatureClick: (id: string) => void;
  onLocationChange: (latlng: LatLngTuple) => void;
  regionsWithFacilitiesData: RegionsWithFacilitiesQuery | undefined;
}

const HubEditLocation: React.FC<Props> = ({
  associatedFacilityIds,
  bounds,
  center,
  className,
  facilityFeatures,
  hubLocation,
  onFeatureClick,
  onLocationChange,
  regionsWithFacilitiesData
}) => {
  const { getUserOperatorId, getUserRegionId } = useAuth();
  const userOperatorId = getUserOperatorId();
  const map: any = React.useRef(null);
  const [zoomState, setZoomState] = React.useState(DEFAULT_ZOOM);

  const allowedFacilities = React.useMemo(() => {
    const userRegionsId = getUserRegionId();
    const region =
      regionsWithFacilitiesData &&
      regionsWithFacilitiesData.regionsWithFacilities &&
      regionsWithFacilitiesData.regionsWithFacilities.find(
        item => item.id === userRegionsId
      );
    return userRegionsId ? (region && region.facilityIds) || [] : [];
  }, [getUserRegionId, regionsWithFacilitiesData]);

  const filteredFacilityFeatures = React.useMemo(() => {
    return facilityFeatures.filter(feature =>
      userOperatorId
        ? userOperatorId === feature.properties.operatorId ||
          associatedFacilityIds.includes(feature.id) ||
          allowedFacilities.includes(feature.id)
        : true
    );
  }, [
    allowedFacilities,
    associatedFacilityIds,
    facilityFeatures,
    userOperatorId
  ]);
  const features = React.useMemo(() => {
    return zoomState < FACILITY_RESOLUTION_TRESHOLD
      ? filteredFacilityFeatures.map(feature => ({
          ...feature,
          geometry: {
            bbox: feature.geometry.bbox,
            crs: feature.geometry.crs,
            coordinates: getPolygonCenter(feature.geometry.coordinates),
            type: "Point"
          }
        }))
      : filteredFacilityFeatures;
  }, [filteredFacilityFeatures, zoomState]);

  const geojson: any = {
    type: "FeaturesCollection",
    features
  };

  const handleClickFeature = (feature: FacilityGeoJson) => {
    if (
      !userOperatorId ||
      userOperatorId === feature.properties.operatorId ||
      allowedFacilities.includes(feature.id)
    ) {
      onFeatureClick(feature.id);
    }
  };

  const handleDoubleClick = (event: LeafletMouseEvent) => {
    onLocationChange([event.latlng.lng, event.latlng.lat]);
  };

  const handleViewportChanged = () => {
    if (map.current) {
      setZoomState(map.current.leafletElement.getZoom());
    }
  };

  React.useEffect(() => {
    if (map.current) {
      setZoomState(map.current.leafletElement.getZoom());
    }
  }, []);

  return (
    <div className={styles.mapContainer}>
      <div className={className}>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
        />
        <Map
          ref={map}
          bounds={bounds}
          center={center || DEFAULT_MAP_CENTER}
          doubleClickZoom={false}
          onDblClick={handleDoubleClick}
          maxZoom={MAX_ZOOM}
          onViewportChanged={handleViewportChanged}
          zoom={zoomState}
          zoomAnimation={false}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {hubLocation && (
            <CircleMarker
              center={coordsToLatLng(hubLocation)}
              fillColor={COLORS.HUB}
              fillOpacity={1}
              radius={10}
              weight={0}
            />
          )}

          <GeoJSON
            key={JSON.stringify({ associatedFacilityIds, geojson })}
            data={geojson}
            coordsToLatLng={coordsToLatLng}
            onEachFeature={(feature: any, layer: any) => {
              if (associatedFacilityIds.indexOf(feature.id) !== -1) {
                layer.setStyle(SELECTED_FACILITY_STYLE);
              } else {
                layer.setStyle(UNSELECTED_FACILITY_STYLE);
              }
              layer.on({
                click: () => handleClickFeature(feature)
              });
            }}
            pointToLayer={(feature, latlng) => {
              return L.circleMarker(latlng, FACILITY_MARKER_OPTIONS);
            }}
          />
        </Map>
      </div>
    </div>
  );
};

export default HubEditLocation;
