import * as React from "react";
import styles from "./Modal.module.css";

interface Props {
  children: any;
}

const ModalContent: React.FC<Props> = ({ children }) => {
  return <div className={styles["modal-content"]}>{children}</div>;
};

export default ModalContent;
