import classNames from "classnames";
import * as React from "react";
import styles from "./Modal.module.css";

interface Props {
  children: any;
  isCentered?: boolean;
}

const ModalFooter: React.FC<Props> = ({ children, isCentered }) => {
  return (
    <div
      className={classNames(styles["modal-footer"], {
        [styles.centered]: isCentered
      })}
    >
      {children}
    </div>
  );
};

export default ModalFooter;
