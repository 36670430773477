import { FeatureCollection, GeoJsonObject } from "geojson";
import L, { Layer } from "leaflet";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { GeoJSON, Map, TileLayer } from "react-leaflet";
import { Link } from "react-router-dom";
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  FACILITY_MARKER_OPTIONS,
  FACILITY_RESOLUTION_TRESHOLD,
  HUB_MARKER_OPTIONS,
  MAX_ZOOM,
  SELECTED_FACILITY_STYLE
} from "../../constants";
import { Hub, Port } from "../../generated/graphql";
import { getStreetAddress } from "../../util/facilityHelpers";
import {
  coordsToLatLng,
  FacilityFeatureGeometry,
  getGeoJson,
  getPolygonCenter,
  getPortIcon,
  LatLngTuple,
  PortFeature,
  PortProperties
} from "../../util/mapUtils";
import PortModal from "../FacilityProfile/PortModal";
import Column from "./Column";
import styles from "./Location.module.css";
import Row from "./Row";

interface Props {
  bounds: LatLngTuple[] | undefined;
  center: LatLngTuple | undefined;
  facilitiesGeoJson: FeatureCollection<FacilityFeatureGeometry>;
  facilityType?: string | null;
  hubGeoJson?: GeoJsonObject;
  hubs: Hub[] | undefined;
  portsGeoJson?: GeoJsonObject;
  ports?: Port[];
}

const Location: React.FC<Props> = ({
  bounds,
  center,
  facilitiesGeoJson,
  facilityType,
  hubGeoJson,
  hubs,
  portsGeoJson,
  ports
}) => {
  const map: any = React.useRef(null);
  const { t } = useTranslation(["commons", "contactList"]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [zoomState, setZoomState] = React.useState(DEFAULT_ZOOM);
  const [port, setPort] = React.useState<PortProperties | null>(null);
  const facilityFeatures: any = React.useMemo(() => {
    return zoomState < FACILITY_RESOLUTION_TRESHOLD
      ? facilitiesGeoJson.features.map(feature => ({
          ...feature,
          geometry: {
            coordinates: getPolygonCenter(feature.geometry.coordinates),
            type: "Point"
          }
        }))
      : facilitiesGeoJson.features;
  }, [facilitiesGeoJson, zoomState]);
  const editedFacilityGeoJson = React.useMemo(
    () => getGeoJson(facilityFeatures),
    [facilityFeatures]
  );

  const handleClickPort = (properties: PortProperties) => {
    setPort(properties);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleViewportChanged = () => {
    if (map.current) {
      setZoomState(map.current.leafletElement.getZoom());
    }
  };

  React.useEffect(() => {
    if (map.current) {
      setZoomState(map.current.leafletElement.getZoom());
    }
  }, []);

  const streetAddressFi = getStreetAddress("fi", ports, facilityType || "");
  const streetAddressSv = getStreetAddress("sv", ports, facilityType || "");
  const streetAddressEn = getStreetAddress("en", ports, facilityType || "");

  return (
    <div>
      <PortModal isOpen={isModalOpen} onClose={handleCloseModal} port={port} />
      <Row>
        <Column>
          <h3>{t("locationPosition")}</h3>
          {streetAddressFi && (
            <>
              <Row>
                <Column>
                  <h4 style={{ marginTop: "0" }}>
                    {t("contactList:labelStreetAddress")}{" "}
                  </h4>
                </Column>
              </Row>
              <Row hasSmallMarginBottom={true}>
                <Column column={3} isFirstColumn={true}>
                  <span>{streetAddressFi || ""} (fi)</span>
                </Column>
                <Column column={3}>
                  <span>{streetAddressSv || ""} (sv)</span>
                </Column>
                <Column column={3} isLastColumn={true}>
                  <span>{streetAddressEn || ""} (en)</span>
                </Column>
              </Row>
            </>
          )}

          {Array.isArray(hubs) && !!hubs.length && (
            <>
              <h4>{t("locationBelongsToArea")}</h4>
              <Row hasSmallMargin={false} hasLargeMargin={true}>
                <Column>
                  {hubs.map((hub, i) => (
                    <p key={i}>
                      <Link to={`/hubs/${hub.id}`}>{hub.name.fi}</Link>
                    </p>
                  ))}
                </Column>
              </Row>
            </>
          )}
        </Column>
      </Row>
      <div className={styles.mapContainer}>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
        />
        <Map
          ref={map}
          bounds={bounds}
          center={center || DEFAULT_MAP_CENTER}
          maxZoom={MAX_ZOOM}
          onViewportChanged={handleViewportChanged}
          zoom={zoomState}
          zoomAnimation={false}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {hubGeoJson && (
            <GeoJSON
              data={hubGeoJson}
              coordsToLatLng={coordsToLatLng}
              pointToLayer={(feature, latlng) => {
                return L.circleMarker(latlng, HUB_MARKER_OPTIONS);
              }}
            />
          )}
          <GeoJSON
            key={JSON.stringify(editedFacilityGeoJson)}
            data={editedFacilityGeoJson}
            coordsToLatLng={coordsToLatLng}
            onEachFeature={(feature, layer: any) => {
              layer.setStyle(SELECTED_FACILITY_STYLE);
            }}
            pointToLayer={(feature, latlng) => {
              return L.circleMarker(latlng, FACILITY_MARKER_OPTIONS);
            }}
          />
          {portsGeoJson && (
            <GeoJSON
              data={portsGeoJson}
              coordsToLatLng={coordsToLatLng}
              onEachFeature={(feature: PortFeature, layer: Layer) => {
                layer.on({
                  click: () => handleClickPort(feature.properties)
                });
              }}
              pointToLayer={(feature, latlng) => {
                const icon = getPortIcon(feature.properties);
                return L.marker(latlng, { icon });
              }}
            />
          )}
        </Map>
      </div>
    </div>
  );
};

export default Location;
