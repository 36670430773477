import classNames from "classnames";
import { FieldProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import styles from "./Field.module.css";

interface Props extends FieldProps {
  hasSmallFontSize?: boolean;
  rows?: number;
  withAddon?: boolean;
}

const TextAreaField: React.FC<Props> = ({
  field,
  form,
  hasSmallFontSize = false,
  rows = 4,
  withAddon,
  ...props
}) => {
  const { errors, submitCount, touched } = form;
  const { name } = field;

  return (
    <textarea
      {...props}
      className={classNames(styles["form-control"], {
        [styles.smallFontSize]: hasSmallFontSize,
        [styles["form-control-with-addon"]]: withAddon,
        [styles.validationError]: get(errors, name),
        [styles.isTouched]: get(touched, name),
        [styles.isSubmitted]: submitCount
      })}
      rows={rows}
      {...field}
    />
  );
};

export default TextAreaField;
