import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  useAuthenticationMethodListQuery,
  useCapacityTypeListQuery,
  useDayTypeListQuery,
  useFacilityStatusListQuery,
  useOperatorListQuery,
  usePaymentMethodListQuery,
  usePricingMethodListQuery,
  useServiceListQuery,
  useSurveyOptionsListQuery,
  useUsageListQuery
} from "../../generated/graphql";
import LoadingSpinner from "../Commons/LoadingSpinner";
import FacilityEdit from "../FacilityEdit/FacilityEdit";

const FacilityEditContainer = () => {
  const { t } = useTranslation("commons");
  const {
    data: capacityTypesData,
    error: capacityTypesError,
    loading: loadingCapacityTypes
  } = useCapacityTypeListQuery();
  const {
    data: dayTypesData,
    error: dayTypesError,
    loading: loadingDayTypes
  } = useDayTypeListQuery();
  const {
    data: facilityStatusesData,
    error: facilityStatusesError,
    loading: loadingFacilityStatuses
  } = useFacilityStatusListQuery();
  const {
    data: operatorsData,
    error: operatorsError,
    loading: loadingOperators
  } = useOperatorListQuery();
  const {
    data: paymentMethodsData,
    error: paymentMethodsError,
    loading: loadingPaymentMethods
  } = usePaymentMethodListQuery();

  const {
    data: authenticationMethodsData,
    error: authenticationMethodsError,
    loading: loadingAuthenticationMethods
  } = useAuthenticationMethodListQuery();
  const {
    data: surveyOptionsData,
    error: surveyOptionsError,
    loading: loadingSurveyOptions
  } = useSurveyOptionsListQuery();
  const {
    data: pricingMethodsData,
    error: pricingMethodsError,
    loading: loadingPricingMethods
  } = usePricingMethodListQuery();
  const {
    data: servicesData,
    error: servicesError,
    loading: loadingServices
  } = useServiceListQuery();
  const {
    data: usagesData,
    error: usagesError,
    loading: loadingUsages
  } = useUsageListQuery();

  if (
    loadingCapacityTypes ||
    loadingDayTypes ||
    loadingFacilityStatuses ||
    loadingOperators ||
    loadingPaymentMethods ||
    loadingAuthenticationMethods ||
    loadingSurveyOptions ||
    loadingPricingMethods ||
    loadingServices ||
    loadingUsages
  ) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (
    !capacityTypesData ||
    capacityTypesError ||
    !dayTypesData ||
    dayTypesError ||
    !facilityStatusesData ||
    facilityStatusesError ||
    !operatorsData ||
    operatorsError ||
    !paymentMethodsData ||
    paymentMethodsError ||
    !authenticationMethodsData ||
    authenticationMethodsError ||
    !surveyOptionsData ||
    surveyOptionsError ||
    !pricingMethodsData ||
    pricingMethodsError ||
    !servicesData ||
    servicesError ||
    !usagesData ||
    usagesError
  ) {
    return <div>{t("error")}</div>;
  }

  return (
    <FacilityEdit
      authenticationMethodsData={authenticationMethodsData}
      capacityTypesData={capacityTypesData}
      dayTypesData={dayTypesData}
      facilityStatusesData={facilityStatusesData}
      operatorsData={operatorsData}
      paymentMethodsData={paymentMethodsData}
      pricingMethodsData={pricingMethodsData}
      servicesData={servicesData}
      usagesData={usagesData}
      surveyOptionsData={surveyOptionsData}
    />
  );
};

export default FacilityEditContainer;
