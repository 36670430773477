import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFacilityListQuery, useHubListQuery } from "../../generated/graphql";
import LoadingSpinner from "../Commons/LoadingSpinner";
import HubList from "./HubList";

const HubListContainer = () => {
  const { t } = useTranslation("commons");
  const {
    data: facilitiesData,
    error: facilitiesError,
    loading: loadingFacilities
  } = useFacilityListQuery();
  const {
    data: hubsData,
    error: hubsError,
    loading: loadingHubs
  } = useHubListQuery();

  if (loadingFacilities || loadingHubs) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (facilitiesError || hubsError || !facilitiesData || !hubsData) {
    return <div>{t("error")}</div>;
  }

  return <HubList facilitiesData={facilitiesData} hubsData={hubsData} />;
};

export default HubListContainer;
