import * as React from "react";
import { useTranslation } from "react-i18next";
import { FacilityDetails } from "../../generated/graphql";
import { translateValue } from "../../util/translateUtils";
import Column from "../Commons/Column";
import Row from "../Commons/Row";
import Operator from "./Operator";
interface Props {
  facilityDetails: FacilityDetails;
}

const FacilityBasicInfo: React.FC<Props> = ({ facilityDetails }) => {
  const { t } = useTranslation(["facilityProfile", "commons"]);

  const showAliases = () => {
    return !!facilityDetails.aliases.length;
  };


  return (
    <>
      <h3 className="h3-first">{t("titleBasicInfo")}</h3>
      <h4>{t("labelName")}</h4>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <p>
            <span>{facilityDetails.name.fi || "-"}</span> (fi)
          </p>
        </Column>
        <Column column={3}>
          <p>
            <span>{facilityDetails.name.sv || "-"}</span> (sv)
          </p>
        </Column>
        <Column column={3} isLastColumn={true}>
          <p>
            <span>
              {facilityDetails.name.en || facilityDetails.name.fi || "-"}
            </span>{" "}
            (en)
          </p>
        </Column>
      </Row>

      {showAliases() && (
        <>
          <h4>{t("titleAliases")}</h4>
          <p>{facilityDetails.aliases.join(", ")}</p>
        </>
      )}

      <Operator id={facilityDetails.operatorId} />

      <h4>{t("labelStatus")}</h4>
      <Row>
        <Column>
          {facilityDetails.status
            ? translateValue("commons:status", facilityDetails.status, t)
            : ""}
        </Column>
      </Row>
    </>
  );
};

export default FacilityBasicInfo;
