import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { get, isEmpty, remove } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";

import {
  BICYCLE_CAPACITY_TYPES,
  CAR_CAPACITY_TYPES,
  CRS,
  FACILITY_TYPE,
  PRICING_METHOD
} from "../../constants";
import {
  AuthenticationMethodListQuery,
  CapacityTypeListQuery,
  DayTypeListQuery,
  FacilityListDocument,
  FacilityProfileQuery,
  FacilityStatusListQuery,
  OperatorListQuery,
  PaymentMethodListQuery,
  PortInput,
  PricingMethodListQuery,
  ServiceListQuery,
  SurveyOptionsListQuery,
  UsageListQuery,
  useCreateFacilityMutation,
  useEditFacilityMutation
} from "../../generated/graphql";
import { getStreetAddress } from "../../util/facilityHelpers";
import {
  clearNullValuesFromObject,
  decodeNullValues,
  handleError
} from "../../util/helpers";
import {
  getBboxFromPoint,
  getBounds,
  getCenter,
  getMaxBbox,
  LatLngTuple
} from "../../util/mapUtils";
import { translatePath, translateValue } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import { FacilityEditValidationSchema as validationSchema } from "../../util/validationUtils";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import ButtonGroupSelector from "../Commons/ButtonGroupSelector";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import ErrorMessage from "../Commons/ErrorMessage";
import { useErrorModalContext } from "../Commons/ErrorModal";
import fieldStyles from "../Commons/Field.module.css";
import FormError from "../Commons/FormError";
import FormGroup from "../Commons/FormGroup";
import InputGroup, { InputGroupText } from "../Commons/InputGroup";
import { useLoginModalContext } from "../Commons/LoginModal";
import NaviBelow from "../Commons/NaviBelow";
import RadioButtonField from "../Commons/RadioButtonField";
import Row from "../Commons/Row";
import iconStyles from "../FacilityList/FacilityList.module.css";
import BasicInfo from "./BasicInfo";
import BuiltCapacitiesTable from "./BuiltCapacitiesTable";
import FacilityEditLocation from "./FacilityEditLocation";
import PriceAndPayment from "./PriceAndPayment";
import PriceAndPaymentBicycle from "./PriceAndPaymentBicycle";
import UnavailableCapacitiesTable from "./UnavailableCapacitiesTable";

const MAP_MODE = {
  LOCATION: "LOCATION",
  PORTS: "PORTS"
};

interface Params {
  id?: string;
}

interface Props extends RouteComponentProps<Params> {
  capacityTypesData: CapacityTypeListQuery;
  dayTypesData: DayTypeListQuery;
  facilityData?: FacilityProfileQuery;
  facilityStatusesData: FacilityStatusListQuery;
  operatorsData: OperatorListQuery;
  paymentMethodsData: PaymentMethodListQuery;
  authenticationMethodsData: AuthenticationMethodListQuery;
  pricingMethodsData: PricingMethodListQuery;
  servicesData: ServiceListQuery;
  usagesData: UsageListQuery;
  surveyOptionsData: SurveyOptionsListQuery;
}

const FacilityEdit: React.FC<Props> = ({
  capacityTypesData,
  dayTypesData,
  facilityData,
  facilityStatusesData,
  history,
  match,
  operatorsData,
  paymentMethodsData,
  authenticationMethodsData,
  pricingMethodsData,
  servicesData,
  usagesData,
  surveyOptionsData
}) => {
  const form = React.useRef<any>(null);
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const {
    params: { id }
  } = match;

  const [createFacilityMutation] = useCreateFacilityMutation();
  const [editFacilityMutation] = useEditFacilityMutation();
  const errorComponent = React.useRef<any>(null);
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const [mapMode, setMapMode] = React.useState<string>(
    id ? MAP_MODE.PORTS : MAP_MODE.LOCATION
  );
  const { getUserOperatorId, hasPermission } = useAuth();
  const operatorId = facilityData
    ? facilityData.facilityDetails.operatorId
    : null;
  const { t } = useTranslation([
    "facilityEdit",
    "facilityList",
    "facilityProfile",
    "commons"
  ]);

  const allCapacityTypes =
    capacityTypesData && capacityTypesData.capacityTypes
      ? capacityTypesData.capacityTypes
      : [];
  const capacityTypeOptions = allCapacityTypes.map(capacityType => {
    return {
      label: translateValue("commons:capacityType", capacityType, t),
      value: capacityType
    };
  });

  const bboxes =
    facilityData &&
    facilityData.facilityDetails &&
    facilityData.facilityDetails.location
      ? [facilityData.facilityDetails.location.bbox]
      : [];
  const ports =
    facilityData &&
    facilityData.facilityDetails &&
    facilityData.facilityDetails.ports
      ? facilityData.facilityDetails.ports
      : [];
  ports.forEach((port: any) => {
    bboxes.push(getBboxFromPoint(port.location.coordinates));
  });
  const bbox = getMaxBbox(bboxes);
  const bounds = getBounds(bbox);
  const center = getCenter(bbox);

  const getInitialValueByPath = React.useCallback(
    (path: string, defaultValue: any = "") => {
      return get(facilityData, `facilityDetails.${path}`) || defaultValue;
    },
    [facilityData]
  );

  const initialFormValues = React.useMemo(() => {
    return {
      name: {
        fi: id ? getInitialValueByPath("name.fi") : "",
        sv: id ? getInitialValueByPath("name.sv") : "",
        en: id ? getInitialValueByPath("name.en") : ""
      },
      aliases: id ? getInitialValueByPath("aliases") : [],
      operatorId: id
        ? getInitialValueByPath("operatorId")
        : getUserOperatorId() || "",
      status: id ? getInitialValueByPath("status") : "IN_OPERATION",
      location: id
        ? {
            crs: {
              properties: {
                name: getInitialValueByPath("location.crs.properties.name")
              },
              type: getInitialValueByPath("location.crs.type")
            },
            type: getInitialValueByPath("location.type"),
            bbox: getInitialValueByPath("location.bbox"),
            coordinates: getInitialValueByPath("location.coordinates")
          }
        : null,
      ports: id
        ? ports.map(port => ({
            address: {
              streetAddress: {
                fi:
                  port.address && port.address.streetAddress
                    ? port.address.streetAddress.fi
                    : "",
                sv:
                  port.address && port.address.streetAddress
                    ? port.address.streetAddress.sv
                    : "",
                en:
                  port.address && port.address.streetAddress
                    ? port.address.streetAddress.en
                    : ""
              },
              city: {
                fi:
                  port.address && port.address.city ? port.address.city.fi : "",
                sv:
                  port.address && port.address.city ? port.address.city.sv : "",
                en:
                  port.address && port.address.city ? port.address.city.en : ""
              }
            },
            bicycle: port.bicycle,
            entry: port.entry,
            exit: port.exit,
            location: {
              crs: {
                properties: {
                  name: port.location.crs.properties.name
                },
                type: port.location.crs.type
              },
              type: port.location.type,
              coordinates: port.location.coordinates
            },
            pedestrian: port.pedestrian
          }))
        : [],
      openingHours: {
        detail: {
          fi: id ? getInitialValueByPath("openingHours.info.fi") : "",
          sv: id ? getInitialValueByPath("openingHours.info.sv") : "",
          en: id ? getInitialValueByPath("openingHours.info.en") : ""
        },
        url: {
          fi: id ? getInitialValueByPath("openingHours.url.fi") : "",
          sv: id ? getInitialValueByPath("openingHours.url.sv") : "",
          en: id ? getInitialValueByPath("openingHours.url.en") : ""
        }
      },
      builtCapacity: allCapacityTypes
        .map(key => ({
          [key]: id ? getInitialValueByPath(`builtCapacity.${key}`) : ""
        }))
        .reduce((p, n) => ({ ...p, ...n }), {}),
      unavailableCapacities: id
        ? getInitialValueByPath(`unavailableCapacities`, []).map(
            (item: any) => ({
              capacityType: item.capacityType || "",
              usage: item.usage || "",
              capacity: item.capacity || 0
            })
          )
        : [],
      pricingMethod: id
        ? getInitialValueByPath(`pricingMethod`)
        : PRICING_METHOD.PARK_AND_RIDE_247_FREE,
      pricing: id
        ? getInitialValueByPath(`pricing`, []).map((item: any) => ({
            selected: false,
            isOnClipboard: false,
            capacityType: item.capacityType || "",
            dayType: item.dayType || "",
            maxCapacity: item.maxCapacity || "",
            price: {
              fi: item.price ? item.price.fi || "" : "",
              sv: item.price ? item.price.sv || "" : "",
              en: item.price ? item.price.en || "" : ""
            },
            priceExtra: item.priceExtra || "",
            priceOther: item.priceOther || "",
            time: {
              from: item.time ? item.time.from || "" : "",
              until: item.time ? item.time.until || "" : ""
            },
            usage: item.usage || ""
          }))
        : [],
      services: id ? getInitialValueByPath("services", []) : [],
      paymentInfo: {
        detail: {
          fi: id ? getInitialValueByPath("paymentInfo.detail.fi") : "",
          sv: id ? getInitialValueByPath("paymentInfo.detail.sv") : "",
          en: id ? getInitialValueByPath("paymentInfo.detail.en") : ""
        },
        paymentMethods: id
          ? getInitialValueByPath("paymentInfo.paymentMethods", [])
          : [],
        url: {
          fi: id ? getInitialValueByPath("paymentInfo.url.fi") : "",
          sv: id ? getInitialValueByPath("paymentInfo.url.sv") : "",
          en: id ? getInitialValueByPath("paymentInfo.url.en") : ""
        }
      },
      contacts: {
        emergency: id ? getInitialValueByPath("contacts.emergency") : "",
        operator: id ? getInitialValueByPath("contacts.operator") : "",
        service: id ? getInitialValueByPath("contacts.service") : ""
      },
      type: id ? getInitialValueByPath("type") : FACILITY_TYPE.CAR,
      authenticationMethods: id
        ? getInitialValueByPath("authenticationMethods", [])
        : [],
      time: null,
      surveyOptions: id ? getInitialValueByPath("surveyOptions", []) : []
    };
  }, [allCapacityTypes, id, getInitialValueByPath, ports, getUserOperatorId]);

  const mapModeOptions = [MAP_MODE.LOCATION, MAP_MODE.PORTS].map(mode => ({
    label: translateValue("mapMode", mode, t),
    value: mode
  }));

  const constructError = React.useCallback(
    (path: string, error: string) => {
      return `${translatePath("facilityEdit:path", path, t)}: ${t(
        "commons:error" + error
      )}`;
    },
    [t]
  );

  const scrollToError = React.useCallback(() => {
    if (errorComponent.current) {
      setTimeout(() => {
        errorComponent.current.scrollIntoView();
      }, 10);
    }
  }, [errorComponent]);

  const formatPayload = (data: any) => {
    const payload = { ...decodeNullValues(data) };

    // Set name.en same as name.fi if empty
    if (!payload.name.en) {
      payload.name.en = payload.name.fi;
    }

    Object.keys(payload.builtCapacity).forEach(key => {
      if (
        payload.type === FACILITY_TYPE.BICYCLE &&
        !BICYCLE_CAPACITY_TYPES.includes(key)
      ) {
        payload.builtCapacity[key] = null;
      } else if (
        payload.type === FACILITY_TYPE.CAR &&
        !CAR_CAPACITY_TYPES.includes(key)
      ) {
        payload.builtCapacity[key] = null;
      }
    });

    payload.builtCapacity = clearNullValuesFromObject({
      ...payload.builtCapacity
    });

    payload.ports = data.ports.map((port: any) => {
      return {
        ...decodeNullValues(port),
        address: decodeNullValues(port.address)
      };
    });
    payload.pricing = data.pricing.map((row: any) => {
      return {
        ...decodeNullValues(row)
      };
    });
    payload.unavailableCapacities = data.unavailableCapacities.map(
      (item: any) => ({ ...item, capacity: item.capacity || 0 })
    );
    return payload;
  };

  const createFacility = async (values: any) => {
    try {
      await createFacilityMutation({
        variables: { facility: { ...formatPayload({ ...values }) } },
        update(cache, createdFacility) {
          try {
            const facilityListData: any = cache.readQuery({
              query: FacilityListDocument
            });

            if (facilityListData) {
              const newFacility =
                createdFacility &&
                createdFacility.data &&
                createdFacility.data.createFacility;
              const newFacilities: any = [
                ...facilityListData.facilities.facilities,
                newFacility
              ];
              facilityListData.facilities.facilities = newFacilities;
              cache.writeQuery({
                query: FacilityListDocument,
                data: facilityListData
              });
            }
          } catch (err) {
            console.error(err);
          }
          const hubId =
            createdFacility && createdFacility.data
              ? createdFacility.data.createFacility.id
              : null;
          if (hubId) {
            moveToFacilityById(hubId);
          }
        }
      });
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => createFacility(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  const editFacility = async (values: any) => {
    try {
      await editFacilityMutation({
        variables: { facility: { ...formatPayload({ ...values, id }) } },
        update(cache, editedFacilityData) {
          try {
            const facilityListData: any = cache.readQuery({
              query: FacilityListDocument
            });

            if (facilityListData) {
              const newFacility =
                editedFacilityData &&
                editedFacilityData.data &&
                editedFacilityData.data.editFacility;
              const newFacilities: any = facilityListData.facilities.facilities.map(
                (item: any) =>
                  newFacility && item.id === newFacility.id ? newFacility : item
              );

              facilityListData.facilities.facilities = newFacilities;
              cache.writeQuery({
                query: FacilityListDocument,
                data: facilityListData
              });
            }
          } catch (err) {
            console.error(err);
          }
          const facilityId =
            editedFacilityData && editedFacilityData.data
              ? editedFacilityData.data.editFacility.id
              : null;
          if (facilityId) {
            moveToFacilityById(facilityId);
          }
        }
      });
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => editFacility(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  const handleMapModeChange = (mode: string) => {
    setMapMode(mode);
  };

  const moveToHubList = () => {
    history.push(`/hubs`);
  };

  const moveToFacility = () => {
    history.push(`/facilities/${id}`);
  };

  const moveToFacilityById = (facilityId: string) => {
    history.push(`/facilities/${facilityId}`);
  };

  React.useEffect(() => {
    if (form.current) {
      form.current.validateForm();
    }
  }, [form]);

  const renderBuiltCapacities = (type: string) => {
    return (
      <BuiltCapacitiesTable
        capacityTypes={capacityTypeOptions}
        facilityType={type}
      />
    );
  };

  return (
    <Formik
      ref={form}
      initialValues={initialFormValues}
      onSubmit={values => {
        const pricing = values.pricing.map((pricingRow: any) => {
          const row = { ...pricingRow };
          delete row.selected;
          delete row.isOnClipboard;
          return row;
        });
        delete values.time;

        if (id) {
          editFacility({ ...values, pricing, id });
        } else {
          createFacility({ ...values, pricing });
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleSubmit,
        setFieldValue,
        submitCount,
        handleChange,
        touched,
        values
      }) => {
        const { pricing, unavailableCapacities } = values;
        const submitHandler = () => {
          if (!isEmpty(errors)) {
            setValidationErrors([
              constructError("Facility", "BasicRequirements")
            ]);
            scrollToError();
          } else {
            setValidationErrors([]);
          }

          handleSubmit();
        };
        // tslint:disable-next-line: no-shadowed-variable
        const handleSetLocation = (coords: LatLngTuple[][], bbox: number[]) => {
          setFieldValue("location", {
            bbox,
            crs: CRS,
            coordinates: coords,
            type: "Polygon"
          });
        };

        const handlePortDelete = (index: number) => {
          setFieldValue("ports", [
            ...remove(values.ports, (item, i) => {
              return i !== index;
            })
          ]);
        };

        const handlePortSave = (port: PortInput, index?: number) => {
          if (index != null) {
            const newPorts: any = [...values.ports];

            newPorts[index] = port;
            setFieldValue("ports", [...newPorts]);
          } else {
            setFieldValue("ports", [...values.ports, port]);
          }
        };

        const location: any = values.location;

        const streetAddressFi = getStreetAddress(
          "fi",
          values.ports,
          values.type
        );
        const streetAddressEn = getStreetAddress(
          "en",
          values.ports,
          values.type
        );
        const streetAddressSv = getStreetAddress(
          "sv",
          values.ports,
          values.type
        );

        return (
          <Form>
            <Breadcrumb
              name={values.name.fi || t("titleFacility")}
              canCancel={true}
              canSave={
                id
                  ? facilityData &&
                    !facilityData.facilityDetails.softDeletedAt &&
                    !facilityData.facilityDetails.deletedAt &&
                    hasPermission(PERMISSIONS.FACILITY_UPDATE, operatorId)
                  : hasPermission(
                      PERMISSIONS.FACILITY_CREATE,
                      values.operatorId
                    )
              }
              onClickCancel={id ? moveToFacility : moveToHubList}
              onClickSave={submitHandler}
            />
            <Container isContent={true} ref={errorComponent}>
              <FormError errors={validationErrors} />
              <div>
                <h3 className="h3-first ">{t("titleBasicInfo")}</h3>
                <Row hasSmallMarginBottom={true}>
                  <Column>
                    <Field
                      checked={values.type === FACILITY_TYPE.CAR}
                      component={RadioButtonField}
                      icon={
                        <span
                          title={translateValue(
                            "capacityType",
                            FACILITY_TYPE.CAR,
                            t
                          )}
                          className={iconStyles.facilityIconCar}
                        />
                      }
                      label={t("commons:facilityForCars")}
                      name="type"
                      value={FACILITY_TYPE.CAR}
                      title={t("commons:facilityForCars")}
                    />
                    <Field
                      checked={values.type === FACILITY_TYPE.BICYCLE}
                      component={RadioButtonField}
                      icon={
                        <span
                          title={translateValue(
                            "capacityType",
                            FACILITY_TYPE.BICYCLE,
                            t
                          )}
                          className={iconStyles.facilityIconBicycle}
                        />
                      }
                      label={t("commons:facilityForBicycle")}
                      name="type"
                      value={FACILITY_TYPE.BICYCLE}
                      title={t("commons:facilityForBicycle")}
                    />
                    {!values.type && touched.type && (
                      <ErrorMessage error={t("commons:errorRequired")} />
                    )}
                  </Column>
                </Row>

                <BasicInfo
                  operatorsData={operatorsData}
                  facilityStatusesData={facilityStatusesData}
                  surveyOptionsData={surveyOptionsData}
                  values={values}
                  id={match.params.id}
                  setFieldValue={setFieldValue}
                />
                <h3 className="h3-first ">{t("titleLocation")} *</h3>
                {streetAddressFi && (
                  <>
                    <Row>
                      <Column>
                        <label>{t("labelStreetAddress")} </label>
                      </Column>
                    </Row>

                    <Row>
                      <Column column={3} isFirstColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <InputGroup>
                            <input
                              type="text"
                              placeholder={streetAddressFi || ""}
                              disabled={true}
                              className={classNames(
                                fieldStyles["form-control"],
                                {
                                  [fieldStyles.smallFontSize]: true,
                                  [fieldStyles["form-control-with-addon"]]: true
                                }
                              )}
                            />

                            <InputGroupText>fi</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Column>
                      <Column column={3}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <InputGroup>
                            <input
                              type="text"
                              placeholder={streetAddressSv || ""}
                              disabled={true}
                              className={classNames(
                                fieldStyles["form-control"],
                                {
                                  [fieldStyles.smallFontSize]: true,
                                  [fieldStyles["form-control-with-addon"]]: true
                                }
                              )}
                            />
                            <InputGroupText>sv</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Column>
                      <Column column={3} isLastColumn={true}>
                        <FormGroup hasLargeMarginBottom={true}>
                          <InputGroup>
                            <input
                              type="text"
                              placeholder={streetAddressEn || ""}
                              disabled={true}
                              className={classNames(
                                fieldStyles["form-control"],
                                {
                                  [fieldStyles.smallFontSize]: true,
                                  [fieldStyles["form-control-with-addon"]]: true
                                }
                              )}
                            />
                            <InputGroupText>en</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Column>
                    </Row>
                  </>
                )}

                <Row>
                  <Column>
                    <p>{t("helpLocation")}</p>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <ul>
                      <li>{t("helpLocationFacility")}</li>
                      <li>{t("helpLocationPort")}</li>
                    </ul>
                  </Column>
                </Row>

                <Row hasSmallMargin={true}>
                  <Column>
                    <ButtonGroupSelector
                      onChange={handleMapModeChange}
                      options={mapModeOptions}
                      value={mapMode}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <FacilityEditLocation
                      bounds={bounds}
                      center={center}
                      className={classNames({
                        [fieldStyles.validationError]: get(errors, "location"),
                        [fieldStyles.isTouched]: get(touched, "location"),
                        [fieldStyles.isSubmitted]: submitCount
                      })}
                      coordinates={location ? location.coordinates : null}
                      isLocationEditEnabled={mapMode === MAP_MODE.LOCATION}
                      isPortEditEnabled={mapMode === MAP_MODE.PORTS}
                      onPortDelete={handlePortDelete}
                      onPortSave={handlePortSave}
                      ports={values.ports}
                      setLocation={handleSetLocation}
                      facilityType={values.type}
                    />
                  </Column>
                </Row>
                <h3>{t("titleCapacity")}</h3>
                <h4>{t("labelBuiltCapacity")}</h4>
                {renderBuiltCapacities(values.type)}
                <Row hasSmallMargin={true}>
                  <Column>
                    <h4>{t("labelUnavailable")}</h4>
                  </Column>
                </Row>
                <UnavailableCapacitiesTable
                  unavailableCapacities={unavailableCapacities}
                />
                {values.type === FACILITY_TYPE.BICYCLE && (
                  <PriceAndPaymentBicycle
                    capacityTypesData={capacityTypesData}
                    dayTypesData={dayTypesData}
                    paymentMethodsData={paymentMethodsData}
                    authenticationMethodsData={authenticationMethodsData}
                    pricingMethodsData={pricingMethodsData}
                    servicesData={servicesData}
                    values={values}
                    pricing={pricing}
                    usagesData={usagesData}
                    setFieldValue={setFieldValue}
                    facilityType={values.type}
                    timetable={true}
                    handleChange={handleChange}
                  />
                )}
                {values.type === FACILITY_TYPE.CAR && (
                  <PriceAndPayment
                    capacityTypesData={capacityTypesData}
                    dayTypesData={dayTypesData}
                    paymentMethodsData={paymentMethodsData}
                    pricingMethodsData={pricingMethodsData}
                    authenticationMethodsData={authenticationMethodsData}
                    servicesData={servicesData}
                    values={values}
                    pricing={pricing}
                    usagesData={usagesData}
                    setFieldValue={setFieldValue}
                    facilityType={values.type}
                  />
                )}
              </div>
            </Container>
            <NaviBelow>
              <Button
                isResponsive={true}
                size={ButtonSizes.SMALL}
                onClick={id ? moveToFacility : moveToHubList}
                type="button"
              >
                {t("buttonCancel")}
              </Button>
              {(id
                ? facilityData &&
                  !facilityData.facilityDetails.softDeletedAt &&
                  !facilityData.facilityDetails.deletedAt &&
                  hasPermission(PERMISSIONS.FACILITY_UPDATE, operatorId)
                : hasPermission(
                    PERMISSIONS.FACILITY_CREATE,
                    values.operatorId
                  )) && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={submitHandler}
                  type="button"
                >
                  {t("buttonSave")}
                </Button>
              )}
            </NaviBelow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withRouter(FacilityEdit);
