import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useForgottenPasswordMutation } from "../../generated/graphql";
import logoUrl from "../../resources/assets/img/hsl-liipi-logo2.png";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import FormGroup from "../Commons/FormGroup";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";

const ForgottenPassword = () => {
  const { t } = useTranslation(["commons"]);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("commons:errorEmail"))
      .required("Required")
  });

  const [forgottenPassword] = useForgottenPasswordMutation();
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  
  return (
    <>
      <hr />
      <Container>
        <Row>
          <Column col={4}>
            <Panel>
              <div className="panel-body">
                <Row hasLargeMargin={true}>
                  <Column>
                    <img
                      className="img-responsive logo"
                      src={logoUrl}
                      alt="HSL Liityntäpysäköinti"
                    />
                  </Column>
                </Row>
                <div className="text-center">
                  <h3>
                    <i className="fa fa-lock fa-4x" />
                  </h3>
                  <h2 className="text-center">
                    {t("commons:forgottenPassword")}
                  </h2>
                  <p>{t("commons:resetLinkText")}</p>
                  <div className="panel-body">
                    <Formik
                      initialValues={{
                        email: ""
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async values => {
                        try {
                          await forgottenPassword({
                            variables: { email: values.email }
                          });
                          setSuccessMessage(t("commons:emailSent"));
                          setErrorMessage("");
                        } catch (error) {
                          setSuccessMessage("");
                          setErrorMessage(
                            values.email + " " + t("commons:emailNotRecognized")
                          );
                        }
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <Field
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder={t("commons:yourEmail")}
                          />
                          {errors.email && touched.email ? (
                            <span style={{ color: "red", marginBottom: "4px" }}>
                              {errors.email}
                            </span>
                          ) : null}
                          {<span>{successMessage}</span>}
                          {<span style={{ color: "red" }}>{errorMessage}</span>}
                          <FormGroup>
                            <input
                              className="btn btn-lg btn-primary btn-block"
                              value={t("commons:send").toString()}
                              type="submit"
                            />
                          </FormGroup>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Panel>
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default ForgottenPassword;
