import * as React from "react";
import styles from "./LinkButton.module.css";

interface Props {
  onClick: () => void;
}

const LinkButton: React.FC<Props> = ({ children, onClick }) => {
  return (
    <button className={styles.linkButton} onClick={onClick} type="button">
      {children}
    </button>
  );
};

export default LinkButton;
