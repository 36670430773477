export const COLORS = {
  HUB: "#4DC7FF",
  SELECTED_FACILITY: "#FF6319",
  UNSELECTED_FACILITY: "#3388ff"
};

export const HUB_MARKER_OPTIONS = {
  fillColor: COLORS.HUB,
  fillOpacity: 1,
  radius: 10,
  weight: 0
};

export const FACILITY_MARKER_OPTIONS = {
  color: COLORS.SELECTED_FACILITY,
  radius: 8,
  weight: 0,
  fillOpacity: 1
};

export const SELECTED_FACILITY_STYLE = {
  color: COLORS.SELECTED_FACILITY,
  fillColor: COLORS.SELECTED_FACILITY,
  weight: 2
};

export const UNSELECTED_FACILITY_STYLE = {
  color: COLORS.UNSELECTED_FACILITY,
  fillColor: COLORS.UNSELECTED_FACILITY,
  weight: 2
};

export const CRS = { type: "name", properties: { name: "EPSG:4326" } };

export const DEFAULT_MAP_CENTER: any = [60.173324, 24.941025];

export const FACILITY_RESOLUTION_TRESHOLD = 15;

export const DEFAULT_ZOOM = 15;

export const MAX_ZOOM = 19;

export const CONTACT_TYPE = {
  EMERGENCY: "emergency",
  OPERATOR: "operator",
  SERVICE: "service"
};

export const PRICING_METHOD = {
  CUSTOM: "CUSTOM",
  PARK_AND_RIDE_247_FREE: "PARK_AND_RIDE_247_FREE",
  FREE_10H: "FREE_10H",
  FREE_12H: "FREE_12H",
  PAID_10H: "PAID_10H",
  PAID_12H: "PAID_12H",
  PAID_16H: "PAID_16H",
  PAID_24H: "PAID_24H",
  PAID_16H_24H_48H: "PAID_16H_24H_48H"
};

export const FACILITY_TYPE = {
  BICYCLE: "BICYCLE",
  CAR: "CAR"
};

export const CAPACITY_TYPES = {
  BICYCLE: "BICYCLE",
  BICYCLE_SECURE_SPACE: "BICYCLE_SECURE_SPACE",
  CAR: "CAR",
  DISABLED: "DISABLED",
  ELECTRIC_CAR: "ELECTRIC_CAR",
  MOTORCYCLE: "MOTORCYCLE"
};

export const BICYCLE_CAPACITY_TYPES = [
  CAPACITY_TYPES.BICYCLE,
  CAPACITY_TYPES.BICYCLE_SECURE_SPACE
];

export const CAR_CAPACITY_TYPES = [
  CAPACITY_TYPES.CAR,
  CAPACITY_TYPES.DISABLED,
  CAPACITY_TYPES.ELECTRIC_CAR,
  CAPACITY_TYPES.MOTORCYCLE
];

export const USER_ROLE = {
  ADMIN: "ADMIN",
  OPERATOR_API: "OPERATOR_API",
  OPERATOR: "OPERATOR"
};
