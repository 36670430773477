import * as React from "react";

import { RouteComponentProps, withRouter } from "react-router";

import { useTranslation } from "react-i18next";
import { useHubProfileQuery, useUserListQuery } from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import useAuth from "../../util/useAuth";
import { useErrorModalContext } from "../Commons/ErrorModal";

import LoadingSpinner from "../Commons/LoadingSpinner";
import HubProfile from "./HubProfile";

interface Params {
  id: string;
}

const HubProfileContainer = ({ match }: RouteComponentProps<Params>) => {
  const { t } = useTranslation(["commons"]);
  const { openErrorModal } = useErrorModalContext();
  const { isUserLoggedIn } = useAuth();
  const { data: usersData, loading: loadingUsers } = useUserListQuery({
    skip: !isUserLoggedIn()
  });
  const { data, error, loading } = useHubProfileQuery({
    variables: { id: match.params.id }
  });

  React.useEffect(() => {
    if (error) {
      handleError(error, openErrorModal, t);
    }
  }, [error, openErrorModal, t]);

  if (loading || loadingUsers) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error || !data) {
    return <div />;
  }

  return <HubProfile hub={data} usersData={usersData} />;
};

export default withRouter(HubProfileContainer);
