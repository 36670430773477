import { Field } from "formik";
import { get, map } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
  FacilityStatusListQuery,
  OperatorListQuery,
  useContactsByOperatorMutation,
  SurveyOptionsListQuery
} from "../../generated/graphql";
import { translateValue } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Button from "../Commons/Button";
import Column from "../Commons/Column";
import ContactSelectField, {
  ContactOption
} from "../Commons/ContactSelectField";
import CreatableInputField from "../Commons/CreatableInputField";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import InputGroup, {
  InputGroupButton,
  InputGroupText
} from "../Commons/InputGroup";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";
import ContactModal from "../ContacList/ContactModal";
import iconStyles from "../FacilityList/FacilityList.module.css";
import OperatorModal from "../OperatorList/OperatorModal";
interface Params {
  id?: string;
}
interface Props extends RouteComponentProps<Params> {
  facilityStatusesData: FacilityStatusListQuery;
  operatorsData: OperatorListQuery;
  surveyOptionsData: SurveyOptionsListQuery;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  id: any;
}

const BasicInfo: React.FC<Props> = ({
  operatorsData,
  facilityStatusesData,
  surveyOptionsData,
  values,
  id,
  setFieldValue
}) => {
  const { t } = useTranslation([
    "facilityEdit",
    "facilityList",
    "facilityProfile",
    "commons"
  ]);
  const { hasPermission } = useAuth();
  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false);
  const [isOperatorModalOpen, setIsOperatorModalOpen] = React.useState(false);
  const handleOperatorChange = async (field: string, value: string) => {
    setFieldValue(field, value);

    await getContactsPerOperator(value);
  };

  const getContactsPerOperator = async (value: string) => {
    try {
      // Update contact list after changing operatorId
      const response = await fetchContactsByOperator({
        variables: { operatorId: value }
      });
      const newContacts =
        response && response.data
          ? response.data.contactsByOperator.results
          : [];
      const newContactIds = map(newContacts, "id");

      // Clear contacts if current value doesn't exist on new contacts
      if (newContactIds.indexOf(values.contacts.emergency) === -1) {
        setFieldValue("contacts.emergency", "");
      }
      if (newContactIds.indexOf(values.contacts.operator) === -1) {
        setFieldValue("contacts.operator", "");
      }
      if (newContactIds.indexOf(values.contacts.service) === -1) {
        setFieldValue("contacts.service", "");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [
    fetchContactsByOperator,
    { data: contactsData, loading: mutationLoading }
  ] = useContactsByOperatorMutation({
    variables: values.operatorId
  });
  if (!contactsData && !mutationLoading) {
    fetchContactsByOperator({
      variables: { operatorId: values.operatorId }
    })
      .then(() => {
        console.log("Contacts received");
      })
      .catch(() => {
        console.log("Contacts cannot be received");
      });
  }

  const [contactOptions, setContactOptions] = React.useState<ContactOption[]>(
    []
  );

  React.useEffect(() => {
    if (contactsData) {
      const allContacts =
        contactsData && contactsData.contactsByOperator
          ? contactsData.contactsByOperator.results
          : [];

      const options: ContactOption[] = allContacts.map(contact => {
        const getContactLabel = () => {
          let text = contact.name.fi;
          if (contact.email || contact.phone) {
            text = text.concat(
              ` (${contact.email ? contact.email : ""}${
                contact.email && contact.phone ? " / " : ""
              }${contact.phone ? contact.phone : ""})`
            );
          }
          return text;
        };
        return {
          label: getContactLabel(),
          name: contact.name.fi,
          phone: contact.phone,
          email: contact.email,
          value: contact.id
        };
      });

      setContactOptions(options);
    }
  }, [contactsData, setContactOptions]);
  const allOperators =
    operatorsData && operatorsData.operators
      ? operatorsData.operators.results
      : [];
  const getOperatorName = (oId: string, language: "en" | "fi" | "sv") => {
    const operator = allOperators.find(item => item.id === oId);
    return operator ? get(operator, `name.${language}`) : "";
  };
  const operatorOptions: Option[] = allOperators.map(item => ({
    label: item.name ? item.name.fi || "" : "",
    value: item.id
  }));
  const allStatuses = facilityStatusesData
    ? facilityStatusesData.facilityStatuses
    : [];
  const statusOptions: Option[] = allStatuses.map(item => ({
    label: translateValue("commons:status", item, t),
    value: item
  }));
  const allSurveyOptions = surveyOptionsData ? surveyOptionsData.surveyOptions : []
  const surveyOptions: Option[] = allSurveyOptions.map(item => ({
    label: translateValue("commons:surveyOptions", item, t),
    value: item
  }));
  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };
  const handleOpenOperatorModal = () => {
    setIsOperatorModalOpen(true);
  };
  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const handleCloseContactModalAfterSave = () => {
    // Update contact list after adding new contact
    if (values.operatorId) {
      fetchContactsByOperator({
        variables: { operatorId: values.operatorId }
      });
    }

    setIsContactModalOpen(false);
  };
  const handleCloseOperatorModal = () => {
    setIsOperatorModalOpen(false);
  };

  return (
    <>
      <ContactModal
        operatorOptions={operatorOptions}
        isOpen={isContactModalOpen}
        contactId={null}
        onClose={handleCloseContactModal}
        onCloseAfterSave={handleCloseContactModalAfterSave}
      />
      <OperatorModal
        isOpen={isOperatorModalOpen}
        operatorId={null}
        onClose={handleCloseOperatorModal}
      />
      <Row>
        <Column>
          <label>{t("labelName")} *</label>
        </Column>
      </Row>

      <Row>
        <Column column={3} isFirstColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="name.fi"
                type="text"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>fi</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
        <Column column={3}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="name.sv"
                type="text"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>sv</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
        <Column column={3} isLastColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="name.en"
                type="text"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>en</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column>
          <label>{t("labelAliases")}</label>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormGroup hasLargeMarginBottom={true}>
            <Field
              name="aliases"
              placeholder={t("placeholderAliases")}
              component={CreatableInputField}
            />
          </FormGroup>
        </Column>
      </Row>

      <Row>
        <Column>
          <label>{t("labelOperator")} *</label>
        </Column>
      </Row>
      {!hasPermission(PERMISSIONS.ALL_OPERATORS) && id ? (
        <Row>
          <Column column={3} isFirstColumn={true}>
            <FormGroup hasLargeMarginBottom={true}>
              <p>
                <span>{getOperatorName(values.operatorId, "fi")}</span> (fi)
              </p>
            </FormGroup>
          </Column>
          <Column column={3} isFirstColumn={true}>
            <FormGroup hasLargeMarginBottom={true}>
              <p>
                <span>{getOperatorName(values.operatorId, "sv")}</span> (sv)
              </p>
            </FormGroup>
          </Column>
          <Column column={3} isFirstColumn={true}>
            <FormGroup hasLargeMarginBottom={true}>
              <p>
                <span>{getOperatorName(values.operatorId, "en")}</span> (en)
              </p>
            </FormGroup>
          </Column>
        </Row>
      ) : (
        <Row>
          <Column column={3} isFirstColumn={true}>
            <FormGroup hasLargeMarginBottom={true}>
              <InputGroup>
                <Field
                  isClearable={false}
                  isMulti={false}
                  name="operatorId"
                  onChange={handleOperatorChange}
                  options={operatorOptions}
                  placeholder={t("placeholderOperator")}
                  withAddon={true}
                  alignWithAddon={true}
                  component={SelectField}
                />
                {hasPermission(PERMISSIONS.OPERATOR_CREATE) && (
                  <InputGroupButton>
                    <Button
                      isInputGroupButton={true}
                      onClick={handleOpenOperatorModal}
                      type="button"
                    >
                      +
                    </Button>
                  </InputGroupButton>
                )}
              </InputGroup>
            </FormGroup>
          </Column>
        </Row>
      )}

      <Row>
        <Column>
          <label>{t("labelStatus")}</label>
        </Column>
      </Row>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <Field
              isClearable={false}
              isMulti={false}
              name="status"
              options={statusOptions}
              placeholder={t("placeholderStatus")}
              alignWithAddon={true}
              component={SelectField}
            />
          </FormGroup>
        </Column>
      </Row>

      <Row>
        <Column className={iconStyles.facilityIconsContainer}>
          <label>{t("labelTechnicalEmergencies")}</label>

          <span
            title={t("labelTechnicalEmergencies")}
            className={iconStyles.facilityIconEmergency}
          />
        </Column>
        <Column>
          <p>
            Vikapäivystyksen numero on pakollinen niissä kohteissa, joissa on
            puomi tai muu mekaaninen ajonesto.
          </p>
        </Column>
        <Column column={3} isFirstColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="contacts.emergency"
                component={ContactSelectField}
                isClearable={false}
                options={contactOptions}
                placeholder={t("placeholderContacts")}
                withAddon={true}
                alignWithAddon={true}
              />

              {hasPermission(PERMISSIONS.CONTACT_CREATE) && (
                <InputGroupButton>
                  <Button
                    isInputGroupButton={true}
                    onClick={handleOpenContactModal}
                    type="button"
                  >
                    +
                  </Button>
                </InputGroupButton>
              )}
            </InputGroup>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column>
          <label>{t("labelCustomerService")} *</label>
        </Column>
      </Row>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="contacts.operator"
                component={ContactSelectField}
                isClearable={true}
                options={contactOptions}
                placeholder={t("placeholderContacts")}
                withAddon={true}
                alignWithAddon={true}
              />
              {hasPermission(PERMISSIONS.CONTACT_CREATE) && (
                <InputGroupButton>
                  <Button
                    isInputGroupButton={true}
                    onClick={handleOpenContactModal}
                    type="button"
                  >
                    +
                  </Button>
                </InputGroupButton>
              )}
            </InputGroup>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column>
          <label>{t("commons:titleSurvey")}</label>
        </Column>
      </Row>
      <Row>
        <Column isFirstColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <Field
              isClearable={true}
              isMulti={true}
              name="surveyOptions"
              options={surveyOptions}
              placeholder={t("placeholderSurvey")}
              component={SelectField}
            />
          </FormGroup>
        </Column>
      </Row>
    </>
  );
};
export default withRouter(BasicInfo);
