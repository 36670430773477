import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "./MapButtons.module.css";

interface Props {
  onClickCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickOk: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showButtonCancel: boolean;
  showButtonOk: boolean;
}

const MapButtons: React.FC<Props> = ({
  onClickCancel,
  onClickOk,
  showButtonCancel,
  showButtonOk
}) => {
  const { t } = useTranslation("facilityEdit");
  return (
    <div className={styles.mapButtons}>
      {showButtonCancel && (
        <button
          className={styles.buttonMap}
          onClick={onClickCancel}
          title={t("buttonCancel")}
          type="button"
        >
          X
        </button>
      )}

      {showButtonOk && (
        <button
          className={styles.buttonMap}
          onClick={onClickOk}
          title={t("buttonSave")}
          type="button"
        >
          ✓
        </button>
      )}
    </div>
  );
};

export default MapButtons;
