import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
  useAuthenticationMethodListQuery,
  useCapacityTypeListQuery,
  useDayTypeListQuery,
  useFacilityProfileQuery,
  useFacilityStatusListQuery,
  useOperatorListQuery,
  usePaymentMethodListQuery,
  usePricingMethodListQuery,
  useServiceListQuery,
  useSurveyOptionsListQuery,
  useUsageListQuery
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import { useErrorModalContext } from "../Commons/ErrorModal";
import LoadingSpinner from "../Commons/LoadingSpinner";
import FacilityEdit from "./FacilityEdit";
interface Params {
  id: string;
}
const FacilityEditContainer = ({ match }: RouteComponentProps<Params>) => {
  const { t } = useTranslation("commons");
  const { openErrorModal } = useErrorModalContext();
  const {
    data: capacityTypesData,
    error: capacityTypesError,
    loading: loadingCapacityTypes
  } = useCapacityTypeListQuery();
  const {
    data: dayTypesData,
    error: dayTypesError,
    loading: loadingDayTypes
  } = useDayTypeListQuery();
  const {
    data: facilityData,
    error: facilityError,
    loading: loadingFacility
  } = useFacilityProfileQuery({
    variables: { id: match.params.id }
  });
  const {
    data: facilityStatusesData,
    error: facilityStatusesError,
    loading: loadingFacilityStatuses
  } = useFacilityStatusListQuery();
  const {
    data: operatorsData,
    error: operatorsError,
    loading: loadingOperators
  } = useOperatorListQuery();
  const {
    data: paymentMethodsData,
    error: paymentMethodsError,
    loading: loadingPaymentMethods
  } = usePaymentMethodListQuery();

  const {
    data: authenticationMethodsData,
    error: authenticationMethodsError,
    loading: loadingAuthenticationMethods
  } = useAuthenticationMethodListQuery();
  const {
    data: surveyOptionsData,
    error: surveyOptionsError,
    loading: loadingSurveyOptions
  } = useSurveyOptionsListQuery();
  const {
    data: pricingMethodsData,
    error: pricingMethodsError,
    loading: loadingPricingMethods
  } = usePricingMethodListQuery();
  const {
    data: servicesData,
    error: servicesError,
    loading: loadingServices
  } = useServiceListQuery();
  const {
    data: usagesData,
    error: usagesError,
    loading: loadingUsages
  } = useUsageListQuery();

  React.useEffect(() => {
    if (facilityError) {
      handleError(facilityError, openErrorModal, t);
    }
  }, [facilityError, openErrorModal, t]);

  if (
    loadingCapacityTypes ||
    loadingDayTypes ||
    loadingFacility ||
    loadingFacilityStatuses ||
    loadingOperators ||
    loadingPaymentMethods ||
    loadingAuthenticationMethods ||
    loadingSurveyOptions ||
    loadingPricingMethods ||
    loadingServices ||
    loadingUsages
  ) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (
    !capacityTypesData ||
    capacityTypesError ||
    !dayTypesData ||
    dayTypesError ||
    !facilityData ||
    facilityError ||
    !facilityStatusesData ||
    facilityStatusesError ||
    !operatorsData ||
    operatorsError ||
    !paymentMethodsData ||
    paymentMethodsError ||
    !authenticationMethodsData ||
    authenticationMethodsError ||
    !surveyOptionsData ||
    surveyOptionsError ||
    !pricingMethodsData ||
    pricingMethodsError ||
    !servicesData ||
    servicesError ||
    !usagesData ||
    usagesError
  ) {
    return <div />;
  }

  return (
    <FacilityEdit
      capacityTypesData={capacityTypesData}
      dayTypesData={dayTypesData}
      facilityData={facilityData}
      facilityStatusesData={facilityStatusesData}
      operatorsData={operatorsData}
      paymentMethodsData={paymentMethodsData}
      authenticationMethodsData={authenticationMethodsData}
      surveyOptionsData={surveyOptionsData}
      pricingMethodsData={pricingMethodsData}
      servicesData={servicesData}
      usagesData={usagesData}
    />
  );
};

export default withRouter(FacilityEditContainer);
