import React from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonSizes } from "./Button";
import styles from "./ErrorModal.module.css";
import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";

interface ErrorModalContext {
  closeErrorModal: () => void;
  error: string | null;
  isErrorModalOpen: boolean;
  openErrorModal: (error: string) => void;
}

export const ErrorModalContext = React.createContext<ErrorModalContext>({
  closeErrorModal: () => null,
  error: null,
  isErrorModalOpen: false,
  openErrorModal: () => null
});

export const ErrorModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [isErrorModalOpen, setIsModalOpen] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const closeErrorModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openErrorModal = React.useCallback((err: string) => {
    setIsModalOpen(true);
    setError(err);
  }, []);

  return (
    <ErrorModalContext.Provider
      value={{
        closeErrorModal,
        error,
        isErrorModalOpen,
        openErrorModal
      }}
    >
      {children}
    </ErrorModalContext.Provider>
  );
};

export const useErrorModalContext = (): ErrorModalContext =>
  React.useContext(ErrorModalContext);

interface Props {
  isOpen: boolean;
  error: string | null;
  onClose: () => void;
}

const ErrorModal: React.FC<Props> = ({ error, isOpen, onClose }) => {
  const { t } = useTranslation("errorModal");

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <>
      <Modal isCentered={true} isOpen={isOpen} width={400} zIndex={1070}>
        <ModalContent>
          <ModalBody>
            <div className={styles.textWrapper}>
              <p>{error}</p>
            </div>
          </ModalBody>
          <ModalFooter isCentered={true}>
            <Button
              onClick={handleCloseModal}
              size={ButtonSizes.XSMALL}
              type="button"
            >
              {t("buttonError")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ErrorModal;
