import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
  useCapacityTypeListQuery,
  useFacilityPredictionQuery,
  useFacilityProfileQuery,
  useFacilityUtilizationQuery,
  useUsageListQuery,
  useUserListQuery
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import useAuth from "../../util/useAuth";
import { useErrorModalContext } from "../Commons/ErrorModal";
import LoadingSpinner from "../Commons/LoadingSpinner";
import FacilityProfile from "./FacilityProfile";
interface Params {
  id: string;
}
const FacilityProfileContainer = ({ match }: RouteComponentProps<Params>) => {
  const { t } = useTranslation("commons");
  const { openErrorModal } = useErrorModalContext();
  const { isUserLoggedIn } = useAuth();
  const {
    data: capacityTypesData,
    loading: loadingCapacityTypes
  } = useCapacityTypeListQuery();
  const {
    data: prediction0Data,
    loading: loadingPrediction0
  } = useFacilityPredictionQuery({
    variables: { id: match.params.id, after: 0 },
    // This should be real-time data so no caching
    fetchPolicy: "no-cache"
  });
  const {
    data: prediction10Data,
    loading: loadingPrediction10
  } = useFacilityPredictionQuery({
    variables: { id: match.params.id, after: 10 },
    // This should be real-time data so no caching
    fetchPolicy: "no-cache"
  });
  const {
    data: prediction20Data,
    loading: loadingPrediction20
  } = useFacilityPredictionQuery({
    variables: { id: match.params.id, after: 20 },
    // This should be real-time data so no caching
    fetchPolicy: "no-cache"
  });
  const {
    data: prediction30Data,
    loading: loadingPrediction30
  } = useFacilityPredictionQuery({
    variables: { id: match.params.id, after: 30 },
    // This should be real-time data so no caching
    fetchPolicy: "no-cache"
  });
  const {
    data: prediction60Data,
    loading: loadingPrediction60
  } = useFacilityPredictionQuery({
    variables: { id: match.params.id, after: 60 },
    // This should be real-time data so no caching
    fetchPolicy: "no-cache"
  });
  const { data, error, loading } = useFacilityProfileQuery({
    variables: { id: match.params.id }
  });
  const {
    data: utilizationData,
    loading: loadingUtilization
  } = useFacilityUtilizationQuery({
    variables: { id: match.params.id },
    // This should be real-time data so no caching
    fetchPolicy: "no-cache"
  });
  const { data: usersData, loading: loadingUsers } = useUserListQuery({
    skip: !isUserLoggedIn()
  });
  const { data: usagesData, loading: loadingUsages } = useUsageListQuery();

  React.useEffect(() => {
    if (error) {
      handleError(error, openErrorModal, t);
    }
  }, [error, openErrorModal, t]);

  if (
    loading ||
    loadingCapacityTypes ||
    loadingPrediction0 ||
    loadingPrediction10 ||
    loadingPrediction20 ||
    loadingPrediction30 ||
    loadingPrediction60 ||
    loadingUsages ||
    loadingUsers ||
    loadingUtilization
  ) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error || !data) {
    return <div />;
  }

  return (
    <FacilityProfile
      capacityTypesData={capacityTypesData}
      data={data}
      prediction0Data={prediction0Data}
      prediction10Data={prediction10Data}
      prediction20Data={prediction20Data}
      prediction30Data={prediction30Data}
      prediction60Data={prediction60Data}
      usagesData={usagesData}
      usersData={usersData}
      utilizationData={utilizationData}
    />
  );
};

export default withRouter(FacilityProfileContainer);
