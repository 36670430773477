import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { get, isEmpty } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import * as Yup from "yup";

import {
  FacilitiesGeoJsonQuery,
  FacilityGeoJson,
  HubListDocument,
  HubProfileQuery,
  RegionsWithFacilitiesQuery,
  useCreateHubMutation,
  useEditHubMutation
} from "../../generated/graphql";
import { decodeNullValues, handleError } from "../../util/helpers";
import {
  getBboxFromPoint,
  getBounds,
  getCenter,
  getMaxBbox,
  LatLngTuple
} from "../../util/mapUtils";
import { translatePath } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import { multiLanguageValidation } from "../../util/validationUtils";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import { useErrorModalContext } from "../Commons/ErrorModal";
import fieldStyles from "../Commons/Field.module.css";
import FormError from "../Commons/FormError";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import InputGroup, { InputGroupText } from "../Commons/InputGroup";
import { useLoginModalContext } from "../Commons/LoginModal";
import NaviBelow from "../Commons/NaviBelow";
import Row from "../Commons/Row";
import FacilityTable from "../HubProfile/FacilityTable";
import HubEditLocation from "./HubEditLocation";

interface Params {
  id: string;
}

interface Props extends RouteComponentProps<Params> {
  facilitiesGeoJsonData: FacilitiesGeoJsonQuery;
  hubData?: HubProfileQuery;
  regionsWithFacilitiesData: RegionsWithFacilitiesQuery | undefined;
}

const validationSchema = Yup.object().shape({
  name: Yup.object().shape({
    fi: Yup.string()
      .max(255)
      .required(),
    sv: Yup.string()
      .max(255)
      .required(),
    en: Yup.string()
      .max(255)
      .required()
  }),
  address: Yup.object().shape({
    streetAddress: multiLanguageValidation,
    city: multiLanguageValidation
  }),
  location: Yup.mixed().required()
});

const HubEdit: React.FC<Props> = ({
  facilitiesGeoJsonData,
  history,
  hubData,
  match,
  regionsWithFacilitiesData
}) => {
  const form = React.useRef<any>(null);
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const { t } = useTranslation(["hubEdit", "hubProfile", "commons"]);
  const [editHubMutation] = useEditHubMutation();
  const [createHubMutation] = useCreateHubMutation();

  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const errorComponent = React.useRef<any>(null);
  const scrollToError = React.useCallback(() => {
    if (errorComponent.current) {
      setTimeout(() => {
        errorComponent.current.scrollIntoView();
      }, 10);
    }
  }, [errorComponent]);
  const constructError = React.useCallback(
    (path: string, error: string) => {
      return `${translatePath("path", path, t)}: ${t("commons:error" + error)}`;
    },
    [t]
  );

  const { hasPermission } = useAuth();
  const {
    params: { id }
  } = match;
  const facilityFeatures: FacilityGeoJson[] =
    facilitiesGeoJsonData && facilitiesGeoJsonData.facilitiesGeoJSON
      ? facilitiesGeoJsonData.facilitiesGeoJSON.features
      : [];
  const hubLocation: any =
    hubData && hubData.hubDetails ? hubData.hubDetails.location : undefined;
  const savedFacilityIds =
    hubData && hubData.hubDetails ? hubData.hubDetails.facilityIds : [];
  const bboxes = facilityFeatures
    .filter(facility => savedFacilityIds.indexOf(facility.id) !== -1)
    .map(facility => facility.geometry.bbox);

  if (hubLocation) {
    bboxes.push(getBboxFromPoint(hubLocation.coordinates));
  }

  const bbox = getMaxBbox(bboxes);
  const bounds = getBounds(bbox);
  const center = getCenter(bbox);

  const getInitialValueByPath = React.useCallback(
    (path: string, defaultValue: any = "") => {
      return get(hubData, `hubDetails.${path}`) || defaultValue;
    },
    [hubData]
  );

  const initialFormValues = React.useMemo(() => {
    return {
      name: {
        fi: id ? getInitialValueByPath("name.fi") : "",
        sv: id ? getInitialValueByPath("name.sv") : "",
        en: id ? getInitialValueByPath("name.en") : ""
      },
      address: {
        streetAddress: {
          fi: id ? getInitialValueByPath("address.streetAddress.fi") : "",
          sv: id ? getInitialValueByPath("address.streetAddress.sv") : "",
          en: id ? getInitialValueByPath("address.streetAddress.en") : ""
        },
        postalCode: id ? getInitialValueByPath("address.postalCode") : "",
        city: {
          fi: id ? getInitialValueByPath("address.city.fi") : "",
          sv: id ? getInitialValueByPath("address.city.sv") : "",
          en: id ? getInitialValueByPath("address.city.en") : ""
        }
      },
      location: id
        ? {
            coordinates: getInitialValueByPath("location.coordinates", null),
            type: getInitialValueByPath("location.type", null)
          }
        : null,
      facilityIds: id ? getInitialValueByPath("facilityIds", []) : []
    };
  }, [id, getInitialValueByPath]);

  const moveToHubList = () => {
    history.push(`/hubs`);
  };

  const moveToHub = () => {
    history.push(`/hubs/${id}`);
  };

  const moveToHubById = (hubId: string) => {
    history.push(`/hubs/${hubId}`);
  };

  const editHub = async (values: any) => {
    try {
      await editHubMutation({
        variables: { ...decodeNullValues(values) },
        update(cache, editedHub) {
          try {
            const hubListData: any = cache.readQuery({
              query: HubListDocument
            });

            if (hubListData) {
              const newHub =
                editedHub && editedHub.data && editedHub.data.editHub;
              const newHubs: any = hubListData.hubs.hubs.map((item: any) => {
                return newHub && item.id === newHub.id ? newHub : item;
              });
              hubListData.hubs.hubs = newHubs;

              cache.writeQuery({
                query: HubListDocument,
                data: hubListData
              });
            }
          } catch (err) {
            console.error(err);
          }
          const hubId =
            editedHub && editedHub.data ? editedHub.data.editHub.id : null;
          if (hubId) {
            moveToHubById(hubId);
          }
        }
      });
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => editHub(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  const createHub = async (values: any) => {
    try {
      await createHubMutation({
        variables: { ...decodeNullValues(values) },
        update(cache, createdHub) {
          try {
            const hubListData: any = cache.readQuery({
              query: HubListDocument
            });

            if (hubListData) {
              const newHub =
                createdHub && createdHub.data && createdHub.data.createHub;
              const newHubs: any = [...hubListData.hubs.hubs, newHub];
              hubListData.hubs.hubs = newHubs;

              cache.writeQuery({
                query: HubListDocument,
                data: hubListData
              });
            }
          } catch (err) {
            console.error(err);
          }
          const hubId =
            createdHub && createdHub.data ? createdHub.data.createHub.id : null;
          if (hubId) {
            moveToHubById(hubId);
          }
        }
      });
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => createHub(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  React.useEffect(() => {
    if (form.current) {
      form.current.validateForm();
    }
  }, [form]);

  return (
    <Formik
      ref={form}
      initialValues={initialFormValues}
      onSubmit={values => {
        if (id) {
          editHub({ ...values, id });
        } else {
          createHub({ ...values });
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleSubmit,
        setFieldValue,
        submitCount,
        touched,
        values
      }) => {
        const facilityIds = values.facilityIds;
        const location: any = values.location;
        const associatedFacilities: any[] = facilityIds
          .map((facilityId: string) => {
            const feature = facilityFeatures.find(
              item => item.id === facilityId
            );
            return feature ? { ...feature.properties, id: feature.id } : null;
          })
          .filter((item: any) => !!item);

        const handleLocationChange = (latlng: LatLngTuple) => {
          setFieldValue("location", {
            type: "Point",
            coordinates: latlng
          });
        };

        const handleFacilityClick = (facilityId: string) => {
          if (facilityIds.indexOf(facilityId) !== -1) {
            setFieldValue(
              "facilityIds",
              facilityIds.filter((item: any) => item !== facilityId)
            );
          } else {
            setFieldValue("facilityIds", [...facilityIds, facilityId]);
          }
        };

        const submitHandler = () => {
          if (!isEmpty(errors)) {
            setValidationErrors([constructError("Hub", "BasicRequirements")]);
            scrollToError();
          } else {
            setValidationErrors([]);
          }

          handleSubmit();
        };

        return (
          <Form>
            <Breadcrumb
              name={values.name.fi || t("titleHub")}
              canCancel={true}
              canSave={
                id
                  ? hasPermission(PERMISSIONS.HUB_UPDATE)
                  : hasPermission(PERMISSIONS.HUB_CREATE)
              }
              onClickCancel={id ? moveToHub : moveToHubList}
              onClickSave={submitHandler}
            />
            <Container isContent={true} ref={errorComponent}>
              <FormError errors={validationErrors} />

              <h3 className="h3-first">{t("hubProfile:titleBasicInfo")}</h3>
              <Row>
                <Column column={3} isFirstColumn={true}>
                  <label>{t("hubProfile:labelName")} *</label>
                </Column>
              </Row>
              <Row>
                <Column column={3} isFirstColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="name.fi"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>fi</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
                <Column column={3}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="name.sv"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>sv</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
                <Column column={3} isLastColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="name.en"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>en</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
              </Row>

              <h4>{t("titleAddress")}</h4>
              <Row>
                <Column>
                  <label>{t("labelStreetAddress")}</label>
                </Column>
              </Row>
              <Row>
                <Column column={3} isFirstColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="address.streetAddress.fi"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>fi</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
                <Column column={3}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="address.streetAddress.sv"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>sv</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
                <Column column={3} isLastColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="address.streetAddress.en"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>en</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
              </Row>

              <Row>
                <Column column={3} isFirstColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <label>{t("labelPostalCode")}</label>
                    <Field
                      name="address.postalCode"
                      type="text"
                      maxLength={255}
                      component={InputField}
                    />
                  </FormGroup>
                </Column>
              </Row>

              <Row>
                <Column>
                  <label>{t("labelCity")}</label>
                </Column>
              </Row>
              <Row>
                <Column column={3} isFirstColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="address.city.fi"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>fi</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
                <Column column={3}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="address.city.sv"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>sv</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
                <Column column={3} isLastColumn={true}>
                  <FormGroup hasLargeMarginBottom={true}>
                    <InputGroup>
                      <Field
                        name="address.city.en"
                        type="text"
                        maxLength={255}
                        component={InputField}
                        withAddon={true}
                      />
                      <InputGroupText>en</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Column>
              </Row>

              <h3>{t("labelLocation")} *</h3>
              <Row>
                <Column>
                  <p>{t("helpLocation")}</p>
                </Column>
              </Row>
              <Row hasSmallMargin={true}>
                <Column>
                  <HubEditLocation
                    associatedFacilityIds={facilityIds}
                    bounds={bounds}
                    center={center}
                    className={classNames({
                      [fieldStyles.validationError]: get(errors, "location"),
                      [fieldStyles.isTouched]: get(touched, "location"),
                      [fieldStyles.isSubmitted]: submitCount
                    })}
                    facilityFeatures={facilityFeatures}
                    hubLocation={location && location.coordinates}
                    onFeatureClick={handleFacilityClick}
                    onLocationChange={handleLocationChange}
                    regionsWithFacilitiesData={regionsWithFacilitiesData}
                  />
                </Column>
              </Row>

              <h3>{t("hubProfile:titleFacilities")}</h3>
              <Row>
                <Column>
                  {!associatedFacilities.length ? (
                    <p>{t("hubProfile:messageNoFacilities")}</p>
                  ) : (
                    <FacilityTable facilities={associatedFacilities} />
                  )}
                </Column>
              </Row>
            </Container>

            <NaviBelow>
              <Button
                isResponsive={true}
                size={ButtonSizes.SMALL}
                onClick={id ? moveToHub : moveToHubList}
                type="button"
              >
                {t("buttonCancel")}
              </Button>
              {(id
                ? hasPermission(PERMISSIONS.HUB_UPDATE)
                : hasPermission(PERMISSIONS.HUB_CREATE)) && (
                <Button
                  isResponsive={true}
                  size={ButtonSizes.SMALL}
                  onClick={submitHandler}
                  type="button"
                >
                  {t("buttonSave")}
                </Button>
              )}
            </NaviBelow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withRouter(HubEdit);
