import { Field } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { PRICING_METHOD } from "../../constants";
import {
  AuthenticationMethodListQuery,
  CapacityTypeListQuery,
  DayTypeListQuery,
  PaymentMethodListQuery,
  PricingMethodListQuery,
  ServiceListQuery,
  UsageListQuery
} from "../../generated/graphql";
import { toPascalCase, translateValue } from "../../util/translateUtils";
import Column from "../Commons/Column";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import InputGroup, { InputGroupText } from "../Commons/InputGroup";
import RadioButtonField from "../Commons/RadioButtonField";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";
import iconStyles from "../FacilityList/FacilityList.module.css";
import PricingTable from "./PricingTable";
interface Params {
  id?: string;
}
interface Props extends RouteComponentProps<Params> {
  dayTypesData: DayTypeListQuery;
  paymentMethodsData: PaymentMethodListQuery;
  pricingMethodsData: PricingMethodListQuery;
  authenticationMethodsData: AuthenticationMethodListQuery;
  servicesData: ServiceListQuery;
  usagesData: UsageListQuery;
  capacityTypesData: CapacityTypeListQuery;
  values: any;
  pricing: any;
  facilityType: string;
  setFieldValue: (field: string, value: any) => void;
}

const PriceAndPayment: React.FC<Props> = ({
  paymentMethodsData,
  capacityTypesData,
  authenticationMethodsData,
  pricingMethodsData,
  dayTypesData,
  servicesData,
  values,
  pricing,
  usagesData,
  setFieldValue,
  facilityType
}) => {
  const { t } = useTranslation([
    "facilityEdit",
    "facilityList",
    "facilityProfile",
    "commons"
  ]);

  const allPaymentMethods = paymentMethodsData
    ? paymentMethodsData.paymentMethods
    : [];
  const paymentMethodOptions: Option[] = allPaymentMethods.map(item => ({
    label: translateValue("commons:paymentMethod", item, t),
    value: item
  }));
  const allAuthenticationMethods = authenticationMethodsData
    ? authenticationMethodsData.authenticationMethods
    : [];
  const authenticationMethodOptions: Option[] = allAuthenticationMethods.map(
    item => ({
      label: translateValue("commons:authenticationMethod", item, t),
      value: item
    })
  );
  const allPricingMethods = pricingMethodsData
    ? pricingMethodsData.pricingMethods
    : [];
  const allServices = servicesData ? servicesData.services : [];
  const serviceOptions: Option[] = allServices.map(service => {
    return {
      label: translateValue("commons:service", service, t),
      value: service
    };
  });
  const allUsages = usagesData ? usagesData.usages : [];
  const usageOptions = allUsages.map(item => {
    return {
      label: translateValue("commons:usage", item, t),
      value: item
    };
  });
  const allCapacityTypes =
    capacityTypesData && capacityTypesData.capacityTypes
      ? capacityTypesData.capacityTypes
      : [];
  const capacityTypeOptions = allCapacityTypes.map(capacityType => {
    return {
      label: translateValue("commons:capacityType", capacityType, t),
      value: capacityType
    };
  });
  const allDayTypes = dayTypesData ? dayTypesData.dayTypes : [];
  const dayTypeOptions = allDayTypes.map(item => {
    return {
      label: translateValue("commons:dayType", item, t),
      value: item
    };
  });

  const renderPricingTable = () => {
    if (values.pricingMethod !== PRICING_METHOD.PARK_AND_RIDE_247_FREE) {
      return (
        <PricingTable
          capacityTypeOptions={capacityTypeOptions}
          dayTypeOptions={dayTypeOptions}
          name="pricing"
          pricing={pricing}
          setFieldValue={setFieldValue}
          usageOptions={usageOptions}
          facilityType={facilityType}
          pricingMethod={values.pricingMethod}
        />
      );
    }
  };

  const renderIcons = (method: string) => {
    return (
      <>
        <span
          title={toPascalCase(method)}
          className={iconStyles["facilityIcon" + toPascalCase(method)]}
        />
        {method.search("FREE_") !== -1 && (
          <span
            title={toPascalCase(method)}
            className={iconStyles.facilityIconPysakointikiekko}
          />
        )}
        {method.search("PAID") !== -1 && (
          <span
            title={toPascalCase(method)}
            className={iconStyles.facilityIconPaid}
          />
        )}
      </>
    );
  };

  return (
    <>
      <h3>{t("titlePaymentAndServices")}</h3>
      <Row>
        <Column>
          {allPricingMethods.map((method, index) => (
            <Column key={method} className={iconStyles.facilityIconsContainer}>
              <Field
                component={RadioButtonField}
                checked={values.pricingMethod === method}
                icon={renderIcons(method)}
                name="pricingMethod"
                label={translateValue("commons:pricingMethod", method, t)}
                title={translateValue("commons:pricingMethod", method, t)}
                value={method}
                key={method}
              />
              {index + 1 !== pricing.length}
            </Column>
          ))}
        </Column>
      </Row>
      <Row>
        <Column>{renderPricingTable()}</Column>
      </Row>

      {(values.pricingMethod.startsWith("PAID") ||
        values.pricingMethod.startsWith("CUSTOM")) && (
        <>
          <h4>{t("titlePaymentMethods")}</h4>
          <Row>
            <Column>
              <FormGroup hasLargeMarginBottom={true}>
                <Field
                  isClearable={true}
                  isMulti={true}
                  name="paymentInfo.paymentMethods"
                  options={paymentMethodOptions}
                  placeholder={t("placeholderPaymentMethods")}
                  component={SelectField}
                />
              </FormGroup>
            </Column>
          </Row>
        </>
      )}

      <h4>{t("titleAuthentication")}</h4>
      <Row>
        <Column>
          <FormGroup hasLargeMarginBottom={true}>
            <Field
              isClearable={true}
              isMulti={true}
              name="authenticationMethods"
              options={authenticationMethodOptions}
              placeholder={t("titleAuthentication")}
              component={SelectField}
            />
          </FormGroup>
        </Column>
      </Row>

      <h4>{t("titleServices")}</h4>
      <Row>
        <Column>
          <FormGroup hasLargeMarginBottom={true}>
            <Field
              isClearable={true}
              isMulti={true}
              name="services"
              options={serviceOptions}
              placeholder={t("placeholderServices")}
              component={SelectField}
            />
          </FormGroup>
        </Column>
      </Row>

      <h4>{t("titlePaymentDetail")}</h4>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="paymentInfo.detail.fi"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>fi</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
        <Column column={3}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="paymentInfo.detail.sv"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>sv</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
        <Column column={3} isLastColumn={true}>
          <FormGroup hasLargeMarginBottom={true}>
            <InputGroup>
              <Field
                name="paymentInfo.detail.en"
                maxLength={255}
                component={InputField}
                withAddon={true}
              />
              <InputGroupText>en</InputGroupText>
            </InputGroup>
          </FormGroup>
        </Column>
      </Row>
    </>
  );
};

export default withRouter(PriceAndPayment);
