import classNames from "classnames";
import * as React from "react";
import styles from "./Container.module.css";

interface Props {
  isContent?: boolean;
  isFluid?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

const Container: React.FC<Props> = React.forwardRef(
  ({ children, isContent, isFluid }, ref?: React.Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={classNames(
          isFluid ? styles["container-fluid"] : styles.container,
          { [styles.content]: isContent }
        )}
      >
        {children}
      </div>
    );
  }
);

export default Container;
