import * as React from "react";
import { useTranslation } from "react-i18next";
import { FACILITY_TYPE, PRICING_METHOD } from "../../constants";
import { FacilityDetails } from "../../generated/graphql";
import {
  toPascalCase,
  translateList,
  translateValue
} from "../../util/translateUtils";
import Column from "../Commons/Column";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import Table from "../Commons/Table";
import iconStyles from "../FacilityList/FacilityList.module.css";
import styles from "./PricingAndServices.module.css";
interface Props {
  facilityDetails: FacilityDetails;
}

const PricingAndServices: React.FC<Props> = ({ facilityDetails }) => {
  const { t } = useTranslation(["facilityProfile", "commons"]);

  const showPricing = (): boolean => {
    return !!facilityDetails.pricing && !!facilityDetails.pricing.length;
  };

  const showPaymentMethods = (): boolean => {
    return !!facilityDetails.paymentInfo.paymentMethods.length;
  };
  
  const showAuthenticationMethods = (): boolean => {
    return (
      !!facilityDetails.authenticationMethods &&
      !!facilityDetails.authenticationMethods.length
    );
  };

  const pricingMethod = facilityDetails.pricingMethod;

  const showServices = (): boolean => {
    return !!facilityDetails.services.length;
  };

  const showPaymentInfoDetails = (): boolean => {
    return !!facilityDetails.paymentInfo.detail;
  };

  const showPaymentInfoUrls = (): boolean => {
    return !!facilityDetails.paymentInfo.url;
  };

  const showPaymentInfoAdditionalInfo = (): boolean => {
    return showPaymentInfoDetails() || showPaymentInfoUrls();
  };

  const renderIcons = (method: string) => {
    return (
      <>
        <span
          title={toPascalCase(method)}
          className={iconStyles["facilityIcon" + toPascalCase(method)]}
        />
        {method.search("FREE_") !== -1 && (
          <span
            title={toPascalCase(method)}
            className={iconStyles.facilityIconPysakointikiekko}
          />
        )}
        {method.search("PAID") !== -1 && (
          <span
            title={toPascalCase(method)}
            className={iconStyles.facilityIconPaid}
          />
        )}
      </>
    );
  };

  const hourLimit = () => {
    return pricingMethod && pricingMethod.match(/-?\d+\.?\d*/);
  };

  const facilityType = facilityDetails.type;

  const showPriceFields =
    pricingMethod === PRICING_METHOD.PAID_10H ||
    pricingMethod === PRICING_METHOD.PAID_12H ||
    pricingMethod === PRICING_METHOD.PAID_16H ||
    pricingMethod === PRICING_METHOD.PAID_24H ||
    pricingMethod === PRICING_METHOD.PAID_16H_24H_48H;

  return (
    <>
      {showPricing() && (
        <>
          <Row>
            <Column>
              <h3 className="h3-view">{t("titlePricingAndServices")}</h3>
            </Column>
          </Row>
          <Row>
            <Column className={iconStyles.facilityIconsContainer}>
              <p>
                {translateValue(
                  "commons:pricingMethod",
                  facilityDetails.pricingMethod,
                  t
                )}
              </p>
              {renderIcons(facilityDetails.pricingMethod)}
            </Column>
          </Row>
          <Row>
            <Column>
              <Panel hasBorder={true}>
                <Table
                  isBordered={true}
                  isCondensed={true}
                  isInsidePanel={true}
                  isStriped={true}
                >
                  <thead>
                    <tr>
                      <th colSpan={3}>{t("labelCapacity")}</th>
                      <th colSpan={4}>{t("labelOpeningHours")}</th>
                      <th
                        colSpan={4}
                        style={
                          facilityType === "BICYCLE" ? { display: "none" } : {}
                        }
                      >
                        {t("labelPrice")}
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.capacityTypeColumn}>
                        {t("labelCapacityType")}
                      </th>
                      <th className={styles.usageColumn}>{t("labelUsage")}</th>
                      <th className={styles.maxCapacityColumn}>
                        {t("labelMaxCapacity")}
                      </th>
                      <th className={styles.dayTypeColumn}>
                        {t("labelDayType")}
                      </th>
                      <th className={styles.h24Column}>{t("label24H")}</th>
                      <th className={styles.fromColumn}>{t("labelFrom")}</th>
                      <th className={styles.untilColumn}>{t("labelUntil")}</th>
                      <th
                        className={styles.freeColumn}
                        style={
                          facilityType === FACILITY_TYPE.BICYCLE
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {t("labelFree")}
                      </th>
                      <th
                        style={
                          facilityType === FACILITY_TYPE.BICYCLE ||
                          !showPriceFields
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {pricingMethod === PRICING_METHOD.PAID_16H_24H_48H
                          ? <span>€/16 h</span>
                          : <span>€/{hourLimit()} h</span>
                        }
                      </th>

                      <th
                        style={
                          facilityType === FACILITY_TYPE.BICYCLE ||
                          !showPriceFields
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {pricingMethod === PRICING_METHOD.PAID_16H_24H_48H
                          ? <span>€/24 h</span>
                          : <span>{t("commons:over")} {hourLimit()} h €/h</span>
                        }
                      </th>
                      <th
                        style={
                          facilityType === FACILITY_TYPE.BICYCLE
                            ? { display: "none" }
                            : {}
                        }
                      >
                        {pricingMethod === PRICING_METHOD.PAID_16H_24H_48H
                          ? <span>€/48 h</span>
                          : <span>{t("labelPriceOther")}</span>
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!facilityDetails.pricing &&
                      facilityDetails.pricing.map((price, i: any) => {
                        const shouldShowCapacityType = () => {
                          if (!i) {
                            return true;
                          } else if (
                            price.capacityType ===
                              facilityDetails.pricing[i - 1].capacityType &&
                            price.usage === facilityDetails.pricing[i - 1].usage
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        };

                        const is24h = (): boolean => {
                          return (
                            Number(price.time.from) === 0 &&
                            Number(price.time.until) === 24
                          );
                        };

                        const isFree = (): boolean => {
                          return (
                            !price.price &&
                            !price.priceExtra &&
                            !price.priceOther
                          );
                        };

                        return (
                          <tr key={i}>
                            <td>
                              {shouldShowCapacityType() &&
                                translateValue(
                                  "commons:capacityType",
                                  price.capacityType,
                                  t
                                )}
                            </td>
                            <td>
                              {shouldShowCapacityType() &&
                                translateValue("commons:usage", price.usage, t)}
                            </td>
                            <td className={styles.textRight}>
                              {price.maxCapacity}
                            </td>
                            <td>
                              {translateValue(
                                "commons:dayType",
                                price.dayType,
                                t
                              )}
                            </td>
                            <td className={styles.centeredCell}>
                              {is24h() ? "✓" : ""}
                            </td>
                            <td>{is24h() ? "" : price.time.from}</td>
                            <td>{is24h() ? "" : price.time.until}</td>
                            <td
                              className={styles.centeredCell}
                              style={
                                facilityType === FACILITY_TYPE.BICYCLE
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              {isFree() ? "✓" : ""}
                            </td>
                            <td
                              className={styles.tableText}
                              style={
                                facilityType === FACILITY_TYPE.BICYCLE ||
                                !showPriceFields
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              {price.price && price.price.fi}
                            </td>
                            <td
                              className={styles.tableText}
                              style={
                                facilityType === FACILITY_TYPE.BICYCLE ||
                                !showPriceFields
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              {price.priceExtra}
                            </td>
                            <td
                              className={styles.tableText}
                              style={
                                facilityType === FACILITY_TYPE.BICYCLE
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              {price.priceOther}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Panel>
            </Column>
          </Row>
        </>
      )}

      {showPaymentMethods() && (
        <>
          <h4>{t("titlePaymentMethods")}</h4>
          <Row>
            <Column>
              {translateList(
                "commons:paymentMethod",
                facilityDetails.paymentInfo.paymentMethods,
                t
              )}
            </Column>
          </Row>
        </>
      )}

      {showAuthenticationMethods() && (
        <>
          <h4>{t("commons:titleAuthentication")}</h4>
          <Row>
            <Column>
              {translateList(
                "commons:authenticationMethod",
                facilityDetails.authenticationMethods || [],
                t
              )}
            </Column>
          </Row>
        </>
      )}

      {showServices() && (
        <>
          <h4>{t("titleServices")}</h4>
          <Row>
            <Column>
              {translateList("commons:service", facilityDetails.services, t)}
            </Column>
          </Row>
        </>
      )}

      {showPaymentInfoAdditionalInfo() && (
        <>
          <h4>{t("titleAdditionalInfo")}</h4>
          {showPaymentInfoDetails() && (
            <Row>
              <Column column={3} isFirstColumn={true}>
                <p>
                  <span>
                    {facilityDetails.paymentInfo.detail &&
                      facilityDetails.paymentInfo.detail.fi}
                  </span>{" "}
                  (fi)
                </p>
              </Column>
              <Column column={3}>
                <p>
                  <span>
                    {facilityDetails.paymentInfo.detail &&
                      facilityDetails.paymentInfo.detail.sv}
                  </span>{" "}
                  (sv)
                </p>
              </Column>
              <Column column={3} isLastColumn={true}>
                <p>
                  <span>
                    {facilityDetails.paymentInfo.detail &&
                      facilityDetails.paymentInfo.detail.en}
                  </span>{" "}
                  (en)
                </p>
              </Column>
            </Row>
          )}
          {showPaymentInfoUrls() && (
            <Row>
              <Column column={3} isFirstColumn={true}>
                <p>
                  <span>
                    {facilityDetails.paymentInfo.url &&
                      facilityDetails.paymentInfo.url.fi && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={facilityDetails.paymentInfo.url.fi}
                        >
                          {facilityDetails.paymentInfo.url.fi}
                        </a>
                      )}
                  </span>{" "}
                  (fi)
                </p>
              </Column>
              <Column column={3}>
                <p>
                  <span>
                    {facilityDetails.paymentInfo.url &&
                      facilityDetails.paymentInfo.url.sv && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={facilityDetails.paymentInfo.url.sv}
                        >
                          {facilityDetails.paymentInfo.url.sv}
                        </a>
                      )}
                  </span>{" "}
                  (sv)
                </p>
              </Column>
              <Column column={3} isLastColumn={true}>
                <p>
                  <span>
                    {facilityDetails.paymentInfo.url &&
                      facilityDetails.paymentInfo.url.en && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={facilityDetails.paymentInfo.url.en}
                        >
                          {facilityDetails.paymentInfo.url.en}
                        </a>
                      )}
                  </span>{" "}
                  (en)
                </p>
              </Column>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default PricingAndServices;
