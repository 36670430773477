import * as React from "react";
import { useTranslation } from "react-i18next";
import Column from "../Commons/Column";
import Row from "../Commons/Row";

const Info: React.FC<any> = () => {
  const { t } = useTranslation("reports");

  return (
    <Row hasSmallMarginBottom={true}>
      <Column>
        <p>
          <strong>{t("infoLead")}</strong>
        </p>
        <ul>
          <li>{t("infoBullet1")}</li>
          <li>{t("infoBullet2")}</li>
        </ul>
      </Column>
    </Row>
  );
};

export default Info;
