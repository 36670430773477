import * as React from "react";
import { useTranslation } from "react-i18next";
import { Facility } from "../../generated/graphql";
import Panel from "../Commons/Panel";
import Table from "../Commons/Table";
import FacilityList from "../FacilityList/FacilityList";

interface Props {
  facilities: Facility[];
}

const FacilityTable: React.FC<Props> = ({ facilities }) => {
  const { t } = useTranslation("hubProfile");
  return (
    <Panel hasBorder={true}>
      <Table
        isBordered={true}
        isCondensed={true}
        isInsidePanel={true}
        isStriped={true}
      >
        <thead>
          <tr>
            <th>{t("labelName")}</th>
            <th>{t("labelStatus")}</th>
            <th>{t("labelUsage")}</th>
            <th>{t("labelCapacityTypes")}</th>
          </tr>
        </thead>
        <tbody>
          <FacilityList facilities={facilities} />
        </tbody>
      </Table>
    </Panel>
  );
};

export default FacilityTable;
