import classnames from "classnames";
// tslint:disable-next-line: no-submodule-imports
import { fi } from "date-fns/locale";
import * as React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
// tslint:disable-next-line: no-submodule-imports
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Field.module.css";

registerLocale("fi", fi);

interface Props {
  className?: string;
  endDate?: Date;
  isClearable?: boolean;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  onChange: (name: string, value: Date | null) => void;
  startDate?: Date;
  value: Date | null;
}

const DatePickerField: React.FC<Props> = ({
  className,
  endDate,
  isClearable = true,
  maxDate,
  minDate,
  name,
  onChange,
  startDate,
  value
}) => {
  const handleChange = (val: Date | null) => {
    if (isClearable || val) {
      onChange(name, val);
    }
  };

  return (
    <DatePicker
      className={classnames(
        styles["form-control"],
        styles.dateInput,
        className
      )}
      locale="fi"
      dateFormat="dd.MM.yyyy"
      endDate={endDate}
      isClearable={isClearable}
      maxDate={maxDate}
      minDate={minDate}
      onChange={handleChange}
      selected={(value && new Date(value)) || null}
      startDate={startDate}
    />
  );
};

export default DatePickerField;
