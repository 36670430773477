import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { USER_ROLE } from "../../constants";
import {
  useCreateUserMutation,
  UserListDocument
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import { translatePath } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import { useErrorModalContext } from "../Commons/ErrorModal";
import FormError from "../Commons/FormError";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import { useLoginModalContext } from "../Commons/LoginModal";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";
import ModalHeader from "../Commons/ModalHeader";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";

const validationSchema = Yup.object().shape({
  role: Yup.string().required(),
  username: Yup.string()
    .required()
    .max(255),
  password: Yup.string().when(["role"], {
    is: role => {
      return role !== USER_ROLE.OPERATOR_API;
    },
    then: Yup.string()
      .required()
      .max(255)
  }),
  operatorId: Yup.string().when(["role"], {
    is: role => {
      return role === USER_ROLE.OPERATOR || role === USER_ROLE.OPERATOR_API;
    },
    then: Yup.string().required()
  })
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  operatorOptions: Option[];
  userRoleOptions: Option[];
}

const NewUserModal: React.FC<Props> = ({
  isOpen,
  onClose,
  operatorOptions,
  userRoleOptions
}) => {
  const form = React.useRef<any>(null);
  const errorComponent = React.useRef<any>(null);
  const { getUserOperatorId, getUserRole, hasPermission } = useAuth();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const [createUser] = useCreateUserMutation();
  const { t } = useTranslation(["userList", "login", "commons"]);

  const constructError = React.useCallback(
    (path: string, error: string) => {
      return `${translatePath("path", path, t)}: ${t("commons:error" + error)}`;
    },
    [t]
  );

  const scrollToError = React.useCallback(() => {
    if (errorComponent.current) {
      setTimeout(() => {
        errorComponent.current.scrollIntoView();
      }, 10);
    }
  }, [errorComponent]);

  const handleCloseModal = () => {
    onClose();
  };

  React.useEffect(() => {
    if (form.current) {
      form.current.resetForm();
      form.current.validateForm();
    }
  }, [isOpen]);

  return (
    <Formik
      ref={form}
      initialValues={{
        operatorId:
          getUserRole() === USER_ROLE.OPERATOR ? getUserOperatorId() || "" : "",
        password: "",
        role: "",
        username: ""
      }}
      onSubmit={async values => {
        try {
          const payload: any = {
            role: values.role,
            username: values.username
          };
          if (values.role !== USER_ROLE.OPERATOR_API) {
            payload.password = values.password;
          }
          if (
            values.role === USER_ROLE.OPERATOR ||
            values.role === USER_ROLE.OPERATOR_API
          ) {
            payload.operatorId = values.operatorId;
          }
          await createUser({
            variables: {
              user: payload
            },
            update(cache, createdUserData) {
              try {
                const userListData: any = cache.readQuery({
                  query: UserListDocument
                });
                const newUser =
                  createdUserData &&
                  createdUserData.data &&
                  createdUserData.data.createUser;

                if (createUser && userListData.users && newUser) {
                  const newUsers: any = [...userListData.users];

                  newUsers.push(newUser);
                  cache.writeQuery({
                    query: UserListDocument,
                    data: { users: newUsers }
                  });
                }
              } catch (err) {
                console.error(err);
              }
            }
          });
          onClose();
        } catch (err) {
          handleError(
            err,
            openErrorModal,
            t,
            openLoginModal,
            constructError,
            setValidationErrors,
            scrollToError
          );
        }
      }}
      validationSchema={validationSchema}
    >
      {({ errors, handleSubmit, values }) => {
        const submitHandler = () => {
          if (!isEmpty(errors)) {
            setValidationErrors([
              constructError("UserInfo", "BasicRequirements")
            ]);
            scrollToError();
          }

          handleSubmit();
        };

        return (
          <Modal isOpen={isOpen}>
            <ModalContent>
              <Form>
                <ModalHeader title={t("titleAddNewUser")} />

                <ModalBody ref={errorComponent}>
                  <FormError errors={validationErrors} />
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <label>{t("labelRole")} *</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <Field
                          component={SelectField}
                          isClearable={false}
                          name="role"
                          placeholder={t("placeholderRole")}
                          options={userRoleOptions}
                        />
                      </FormGroup>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <label> {t("labelUsername")} *</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <Field
                          component={InputField}
                          maxLength={255}
                          name="username"
                          type="text"
                        />
                      </FormGroup>
                    </Column>
                  </Row>
                  {values.role !== USER_ROLE.OPERATOR_API && (
                    <>
                      <Row>
                        <Column column={3} isFirstColumn={true}>
                          <label> {t("labelPassword")} *</label>
                        </Column>
                      </Row>
                      <Row>
                        <Column column={3} isFirstColumn={true}>
                          <FormGroup hasLargeMarginBottom={true}>
                            <Field
                              component={InputField}
                              maxLength={255}
                              name="password"
                              type="password"
                            />
                          </FormGroup>
                        </Column>
                      </Row>
                    </>
                  )}
                  {values.role !== USER_ROLE.ADMIN && (
                    <>
                      <Row>
                        <Column column={3} isFirstColumn={true}>
                          <label> {t("labelOperator")} *</label>
                        </Column>
                      </Row>
                      <Row>
                        <Column column={3} isFirstColumn={true}>
                          <FormGroup hasLargeMarginBottom={true}>
                            <Field
                              component={SelectField}
                              isDisabled={
                                !hasPermission(PERMISSIONS.ALL_OPERATORS)
                              }
                              name="operatorId"
                              options={operatorOptions}
                              placeholder={t("placeholderOperator")}
                            />
                          </FormGroup>
                        </Column>
                      </Row>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    size={ButtonSizes.XSMALL_SECONDARY}
                    onClick={handleCloseModal}
                  >
                    {t("buttonCancel")}
                  </Button>
                  {hasPermission(PERMISSIONS.USER_CREATE) && (
                    <Button
                      type="button"
                      onClick={submitHandler}
                      size={ButtonSizes.XSMALL}
                    >
                      {t("buttonReady")}
                    </Button>
                  )}
                </ModalFooter>
              </Form>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
export default NewUserModal;
