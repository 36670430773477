import { Field } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { BICYCLE_CAPACITY_TYPES, CAPACITY_TYPES } from "../../constants";
import Column from "../Commons/Column";
import InputField from "../Commons/InputField";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import { Option } from "../Commons/SelectField";
import Table from "../Commons/Table";

interface Props {
  capacityTypes: Option[];
  facilityType: string;
}

const BuiltCapacitiesTable: React.FC<Props> = ({
  capacityTypes,
  facilityType
}) => {
  const { t } = useTranslation("facilityEdit");

  const renderBicycleCapacities = () => {
    return capacityTypes.map((capacityType, i: any) => {
      if (capacityType.value === CAPACITY_TYPES.BICYCLE) {
        return (
          <tr key={i}>
            <td>{capacityType.label}</td>
            <td>
              <Field
                component={InputField}
                name={`builtCapacity.${capacityType.value}`}
                type="number"
              />
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={i} style={{ display: "none" }}>
            <td>{capacityType.label}</td>
            <td>
              <Field
                component={InputField}
                name={`builtCapacity.${capacityType.value}`}
                type="number"
                defaultValue={0}
              />
            </td>
          </tr>
        );
      }
    });
  };

  const renderCapacities = () => {
    if (facilityType === "BICYCLE") {
      return renderBicycleCapacities();
    } else if (facilityType === "CAR") {
      return renderCarCapacities();
    } else {
      return renderBuiltCapacities();
    }
  };
  const renderCarCapacities = () => {
    return capacityTypes.map((capacityType, i: any) => {
      if (BICYCLE_CAPACITY_TYPES.includes(capacityType.value)) {
        return (
          <tr key={i} style={{ display: "none" }}>
            <td>{capacityType.label}</td>
            <td>
              <Field
                component={InputField}
                name={`builtCapacity.${capacityType.value}`}
                type="number"
              />
            </td>
          </tr>
        );
      } else {
        return (
          <tr key={i}>
            <td>{capacityType.label}</td>
            <td>
              <Field
                component={InputField}
                name={`builtCapacity.${capacityType.value}`}
                type="number"
              />
            </td>
          </tr>
        );
      }
    });
  };
  const renderBuiltCapacities = () => {
    return capacityTypes.map((capacityType, i: any) => {
      return (
        <tr key={i}>
          <td>{capacityType.label}</td>
          <td>
            <Field
              component={InputField}
              name={`builtCapacity.${capacityType.value}`}
              type="number"
            />
          </td>
        </tr>
      );
    });
  };
  return (
    <Row>
      <Column column={2} isFirstColumn={true}>
        <Panel hasBorder={true}>
          <Table
            isBordered={true}
            isCondensed={true}
            isInsidePanel={true}
            isStriped={true}
          >
            <thead>
              <tr>
                <th>{t("labelCapacityType")}</th>
                <th>{t("labelBuiltCapacity")}</th>
              </tr>
            </thead>
            <tbody>{renderCapacities()}</tbody>
          </Table>
        </Panel>
      </Column>
    </Row>
  );
};

export default BuiltCapacitiesTable;
