import { Field } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { UnavailableCapacity } from "../../generated/graphql";
import { translateValue } from "../../util/translateUtils";
import Column from "../Commons/Column";
import InputField from "../Commons/InputField";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import Table from "../Commons/Table";

interface Props {
  unavailableCapacities: UnavailableCapacity[];
}

const UnavailableCapacitiesTable: React.FC<Props> = ({
  unavailableCapacities
}) => {
  const { t } = useTranslation(["facilityEdit", "commons"]);

  if (!unavailableCapacities.length) {
    return (
      <Row hasSmallMarginBottom={true}>
        <Column>
          <p>{t("noBuiltCapacity")}</p>
        </Column>
      </Row>
    );
  }
  return (
    <Row hasSmallMarginBottom={true}>
      <Column column={2} isFirstColumn={true}>
        <Panel hasBorder={true}>
          <Table
            isBordered={true}
            isCondensed={true}
            isInsidePanel={true}
            isStriped={true}
          >
            <thead>
              <tr>
                <th>{t("labelCapacityType")}</th>
                <th>{t("labelUsage")}</th>
                <th>{t("labelUnavailable")}</th>
              </tr>
            </thead>
            <tbody>
              {unavailableCapacities.map((capacity, i: any) => {
                return (
                  <tr key={i}>
                    <td>
                      {translateValue(
                        "commons:capacityType",
                        capacity.capacityType,
                        t
                      )}
                    </td>
                    <td>
                      {translateValue("commons:usage", capacity.usage, t)}
                    </td>
                    <td>
                      <Field
                        component={InputField}
                        min={0}
                        max={100000}
                        name={`unavailableCapacities[${i}].capacity`}
                        type="number"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Panel>
      </Column>
    </Row>
  );
};

export default UnavailableCapacitiesTable;
