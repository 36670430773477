import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { onError } from "apollo-link-error";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { getStoredLoginState, LoginState } from "../util/useAuth";
export default function createClient(uri: string) {
  const getToken = () => {
    const login: LoginState | null = getStoredLoginState();
    return login ? login.token : null;
  };
  const customFetch = (batchUri: string, options: any) => {
    const token = getToken();
    if (token) {
      options.headers.authorization = `Bearer ${token}`;
    }
    return fetch(uri, options);
  };
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          // handle errors differently based on its error code
          switch (!!err && err.extensions && err.extensions.code) {
            case "UNAUTHENTICATED":
              // TODO: if unauthenticated, open the login modal

              return forward(operation);
          }
        }
      }
    }
  );

  const link = ApolloLink.from([
    errorLink,
    createPersistedQueryLink(),
    new BatchHttpLink({
      batchMax: 1,
      fetch: customFetch
    })
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: true
    })
  });
}
