import * as React from "react";
import { useTranslation } from "react-i18next";
import Column from "./Column";
import Container from "./Container";
import styles from "./PageFooter.module.css";
import Row from "./Row";

const PageFooter: React.FC = () => {
  const { t } = useTranslation("commons");
  return (
    <div className={styles.pageFooter}>
      <Container>
        <Row>
          <Column small={12} medium={4}>
            {t("footerCopyright")}
          </Column>
          <Column small={12} medium={8}>
            <div className={styles.contacts}>
              <span>{t("footerContact")}</span>
              <a href="mailto:hsl@hsl.fi">{t("footerEmail")}</a>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default PageFooter;
