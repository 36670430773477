import L, { Icon } from "leaflet";
import {
  Facility,
  FacilityDetails,
  Hub,
  HubDetails,
  Port
} from "../generated/graphql";

export type LatLngTuple = [number, number];
export interface LatLng {
  lat: number;
  lng: number;
}
export interface LatLngBounds {
  _northEast: LatLng;
  _southWest: LatLng;
}

export interface FacilityFeature {
  type: "Feature";
  geometry: FacilityFeatureGeometry;
}
export interface FacilityFeatureGeometry {
  coordinates: number[][][];
  type: "Polygon";
}

export interface HubFeature {
  type: "Feature";
  geometry: HubFeatureGeometry;
}
interface HubFeatureGeometry {
  coordinates: number[];
  type: "Point";
}
export interface PortFeature {
  type: "Feature";
  geometry: PortFeatureGeometry;
  properties: PortProperties;
}

interface PortFeatureGeometry {
  coordinates: number[];
  type: "Point";
}
export interface PortProperties extends Port {
  index: number;
}

export const getBounds: any = (bbox: number[] | undefined) =>
  Array.isArray(bbox) && bbox.length === 4
    ? [[bbox[1], bbox[0]], [bbox[3], bbox[2]]]
    : undefined;

export const getMaxBbox: any = (bboxes: number[][] | undefined) => {
  if (!Array.isArray(bboxes) || !bboxes.length) {
    return undefined;
  }

  const eastPoints: any = [];
  const northPoints: any = [];
  const southPoints: any = [];
  const westPoints: any = [];

  bboxes.forEach(bbox => {
    eastPoints.push(bbox[2]);
    northPoints.push(bbox[3]);
    southPoints.push(bbox[1]);
    westPoints.push(bbox[0]);
  });

  return [
    Math.min(...westPoints),
    Math.min(...southPoints),
    Math.max(...eastPoints),
    Math.max(...northPoints)
  ];
};

export const getCenter: any = (bbox: number[] | undefined) =>
  Array.isArray(bbox) && bbox.length === 4
    ? [bbox[3] - (bbox[3] - bbox[1]) / 2, bbox[2] - (bbox[2] - bbox[0]) / 2]
    : undefined;

export const coordsToLatLng = (coords: number[]) =>
  new L.LatLng(coords[1], coords[0]);

export const getBboxFromPoint = (point: number[]) => {
  const boundsOffset = 0.002;
  return [
    point[0] - boundsOffset,
    point[1] - boundsOffset,
    point[0] + boundsOffset,
    point[1] + boundsOffset
  ];
};

export const getPolygonCenter = (coordinates: number[][][]) => {
  const lngs = coordinates[0].map(coord => coord[0]);
  const lats = coordinates[0].map(coord => coord[1]);

  return [
    Math.max(...lngs) - (Math.max(...lngs) - Math.min(...lngs)) / 2,
    Math.max(...lats) - (Math.max(...lats) - Math.min(...lats)) / 2
  ];
};

export const getGeoJson = (
  features: Array<FacilityFeature | HubFeature | PortFeature>
): any => ({
  type: "FeatureCollection",
  features
});

export const getFacilityFeatures = (
  facilities: Array<Facility | FacilityDetails>
): FacilityFeature[] => {
  return Array.isArray(facilities)
    ? facilities.map(facility => ({
        type: "Feature",
        geometry: {
          coordinates: facility.location.coordinates,
          type: "Polygon"
        }
      }))
    : [];
};

export const getHubFeatures = (hubs: Array<Hub | HubDetails>): HubFeature[] => {
  return Array.isArray(hubs)
    ? hubs.map(hub => ({
        type: "Feature",
        geometry: {
          coordinates: hub.location.coordinates,
          type: "Point"
        }
      }))
    : [];
};

export const getPortFeatures = (ports: Port[]): PortFeature[] => {
  return Array.isArray(ports)
    ? ports.map((port, index) => ({
        type: "Feature",
        geometry: {
          coordinates: port.location.coordinates,
          type: "Point"
        },
        properties: {
          entry: port.entry,
          exit: port.exit,
          pedestrian: port.pedestrian,
          bicycle: port.bicycle,
          location: {
            crs: port.location.crs,
            coordinates: port.location.coordinates,
            type: port.location.type
          },
          address: port.address,
          index
        }
      }))
    : [];
};

export const getPortIcon = (properties: PortProperties | Port): Icon => {
  const iconUrl =
    (properties.entry ? "/entry-" : "/noentry-") +
    (properties.exit ? "exit-" : "noexit-") +
    (properties.pedestrian ? "pedestrian-" : "nopedestrian-") +
    (properties.bicycle ? "bicycle.gif" : "nobicycle.gif");
  return L.icon({
    iconUrl,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0]
  });
};
