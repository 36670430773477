import React from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonSizes, ButtonSizeType } from "./Button";
import styles from "./ErrorModal.module.css";
import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";

export interface Callback {
  callbackFn: () => void;
}
interface ConfirmationModalOptions {
  buttonSize: ButtonSizeType;
  buttonText: string;
  callback: Callback;
  text: string;
}

interface ConfirmationModalContext {
  buttonSize: ButtonSizeType;
  buttonText: string;
  closeConfirmationModal: () => void;
  confirmationCallback: Callback;
  isConfirmationModalOpen: boolean;
  openConfirmationModal: (options: ConfirmationModalOptions) => void;
  text: string;
}

export const ConfirmationModalContext = React.createContext<
  ConfirmationModalContext
>({
  buttonSize: ButtonSizes.XSMALL,
  buttonText: "",
  closeConfirmationModal: () => null,
  confirmationCallback: {
    callbackFn: () => null
  },
  isConfirmationModalOpen: false,
  openConfirmationModal: () => null,
  text: ""
});

export const ConfirmationModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isConfirmationModalOpen, setIsModalOpen] = React.useState(false);
  const [buttonSize, setButtonSize] = React.useState<ButtonSizeType>(
    ButtonSizes.XSMALL
  );
  const [buttonText, setButtonText] = React.useState<string>("");
  const [confirmationCallback, setConfirmationCallback] = React.useState<
    Callback
  >({
    callbackFn: () => null
  });
  const [text, setText] = React.useState<string>("");

  const closeConfirmationModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openConfirmationModal = React.useCallback(
    (options: ConfirmationModalOptions) => {
      setIsModalOpen(true);
      setButtonSize(options.buttonSize);
      setButtonText(options.buttonText);
      setConfirmationCallback(options.callback);
      setText(options.text);
    },
    []
  );

  return (
    <ConfirmationModalContext.Provider
      value={{
        buttonSize,
        buttonText,
        closeConfirmationModal,
        confirmationCallback,
        isConfirmationModalOpen,
        openConfirmationModal,
        text
      }}
    >
      {children}
    </ConfirmationModalContext.Provider>
  );
};

export const useConfirmationModalContext = (): ConfirmationModalContext =>
  React.useContext(ConfirmationModalContext);

interface Props {
  buttonSize: ButtonSizeType;
  buttonText: string;
  confirmationCallback: Callback;
  isOpen: boolean;
  onClose: () => void;
  text: string;
}

const ConfirmationModal: React.FC<Props> = ({
  buttonSize,
  buttonText,
  confirmationCallback,
  isOpen,
  onClose,
  text
}) => {
  const { t } = useTranslation("confirmationModal");

  const handleCloseModal = () => {
    onClose();
  };

  const handleCloseModalAfterSubmit = () => {
    onClose();
    confirmationCallback.callbackFn();
  };

  return (
    <>
      <Modal isCentered={true} isOpen={isOpen} width={400} zIndex={1070}>
        <ModalContent>
          <ModalBody>
            <div className={styles.textWrapper}>
              <p>{text}</p>
            </div>
          </ModalBody>
          <ModalFooter isCentered={true}>
            <Button
              onClick={handleCloseModal}
              size={ButtonSizes.XSMALL_SECONDARY}
              type="button"
            >
              {t("buttonCancel")}
            </Button>
            <Button
              onClick={handleCloseModalAfterSubmit}
              size={buttonSize}
              type="button"
            >
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
