import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  useContactListQuery,
  useOperatorListQuery
} from "../../generated/graphql";
import LoadingSpinner from "../Commons/LoadingSpinner";
import ContactList from "./ContactList";

const ContactListContainer: React.FC = () => {
  const { t } = useTranslation("commons");
  const {
    data: contactsData,
    error: contactsError,
    loading: loadingContacts
  } = useContactListQuery();
  const {
    data: operatorsData,
    error: operatorsError,
    loading: loadingOperators
  } = useOperatorListQuery();

  if (loadingContacts || loadingOperators) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (!contactsData || contactsError || !operatorsData || operatorsError) {
    return <div>{t("error")}</div>;
  }

  return (
    <ContactList contactsData={contactsData} operatorsData={operatorsData} />
  );
};
export default ContactListContainer;
