import * as React from "react";
import styles from "./Modal.module.css";
interface Props {
  ref?: React.Ref<HTMLDivElement>;
}

const ModalBody: React.FC<Props> = React.forwardRef(
  ({ children }, ref?: React.Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className={styles["modal-body"]}>
        {children}
      </div>
    );
  }
);

export default ModalBody;
