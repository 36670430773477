import React from "react";
import { useTranslation } from "react-i18next";
import { OperatorListQuery } from "../../generated/graphql";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import Container from "../Commons/Container";
import LinkButton from "../Commons/LinkButton";
import NaviBelow from "../Commons/NaviBelow";
import Panel from "../Commons/Panel";
import Table from "../Commons/Table";
import Info from "./Info";
import OperatorModal from "./OperatorModal";

interface Props {
  data: OperatorListQuery;
}

const OperatorList: React.FC<Props> = ({ data }) => {
  const { hasPermission } = useAuth();
  const operators = data.operators.results;
  const [operatorId, setOperatorId] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation("operatorList");
  const handleOpenModal = (id: string | null) => {
    setIsModalOpen(true);
    setOperatorId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOperatorId(null);
  };

  return (
    <>
      <OperatorModal
        isOpen={isModalOpen}
        operatorId={operatorId}
        onClose={handleCloseModal}
      />
      <Breadcrumb
        canAddOperator={hasPermission(PERMISSIONS.OPERATOR_CREATE)}
        name={t("titleOperators")}
        onClickNewOperator={() => handleOpenModal(null)}
      />
      <Container isContent={true}>
        <Info />
        <Panel hasBorder={true}>
          <Table isBordered={true} isCondensed={true} isInsidePanel={true}>
            <thead>
              <tr>
                <th>{t("labelName")}</th>
              </tr>
            </thead>
            <tbody>
              {operators.map((operator, i) => (
                <tr key={operator.id}>
                  <td>
                    <LinkButton onClick={() => handleOpenModal(operator.id)}>
                      {operator.name.fi}
                    </LinkButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel>
      </Container>
      <NaviBelow>
        {hasPermission(PERMISSIONS.OPERATOR_CREATE) && (
          <Button
            isResponsive={true}
            size={ButtonSizes.SMALL}
            onClick={() => handleOpenModal(null)}
            type="button"
          >
            {t("buttonAddOperator")}
          </Button>
        )}
      </NaviBelow>
    </>
  );
};
export default OperatorList;
