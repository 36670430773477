import * as React from "react";
import { useTranslation } from "react-i18next";
import { useOperatorListQuery } from "../../generated/graphql";
import LoadingSpinner from "../Commons/LoadingSpinner";
import OperatorList from "./OperatorList";

const OperatorListContainer: React.FC = () => {
  const { t } = useTranslation("commons");
  const { data, error, loading } = useOperatorListQuery();

  if (loading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error || !data) {
    return <div>{t("error")}</div>;
  }

  return <OperatorList data={data} />;
};
export default OperatorListContainer;
