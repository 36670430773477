import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { CONTACT_TYPE } from "../../constants";
import {
  CapacityTypeListQuery,
  FacilityDetails,
  FacilityListDocument,
  FacilityPredictionQuery,
  FacilityProfileQuery,
  FacilityUtilizationQuery,
  UsageListQuery,
  useDeleteFacilityMutation,
  useHubsByFacilityQuery,
  UserListQuery,
  useSoftDeleteFacilityMutation
} from "../../generated/graphql";
import { formatDate } from "../../util/dateUtils";
import { handleError } from "../../util/helpers";
import {
  getBboxFromPoint,
  getBounds,
  getCenter,
  getFacilityFeatures,
  getGeoJson,
  getMaxBbox,
  getPortFeatures
} from "../../util/mapUtils";
import { translateList, translateValue } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import { useConfirmationModalContext } from "../Commons/ConfirmationModal";
import Container from "../Commons/Container";
import { useErrorModalContext } from "../Commons/ErrorModal";
import Location from "../Commons/Location";
import { useLoginModalContext } from "../Commons/LoginModal";
import NaviBelow from "../Commons/NaviBelow";
import Row from "../Commons/Row";
import BasicInfo from "./BasicInfo";
import Capacity from "./Capacity";
import Contact from "./Contact";
import OpeningHours from "./OpeningHours";
import PricingAndServices from "./PricingAndServices";

interface Params {
  id: string;
}
interface Props extends RouteComponentProps<Params> {
  capacityTypesData: CapacityTypeListQuery | undefined;
  data: FacilityProfileQuery;
  prediction0Data: FacilityPredictionQuery | undefined;
  prediction10Data: FacilityPredictionQuery | undefined;
  prediction20Data: FacilityPredictionQuery | undefined;
  prediction30Data: FacilityPredictionQuery | undefined;
  prediction60Data: FacilityPredictionQuery | undefined;
  usagesData: UsageListQuery | undefined;
  usersData: UserListQuery | undefined;
  utilizationData: FacilityUtilizationQuery | undefined;
}

const getContactId = (
  facilityDetails: FacilityDetails,
  field: string
): string | null | undefined => {
  const contacts = facilityDetails.contacts;

  switch (field) {
    case CONTACT_TYPE.EMERGENCY:
      return contacts.emergency;
    case CONTACT_TYPE.OPERATOR:
      return contacts.operator;
    case CONTACT_TYPE.SERVICE:
      return contacts.service;
  }
};

const FacilityProfile: React.FC<Props> = ({
  capacityTypesData,
  data,
  history,
  match,
  prediction0Data,
  prediction10Data,
  prediction20Data,
  prediction30Data,
  prediction60Data,
  usagesData,
  usersData,
  utilizationData
}) => {
  const { t } = useTranslation(["facilityProfile", "commons"]);
  const { hasPermission } = useAuth();
  const facilityDetails = data.facilityDetails;
  const capacityTypes = capacityTypesData
    ? capacityTypesData.capacityTypes
    : [];
  const usages = usagesData ? usagesData.usages : [];
  const prediction0Items = prediction0Data
    ? prediction0Data.facilityPrediction
    : [];
  const prediction10Items = prediction10Data
    ? prediction10Data.facilityPrediction
    : [];
  const prediction20Items = prediction20Data
    ? prediction20Data.facilityPrediction
    : [];
  const prediction30Items = prediction30Data
    ? prediction30Data.facilityPrediction
    : [];
  const prediction60Items = prediction60Data
    ? prediction60Data.facilityPrediction
    : [];
  const utilizationItems = utilizationData
    ? utilizationData.facilityUtilization
    : [];
  const [deleteFacility] = useDeleteFacilityMutation();
  const [softDeleteFacility] = useSoftDeleteFacilityMutation();
  const { openConfirmationModal } = useConfirmationModalContext();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const facilityName = facilityDetails.name ? facilityDetails.name.fi : "-";
  const operatorId = facilityDetails.operatorId;
  const modifierName = React.useMemo(() => {
    const users = usersData && usersData.users ? usersData.users : [];
    const user = users.find(item => item.id === facilityDetails.modifiedBy);

    return user ? user.username : null;
  }, [facilityDetails, usersData]);
  const {
    params: { id: facilityId }
  } = match;
  const { data: hubsData } = useHubsByFacilityQuery({
    variables: { facilityIds: [facilityId] }
  });
  const hubs = !!hubsData && !!hubsData.hubs ? hubsData.hubs.hubs : [];

  const bboxes =
    data.facilityDetails && data.facilityDetails.location
      ? [data.facilityDetails.location.bbox]
      : [];
  const ports =
    data.facilityDetails && data.facilityDetails.ports
      ? data.facilityDetails.ports
      : [];
  ports.forEach((port: any) => {
    bboxes.push(getBboxFromPoint(port.location.coordinates));
  });
  const bbox = getMaxBbox(bboxes);
  const bounds = getBounds(bbox);
  const center = getCenter(bbox);

  const facilitiesGeoJson = getGeoJson(getFacilityFeatures([facilityDetails]));
  const portsGeoJson = getGeoJson(getPortFeatures(ports));

  const moveToFacilityEdit = () => {
    const {
      params: { id }
    } = match;
    history.push(`/facilities/edit/${id}`);
  };

  const moveToHubList = () => {
    history.push(`/hubs`);
  };

  const handleDeleteFacility = async () => {
    try {
      await deleteFacility({
        variables: { facility: { id: facilityId } },
        update(cache) {
          try {
            const facilityListData: any = cache.readQuery({
              query: FacilityListDocument
            });

            if (facilityListData) {
              const newFacilities: any = facilityListData.facilities.facilities.filter(
                (item: any) => item.id !== facilityId
              );
              facilityListData.facilities.facilities = newFacilities;
              cache.writeQuery({
                query: FacilityListDocument,
                data: facilityListData
              });
            }
            moveToHubList();
          } catch (err) {
            moveToHubList();
          }
        }
      });
    } catch (err) {
      handleError(err, openErrorModal, t, () =>
        openLoginModal({
          callbackFn: handleDeleteFacility
        })
      );
    }
  };

  const handleDelete = () => {
    openConfirmationModal({
      buttonSize: ButtonSizes.XSMALL_ALERT,
      buttonText: t("buttonDelete"),
      callback: {
        callbackFn: handleDeleteFacility
      },
      text: t("confirmationDelete")
    });
  };

  const handleSoftDeleteFacility = async () => {
    try {
      await softDeleteFacility({
        variables: { facility: { id: facilityId } },
        update(cache, deletedFacility) {
          try {
            const facilityListData: any = cache.readQuery({
              query: FacilityListDocument
            });

            if (facilityListData) {
              const softDeletedAt =
                deletedFacility &&
                deletedFacility.data &&
                deletedFacility.data.softDeleteFacility.softDeletedAt;
              const newFacilities: any = facilityListData.facilities.facilities.map(
                (item: any) =>
                  item.id !== facilityId ? item : { ...item, softDeletedAt }
              );
              facilityListData.facilities.facilities = newFacilities;
              cache.writeQuery({
                query: FacilityListDocument,
                data: facilityListData
              });
            }
          } catch (err) {
            console.error(err);
          }
        }
      });
    } catch (err) {
      handleError(err, openErrorModal, t, () =>
        openLoginModal({
          callbackFn: handleSoftDeleteFacility
        })
      );
    }
  };

  const handleSoftDelete = () => {
    openConfirmationModal({
      buttonSize: ButtonSizes.XSMALL_ALERT,
      buttonText: t("buttonSoftDelete"),
      callback: {
        callbackFn: handleSoftDeleteFacility
      },
      text: t("confirmationSoftDelete")
    });
  };

  const showSurveyOptions = (): boolean => {
    return (
      !!facilityDetails.surveyOptions &&
      !!facilityDetails.surveyOptions.length
    );
  };

  return (
    <>
      <Breadcrumb
        name={facilityName}
        isSoftDeleted={!!facilityDetails.softDeletedAt}
        canDelete={
          !!facilityDetails.softDeletedAt &&
          !facilityDetails.deletedAt &&
          hasPermission(PERMISSIONS.FACILITY_DELETE, operatorId)
        }
        canEdit={
          !facilityDetails.softDeletedAt &&
          !facilityDetails.deletedAt &&
          hasPermission(PERMISSIONS.FACILITY_UPDATE, operatorId)
        }
        canSoftDelete={
          !facilityDetails.softDeletedAt &&
          !facilityDetails.deletedAt &&
          hasPermission(PERMISSIONS.FACILITY_SOFT_DELETE, operatorId)
        }
        canGoback={true}
        onClickDelete={handleDelete}
        onClickEdit={moveToFacilityEdit}
        onClickSoftDelete={handleSoftDelete}
      />
      <Container isContent={true}>
        <BasicInfo facilityDetails={facilityDetails} />
        {[
          CONTACT_TYPE.EMERGENCY,
          CONTACT_TYPE.OPERATOR,
          CONTACT_TYPE.SERVICE
        ].map((contactType, index) => {
          const id = getContactId(facilityDetails, contactType);
          return id ? (
            <Contact
              key={index}
              title={translateValue("commons:contactType", contactType, t)}
              id={id}
            />
          ) : null;
        })}

        {showSurveyOptions() && (
          <>
            <h4>{t("commons:titleSurvey")}</h4>
            <Row>
              <Column>
                {translateList(
                  "commons:surveyOptions",
                  facilityDetails.surveyOptions || [],
                  t
                )}
              </Column>
            </Row>
          </>
        )}

        <Location
          bounds={bounds}
          center={center}
          facilitiesGeoJson={facilitiesGeoJson}
          facilityType={facilityDetails.type}
          hubs={hubs}
          portsGeoJson={portsGeoJson}
          ports={ports}
        />
        <OpeningHours facilityDetails={facilityDetails} />

        <Capacity
          capacityTypes={capacityTypes}
          facilityDetails={facilityDetails}
          prediction0Items={prediction0Items}
          prediction10Items={prediction10Items}
          prediction20Items={prediction20Items}
          prediction30Items={prediction30Items}
          prediction60Items={prediction60Items}
          usages={usages}
          utilizationItems={utilizationItems}
        />

        <PricingAndServices facilityDetails={facilityDetails} />

        {facilityDetails.modifiedAt && (
          <Row hasLargeMargin={true}>
            <Column>
              <p>
                {t("messageModified")}{" "}
                {formatDate(facilityDetails.modifiedAt, "dd.MM.yyyy HH:mm")}{" "}
                {modifierName ? `- ${modifierName}` : ""}
              </p>
            </Column>
          </Row>
        )}
      </Container>

      <NaviBelow>
        <Button
          isResponsive={true}
          size={ButtonSizes.SMALL}
          onClick={moveToHubList}
          type="button"
        >
          {t("buttonBack")}
        </Button>
        {!facilityDetails.softDeletedAt &&
          !facilityDetails.deletedAt &&
          hasPermission(PERMISSIONS.FACILITY_SOFT_DELETE, operatorId) && (
            <Button
              isResponsive={true}
              size={ButtonSizes.SMALL_ALERT}
              onClick={handleSoftDelete}
              type="button"
            >
              {t("buttonSoftDelete")}
            </Button>
          )}
        {!!facilityDetails.softDeletedAt &&
          !facilityDetails.deletedAt &&
          hasPermission(PERMISSIONS.FACILITY_DELETE, operatorId) && (
            <Button
              isResponsive={true}
              size={ButtonSizes.SMALL_ALERT}
              onClick={handleDelete}
              type="button"
            >
              {t("buttonDelete")}
            </Button>
          )}
        {!facilityDetails.softDeletedAt &&
          !facilityDetails.deletedAt &&
          hasPermission(PERMISSIONS.FACILITY_UPDATE, operatorId) && (
            <Button
              isResponsive={true}
              size={ButtonSizes.SMALL}
              onClick={moveToFacilityEdit}
              type="button"
            >
              {t("buttonEdit")}
            </Button>
          )}
      </NaviBelow>
    </>
  );
};

export default withRouter(FacilityProfile);
