import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import createClient from "./apollo/createClient";
import App from "./App";
import AuthContextProvider from "./components/Commons/AuthContext";
import { ConfirmationModalProvider } from "./components/Commons/ConfirmationModal";
import { ErrorModalProvider } from "./components/Commons/ErrorModal";
import { LoginModalProvider } from "./components/Commons/LoginModal";
import { PasswordExpiredModalProvider } from "./components/Commons/PasswordExpiredModal";
import { PasswordReminderModalProvider } from "./components/Commons/PasswordReminderModal";
import "./i18n";
import "./index.css";
import "./resources/assets/fonts/ProximaNovaSoft/medium/stylesheet.css";
import "./resources/assets/fonts/ProximaNovaSoft/regular/stylesheet.css";
import "./resources/assets/fonts/ProximaNovaSoft/semibold/stylesheet.css";

let uri = null;

if (
  process.env.REACT_APP_ENV === "local" ||
  process.env.REACT_APP_ENV === "debug"
) {
  console.log("Starting the app locally...");
  uri = process.env.REACT_APP_LOCAL_API_URL;
} else {
  console.log("Starting the app inside a docker container...");
  uri = "/proxy/graphql";
}

if (uri) {
  const client = createClient(uri);

  ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <AuthContextProvider>
            <ConfirmationModalProvider>
              <ErrorModalProvider>
                <PasswordExpiredModalProvider>
                  <PasswordReminderModalProvider>
                    <LoginModalProvider>
                      <App />
                    </LoginModalProvider>
                  </PasswordReminderModalProvider>
                </PasswordExpiredModalProvider>
              </ErrorModalProvider>
            </ConfirmationModalProvider>
          </AuthContextProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </BrowserRouter>,

    document.getElementById("root")
  );
}
