import L, { LayerEvent, LeafletMouseEvent } from "leaflet";
import "leaflet-editable";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Map, Marker, Polygon, TileLayer } from "react-leaflet";
import {
  COLORS,
  CRS,
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  MAX_ZOOM,
  SELECTED_FACILITY_STYLE
} from "../../constants";
import {
  Port,
  PortInput,
  PortLocationInput,
  useGeocodedAddressMutation
} from "../../generated/graphql";
import {
  coordsToLatLng,
  getPortFeatures,
  getPortIcon,
  LatLng,
  LatLngBounds,
  LatLngTuple,
  PortProperties
} from "../../util/mapUtils";
import { ButtonSizes } from "../Commons/Button";
import { useConfirmationModalContext } from "../Commons/ConfirmationModal";
import styles from "../Commons/Location.module.css";
import MapButtons from "./MapButtons";
import PortEditModal from "./PortEditModal";

const convertCoordinatesToMap = (coords: LatLngTuple[][]) => {
  return coords[0].map((coord: any) => coordsToLatLng(coord));
};

const convertLatLngToCoordinate = (latlng: LatLng): LatLngTuple => {
  return [latlng.lng, latlng.lat];
};

const convertLatLngsToCoordinates = (latlngs: LatLng[][]): LatLngTuple[][] => {
  return [latlngs[0].map(latlng => convertLatLngToCoordinate(latlng))];
};

const convertBoundsToBBox = (bounds: LatLngBounds): number[] => {
  return [
    bounds._southWest.lng,
    bounds._southWest.lat,
    bounds._northEast.lng,
    bounds._northEast.lat
  ];
};

interface Props {
  bounds: LatLngTuple[] | undefined;
  center: LatLngTuple | undefined;
  className: string;
  coordinates: LatLngTuple[][];
  isLocationEditEnabled: boolean;
  isPortEditEnabled: boolean;
  onPortDelete: (index: number) => void;
  onPortSave: (port: PortInput, index?: number) => void;
  ports: Port[];
  setLocation: (coords: LatLngTuple[][], bbox: number[]) => void;
  facilityType: string;
}

const FacilityEditLocation: React.FC<Props> = ({
  bounds,
  center,
  className,
  coordinates,
  isLocationEditEnabled,
  isPortEditEnabled,
  onPortDelete,
  onPortSave,
  ports,
  setLocation,
  facilityType
}) => {
  const { t } = useTranslation("facilityEdit");
  const { openConfirmationModal } = useConfirmationModalContext();
  const mapRef: any = React.useRef(null);
  const [
    portLocation,
    setPortLocation
  ] = React.useState<PortLocationInput | null>(null);
  const [
    portProperties,
    setPortProperties
  ] = React.useState<PortProperties | null>(null);
  const portFeatures = React.useMemo(() => getPortFeatures(ports), [ports]);
  const [polygon, setPolygon] = React.useState<any>(null);
  const [isPortModalOpen, setIsPortModalOpen] = React.useState(false);
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [address, setAddress] = React.useState<string | null>(null);
  const [addressSv, setAddressSv] = React.useState<string | null>(null);
  const [city, setCity] = React.useState<string | null>(null);
  const [getGeocodedAddress] = useGeocodedAddressMutation();
  const getMap = () => {
    return mapRef.current.leafletElement;
  };

  const getEditTools = React.useCallback(() => {
    return getMap().editTools;
  }, []);

  const handlePolygonClick = (event: LeafletMouseEvent) => {
    if (!isLocationEditEnabled) {
      return;
    }

    const map = getMap();
    const poly: any = L.polygon(event.target._latlngs, {
      color: COLORS.SELECTED_FACILITY
    }).addTo(map);

    poly.enableEdit();
    setPolygon(poly);
    setIsEditing(true);
  };

  const handleMapClick = (event: LeafletMouseEvent) => {
    if (!isLocationEditEnabled || isDrawing || isEditing) {
      return;
    }
    const editTools = getEditTools();
    editTools.startPolygon(event.latlng, SELECTED_FACILITY_STYLE);
  };

  const handleDrawingStart = () => {
    setIsDrawing(true);
  };

  const handleDrawingCancel = React.useCallback((event: LayerEvent) => {
    const map = getMap();

    map.removeLayer(event.layer);
  }, []);

  const handleDrawingCommit = React.useCallback(
    (event: LayerEvent) => {
      const map = getMap();
      const layer: any = event.layer;

      map.removeLayer(event.layer);
      setLocation(
        addStartPointToPolygon(convertLatLngsToCoordinates(layer._latlngs)),
        convertBoundsToBBox(layer._bounds)
      );
      setIsDrawing(false);
    },
    [setLocation]
  );

  const addStartPointToPolygon = (coords: LatLngTuple[][]) => {
    const newCoords = [...coords];
    newCoords[0].push(newCoords[0][0]);
    return newCoords;
  };

  const saveEditedPolygon = () => {
    const map = getMap();
    if (polygon) {
      setLocation(
        addStartPointToPolygon(convertLatLngsToCoordinates(polygon._latlngs)),
        convertBoundsToBBox(polygon._bounds)
      );
      setPolygon(null);
      map.removeLayer(polygon);
    }
    setIsEditing(false);
  };

  const cancelDrawing = React.useCallback(() => {
    const map = getMap();
    const editTools = getEditTools();
    editTools.stopDrawing();

    if (polygon) {
      map.removeLayer(polygon);
    }
    setIsEditing(false);
    setIsDrawing(false);
  }, [getEditTools, polygon]);

  React.useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current && mapRef.current.leafletElement;
      map.on("editable:drawing:start", handleDrawingStart);
      map.on("editable:drawing:commit", handleDrawingCommit);
      map.on("editable:drawing:cancel", handleDrawingCancel);
    }
  }, []);

  React.useEffect(() => {
    if (!isLocationEditEnabled) {
      cancelDrawing();
    }
  }, [isLocationEditEnabled, cancelDrawing]);

  const handleMapDblClick = async (event: LeafletMouseEvent) => {
    if (!isPortEditEnabled) {
      return;
    }
    setPortLocation({
      crs: CRS,
      coordinates: convertLatLngToCoordinate(event.latlng),
      type: "Point"
    });

    // Request address in Finnish and Swedish parallel
    const request = getGeocodedAddress({
      variables: { lang: "fi", lat: event.latlng.lat, lon: event.latlng.lng }
    });

    const requestSv = getGeocodedAddress({
      variables: { lang: "sv", lat: event.latlng.lat, lon: event.latlng.lng }
    });

    const response = await request;
    const responseSv = await requestSv;

    if (response && response.data && response.data.address.features) {
      const features = response.data.address.features;
      if (features.length) {
        const properties = features[0].properties;
        setAddress(properties.name || null);
        setCity(properties.locality || null);
      }
    } else {
      setAddress(null);
      setCity(null);
    }

    if (responseSv && responseSv.data && responseSv.data.address.features) {
      const features = responseSv.data.address.features;
      if (features.length) {
        const properties = features[0].properties;
        setAddressSv(properties.name || null);
      }
    } else {
      setAddressSv(null);
    }

    setPortProperties(null);
    setIsPortModalOpen(true);
  };

  const handleClickPort = async (properties: PortProperties) => {
    if (!isPortEditEnabled) {
      return;
    }
    setPortLocation(null);
    setPortProperties(properties);
    setIsPortModalOpen(true);
  };

  const handleClickPortModalCancel = () => {
    setIsPortModalOpen(false);
  };

  const handleClickPortModalDelete = (index: number) => {
    openConfirmationModal({
      buttonSize: ButtonSizes.XSMALL_ALERT,
      buttonText: t("buttonPortDelete"),
      callback: {
        callbackFn: () => {
          onPortDelete(index);
          setIsPortModalOpen(false);
        }
      },
      text: t("confirmationDeletePort")
    });
  };

  const handleClickPortModalOk = (port: PortInput, index?: number) => {
    onPortSave(port, index);
    setIsPortModalOpen(false);
  };

  return (
    <div className={styles.mapContainer}>
      <div className={className}>
        {isPortModalOpen && (
          <PortEditModal
            initialAddress={address}
            initialAddressSv={addressSv}
            initialCity={city}
            isOpen={isPortModalOpen}
            location={portLocation}
            onClickCancel={handleClickPortModalCancel}
            onClickDelete={handleClickPortModalDelete}
            onClickOk={handleClickPortModalOk}
            portProperties={portProperties}
            facilityType={facilityType}
          />
        )}
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css"
        />
        <Map
          ref={mapRef}
          bounds={bounds}
          center={center || DEFAULT_MAP_CENTER}
          editable={true}
          editOptions={{ lineGuideOptions: SELECTED_FACILITY_STYLE }}
          maxZoom={MAX_ZOOM}
          doubleClickZoom={false}
          onDblClick={handleMapDblClick}
          onClick={handleMapClick}
          zoom={DEFAULT_ZOOM}
          zoomAnimation={false}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {!isDrawing && !isEditing && coordinates && (
            <Polygon
              onClick={handlePolygonClick}
              positions={convertCoordinatesToMap(coordinates)}
              color={COLORS.SELECTED_FACILITY}
            />
          )}
          {portFeatures.map(feature => {
            return (
              <Marker
                key={JSON.stringify(feature)}
                icon={getPortIcon(feature.properties)}
                onClick={() => handleClickPort(feature.properties)}
                position={coordsToLatLng(feature.geometry.coordinates)}
              />
            );
          })}

          <MapButtons
            onClickCancel={cancelDrawing}
            onClickOk={saveEditedPolygon}
            showButtonCancel={isDrawing || isEditing}
            showButtonOk={isEditing}
          />
        </Map>
      </div>
    </div>
  );
};

export default FacilityEditLocation;
