import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  FacilityDetails,
  Prediction,
  Utilization
} from "../../generated/graphql";
import BuiltCapacity from "../Commons/BuiltCapacity";
import Column from "../Commons/Column";
import Row from "../Commons/Row";
import UnavailableCapacityTable from "./UnavailableCapacityTable";
import UtilizationTable from "./UtilizationTable";

interface Props {
  capacityTypes: string[];
  facilityDetails: FacilityDetails;
  prediction0Items: Prediction[];
  prediction10Items: Prediction[];
  prediction20Items: Prediction[];
  prediction30Items: Prediction[];
  prediction60Items: Prediction[];
  usages: string[];
  utilizationItems: Utilization[];
}

const Capacity: React.FC<Props> = ({
  capacityTypes,
  facilityDetails,
  prediction0Items,
  prediction10Items,
  prediction20Items,
  prediction30Items,
  prediction60Items,
  usages,
  utilizationItems
}) => {
  const { t } = useTranslation(["facilityProfile", "commons"]);
  const facilities: any = [facilityDetails];
  const sortedUtilizationItems = React.useMemo(() => {
    const items = [...utilizationItems];
    return items
      .sort((a, b) =>
        usages.indexOf(a.usage) < usages.indexOf(b.usage) ? -1 : 1
      )
      .sort((a, b) =>
        capacityTypes.indexOf(a.capacityType) <
        capacityTypes.indexOf(b.capacityType)
          ? -1
          : 1
      );
  }, [capacityTypes, usages, utilizationItems]);

  const isBuiltCapacityEmpty = () => {
    let isEmpty = true;
    const builtCapacity: any = facilityDetails.builtCapacity
      ? facilityDetails.builtCapacity
      : {};
    delete builtCapacity.__typename;

    Object.keys(builtCapacity || {}).forEach(key => {
      if (builtCapacity[key]) {
        isEmpty = false;
      }
    });
    return isEmpty;
  };

  if (isBuiltCapacityEmpty()) {
    return null;
  }

  return (
    <>
      <h3 className="h3-view">{t("titleCapacity")}</h3>
      {!!utilizationItems.length && (
        <UtilizationTable
          facilities={facilities}
          prediction0Items={prediction0Items}
          prediction10Items={prediction10Items}
          prediction20Items={prediction20Items}
          prediction30Items={prediction30Items}
          prediction60Items={prediction60Items}
          utilizationItems={sortedUtilizationItems}
        />
      )}

      <h4>{t("titleBuiltCapacity")}</h4>
      <Row>
        <Column column={2} isFirstColumn={true}>
          <BuiltCapacity facilities={facilities} />
        </Column>
      </Row>

      <Row hasSmallMargin={true}>
        <Column>
          <h4>{t("titleUnavailable")}</h4>
        </Column>
      </Row>
      <Row>
        <Column column={2} isFirstColumn={true}>
          <UnavailableCapacityTable
            unavailableCapacities={facilityDetails.unavailableCapacities}
          />
        </Column>
      </Row>
    </>
  );
};

export default Capacity;
