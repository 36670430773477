import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import styles from "./App.module.css";
import About from "./components/About/About";
import AboutEn from "./components/About/AboutEn";
import ConfirmationModal, {
  useConfirmationModalContext
} from "./components/Commons/ConfirmationModal";
import ErrorModal, {
  useErrorModalContext
} from "./components/Commons/ErrorModal";
import LoginModal, {
  useLoginModalContext
} from "./components/Commons/LoginModal";
import Navbar from "./components/Commons/Navbar";
import PageFooter from "./components/Commons/PageFooter";
import PasswordExpiredModal, {
  usePasswordExpiredModalContext
} from "./components/Commons/PasswordExpiredModal";
import PasswordReminderModal, {
  usePasswordReminderModalContext
} from "./components/Commons/PasswordReminderModal";
import ContactListContainer from "./components/ContacList";
import FacilityCreate from "./components/FacilityCreate";
import FacilityEditContainer from "./components/FacilityEdit";
import FacilityProfileContainer from "./components/FacilityProfile";
import HubCreate from "./components/HubCreate";
import HubEditContainer from "./components/HubEdit";
import HubList from "./components/HubList";
import HubProfileContainer from "./components/HubProfile";
import OperatorListContainer from "./components/OperatorList";
import ForgottenPassword from "./components/PasswordService/ForgottenPassword";
import ResetPassword from "./components/PasswordService/ResetPassword";
import ReportsContainer from "./components/Reports";
import UserListContainer from "./components/UserList";

const App = () => {
  const {
    buttonSize: confirmationButtonSize,
    buttonText: confirmationButtonText,
    closeConfirmationModal,
    confirmationCallback,
    isConfirmationModalOpen,
    text: confirmationModalText
  } = useConfirmationModalContext();
  const {
    closeLoginModal,
    isLoginModalOpen,
    loginCallback
  } = useLoginModalContext();
  const { closeErrorModal, error, isErrorModalOpen } = useErrorModalContext();
  const {
    closePasswordExpiredModal,
    isPasswordExpiredModalOpen
  } = usePasswordExpiredModalContext();
  const {
    closePasswordReminderModal,
    isPasswordReminderModalOpen
  } = usePasswordReminderModalContext();

  return (
    <>
      <div id="wrap" className={styles.appWrapper}>
        <Switch>
          <Route path="/hubs" exact={true}>
            <Navbar />
            <HubList />
          </Route>
          <Route path="/hubs/create">
            <Navbar />
            <HubCreate />
          </Route>
          <Route path="/hubs/edit/:id">
            <Navbar />
            <HubEditContainer />
          </Route>
          <Route path="/hubs/:id">
            <Navbar />
            <HubProfileContainer />
          </Route>
          <Route path="/facilities/create">
            <Navbar />
            <FacilityCreate />
          </Route>
          <Route path="/facilities/edit/:id">
            <Navbar />
            <FacilityEditContainer />
          </Route>
          <Route path="/facilities/:id">
            <Navbar />
            <FacilityProfileContainer />
          </Route>
          <Route path="/operators">
            <Navbar />
            <OperatorListContainer />
          </Route>
          <Route path="/contacts">
            <Navbar />
            <ContactListContainer />
          </Route>
          <Route path="/about">
            <Navbar />
            <About />
          </Route>
          <Route path="/about-en">
            <Navbar />
            <AboutEn />
          </Route>
          <Route path="/users">
            <Navbar />
            <UserListContainer />
          </Route>
          <Route path="/reports">
            <Navbar />
            <ReportsContainer />
          </Route>
          <Route path="/forgottenpassword">
            <ForgottenPassword />
          </Route>
          <Route path="/reset">
            <ResetPassword />
          </Route>
          <Redirect path="/" to="/hubs" />
        </Switch>
        <div className={styles.appWrapper}>
          {isConfirmationModalOpen && (
            <ConfirmationModal
              buttonSize={confirmationButtonSize}
              buttonText={confirmationButtonText}
              confirmationCallback={confirmationCallback}
              isOpen={isConfirmationModalOpen}
              onClose={closeConfirmationModal}
              text={confirmationModalText}
            />
          )}
          {isErrorModalOpen && (
            <ErrorModal
              error={error}
              isOpen={isErrorModalOpen}
              onClose={closeErrorModal}
            />
          )}

          {isLoginModalOpen && (
            <LoginModal
              isOpen={isLoginModalOpen}
              loginCallback={loginCallback}
              onClose={closeLoginModal}
            />
          )}

          {isPasswordExpiredModalOpen && (
            <PasswordExpiredModal
              isOpen={isPasswordExpiredModalOpen}
              onClose={closePasswordExpiredModal}
            />
          )}
          {isPasswordReminderModalOpen && (
            <PasswordReminderModal
              isOpen={isPasswordReminderModalOpen}
              onClose={closePasswordReminderModal}
            />
          )}
        </div>
      </div>

      <PageFooter />
    </>
  );
};

export default App;
