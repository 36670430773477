import { Field, Formik } from "formik";
import { get, isEmpty, sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { USER_ROLE } from "../../constants";
import {
  ContactListDocument,
  useContactDetailsQuery,
  useCreateContactMutation,
  useEditContactMutation
} from "../../generated/graphql";
import { decodeNullValues, handleError } from "../../util/helpers";
import { translatePath } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import {
  multiLanguageValidation,
  postalCodeValidation
} from "../../util/validationUtils";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import { useErrorModalContext } from "../Commons/ErrorModal";
import FormError from "../Commons/FormError";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import InputGroup, {
  InputGroupButton,
  InputGroupText
} from "../Commons/InputGroup";
import LoadingSpinner from "../Commons/LoadingSpinner";
import { useLoginModalContext } from "../Commons/LoginModal";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";
import ModalHeader from "../Commons/ModalHeader";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";
import TextAreaField from "../Commons/TextAreaField";
import OperatorModal from "../OperatorList/OperatorModal";

const validationSchema = Yup.object().shape(
  {
    name: Yup.object().shape({
      fi: Yup.string()
        .max(255)
        .required(),
      sv: Yup.string()
        .max(255)
        .required(),
      en: Yup.string()
        .max(255)
        .required()
    }),
    phone: Yup.string().when(["email"], {
      is: email => !email,
      then: Yup.string().required()
    }),
    email: Yup.string()
      .email()
      .when(["phone"], {
        is: phone => !phone,
        then: Yup.string().required()
      }),
    address: Yup.object().shape({
      streetAddress: multiLanguageValidation,
      postalCode: postalCodeValidation,
      city: multiLanguageValidation
    }),
    openingHours: multiLanguageValidation,
    info: multiLanguageValidation
  },
  [["email", "phone"]]
);

interface Props {
  contactId: string | null;
  isOpen: boolean;
  onClose: () => void;
  onCloseAfterSave: (contactId: string) => void;
  operatorOptions: Option[];
}

const ContactModal: React.FC<Props> = ({
  contactId,
  isOpen,
  onClose,
  onCloseAfterSave,
  operatorOptions
}) => {
  const form = React.useRef<any>(null);
  const [createContactMutation] = useCreateContactMutation();
  const [editContactMutation] = useEditContactMutation();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const { getUserOperatorId, getUserRole, hasPermission } = useAuth();
  const [isOperatorModalOpen, setIsOperatorModalOpen] = React.useState(false);
  const errorComponent = React.useRef<any>(null);
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);
  const { t } = useTranslation(["contactList", "commons"]);
  const {
    data: contactData,
    error: contactError,
    loading
  } = useContactDetailsQuery({
    skip: !contactId,
    variables: { id: contactId ? contactId : "" }
  });
  const operatorId = contactData ? contactData.contactDetails.operatorId : null;
  const constructError = React.useCallback(
    (path: string, error: string) => {
      return `${translatePath("path", path, t)}: ${t("commons:error" + error)}`;
    },
    [t]
  );

  const scrollToError = React.useCallback(() => {
    if (errorComponent.current) {
      setTimeout(() => {
        errorComponent.current.scrollIntoView();
      }, 10);
    }
  }, [errorComponent]);

  const getInitialValueByPath = React.useCallback(
    (path: string, defaultValue: any = "") => {
      return get(contactData, `contactDetails.${path}`) || defaultValue;
    },
    [contactData]
  );

  const initialFormValues = React.useMemo(() => {
    return {
      operatorId: contactId
        ? getInitialValueByPath("operatorId")
        : getUserRole() === USER_ROLE.OPERATOR
        ? getUserOperatorId()
        : "",
      name: {
        fi: contactId ? getInitialValueByPath("name.fi") : "",
        sv: contactId ? getInitialValueByPath("name.sv") : "",
        en: contactId ? getInitialValueByPath("name.en") : ""
      },
      phone: contactId ? getInitialValueByPath("phone") : "",
      email: contactId ? getInitialValueByPath("email") : "",
      address: {
        streetAddress: {
          fi: contactId
            ? getInitialValueByPath("address.streetAddress.fi")
            : "",
          sv: contactId
            ? getInitialValueByPath("address.streetAddress.sv")
            : "",
          en: contactId ? getInitialValueByPath("address.streetAddress.en") : ""
        },
        postalCode: contactId
          ? getInitialValueByPath("address.postalCode")
          : "",
        city: {
          fi: contactId ? getInitialValueByPath("address.city.fi") : "",
          sv: contactId ? getInitialValueByPath("address.city.sv") : "",
          en: contactId ? getInitialValueByPath("address.city.en") : ""
        }
      },
      openingHours: {
        fi: contactId ? getInitialValueByPath("openingHours.fi") : "",
        sv: contactId ? getInitialValueByPath("openingHours.sv") : "",
        en: contactId ? getInitialValueByPath("openingHours.en") : ""
      },
      info: {
        fi: contactId ? getInitialValueByPath("info.fi") : "",
        sv: contactId ? getInitialValueByPath("info.sv") : "",
        en: contactId ? getInitialValueByPath("info.en") : ""
      }
    };
  }, [contactId, getInitialValueByPath, getUserOperatorId, getUserRole]);

  React.useEffect(() => {
    if (form.current) {
      form.current.resetForm();
    }
    setValidationErrors([]);
  }, [isOpen]);

  const formatPayload = (data: any) => {
    const payload = { ...decodeNullValues(data) };
    payload.address = { ...decodeNullValues(data.address) };

    return payload;
  };

  const createContact = async (values: any) => {
    try {
      const response = await createContactMutation({
        variables: {
          contact: { ...formatPayload({ ...values }) }
        },
        update(cache, createdFacilityData) {
          try {
            const contactListData: any = cache.readQuery({
              query: ContactListDocument
            });
            const newContact =
              createdFacilityData &&
              createdFacilityData.data &&
              createdFacilityData.data.createContact;

            if (
              contactListData &&
              contactListData.contacts.results &&
              newContact
            ) {
              const newContacts: any = { ...contactListData.contacts };

              newContacts.results.push(newContact);
              newContacts.results = sortBy(newContacts.results, ["name.fi"]);
              cache.writeQuery({
                query: ContactListDocument,
                data: { contacts: newContacts }
              });
            }
          } catch (err) {
            console.error(err);
          }
        }
      });
      const id = response.data && response.data.createContact.id;
      if (id) {
        onCloseAfterSave(id);
      }
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => createContact(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  const editContact = async (values: any) => {
    try {
      await editContactMutation({
        variables: {
          contact: { ...formatPayload({ ...values, id: contactId }) }
        }
      });

      onClose();
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => editContact(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  if (loading) {
    return <LoadingSpinner isLoading={true} hasOverlay={true} />;
  }

  if (contactError) {
    return <div>{t("commons:error")}</div>;
  }

  const handleCloseModal = () => {
    onClose();
  };

  const handleCloseOperatorModal = () => {
    setIsOperatorModalOpen(false);
  };

  const handleOpenOperatorModal = () => {
    setIsOperatorModalOpen(true);
  };

  return (
    <>
      <OperatorModal
        isOpen={isOperatorModalOpen}
        operatorId={null}
        onClose={handleCloseOperatorModal}
      />
      <Formik
        ref={form}
        initialValues={initialFormValues}
        onSubmit={values => {
          if (contactId) {
            editContact(values);
          } else {
            createContact(values);
          }
        }}
        validationSchema={validationSchema}
      >
        {({ errors, handleSubmit, values }) => {
          const submitHandler = () => {
            if (!isEmpty(errors)) {
              setValidationErrors([
                constructError("Contact", "BasicRequirements")
              ]);
              scrollToError();
            } else {
              setValidationErrors([]);
            }

            handleSubmit();
          };

          return (
            <Modal isOpen={isOpen}>
              <ModalContent>
                <ModalHeader
                  title={
                    contactId ? t("titleEditContact") : t("titleCreateContact")
                  }
                />
                <ModalBody ref={errorComponent}>
                  <FormError errors={validationErrors} />
                  <Row>
                    <Column>
                      <label>{t("labelVisibilityToOperators")}</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={SelectField}
                            isClearable={true}
                            isDisabled={getUserRole() === USER_ROLE.OPERATOR}
                            name="operatorId"
                            options={operatorOptions}
                            placeholder={t("placeholderOperator")}
                            withAddon={true}
                          />
                          {hasPermission(PERMISSIONS.OPERATOR_CREATE) && (
                            <InputGroupButton>
                              <Button
                                isInputGroupButton={true}
                                onClick={handleOpenOperatorModal}
                                type="button"
                              >
                                +
                              </Button>
                            </InputGroupButton>
                          )}
                        </InputGroup>
                      </FormGroup>
                    </Column>
                  </Row>

                  <Row>
                    <Column>
                      <label>{t("labelName")} *</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="name.fi"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>fi</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="name.sv"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>sv</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3} isLastColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="name.en"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>en</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                  </Row>

                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <label>{t("labelPhone")} (*)</label>
                        <Field
                          component={InputField}
                          name="phone"
                          maxLength={255}
                          type="text"
                        />
                        <Row>
                          <Column>
                            <p> {t("messageFillPhoneOrEmail")}</p>
                          </Column>
                        </Row>
                      </FormGroup>
                    </Column>
                  </Row>

                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <label> {t("labelEmail")} (*)</label>
                        <Field
                          component={InputField}
                          name="email"
                          maxLength={255}
                          type="text"
                        />
                      </FormGroup>
                    </Column>
                  </Row>

                  <h4>{t("titleAddress")} </h4>
                  <Row>
                    <Column>
                      <label>{t("labelStreetAddress")} </label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="address.streetAddress.fi"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>fi</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="address.streetAddress.sv"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>sv</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3} isLastColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="address.streetAddress.en"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>en</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                  </Row>

                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <label>{t("labelPostalCode")}</label>
                        <Field
                          component={InputField}
                          name="address.postalCode"
                          maxLength={255}
                          type="text"
                        />
                      </FormGroup>
                    </Column>
                  </Row>

                  <Row>
                    <Column>
                      <label>{t("labelCity")}</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="address.city.fi"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>fi</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="address.city.sv"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>sv</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3} isLastColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={InputField}
                            name="address.city.en"
                            maxLength={255}
                            type="text"
                            withAddon={true}
                          />
                          <InputGroupText>en</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                  </Row>

                  <h4>{t("labelAdditionalInfo")}</h4>
                  <Row>
                    <Column>
                      <label>{t("labelOpeningHours")}</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={TextAreaField}
                            name="openingHours.fi"
                            maxLength={255}
                            withAddon={true}
                          />
                          <InputGroupText>fi</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={TextAreaField}
                            name="openingHours.sv"
                            maxLength={255}
                            withAddon={true}
                          />
                          <InputGroupText>sv</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3} isLastColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={TextAreaField}
                            name="openingHours.en"
                            maxLength={255}
                            withAddon={true}
                          />
                          <InputGroupText>en</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                  </Row>

                  <Row>
                    <Column>
                      <label>{t("labelInfo")}</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={TextAreaField}
                            name="info.fi"
                            maxLength={255}
                            withAddon={true}
                          />
                          <InputGroupText>fi</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={TextAreaField}
                            name="info.sv"
                            maxLength={255}
                            withAddon={true}
                          />
                          <InputGroupText>sv</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                    <Column column={3} isLastColumn={true}>
                      <FormGroup hasLargeMarginBottom={true}>
                        <InputGroup>
                          <Field
                            component={TextAreaField}
                            name="info.en"
                            maxLength={255}
                            withAddon={true}
                          />
                          <InputGroupText>en</InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Column>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    size={ButtonSizes.XSMALL_SECONDARY}
                    onClick={handleCloseModal}
                  >
                    {t("buttonCancel")}
                  </Button>
                  {(contactId
                    ? hasPermission(PERMISSIONS.CONTACT_UPDATE, operatorId)
                    : hasPermission(
                        PERMISSIONS.CONTACT_CREATE,
                        values.operatorId
                      )) && (
                    <Button
                      type="button"
                      size={ButtonSizes.XSMALL}
                      onClick={submitHandler}
                    >
                      {t("buttonSave")}
                    </Button>
                  )}
                </ModalFooter>
              </ModalContent>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactModal;
