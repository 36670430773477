import classNames from "classnames";
import { FieldProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import styles from "./Field.module.css";
import "./SelectField.css";

type Value = string | number | null;

export interface ContactOption {
  email: string | null | undefined;
  label: string;
  name: string;
  phone: string | null | undefined;
  value: string | number;
}

interface ContactOptionProps {
  data: ContactOption;
  innerProps: any;
  isSelected: boolean;
}

const ContactOption = (props: ContactOptionProps) => {
  const { t } = useTranslation("contactList");
  const { data, innerProps, isSelected } = props;
  const { email, name, phone } = data;
  return (
    <div
      {...innerProps}
      className={classNames(styles.selectFieldContactOption, {
        [styles.isSelected]: isSelected
      })}
    >
      <div>{name}</div>
      {phone && (
        <p>
          <small>
            {t("labelPhone")}: {phone}
          </small>
        </p>
      )}
      {email && (
        <p>
          <small>
            {t("labelEmail")}: {email}
          </small>
        </p>
      )}
    </div>
  );
};

const components = {
  Option: ContactOption,
  IndicatorSeparator: null
};

interface Props extends FieldProps {
  alignWithAddon?: boolean;
  className?: string;
  isClearable?: boolean;
  options: ContactOption[];
  placeholder?: string;
  withAddon?: boolean;
}

const ContactSelectField: React.FC<Props> = ({
  alignWithAddon,
  className,
  field,
  form,
  isClearable = true,
  options,
  placeholder,
  withAddon
}) => {
  const { name, value } = field;
  const { errors, setFieldValue, submitCount, touched } = form;
  const handleChange = (val: any) => {
    setFieldValue(name, val ? val.value : null, true);
  };

  const { t } = useTranslation("commons");

  const getValue = (val: Value | Value[]) => {
    return value ? options.find(option => option.value === value) : null;
  };

  return (
    <Select
      className={classNames(className, {
        [styles["form-control-with-addon"]]: withAddon,
        [styles["form-control-align-with-addon"]]: alignWithAddon,
        [styles.selectValidationError]: get(errors, name),
        [styles.selectIsTouched]: get(touched, name),
        [styles.selectIsSubmitted]: submitCount
      })}
      classNamePrefix="select-field"
      components={components}
      options={options}
      isClearable={isClearable}
      isMulti={false}
      onChange={handleChange}
      placeholder={placeholder || t("placeholder")}
      value={getValue(value)}
    />
  );
};

export default ContactSelectField;
