import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Facility } from "../../generated/graphql";
import {
  toPascalCase,
  translateList,
  translateValue
} from "../../util/translateUtils";
import styles from "./FacilityList.module.css";

interface Props {
  facilities: Facility[];
}

const FacilityList: React.FC<Props> = ({ facilities }) => {
  const { t } = useTranslation(["commons", "facilityList"]);

  return (
    <>
      {facilities.map(
        facility =>
          !!facility && (
            <tr key={facility.id}>
              <td className={styles.tableColumn}>
                <Link
                  to={{
                    pathname: `/facilities/${facility.id}`
                  }}
                >
                  {facility.name.fi}
                </Link>
                {facility.softDeletedAt && (
                  <span> ({t("facilityList:messageSoftDeleted")})</span>
                )}
              </td>
              <td className={styles.tableColumn}>
                {facility.status
                  ? translateValue("commons:status", facility.status, t)
                  : ""}
              </td>
              <td className={styles.tableColumn}>
                {facility.usages && facility.usages.length
                  ? translateList("usage", facility.usages, t)
                  : ""}
              </td>
              <td className={styles.tableColumn}>
                {facility.builtCapacity && (
                  <div className={styles.facilityIconsContainer}>
                    {Object.keys(facility.builtCapacity)
                      .filter(key => {
                        const builtCapacity: any = facility.builtCapacity;
                        return builtCapacity[key] && key !== "__typename";
                      })
                      .map(type => {
                        return (
                          <span
                            title={translateValue("capacityType", type, t)}
                            key={type}
                            className={
                              styles["facilityIcon" + toPascalCase(type)]
                            }
                          />
                        );
                      })}
                  </div>
                )}
              </td>
            </tr>
          )
      )}
    </>
  );
};

export default FacilityList;
