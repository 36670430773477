import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  useChangePasswordMutation,
  useLoginMutation
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import { translatePath } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import { useErrorModalContext } from "../Commons/ErrorModal";
import FormError from "../Commons/FormError";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import { useLoginModalContext } from "../Commons/LoginModal";
import Button, { ButtonSizes } from "./Button";
import Column from "./Column";
import Modal from "./Modal";
import ModalBody from "./ModalBody";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import Row from "./Row";

interface PasswordExpiredModalContext {
  closePasswordExpiredModal: () => void;
  isPasswordExpiredModalOpen: boolean;
  openPasswordExpiredModal: () => void;
}

export const PasswordExpiredModalContext = React.createContext<
  PasswordExpiredModalContext
>({
  closePasswordExpiredModal: () => null,
  isPasswordExpiredModalOpen: false,
  openPasswordExpiredModal: () => null
});

export const PasswordExpiredModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isPasswordExpiredModalOpen, setIsModalOpen] = React.useState(false);

  const closePasswordExpiredModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openPasswordExpiredModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <PasswordExpiredModalContext.Provider
      value={{
        closePasswordExpiredModal,
        isPasswordExpiredModalOpen,
        openPasswordExpiredModal
      }}
    >
      {children}
    </PasswordExpiredModalContext.Provider>
  );
};

export const usePasswordExpiredModalContext = (): PasswordExpiredModalContext =>
  React.useContext(PasswordExpiredModalContext);

interface Props {
  isOpen: boolean;
  onClose: any;
}

const PasswordExpiredModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(["login", "commons"]);
  const [changePassword] = useChangePasswordMutation();
  const [login] = useLoginMutation();
  const { hasPermission, getUserId, getUsername, setLoginState } = useAuth();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

  const handleCloseModal = () => {
    onClose();
  };

  const constructError = React.useCallback(
    (path: string, error: string) => {
      return `${translatePath("path", path, t)}: ${t("commons:error" + error)}`;
    },
    [t]
  );

  const isPasswordValid = (data: any) => {
    if (!data.password && !data.passwordConfirm) {
      setValidationErrors([constructError("password", "BadPassword")]);
      return false;
    } else if (data.password !== data.passwordConfirm) {
      setValidationErrors([
        constructError("newPassword", "PasswordConfirmationMismatch")
      ]);
      return false;
    }

    return true;
  };

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: ""
      }}
      onSubmit={async values => {
        if (isPasswordValid(values)) {
          try {
            const userId = getUserId();
            await changePassword({
              variables: {
                id: userId ? userId.toString() : "",
                newPassword: values.password
              }
            });
            // Relogin after updating password
            const loginResponse = await login({
              variables: {
                password: values.password,
                username: getUsername()
              }
            });

            if (loginResponse.data && loginResponse.data.login) {
              setLoginState(loginResponse.data.login);
            }
            onClose();
          } catch (err) {
            handleError(
              err,
              openErrorModal,
              t,
              openLoginModal,
              constructError,
              setValidationErrors
            );
          }
        }
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Modal isOpen={isOpen}>
            <ModalContent>
              <Form>
                <ModalHeader title={t("titleExpiredPassword")} />
                <ModalBody>
                  <FormError errors={validationErrors} />
                  <Row>
                    <Column>
                      <label>{t("textExpiredPassword")}</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <label>{t("labelNewPassword")} *</label>
                      <FormGroup hasLargeMarginBottom={true}>
                        <Field
                          component={InputField}
                          name="password"
                          type="password"
                        />
                      </FormGroup>
                    </Column>
                  </Row>
                  <Row>
                    <Column column={3} isFirstColumn={true}>
                      <label>{t("labelConfirmNewPassword")} *</label>
                      <FormGroup hasLargeMarginBottom={true}>
                        <Field
                          component={InputField}
                          name="passwordConfirm"
                          type="password"
                        />
                      </FormGroup>
                    </Column>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Button
                    size={ButtonSizes.XSMALL_SECONDARY}
                    type="button"
                    onClick={handleCloseModal}
                  >
                    {t("buttonCancel")}
                  </Button>
                  {hasPermission(PERMISSIONS.USER_UPDATE) && (
                    <Button
                      onClick={handleSubmit}
                      size={ButtonSizes.XSMALL}
                      type="button"
                    >
                      {t("buttonReady")}
                    </Button>
                  )}
                </ModalFooter>
              </Form>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default PasswordExpiredModal;
