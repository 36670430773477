import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactListQuery, OperatorListQuery } from "../../generated/graphql";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Breadcrumb from "../Commons/Breadcrumb";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import LinkButton from "../Commons/LinkButton";
import NaviBelow from "../Commons/NaviBelow";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import Table from "../Commons/Table";
import ContactModal from "./ContactModal";
import Info from "./Info";

interface Props {
  contactsData: ContactListQuery;
  operatorsData: OperatorListQuery;
}

const ContactList: React.FC<Props> = ({ contactsData, operatorsData }) => {
  const { hasPermission } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactId, setContactId] = useState<string | null>(null);
  const contacts = contactsData.contacts.results;
  const operators = operatorsData.operators.results;
  const operatorOptions = operators.map(item => ({
    label: item.name.fi ? item.name.fi : "",
    value: item.id
  }));

  const handleOpenModal = (id: string | null) => {
    setContactId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setContactId(null);
  };

  const { t } = useTranslation("contactList");

  return (
    <React.Fragment>
      <Breadcrumb
        canAddContact={hasPermission(PERMISSIONS.CONTACT_CREATE)}
        name={t("titleContacts")}
        onClickNewContact={() => handleOpenModal(null)}
      />
      <Container isContent={true}>
        <Info />
        {!!contacts.length ? (
          <Panel hasBorder={true}>
            <Table isBordered={true} isCondensed={true} isInsidePanel={true}>
              <thead>
                <tr>
                  <th>{t("labelName")}</th>
                  <th>{t("labelPhone")}</th>
                  <th>{t("labelEmail")}</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, i) => (
                  <tr key={contact.id}>
                    <td>
                      <LinkButton onClick={() => handleOpenModal(contact.id)}>
                        {contact.name.fi}
                      </LinkButton>
                    </td>
                    <td>
                      <span>{contact.phone}</span>
                    </td>
                    <td>
                      <span>{contact.email}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Panel>
        ) : (
          <Row hasSmallMargin={true}>
            <Column>
              <p>{t("messageNoContacts")}</p>
            </Column>
          </Row>
        )}

        <ContactModal
          operatorOptions={operatorOptions}
          isOpen={isModalOpen}
          contactId={contactId}
          onClose={handleCloseModal}
          onCloseAfterSave={handleCloseModal}
        />
      </Container>
      <NaviBelow>
        {hasPermission(PERMISSIONS.FACILITY_CREATE) && (
          <Button
            isResponsive={true}
            size={ButtonSizes.SMALL}
            onClick={() => handleOpenModal(null)}
            type="button"
          >
            {t("buttonAddContact")}
          </Button>
        )}
      </NaviBelow>
    </React.Fragment>
  );
};
export default ContactList;
