import React from "react";
import { useTranslation } from "react-i18next";
import { Port } from "../../generated/graphql";
import Button, { ButtonSizes } from "../Commons/Button";
import Column from "../Commons/Column";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";
import ModalHeader from "../Commons/ModalHeader";
import Row from "../Commons/Row";

interface Props {
  isOpen: boolean;
  onClose: any;
  port: Port | null;
}

const PortModal: React.FC<Props> = ({ isOpen, onClose, port }) => {
  const { t } = useTranslation("facilityProfile");
  const streetAddress =
    port && port.address ? port.address.streetAddress : null;
  const city = port && port.address ? port.address.city : null;

  const handleClose = () => {
    onClose();
  };

  const hasAddress = (): boolean => {
    return !!streetAddress || !!city;
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <ModalHeader title={t("titlePortModal")} />
        <ModalBody>
          <Row>
            <Column>
              {port && !!port.entry && <div>{t("portEntry")}</div>}
              {port && !!port.exit && <div>{t("portExit")}</div>}
              {port && !!port.pedestrian && <div>{t("portPedestrian")}</div>}
              {port && !!port.bicycle && <div>{t("portBicycle")}</div>}
            </Column>
          </Row>
          {hasAddress() && (
            <>
              <h4>{t("titlePortAddress")}</h4>
              {!!streetAddress && (
                <Row>
                  <Column column={3} isFirstColumn={true}>
                    <p>
                      <span>{streetAddress.fi}</span> (fi)
                    </p>
                  </Column>
                  <Column column={3}>
                    <p>
                      <span>{streetAddress.sv}</span> (sv)
                    </p>
                  </Column>
                  <Column column={3} isLastColumn={true}>
                    <p>
                      <span>{streetAddress.en}</span> (en)
                    </p>
                  </Column>
                </Row>
              )}
              {!!city && (
                <Row>
                  <Column column={3} isFirstColumn={true}>
                    <p>
                      <span>{city.fi}</span> (fi)
                    </p>
                  </Column>
                  <Column column={3}>
                    <p>
                      <span>{city.sv}</span> (sv)
                    </p>
                  </Column>
                  <Column column={3} isLastColumn={true}>
                    <p>
                      <span>{city.en}</span> (en)
                    </p>
                  </Column>
                </Row>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button size={ButtonSizes.XSMALL} onClick={handleClose} type="button">
            {t("buttonPortOk")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default PortModal;
