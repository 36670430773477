import classNames from "classnames";
import React, { SyntheticEvent } from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import {
  useRegionsWithHubsQuery,
  useUserListQuery
} from "../../generated/graphql";
import logoUrl from "../../resources/assets/img/hsl-liipi-logo2.png";
import { handleError } from "../../util/helpers";
import useAuth from "../../util/useAuth";
import Column from "./Column";
import Container from "./Container";
import { useErrorModalContext } from "./ErrorModal";
import LinkButton from "./LinkButton";
import linkButtonStyles from "./LinkButton.module.css";
import { useLoginModalContext } from "./LoginModal";
import styles from "./Navbar.module.css";
import Row from "./Row";

const TABS = {
  CONTACTS: "/contacts",
  FACILITIES: "/facilities",
  HUBS: "/hubs",
  OPERATORS: "/operators",
  REPORTS: "/reports",
  USERS: "/users"
};

const Navbar: React.FC<WithApolloClient<any>> = ({ client }) => {
  const history = useHistory();
  const { refetch: refetchRegions } = useRegionsWithHubsQuery({
    skip: true
  });
  const { refetch: refetchUsers } = useUserListQuery({
    skip: true
  });
  const { t } = useTranslation(["navbar", "commons"]);
  const { deleteLoginState, isUserLoggedIn } = useAuth();
  const loggedIn = isUserLoggedIn();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();

  const handleLogout = () => {
    deleteLoginState();
    client.resetStore();
    history.push(TABS.HUBS);
  };

  const handleNavLinkClick = async (event: SyntheticEvent, tab: string) => {
    event.preventDefault();
    const openLoginModalWithCallback = () => {
      openLoginModal({
        callbackFn: () => {
          history.push(tab);
        }
      });
    };

    switch (tab) {
      case TABS.REPORTS: {
        try {
          await refetchRegions();
          history.push(tab);
        } catch (e) {
          handleError(e, openErrorModal, t, openLoginModalWithCallback);
        }
        break;
      }
      case TABS.USERS: {
        try {
          await refetchUsers();
          history.push(tab);
        } catch (e) {
          handleError(e, openErrorModal, t, openLoginModalWithCallback);
        }
        break;
      }
      default: {
        if (loggedIn) {
          history.push(tab);
        } else {
          openLoginModalWithCallback();
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.pageHeader, styles.navbarStaticTop)}>
        <Container>
          <Row>
            <Column>
              <img
                className={styles.logo}
                src={logoUrl}
                alt="HSL Liityntäpysäköinti"
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <div className={styles.announcement}>
                <p>Palvelu on siirtynyt osoitteeseen <a href="https://parking.fintraffic.fi/" target="_blank">https://parking.fintraffic.fi/</a>. Lisätietoja uuteen palveluun siirtymisestä saat osoitteesta <a href="https://www.fintraffic.fi/fi/fintraffic/liityntapysakoinnin-tietopalvelu-liipi" target="_blank">https://www.fintraffic.fi/fi/fintraffic/liityntapysakoinnin-tietopalvelu-liipi</a>.</p>
              </div>
            </Column>
          </Row>
          <Row>
            <Column small={8}>
              <ul className={classNames(styles.nav, styles["nav-tabs"])}>
                <li>
                  <NavLink
                    to="/hubs"
                    isActive={(match, location) => {
                      return (
                        location.pathname.startsWith(TABS.HUBS) ||
                        location.pathname.startsWith(TABS.FACILITIES)
                      );
                    }}
                    activeClassName={styles.active}
                  >
                    {t("facilities")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contacts" activeClassName={styles.active}>
                    {t("contacts")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/operators" activeClassName={styles.active}>
                    {t("operators")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/users"
                    activeClassName={styles.active}
                    onClick={event => {
                      handleNavLinkClick(event, TABS.USERS);
                    }}
                  >
                    {t("users")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/reports"
                    activeClassName={styles.active}
                    onClick={event => {
                      handleNavLinkClick(event, TABS.REPORTS);
                    }}
                  >
                    {t("reports")}
                  </NavLink>
                </li>
              </ul>
            </Column>
            <Column small={4}>
              <div className={styles.buttonContainer}>
                <NavLink to="/about" className={linkButtonStyles.linkButton}>
                  {t("tietoPalvelusta")}
                </NavLink>
                <NavLink to="/about-en" className={linkButtonStyles.linkButton}>
                  In English
                </NavLink>
                {!loggedIn ? (
                  <LinkButton onClick={openLoginModal}>{t("login")}</LinkButton>
                ) : (
                  <LinkButton onClick={handleLogout}>{t("logout")}</LinkButton>
                )}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withApollo(Navbar);
