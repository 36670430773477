import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Day,
  FacilityDetails,
  FromUntil,
  OpeningHour
} from "../../generated/graphql";
import { translateValue } from "../../util/translateUtils";
import Column from "../Commons/Column";
import Panel from "../Commons/Panel";
import Row from "../Commons/Row";
import Table from "../Commons/Table";

type DayType = "BUSINESS_DAY" | "SATURDAY" | "SUNDAY";

const dayTypes: DayType[] = ["BUSINESS_DAY", "SATURDAY", "SUNDAY"];

interface Props {
  facilityDetails: FacilityDetails;
}

const OpeningHours: React.FC<Props> = ({ facilityDetails }) => {
  const { t } = useTranslation(["facilityProfile", "commons"]);

  const translateOpenNow = (openingHours: OpeningHour) =>
    openingHours.openNow ? t("labelOpen") : t("labelClosed");

  const getOpenTime = (byDayType: Day | null | undefined, dayType: DayType) => {
    return byDayType && byDayType[dayType];
  };

  const fromUntilEquals = (
    byDate: FromUntil | null | undefined,
    liipyByDate: FromUntil | null | undefined
  ) => {
    return (
      !!byDate &&
      !!liipyByDate &&
      byDate.from === liipyByDate.from &&
      byDate.until === liipyByDate.until
    );
  };

  const getOpenTimeText = (
    byDayType: Day | null | undefined,
    dayType: DayType
  ) => {
    const openTimes = byDayType && byDayType[dayType];
    return openTimes
      ? openTimes.from + "-" + openTimes.until
      : t("labelClosed");
  };

  const showLiipyTimes = (): boolean => {
    const byDayType = facilityDetails.openingHours.byDayType;
    const liipyByDayType = facilityDetails.openingHours.liipyByDayType;

    return (
      !fromUntilEquals(
        getOpenTime(byDayType, "BUSINESS_DAY"),
        getOpenTime(liipyByDayType, "BUSINESS_DAY")
      ) ||
      !fromUntilEquals(
        getOpenTime(byDayType, "SATURDAY"),
        getOpenTime(liipyByDayType, "SATURDAY")
      ) ||
      !fromUntilEquals(
        getOpenTime(byDayType, "SUNDAY"),
        getOpenTime(liipyByDayType, "SUNDAY")
      )
    );
  };

  const showOpeningHoursInfo = () => {
    return !!facilityDetails.openingHours.info;
  };

  const showOpeningHoursUrl = () => {
    return !!facilityDetails.openingHours.url;
  };

  const showOpeningHoursAdditionalInfo = () => {
    return showOpeningHoursInfo() || showOpeningHoursUrl();
  };

  return (
    <>
      <h3>{t("titleOpeningHours")}</h3>
      <Row>
        <Column column={3} isFirstColumn={true}>
          <Panel hasBorder={true}>
            <Table
              isBordered={true}
              isCondensed={true}
              isInsidePanel={true}
              isStriped={true}
            >
              <tbody>
                {dayTypes.map(dayType => {
                  return (
                    <tr key={dayType}>
                      <td>{translateValue("commons:dayType", dayType, t)}</td>
                      <td>
                        {getOpenTimeText(
                          facilityDetails.openingHours.byDayType,
                          dayType
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Panel>
        </Column>
      </Row>
      {showLiipyTimes() && (
        <>
          <h4>{t("titleLiipyOpeningHours")}</h4>
          <Row>
            <Column column={3} isFirstColumn={true}>
              <Panel hasBorder={true}>
                <Table
                  isBordered={true}
                  isCondensed={true}
                  isInsidePanel={true}
                  isStriped={true}
                >
                  <tbody>
                    {dayTypes.map(dayType => {
                      return (
                        <tr key={dayType}>
                          <td>
                            {translateValue("commons:dayType", dayType, t)}
                          </td>
                          <td>
                            {getOpenTimeText(
                              facilityDetails.openingHours.liipyByDayType,
                              dayType
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Panel>
            </Column>
          </Row>
        </>
      )}

      <h4>{t("titleCurrentSituation")}</h4>
      <Row>
        <Column>
          {facilityDetails.openingHours
            ? translateOpenNow(facilityDetails.openingHours)
            : ""}
        </Column>
      </Row>
      {showOpeningHoursAdditionalInfo() && (
        <>
          <h4>{t("titleAdditionalInfo")}</h4>
          {showOpeningHoursInfo() && (
            <Row>
              <Column column={3} isFirstColumn={true}>
                <p>
                  <span>
                    {facilityDetails.openingHours.info
                      ? facilityDetails.openingHours.info.fi
                      : "-"}
                  </span>{" "}
                  (fi)
                </p>
              </Column>
              <Column column={3}>
                <p>
                  <span>
                    {facilityDetails.openingHours.info
                      ? facilityDetails.openingHours.info.sv
                      : "-"}
                  </span>{" "}
                  (sv)
                </p>
              </Column>
              <Column column={3} isLastColumn={true}>
                <p>
                  <span>
                    {facilityDetails.openingHours.info
                      ? facilityDetails.openingHours.info.en
                      : "-"}
                  </span>{" "}
                  (en)
                </p>
              </Column>
            </Row>
          )}

          {showOpeningHoursUrl() && (
            <Row>
              <Column column={3} isFirstColumn={true}>
                <p>
                  <span>
                    {!!facilityDetails.openingHours.url &&
                      !!facilityDetails.openingHours.url.fi && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={facilityDetails.openingHours.url.fi}
                        >
                          {facilityDetails.openingHours.url.fi}
                        </a>
                      )}
                  </span>{" "}
                  (fi)
                </p>
              </Column>
              <Column column={3}>
                <p>
                  <span>
                    {!!facilityDetails.openingHours.url &&
                      !!facilityDetails.openingHours.url.sv && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={facilityDetails.openingHours.url.sv}
                        >
                          {facilityDetails.openingHours.url.sv}
                        </a>
                      )}
                  </span>{" "}
                  (sv)
                </p>
              </Column>
              <Column column={3} isLastColumn={true}>
                <p>
                  <span>
                    {!!facilityDetails.openingHours.url &&
                      !!facilityDetails.openingHours.url.en && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={facilityDetails.openingHours.url.en}
                        >
                          {facilityDetails.openingHours.url.en}
                        </a>
                      )}
                  </span>{" "}
                  (en)
                </p>
              </Column>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default OpeningHours;
