import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
  useFacilitiesGeoJsonQuery,
  useHubProfileQuery,
  useRegionsWithFacilitiesQuery
} from "../../generated/graphql";
import { handleError } from "../../util/helpers";
import useAuth from "../../util/useAuth";
import { useErrorModalContext } from "../Commons/ErrorModal";
import LoadingSpinner from "../Commons/LoadingSpinner";
import HubEdit from "./HubEdit";
interface Params {
  id: string;
}

const HubEditContainer = ({ match }: RouteComponentProps<Params>) => {
  const { getUserRegionId } = useAuth();
  const { t } = useTranslation("commons");
  const { openErrorModal } = useErrorModalContext();
  const {
    data: facilitiesGeoJsonData,
    error: facilitiesGeoJsonError,
    loading: loadingFacilitiesGeoJson
  } = useFacilitiesGeoJsonQuery({ fetchPolicy: "network-only" });

  const {
    data: hubData,
    error: hubError,
    loading: loadingHub
  } = useHubProfileQuery({
    variables: { id: match.params.id }
  });

  const userRegionId = getUserRegionId();
  const { data: regionsWithFacilitiesData } = useRegionsWithFacilitiesQuery({
    skip: !userRegionId
  });

  React.useEffect(() => {
    if (hubError) {
      handleError(hubError, openErrorModal, t);
    }
  }, [hubError, openErrorModal, t]);

  if (loadingHub || loadingFacilitiesGeoJson) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (
    hubError ||
    !hubData ||
    facilitiesGeoJsonError ||
    !facilitiesGeoJsonData
  ) {
    return <div />;
  }

  return (
    <HubEdit
      hubData={hubData}
      facilitiesGeoJsonData={facilitiesGeoJsonData}
      regionsWithFacilitiesData={regionsWithFacilitiesData}
    />
  );
};

export default withRouter(HubEditContainer);
