import * as Yup from "yup";
import { PRICING_METHOD } from "../constants";

export const capacityValidation = Yup.number()
  .transform((cv, ov) => (ov === "" ? null : cv))
  .nullable()
  .integer()
  .min(0)
  .max(100000);

export const multiLanguageValidation = Yup.object().shape(
  {
    fi: Yup.string()
      .max(255)
      .when(["en", "sv"], {
        is: (en, sv) => en || sv,
        then: Yup.string().required()
      }),
    sv: Yup.string()
      .max(255)
      .when(["fi", "en"], {
        is: (en, fi) => en || fi,
        then: Yup.string().required()
      }),
    en: Yup.string()
      .max(255)
      .when(["fi", "sv"], {
        is: (fi, sv) => fi || sv,
        then: Yup.string().required()
      })
  },
  [["en", "sv"], ["fi", "en"], ["fi", "sv"]]
);

export const postalCodeValidation = Yup.string().matches(/^$|\d{5}/);

export const timeValidation = Yup.string()
  .required()
  .matches(/^(?:(?:0?\d|1\d|2[0-3])(?::[0-5]\d)?|24(?::00)?)$/);

export const FacilityEditValidationSchema = Yup.object().shape({
  type: Yup.string().required(),
  name: Yup.object().shape({
    fi: Yup.string()
      .max(255)
      .required(),
    sv: Yup.string()
      .max(255)
      .required(),
    en: Yup.string().max(255)
  }),
  operatorId: Yup.string().required(),
  status: Yup.string().required(),
  statusDescription: multiLanguageValidation,
  location: Yup.mixed().required(),
  openingHours: Yup.object().shape({
    info: multiLanguageValidation,
    url: multiLanguageValidation
  }),
  builtCapacity: Yup.object().when(["type"], {
    is: type => type === "BICYCLE",
    then: Yup.object().shape(
      {
        BICYCLE: capacityValidation.when(["BICYCLE_SECURE_SPACE"], {
          is: bicycleSecureSpace => !bicycleSecureSpace,
          then: capacityValidation
        }),
        BICYCLE_SECURE_SPACE: capacityValidation.when(["BICYCLE"], {
          is: bicycle => !bicycle,
          then: capacityValidation
        })
      },
      [["BICYCLE", "BICYCLE_SECURE_SPACE"]]
    ),
    otherwise: Yup.object().shape(
      {
        CAR: capacityValidation.when(
          ["DISABLED", "ELECTRIC_CAR", "MOTORCYCLE"],
          {
            is: (disabled, electricCar, motorcycle) =>
              !disabled && !electricCar && !motorcycle,
            then: capacityValidation
          }
        ),
        DISABLED: capacityValidation.when(
          ["CAR", "ELECTRIC_CAR", "MOTORCYCLE"],
          {
            is: (car, electricCar, motorcycle) =>
              !car && !electricCar && !motorcycle,
            then: capacityValidation
          }
        ),
        ELECTRIC_CAR: capacityValidation.when(
          ["CAR", "DISABLED", "MOTORCYCLE"],
          {
            is: (car, disabled, motorcycle) => !car && !disabled && !motorcycle,
            then: capacityValidation
          }
        ),
        MOTORCYCLE: capacityValidation.when(
          ["CAR", "DISABLED", "ELECTRIC_CAR"],
          {
            is: (car, disabled, electricCar) =>
              !car && !disabled && !electricCar,
            then: capacityValidation
          }
        )
      },
      [
        ["CAR", "DISABLED"],
        ["CAR", "ELECTRIC_CAR"],
        ["CAR", "MOTORCYCLE"],
        ["DISABLED", "ELECTRIC_CAR"],
        ["DISABLED", "MOTORCYCLE"],
        ["ELECTRIC_CAR", "MOTORCYCLE"]
      ]
    )
  }),
  unavailableCapacities: Yup.array().of(
    Yup.object().shape({
      capacityType: Yup.string().required(),
      usage: Yup.string().required(),
      capacity: capacityValidation
    })
  ),
  pricing: Yup.array().when(["pricingMethod"], {
    is: value => {
      return value !== PRICING_METHOD.PARK_AND_RIDE_247_FREE;
    },
    then: Yup.array().of(
      Yup.object().shape({
        capacityType: Yup.string().required(),
        usage: Yup.string().required(),
        maxCapacity: Yup.number()
          .integer()
          .min(1)
          .max(100000)
          .required(),
        dayType: Yup.string().required(),
        time: Yup.object().shape({
          from: timeValidation,
          until: timeValidation
        })
      })
    )
  }),
  paymentInfo: Yup.object().shape({
    detail: multiLanguageValidation,
    url: multiLanguageValidation
  }),
  contacts: Yup.object().shape({
    operator: Yup.string().required()
  })
});
