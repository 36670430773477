import classNames from "classnames";
import * as React from "react";
import styles from "./Column.module.css";

type ColSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ColumnSize = 1 | 2 | 3;

interface Props {
  className?: string;
  col?: ColSize;
  column?: ColumnSize;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
  large?: ColSize;
  medium?: ColSize;
  small?: ColSize;
  textRight?: boolean;
  xLarge?: ColSize;
  shortColumn?: boolean;
  longColumn?: boolean;
}

const Column: React.FC<Props> = ({
  className,
  children,
  col,
  column,
  isFirstColumn,
  isLastColumn,
  large,
  medium,
  small,
  textRight,
  xLarge,
  shortColumn,
  longColumn
}) => {
  const showDefaultClass = () => {
    return !col && !column && !large && !medium && !small && !xLarge;
  };
  return (
    <div
      className={classNames(
        {
          [styles[`col-${col}`]]: col,
          [styles.column1]: showDefaultClass(),
          [styles[`column${column}`]]: column,
          [styles[`col-lg-${large}`]]: large,
          [styles[`col-md-${medium}`]]: medium,
          [styles[`col-sm-${small}`]]: small,
          [styles[`col-xl-${xLarge}`]]: xLarge,
          [styles.firstColumn]: isFirstColumn,
          [styles.lastColumn]: isLastColumn,
          [styles.textRight]: textRight,
          [styles.shortColumn]: shortColumn,
          [styles.longColumn]: longColumn
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Column;
