import * as React from "react";
import { getStoredLoginState, LoginState } from "../../util/useAuth";

interface AuthContext {
  deleteLoginState: () => void;
  loginState: LoginState | null;
  setLoginState: (loginState: LoginState) => void;
}

export const authContext = React.createContext<AuthContext>({
  deleteLoginState: () => null,
  loginState: null,
  setLoginState: () => null
});

const { Provider } = authContext;

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [storedLoginState, setStoredLoginState] = React.useState(
    getStoredLoginState()
  );

  const setLoginState = (loginState: LoginState) => {
    sessionStorage.setItem("login", JSON.stringify(loginState));
    setStoredLoginState(loginState);
  };

  const deleteLoginState = () => {
    sessionStorage.removeItem("login");
    setStoredLoginState(null);
  };

  return (
    <Provider
      value={{
        loginState: storedLoginState,
        setLoginState,
        deleteLoginState
      }}
    >
      {children}
    </Provider>
  );
};

export default AuthContextProvider;
