import { format as formatDateStr } from "date-fns";
import { isNumber } from "lodash";

export const dateToArray = (date: Date) => {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
};

export const formatDate = (
  date: any,
  format: string = "dd.MM.yyyy"
): string => {
  if (!date) {
    return "";
  }

  const d = isNumber(date) ? date : new Date(date);
  return formatDateStr(d, format);
};
