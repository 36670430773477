import classNames from "classnames";
import { FieldProps } from "formik";
import { get } from "lodash";
import * as React from "react";
// tslint:disable-next-line: no-submodule-imports
import CreatableSelect from "react-select/creatable";
import styles from "./Field.module.css";
import { Option } from "./SelectField";

const components = {
  DropdownIndicator: null
};

const createOption = (label: string): Option => ({
  label,
  value: label
});

interface Props extends FieldProps {
  className?: string;
  placeholder: string;
}

const CreatableInputField: React.FC<Props> = ({
  className,
  field,
  form,
  placeholder
}) => {
  const { name, value } = field;
  const { errors, setFieldValue, submitCount, touched } = form;
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (val: any) => {
    setFieldValue(
      name,
      Array.isArray(val) ? val.map((item: any) => item.label) : []
    );
  };

  const handleInputChange = (inputVal: string) => {
    setInputValue(inputVal);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");

        if (value.indexOf(inputValue) === -1) {
          setFieldValue(name, [...value, inputValue]);
        }
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      className={classNames(className, {
        [styles.selectValidationError]: get(errors, name),
        [styles.selectIsTouched]: get(touched, name),
        [styles.selectIsSubmitted]: submitCount
      })}
      classNamePrefix="select-field"
      components={components}
      inputValue={inputValue}
      isClearable={true}
      isMulti={true}
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={Array.isArray(value) ? value.map(item => createOption(item)) : []}
    />
  );
};

export default CreatableInputField;
