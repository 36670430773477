import classNames from "classnames";
import * as React from "react";
import styles from "./InputGroup.module.css";

interface Props {
  className?: string;
}

const InputGroup: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames(styles["input-group"], className)}>
      {children}
    </div>
  );
};

const InputGroupButton: React.FC<Props> = ({ children, className }) => {
  return (
    <span className={classNames(styles["input-group-button"], className)}>
      {children}
    </span>
  );
};

const InputGroupText: React.FC<Props> = ({ children, className }) => {
  return (
    <span className={classNames(styles["input-group-text"], className)}>
      {children}
    </span>
  );
};

export { InputGroupButton, InputGroupText };

export default InputGroup;
