import React from "react";

import styles from "./ErrorMessage.module.css";

interface Props {
  error: string;
}

const ErrorMessage: React.FC<Props> = ({ error }) => {
  return <div className={styles.errorMessage}>{error}</div>;
};

export default ErrorMessage;
