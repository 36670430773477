import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "./LoadingSpinner.module.css";

interface Props {
  hasOverlay?: boolean;
  isLoading: boolean;
}

const LoadingSpinner: React.FC<Props> = ({ hasOverlay = false, isLoading }) => {
  const { t } = useTranslation("commons");

  return isLoading ? (
    <div
      className={classNames(styles.loadingSpinnerOverlay, {
        [styles.transparentBackground]: !hasOverlay
      })}
    >
      <div>
        <div className={styles.loadingSpinner} />
      </div>
      <span>{t("loading")}</span>
    </div>
  ) : null;
};

export default LoadingSpinner;
