import classNames from "classnames";
import * as React from "react";
import styles from "./Table.module.css";

interface Props {
  className?: string;
  isBordered?: boolean;
  isCondensed?: boolean;
  isHover?: boolean;
  isInsidePanel?: boolean;
  isResponsive?: boolean;
  isStriped?: boolean;
}

const Table: React.FC<Props> = ({
  children,
  className,
  isBordered,
  isCondensed,
  isHover,
  isInsidePanel,
  isResponsive,
  isStriped
}) => {
  return (
    <table
      className={classNames(styles.table, className, {
        [styles["table-bordered"]]: isBordered,
        [styles["table-condensed"]]: isCondensed,
        [styles["table-hover"]]: isHover,
        [styles["table-inside-panel"]]: isInsidePanel,
        [styles["table-responsive"]]: isResponsive,
        [styles["table-striped"]]: isStriped
      })}
    >
      {children}
    </table>
  );
};

export default Table;
