import { isArray, isEqual, isObject } from "lodash";

enum Language {
  "fi" = "fi",
  "en" = "en",
  "sv" = "sv"
}
export const getLocalizedName = (obj: any, lang: string) => {
  if (obj && obj.hasOwnProperty(lang)) {
    return obj[lang as Language];
  }
  return "";
};

export const validatePassword = (str: string) => {
  const con = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/;
  return con.test(str);
};

const hasOnlyEmptyValues = (obj: any) => {
  let isEmpty = true;
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      isEmpty = false;
    }
  });
  return isEmpty;
};
const isEmptyLocalizedObject = (values: any) => {
  return (
    isEqual(Object.keys(values).sort(), ["en", "fi", "sv"]) &&
    hasOnlyEmptyValues(values)
  );
};

export const decodeNullValues = (values: any) => {
  const newValues: any = { ...values };

  Object.keys(newValues).forEach(key => {
    const value: any = newValues[key];

    // If we get an empty string - then check in matchValues for a null value
    // to place on key instead of the empty string
    if (typeof value === "string" && !value) {
      newValues[key] = null;
    } else if (isArray(value)) {
      newValues[key] = value;
    } else {
      if (isObject(value)) {
        // If we get empty localized object - then remove it from object
        if (isEmptyLocalizedObject(value)) {
          delete newValues[key];
        } else {
          newValues[key] = decodeNullValues(value);
        }
      }
    }
  });

  return newValues;
};

export const clearNullValuesFromObject = (values: object) => {
  const newValues: any = { ...values };

  Object.keys(newValues).forEach(key => {
    const value: any = newValues[key];
    if (!value) {
      delete newValues[key];
    }
  });

  return newValues;
};

export const handleError = (
  errors: any,
  openErrorModal: (error: string) => void,
  t: (path: string) => string,
  openLoginModal?: () => void,
  constructError?: (path: string, type: string) => string,
  setValidationErrors?: (errors: string[]) => void,
  scrollToError?: () => void
) => {
  const { graphQLErrors } = errors;
  const validationErrs: string[] = [];
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const extensions = !!err && err.extensions;

      if (err.message === "Too many invalid attempts!") {
        if (constructError !== undefined) {
          validationErrs.push(constructError("", "TooManyAttempts"));
        }
      } else {
        // handle errors differently based on its error code
        switch (
          extensions && extensions.response && extensions.response.status
        ) {
          // TODO: Didn't find out good solution to open login modal on createClient error link (because hooks are not allowed)
          // so I do it temporatily here
          case 400:
            const violations =
              extensions &&
              extensions.response &&
              extensions.response.body &&
              extensions.response.body.violations
                ? extensions.response.body.violations
                : [];
            if (constructError !== undefined && violations.length) {
              violations.forEach((violation: any) => {
                validationErrs.push(
                  constructError(violation.path, violation.type)
                );
              });
            } else {
              // unexpected error
              validationErrs.push(t("commons:error"));
            }

            break;
          case 401:
            if (openLoginModal) {
              openLoginModal();
            }
            break;
          case 403:
            openErrorModal(t("commons:error403"));
            break;
          case 404:
            openErrorModal(t("commons:error404"));
            break;
          default:
            openErrorModal(t("commons:error500"));
            break;
        }
      }
    }
    if (scrollToError && validationErrs.length) {
      scrollToError();
    }
    if (setValidationErrors) {
      setValidationErrors(validationErrs);
    }
  }
};
