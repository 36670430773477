import { Field, Formik } from "formik";
import { get, isEmpty, sortBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  OperatorListDocument,
  useCreateOperatorMutation,
  useEditOperatorMutation,
  useOperatorDetailsQuery,
  useRegionListQuery
} from "../../generated/graphql";
import { decodeNullValues, handleError } from "../../util/helpers";
import { translatePath } from "../../util/translateUtils";
import useAuth, { PERMISSIONS } from "../../util/useAuth";
import Button, { ButtonSizes } from "../Commons/Button";
import CheckboxField from "../Commons/CheckboxField";
import Column from "../Commons/Column";
import { useErrorModalContext } from "../Commons/ErrorModal";
import FormError from "../Commons/FormError";
import FormGroup from "../Commons/FormGroup";
import InputField from "../Commons/InputField";
import InputGroup, { InputGroupText } from "../Commons/InputGroup";
import LoadingSpinner from "../Commons/LoadingSpinner";
import { useLoginModalContext } from "../Commons/LoginModal";
import Modal from "../Commons/Modal";
import ModalBody from "../Commons/ModalBody";
import ModalContent from "../Commons/ModalContent";
import ModalFooter from "../Commons/ModalFooter";
import ModalHeader from "../Commons/ModalHeader";
import Row from "../Commons/Row";
import SelectField, { Option } from "../Commons/SelectField";
interface Props {
  operatorId: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.object().shape({
    fi: Yup.string()
      .max(255)
      .required(),
    sv: Yup.string()
      .max(255)
      .required(),
    en: Yup.string()
      .max(255)
      .required()
  })
});

const OperatorModal: React.FC<Props> = ({ isOpen, onClose, operatorId }) => {
  const form = React.useRef<any>(null);
  const { t } = useTranslation(["operatorList", "commons", "facilityEdit"]);
  const {
    data: operatorData,
    error: operatorError,
    loading
  } = useOperatorDetailsQuery({
    skip: !operatorId,
    variables: { id: operatorId ? operatorId.toString() : "" }
  });

  const { data: regionData } = useRegionListQuery();
  const allRegions = regionData && regionData.regions ? regionData.regions : [];
  const regionOptions: Option[] = allRegions.map(item => {
    if (!item) {
      return { label: "", value: "" };
    }
    return {
      label: item.name ? item.name.fi || "" : "",
      value: item.id
    };
  });

  const [createOperatorMutation] = useCreateOperatorMutation();
  const [editOperatorMutation] = useEditOperatorMutation();
  const { openErrorModal } = useErrorModalContext();
  const { openLoginModal } = useLoginModalContext();
  const { getUserOperatorId, hasPermission } = useAuth();
  const userOperatorId = getUserOperatorId();
  const errorComponent = React.useRef<any>(null);
  const [validationErrors, setValidationErrors] = React.useState<string[]>([]);

  const constructError = React.useCallback(
    (path: string, error: string) => {
      return `${translatePath("path", path, t)}: ${t("commons:error" + error)}`;
    },
    [t]
  );

  const scrollToError = React.useCallback(() => {
    if (errorComponent.current) {
      setTimeout(() => {
        errorComponent.current.scrollIntoView();
      }, 10);
    }
  }, [errorComponent]);

  const getInitialValueByPath = React.useCallback(
    (path: string, defaultValue: any = "") => {
      return get(operatorData, `operatorDetails.${path}`) || defaultValue;
    },
    [operatorData]
  );

  const getDefaultRegion = (regionId: number, regions: any) => {
    for (const reg of regions) {
      if (Number(regionId) === Number(reg.id)) {
        return { label: reg.name && reg.name.fi, value: reg.id };
      }
    }
    return { label: null, value: null };
  };

  const initialFormValues = React.useMemo(() => {
    return {
      name: {
        fi: operatorId ? getInitialValueByPath("name.fi") : "",
        sv: operatorId ? getInitialValueByPath("name.sv") : "",
        en: operatorId ? getInitialValueByPath("name.en") : ""
      },
      region: getDefaultRegion(
        Number(operatorData && operatorData.operatorDetails.regionId),
        regionData && regionData.regions ? regionData.regions : []
      )
    };
  }, [operatorId, getInitialValueByPath, operatorData, regionData]);

  React.useEffect(() => {
    if (form.current) {
      form.current.resetForm();
    }
    setValidationErrors([]);
  }, [isOpen]);

  if (loading) {
    return <LoadingSpinner isLoading={true} hasOverlay={true} />;
  }

  if (operatorError) {
    return <div>{t("error")}</div>;
  }

  const handleCloseModal = () => {
    onClose();
  };

  const formatPayload = (data: any) => {
    const payload = { ...decodeNullValues(data) };
    return {
      id: payload.id,
      name: payload.name,
      regionId: payload.region.label ? parseFloat(payload.region.value) : null
    };
  };

  const createOperator = async (values: any) => {
    try {
      const response = await createOperatorMutation({
        variables: {
          operator: { ...formatPayload(values) }
        },
        update(cache, createdOperatorData) {
          try {
            const operatorListData: any = cache.readQuery({
              query: OperatorListDocument
            });
            const newOperator =
              createdOperatorData &&
              createdOperatorData.data &&
              createdOperatorData.data.createOperator;

            if (
              operatorListData &&
              operatorListData.operators.results &&
              newOperator
            ) {
              const newOperators: any = { ...operatorListData.operators };

              newOperators.results.push(newOperator);
              newOperators.results = sortBy(newOperators.results, ["name.fi"]);
              cache.writeQuery({
                query: OperatorListDocument,
                data: { operators: newOperators }
              });
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
      const id = response.data && response.data.createOperator.id;
      if (id) {
        onClose();
      }
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => createOperator(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  const editOperator = async (values: any) => {
    try {
      await editOperatorMutation({
        variables: {
          operator: {
            ...formatPayload({
              ...values,
              id: operatorId
            })
          }
        }
      });

      onClose();
    } catch (err) {
      handleError(
        err,
        openErrorModal,
        t,
        () =>
          openLoginModal({
            callbackFn: () => editOperator(values)
          }),
        constructError,
        setValidationErrors,
        scrollToError
      );
    }
  };

  return (
    <Formik
      ref={form}
      initialValues={initialFormValues}
      onSubmit={values => {
        if (operatorId) {
          editOperator(values);
        } else {
          createOperator(values);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ errors, handleSubmit, values }) => {
        const isRegionsErrornous = () => {
          if (values && values.region.label) {
            if (!values.region.value) {
              return true;
            } else {
              return false;
            }
          }
        };
        const submitHandler = () => {
          if (isRegionsErrornous()) {
            setValidationErrors([
              constructError("Operator", "BasicRequirements")
            ]);
            return;
          }
          if (!isEmpty(errors)) {
            setValidationErrors([
              constructError("Operator", "BasicRequirements")
            ]);
            scrollToError();
          } else {
            setValidationErrors([]);
          }

          handleSubmit();
        };

        return (
          <Modal isOpen={isOpen} zIndex={1050}>
            <ModalContent>
              <ModalHeader
                title={
                  operatorId ? t("titleEditOperator") : t("titleCreateOperator")
                }
              />
              <ModalBody ref={errorComponent}>
                <FormError errors={validationErrors} />
                <Row>
                  <Column>
                    <label>{t("labelName")} *</label>
                  </Column>
                </Row>
                <Row>
                  <Column column={3} isFirstColumn={true}>
                    <FormGroup>
                      <InputGroup>
                        <Field
                          component={InputField}
                          maxLength={255}
                          name="name.fi"
                          type="text"
                          withAddon={true}
                        />
                        <InputGroupText>fi</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                  <Column column={3} isFirstColumn={true}>
                    <FormGroup>
                      <InputGroup>
                        <Field
                          component={InputField}
                          maxLength={255}
                          name="name.sv"
                          type="text"
                          withAddon={true}
                        />
                        <InputGroupText>sv</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                  <Column column={3} isFirstColumn={true}>
                    <FormGroup>
                      <InputGroup>
                        <Field
                          component={InputField}
                          maxLength={255}
                          name="name.en"
                          type="text"
                          withAddon={true}
                        />
                        <InputGroupText>en</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Column>
                </Row>
                {(operatorId
                  ? hasPermission(PERMISSIONS.OPERATOR_UPDATE, operatorId)
                  : hasPermission(PERMISSIONS.OPERATOR_CREATE)) && (
                  <Row>
                    <Column>
                      <Field
                        component={CheckboxField}
                        name="region.label"
                        disabled={userOperatorId}
                        label={t("labelRegion")}
                      />
                    </Column>
                    {values.region && values.region.label && (
                      <Column>
                        <Field
                          isClearable={true}
                          isMulti={false}
                          isDisabled={userOperatorId}
                          name="region.value"
                          options={regionOptions}
                          placeholder={t("facilityEdit:placeholderRegions")}
                          component={SelectField}
                        />
                      </Column>
                    )}
                  </Row>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={handleCloseModal}
                  size={ButtonSizes.XSMALL_SECONDARY}
                  type="button"
                >
                  {t("buttonCancel")}
                </Button>
                {(operatorId
                  ? hasPermission(PERMISSIONS.OPERATOR_UPDATE, operatorId)
                  : hasPermission(PERMISSIONS.OPERATOR_CREATE)) && (
                  <Button
                    onClick={submitHandler}
                    size={ButtonSizes.XSMALL}
                    type="button"
                  >
                    {t("buttonSave")}
                  </Button>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
export default OperatorModal;
