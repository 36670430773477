import * as React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../Commons/Breadcrumb";
import Column from "../Commons/Column";
import Container from "../Commons/Container";
import Row from "../Commons/Row";

const AboutEn: React.FC<any> = () => {
  const { t } = useTranslation("aboutEn");
  return (
    <>
      <Breadcrumb name={t("about")} />
      <Container isContent={true}>
        <Row hasSmallMarginBottom={true}>
          <Column>
            <p>{t("aboutInfo")}</p>
            <ul>
              <li>
                <a href="https://www.hsl.fi/kayttoehdot">Terms of Service</a>
              </li>
              <li>
                <a href="/docs/" target="_blank">
                  Documentation for developers (API-description)
                </a>
                {". "}
                The open source service also has it's own
                <a href="https://github.com/HSLdevcom/parkandrideAPI">
                  {" "}
                  GitHub repository
                </a>
              </li>
              <li>
                This UI supports the newest browser versions (IE 11+, Firefox
                29+, Chrome 35+, Safari 7+)
              </li>
              <li>
                HSL’s open data is licensed under the
                <a href="http://creativecommons.org/licenses/by/4.0/deed.fi">
                  {" "}
                  Creative Commons BY 4.0 International -license
                </a>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default AboutEn;
