import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  useFacilitiesGeoJsonQuery,
  useRegionsWithFacilitiesQuery
} from "../../generated/graphql";
import useAuth from "../../util/useAuth";
import LoadingSpinner from "../Commons/LoadingSpinner";
import HubEdit from "../HubEdit/HubEdit";

const HubCreateContainer = () => {
  const { getUserRegionId } = useAuth();
  const { t } = useTranslation("commons");
  const {
    data: facilitiesGeoJsonData,
    error: facilitiesGeoJsonError,
    loading: loadingFacilitiesGeoJson
  } = useFacilitiesGeoJsonQuery({ fetchPolicy: "network-only" });

  const userRegionId = getUserRegionId();
  const { data: regionsWithFacilitiesData } = useRegionsWithFacilitiesQuery({
    skip: !userRegionId
  });

  if (loadingFacilitiesGeoJson) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (facilitiesGeoJsonError || !facilitiesGeoJsonData) {
    return <div>{t("error")}</div>;
  }

  return (
    <HubEdit
      facilitiesGeoJsonData={facilitiesGeoJsonData}
      regionsWithFacilitiesData={regionsWithFacilitiesData}
    />
  );
};

export default HubCreateContainer;
