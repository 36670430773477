import { useContext } from "react";
import { authContext } from "../components/Commons/AuthContext";

export interface LoginState {
  operatorId?: string | null;
  passwordExpireInDays: number;
  permissions: string[];
  regionId?: string | null;
  role: string;
  token: string;
  userId: string;
  username: string;
}

interface TokenParts {
  type: string;
  user: number;
  loggedAt: number;
  hmac: string;
}

export const PERMISSIONS = {
  ALL_OPERATORS: "ALL_OPERATORS",
  FACILITY_CREATE: "FACILITY_CREATE",
  FACILITY_DELETE: "FACILITY_DELETE",
  FACILITY_SOFT_DELETE: "FACILITY_SOFT_DELETE",
  FACILITY_UPDATE: "FACILITY_UPDATE",
  OPERATOR_CREATE: "OPERATOR_CREATE",
  OPERATOR_UPDATE: "OPERATOR_UPDATE",
  CONTACT_CREATE: "CONTACT_CREATE",
  CONTACT_UPDATE: "CONTACT_UPDATE",
  USER_CREATE: "USER_CREATE",
  USER_UPDATE: "USER_UPDATE",
  USER_VIEW: "USER_VIEW",
  FACILITY_UTILIZATION_UPDATE: "FACILITY_UTILIZATION_UPDATE",
  HUB_CREATE: "HUB_CREATE",
  HUB_UPDATE: "HUB_UPDATE",
  REPORT_GENERATE: "REPORT_GENERATE"
};

const useAuth = (): {
  deleteLoginState: () => void;
  getPasswordExpireInDays: () => number | null;
  getUserId: () => string | null;
  getUsername: () => string | null;
  getUserOperatorId: () => string | null | undefined;
  getUserRegionId: () => string | null | undefined;
  getUserRole: () => string | null;
  hasPermission: (permissions: string, operatorId?: string | null) => boolean;
  isTokenExpired: () => boolean;
  isUserLoggedIn: () => boolean;
  loginState: LoginState | null;
  setLoginState: (loginState: LoginState) => void;
} => {
  const { deleteLoginState, loginState, setLoginState } = useContext(
    authContext
  );

  const getPasswordExpireInDays = (): number | null => {
    return loginState ? loginState.passwordExpireInDays : null;
  };

  const getUserId = (): string | null => {
    return loginState && loginState.userId
      ? loginState.userId.toString()
      : null;
  };

  const getUsername = (): string | null => {
    return loginState ? loginState.username : null;
  };

  const getUserOperatorId = (): string | null | undefined => {
    return loginState ? loginState.operatorId : null;
  };

  const getUserRegionId = (): string | null | undefined => {
    return loginState ? loginState.regionId : null;
  };

  const getUserRole = (): string | null => {
    return loginState ? loginState.role : null;
  };

  const hasPermission = (
    permission: string,
    operatorId?: string | undefined | null
  ): boolean => {
    const permissions = loginState ? loginState.permissions : [];
    const savedOperatorId = loginState ? loginState.operatorId : null;
    return permissions.indexOf(PERMISSIONS.ALL_OPERATORS) === -1 &&
      (savedOperatorId && operatorId !== undefined)
      ? savedOperatorId === operatorId && permissions.indexOf(permission) !== -1
      : permissions.indexOf(permission) !== -1;
  };

  const isUserLoggedIn = (): boolean => {
    return !!loginState;
  };

  const isTokenExpired = (): boolean => {
    const tokenParts = loginState ? parseToken(loginState.token) : null;
    const loggedAt = tokenParts ? tokenParts.loggedAt : 0;
    // Add 1 hour to logged at timestamp to get expiration time
    const expiresAt = loggedAt + 1000 * 60 * 60;
    return new Date(expiresAt) < new Date();
  };

  const parseToken = (token: string | null): TokenParts | null => {
    const tokenParts = token ? token.split("|") : [];

    return tokenParts.length === 4
      ? {
          type: tokenParts[0],
          user: Number(tokenParts[1]),
          loggedAt: Number(tokenParts[2]),
          hmac: tokenParts[3]
        }
      : null;
  };

  return {
    deleteLoginState,
    getUserId,
    getUsername,
    getPasswordExpireInDays,
    getUserOperatorId,
    getUserRegionId,
    getUserRole,
    hasPermission,
    isTokenExpired,
    isUserLoggedIn,
    loginState,
    setLoginState
  };
};

export const getStoredLoginState = (): LoginState | null => {
  const loginState = sessionStorage.getItem("login");
  if (loginState) {
    return JSON.parse(loginState);
  }
  return null;
};

export default useAuth;
